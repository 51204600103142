import React, { useEffect, useState } from 'react'
import Invitations from './Invitations'
import UploadStudents from './UploadStudents'
import ListStudents from './ListStudents'
import Budge, { SIZE, VARIANT } from '../../../Components/Badge'
import { useParams } from 'react-router-dom'
import { useLazyGetCourseWorkQuery } from '../../../services/coursworks.api'
import CustomSpin from '../../../Components/customSpin/CustomSpin'
import {
    useLazyGetClassroomQuery,
    useLazyGetClassroomsByOrganisationQuery,
    useLazyGetClassroomsByTeacherAndOrganisationQuery,
} from '../../../services/classroom.api'
import useGetOrganization from '../../../hooks/useGetOrganization'
import { useGetMeQuery } from '../../../services/auth.api'
import useTypeUser from '../../../hooks/useTypeUser'

const Students = () => {
    const params = useParams()
    const { organisation, isLoading: isOrganisationLoading } = useGetOrganization();
    const { data: authenticatedUser } = useGetMeQuery();
    const { isTeacher, isAdmin } = useTypeUser();
    const [classrooms, setClassrooms] = useState([])

    const [triggerGetClassroom, { data: classroom, isFetching }] =
        useLazyGetClassroomQuery()


        console.log("classroom",classroom);
        

    const [
        triggerAllClassrooms,
        { data: allClassrooms, isLoading: isLoadingClassroom },
    ] = useLazyGetClassroomsByOrganisationQuery()

    const [triggerClassroomsByTeacher, { data: teacherClassrooms, isLoading: isLoadingTeacherClassroom }] =
        useLazyGetClassroomsByTeacherAndOrganisationQuery();

    useEffect(() => {
        if (params?.classId) triggerGetClassroom(params.classId)
    }, [])

    useEffect(() => {
        if (authenticatedUser) {
            if (isTeacher && organisation) {
                triggerClassroomsByTeacher({ organisationId: organisation.id, teacherId: authenticatedUser.id })
            } else if (isTeacher && !organisation) {
                triggerClassroomsByTeacher({ teacherId: authenticatedUser.id })
            }

            if (isAdmin) {
                triggerAllClassrooms({ organisationId: organisation.id })
            }
        }
    }, [organisation]);

    useEffect(() => {
        if (isTeacher && teacherClassrooms) {
            setClassrooms(teacherClassrooms['hydra:member'])
        } else if (isAdmin && allClassrooms) {
            setClassrooms(allClassrooms['hydra:member'])
        }

    }, [teacherClassrooms, allClassrooms]);

    

    if (isFetching) return <CustomSpin />

    return (
        <div className="admin-students">
            <div className="flex gap-4 align-end">
                <h1 className="h1">Invite Students</h1>
                {classroom && (
                    <Budge
                        variant={VARIANT.PRIMARY_ROUNDED}
                        size={SIZE.XS}
                        text={classroom.name}
                    />
                )}
            </div>
            <div className="students-container">
                <Invitations classroom={classroom} allClassrooms={classrooms} />
                <UploadStudents classroom={classroom} allClassrooms={classrooms} />
                <ListStudents classroom={classroom} allClassrooms={classrooms} />
            </div>
        </div>
    )
}
export default Students
