import { Modal as AntModal } from "antd";
import styles from "./modal.module.scss";
import Button, { VARIANT, SIZE } from "../Button";
import { IconClose } from "../../assets/icons";
const Modal = ({
  open,
  onCancel,
  onClose,
  onConfirm,
  ConfirmText,
  cancelText,
  title,
  closable = true,
  children,
  footer,
  ...props
}) => {
  return (
    <AntModal
      open={open}
      onCancel={onCancel}
      onClose={onClose}
      {...props}
      closable={false}
      footer={null}
    >
      <div className={styles.modal}>
        {title ? <div className={styles.modalHeader}>{title}</div> : <div />}
        {closable && (
          <button className={styles.close} onClick={onClose}>
            <IconClose />
          </button>
        )}
      </div>
      <div className={styles.modalContent}>{children}</div>
      <div className={styles.modalFooter}>
        <Button
          variant={VARIANT.DEFAULT}
          size={SIZE.MD}
          text={cancelText}
          onClick={onCancel}
          block
        />
        <Button
          variant={VARIANT.PRIMARY}
          size={SIZE.MD}
          text={ConfirmText}
          onClick={onConfirm}
          block
        />
      </div>
    </AntModal>
  );
};

export default Modal;
