import {useEffect, useState} from "react";
import styles from "./importAssignment.module.css";
import {Formik, Field} from "formik";
import {Upload} from "antd";
import {useIntl} from "react-intl";
import CloudDownload from "../../assets/icons/CloudDownload";
import {
    useGenerateCourseworkWithIaMutation,
} from "../../services/coursworks.api";
import * as Yup from "yup";
import {useSelector} from "react-redux";
import {selectClassroom} from "../../redux/app/app.selectors";
import ErrorMessage from "../ErrorMessage";
import { useDispatch } from "react-redux";
import { setNotification } from "../../redux/app/app.slice";
import CourseworkCreation from "../courseworkCreation";
import { FormattedMessage } from "react-intl";
import { LoadingOutlined } from "@ant-design/icons";

const { Dragger } = Upload;

const validationSchema = Yup.object().shape({
    image: Yup.mixed().required(<FormattedMessage id="form.field.error.required" values={{ field: "file" }}/>),
});


const defaultInitialValues = {
    contextFile: null,
}

const ImportAssignment = ({
  creationMode,
  initialValues = defaultInitialValues,
  isEditing = false,
}) => {
  const [toUpdate, setToUpdate] = useState(false);
  const [IaInitialValues, setIaInitialValues] = useState(null);
  const [generateCourseworkWithIa, {isLoading : isGenerating}] = useGenerateCourseworkWithIaMutation()
    const intl = useIntl();

const classroom = useSelector(selectClassroom);
const dispatch = useDispatch();

const handleCancel = () => { setIsVisible(false); };

const onHandleSubmit = async (values) => {
        const formData = new FormData();

        formData.append("image", values.image);
        formData.append("type", "Upload");
        formData.append("grade", classroom.grade);

        const {data, error } = await generateCourseworkWithIa({formData: formData});
        if (data) {
          const formattedQuestions = data['hydra:member'][0]?.quiz?.questions?.map((question)=> {
            return processQuestion(question)
          })

          const formattedData = {
            ...data['hydra:member'][0],
            type : data['hydra:member'][0]?.type || 'Quiz',
            quiz : {
              questions : formattedQuestions
            }
          }

          console.log('data form', formattedData);
          setIaInitialValues(formattedData);
            setToUpdate(true);
        }
        if (error) {
            console.log("error", error);
            dispatch(setNotification({
                message: intl.formatMessage({id: "notification.error.import"}, {type: "coursework"}),
                type: 'error'
            }))
        }
    };

  const processQuestion = (question) => {
    return {
      ...question,
      isJustification: !!question?.isJustification,
      options: !!question?.options?.length ? question?.options : [],
      links: !!question?.links?.length ? question?.links : [],
    };
  };

    const [visible, setIsVisible] = useState(false);

    const propsFileContext = {
    name: "file",
    multiple: true,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        console.log("done");
      } else if (status === "error") {
        console.log("error");
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };




  return (
    <>
        {toUpdate ?  <CourseworkCreation PassedDefaultInitialValues={IaInitialValues}/>:<div className={styles.newAssignmentPage}>
            <div className={styles.newAssignmentContainer}>
                <div className={styles.newAssignmentContent}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onHandleSubmit}
                        enableReinitialize
                        validationSchema={validationSchema}
                    >
                        {({
                              values,
                              handleBlur,
                              setFieldValue,
                              handleSubmit,
                              errors,
                          }) => (
                            <form className={styles.newAssignmentForm} onSubmit={handleSubmit}>
                                <div className={styles.newAssignmentHeader}>
                                    <div className={styles.newAssignmentTitle}>
                                        <h1>
                                            {intl.formatMessage({
                                                id: "assignment.import.page.title",
                                            })}
                                        </h1>
                                    </div>

                                </div>
                                {/** context file **/}
                                <div className={styles.uploadFileContextBlock}>
                                    <label>
                                        {intl.formatMessage({
                                            id: "assignment.create.form.field.label.contextFile",
                                        })}
                                    </label>
                                    <div>
                                        <Dragger
                                            multiple={false}
                                            beforeUpload={(file, fileList) => {
                                                setFieldValue("image",  file);
                                                return false;
                                            }}
                                            onRemove={() => {
                                                setFieldValue("image", null);
                                            }}
                                            {...propsFileContext}
                                            className={styles.uploadFileContext}

                                        >
                                            <div
                                                className={`ant-upload-drag-icon ${styles.fileCloudUpload}`}
                                            >
                                                <CloudDownload className={styles.iconCloudUpload} />
                                            </div>
                                            <p className={styles.uploadTextContent}>
                                                <b>
                                                    {intl.formatMessage({
                                                        id: "assignment.create.form.field.label.import.title",
                                                    })}
                                                </b>
                                                {intl.formatMessage({
                                                    id: "assignment.create.form.field.label.import.text",
                                                })}
                                            </p>
                                        </Dragger>
                                        {errors.image && (<ErrorMessage message={errors.image} />)}
                                    </div>
                                </div>
                                <div className={styles.blockBtn}>
                                    <button type="submit" className={styles.submitButton} disabled={isGenerating}>
                                        {intl.formatMessage({ id: "button.import" })} {" "}
                                      {isGenerating ? <LoadingOutlined /> : null}
                                    </button>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>

        </div>}

    </>
  );
};

export default ImportAssignment;