import React, { useState } from "react";
import styles from "./aiGenerateRubric.module.css";
import {Field, Formik} from "formik";
import { Select, Upload } from "antd";
import {
    LoadingOutlined,
} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import {useIntl} from "react-intl";
import CloudDownload from "../../assets/icons/CloudDownload";
import * as Yup from "yup";
import {useSelector} from "react-redux";
import {selectClassroom} from "../../redux/app/app.selectors";
import {useGenerateRubricWithIaMutation} from "../../services/rubrics.api";
import ErrorMessage from "../ErrorMessage";
import { useDispatch } from "react-redux";
import {setNotification} from "../../redux/app/app.slice";
import EditRubricModal from "../rubricActions/editRubricModal/EditRubricModal";
import SuffixIcon from "../../assets/icons/SuffixIcon";

const { Dragger } = Upload;

const validationSchema = Yup.object().shape({

});


const defaultInitialValues = {
    title: "",
    objective: "",
    format: 'table',
    type: 'Essay',
    image: null,

}

const AiGenerateRubric = ({
  initialValues = defaultInitialValues,
}) => {
  const [toUpdate, setToUpdate] = useState(false);
  const [IaInitialValues, setIaInitialValues] = useState(null);
  const [generateRubricWithIa, {isLoading: isLoadingGenerateRubricWithIa}] = useGenerateRubricWithIaMutation()
  const intl = useIntl();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const classroom = useSelector(selectClassroom);
  const dispatch = useDispatch()
  const handleCancel = () => {
    setIsVisible(false);
  };
  const _handleSubmit = async (values) => {
    const formData = new FormData();
    formData.append("objective", values.objective);
    formData.append("title", values.title);
    formData.append("image", values.image);
    formData.append("type", values.type);
    formData.append("format", values.format);
    formData.append("grade", classroom.grade);

    const { data, error } = await generateRubricWithIa({ formData: formData });
    console.log("data", data);
    if (data) {
      setToUpdate(true);
      setIsEditModalOpen(true);
      setIaInitialValues({ ...values, ...data });
    }
    if (error) {
      console.log("error", error);
      dispatch(
        setNotification({
          type: "error",
          message: intl.formatMessage(
            { id: "notification.error.generate" },
            { type: "rubric" },
          ),
        }),
      );
    }
  };

  const [visible, setIsVisible] = useState(false);

    const propsFileContext = {
    name: "file",
    multiple: true,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        console.log("done");
      } else if (status === "error") {
        console.log("error");
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };




  return (
      <>
          <div className={styles.newAssignmentPage}>
              <div className={styles.newAssignmentContainer}>
                  <div className={styles.newAssignmentContent}>
                      <Formik
                          initialValues={initialValues}
                          onSubmit={_handleSubmit}
                          enableReinitialize
                          validationSchema={validationSchema}
                      >
                          {({
                              values,
                              handleBlur,
                              setFieldValue,
                              handleSubmit,
                              errors,
                          }) => (
                            <form className={styles.newAssignmentForm} onSubmit={handleSubmit}>
                              <div className={styles.newAssignmentHeader}>
                                <div className={styles.newAssignmentTitle}>
                                  <h1>
                                    {intl.formatMessage({
                                      id: "rubric.create.form.title",
                                    })}
                                  </h1>
                                </div>

                              </div>
                              {/* Basic Fields remain the same */}
                              <div className={styles.formField}>
                                <label>
                                  {intl.formatMessage({
                                    id: "rubric.create.form.field.label.name",
                                  })}
                                </label>
                                <div>
                                  <Field
                                    name={"title"}
                                    placeholder={intl.formatMessage({
                                      id: "rubric.create.form.field.placeholder.name",
                                    })}
                                    className={styles.formFieldInput}
                                  />

                                  {errors.name && <ErrorMessage message={errors.name} />}
                                </div>
                              </div>
                              {/** objective */}
                              <div className={styles.objectiveBlock}>
                                <label>
                                  {intl.formatMessage({
                                    id: "rubric.create.form.field.label.objective",
                                  })}
                                </label>
                                <div className={styles.objectiveFileContainer}>
                                  <Field
                                    as="textarea"
                                    name={"objective"}
                                    placeholder={intl.formatMessage({
                                      id: "rubric.create.form.field.placeholder.objective",
                                    })}
                                    className={styles.textAreaObjective}
                                  />
                                </div>
                              </div>
                              <div className={styles.formField}>
                                <label>
                                  {intl.formatMessage({
                                    id: "rubric.create.form.field.label.type",
                                  })}
                                </label>
                                <Select
                                  value={values?.type}
                                  className={styles.formFieldInputSelect}
                                  onChange={(value) => setFieldValue('type', value)}
                                  suffixIcon={<SuffixIcon />}
                                  options={[
                                    {value: 'ShortAnswer', label: intl.formatMessage({id: "rubric.create.form.field.type.option.shortAnswer"})},
                                    {value: 'Essay', label: intl.formatMessage({id: "rubric.create.form.field.type.option.essay"})},
                                  ]}
                                  placeholder={intl.formatMessage({id: "rubric.create.form.field.placeholder.type"})}
                                  defaultValue="Essay"
                                />
                              </div>
                              {/** context file **/}
                              <div className={styles.uploadFileContextBlock}>
                                <label>
                                  {intl.formatMessage({
                                    id: "assignment.create.form.field.label.contextFile",
                                  })}
                                </label>
                                <Dragger
                                  multiple={false}
                                  beforeUpload={(file, fileList) => {
                                    setFieldValue("image", file);
                                    return false;
                                  }}
                                  onRemove={() => {
                                    setFieldValue("image", null);
                                  }}
                                  {...propsFileContext}
                                  className={styles.uploadFileContext}

                                >
                                  <div
                                    className={`ant-upload-drag-icon ${styles.fileCloudUpload}`}
                                  >
                                    <CloudDownload className={styles.iconCloudUpload} />
                                  </div>
                                  <p className={styles.uploadTextContent}>
                                    <b>
                                      {intl.formatMessage({
                                        id: "assignment.create.form.field.label.uploadAi.title",
                                      })}
                                    </b>
                                    {intl.formatMessage({
                                      id: "assignment.create.form.field.label.uploadAi.text",
                                    })}
                                  </p>
                                </Dragger>
                              </div>
                              <div className={styles.blockBtn}>
                                <button type="submit" className={styles.submitButton}>
                                  {intl.formatMessage({ id: "button.generate" })} {" "}
                                  {isLoadingGenerateRubricWithIa ? <LoadingOutlined /> : ""}
                                </button>
                              </div>
                            </form>
                          )}
                      </Formik>
                  </div>
              </div>

            {toUpdate &&
              <EditRubricModal
                isEditModalOpen={isEditModalOpen}
                setIsEditModalOpen={setIsEditModalOpen}
                rubricItemDataEdit={IaInitialValues}
                isEdit={false}
              />}

          </div>

      </>
  );
};

export default AiGenerateRubric;