import React from "react";

const CloudUpload = ({ size = 36, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 47 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="3.5" y="3" width="40" height="40" rx="20" fill="#F2F4F7" />
      <rect
        x="3.5"
        y="3"
        width="40"
        height="40"
        rx="20"
        stroke="#F9FAFB"
        stroke-width="6"
      />
      <path
        d="M20.1667 26.3333L23.5001 23M23.5001 23L26.8334 26.3333M23.5001 23V30.5M30.1667 26.9524C31.1847 26.1117 31.8334 24.8399 31.8334 23.4167C31.8334 20.8854 29.7814 18.8333 27.2501 18.8333C27.068 18.8333 26.8976 18.7383 26.8052 18.5814C25.7185 16.7374 23.7121 15.5 21.4167 15.5C17.965 15.5 15.1667 18.2982 15.1667 21.75C15.1667 23.4718 15.863 25.0309 16.9892 26.1613"
        stroke="#475467"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CloudUpload;
