import React from "react";

const Email = ({ size = 16, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.66675 5.83398L8.47085 10.5969C9.02182 10.9825 9.29731 11.1754 9.59697 11.2501C9.86166 11.3161 10.1385 11.3161 10.4032 11.2501C10.7029 11.1754 10.9783 10.9825 11.5293 10.5969L18.3334 5.83398M5.66675 16.6673H14.3334C15.7335 16.6673 16.4336 16.6673 16.9684 16.3948C17.4388 16.1552 17.8212 15.7727 18.0609 15.3023C18.3334 14.7675 18.3334 14.0674 18.3334 12.6673V7.33398C18.3334 5.93385 18.3334 5.23379 18.0609 4.69901C17.8212 4.2286 17.4388 3.84615 16.9684 3.60647C16.4336 3.33398 15.7335 3.33398 14.3334 3.33398H5.66675C4.26662 3.33398 3.56655 3.33398 3.03177 3.60647C2.56137 3.84615 2.17892 4.2286 1.93923 4.69901C1.66675 5.23379 1.66675 5.93385 1.66675 7.33398V12.6673C1.66675 14.0674 1.66675 14.7675 1.93923 15.3023C2.17892 15.7727 2.56137 16.1552 3.03177 16.3948C3.56655 16.6673 4.26662 16.6673 5.66675 16.6673Z"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Email;
