import React, { useMemo, useState, useEffect } from "react";
import style from "../assignmentCorrectionsPreview/assignmentCorrectionsPreview.module.scss";

function calculateUnifiedGrade(grades) {
  // Find highest denominator
  const highestDenominator = Math.max(...grades.map((grade) => grade[1]));

  // Convert all grades to the highest denominator
  const unifiedGrades = grades.map((grade) => {
    const [numerator, denominator] = grade;
    // If already at highest denominator, no conversion needed
    if (denominator === highestDenominator) {
      return [numerator, highestDenominator];
    }

    // Convert to highest denominator
    const conversionFactor = highestDenominator / denominator;
    const convertedNumerator = numerator * conversionFactor;
    return [convertedNumerator, highestDenominator];
  });

  // Calculate sum of all numerators
  const totalNumerator = unifiedGrades.reduce(
    (sum, grade) => sum + grade[0],
    0,
  );

  // Calculate total possible points (sum of all denominators after conversion)
  const totalDenominator = highestDenominator * grades.length;

  // Calculate final percentage
  const finalPercentage = (totalNumerator / totalDenominator) * 100;

  return {
    highestDenominator,
    unifiedGrades,
    totalNumerator,
    totalDenominator,
    finalGrade: totalNumerator / totalDenominator,
    finalPercentage: finalPercentage.toFixed(2) + "%",
  };
}

const BarCorrectionCount = ({
  length,
  correctLength,
  setFieldValue,
  indexQuestion,
  feedbackIndex,
  creterion,
  isStudent = false,
  values,
  highestScore,
  isManuallyEdited = false,
  setIsManuallyEdited,
}) => {
  const width = useMemo(
    () => (correctLength / length) * 100,
    [correctLength, length],
  );
  const [currentActive, setCurrentActive] = useState(correctLength - 1);
  const [grades, setGrades] = useState([]);

  const calculateGradeForQuestion = (question) => {
    const feedbacks = question?.llmAnswer?.answer?.feedbacks ?? [];
    
    const questionGrades = feedbacks.map((item) => {
      return [Number(item.score), Number(item.maxScore)];
    });
    
    if (questionGrades.length === 0) return 0;
    
    const result = calculateUnifiedGrade(questionGrades);
    const grade = Number(question?.grade) || 0;
    const newResult = (result.totalNumerator * grade) / result.totalDenominator;
    let decimalResult = Math.round(newResult * 100) / 100;
    
    return isNaN(decimalResult) ? 0 : decimalResult;
  };

  useEffect(() => {
    if (isManuallyEdited) return;
    
    // Only update if values exist
    if (!values || values.length === 0) return;
    
    const newGrades = [...grades];
    let gradesChanged = false;
    
    values.forEach((question, idx) => {
      const newGrade = calculateGradeForQuestion(question);
      // Only update if the grade actually changed
      if (newGrades[idx] !== newGrade) {
        newGrades[idx] = newGrade;
        gradesChanged = true;
      }
    });
    
    // Only update state if grades actually changed
    if (gradesChanged) {
      setGrades(newGrades);
    }
  }, [values, isManuallyEdited, grades]);

  // Effect to update form field when grades change
  useEffect(() => {
    if (grades.length > indexQuestion && grades[indexQuestion] !== undefined) {
      setFieldValue(`${indexQuestion}.gradeEvaluation`, grades[indexQuestion]);
    }
  }, [grades, indexQuestion, setFieldValue]);

  const handleClick = (barIndex) => {
    setIsManuallyEdited(false);
    setCurrentActive(barIndex);
    setFieldValue(
      `${indexQuestion}.llmAnswer.answer.feedbacks.${feedbackIndex}.score`,
      `${barIndex + 1}`,
    );
  };

  return (
    <>
      <span>{creterion}</span>
      <span className={style.justificationNumber}>
        {currentActive + 1} / {length}
      </span>
      <div className={style.barContainer}>
        {Array.from({ length }, (_, barIndex) => (
          <div
            key={barIndex}
            className={`${style.scoreBar} ${
              barIndex <= currentActive ? style.active : ""
            }`}
            style={{ width: `${width}%` }}
            onClick={isStudent ? undefined : () => handleClick(barIndex)}
          />
        ))}
      </div>
    </>
  );
};

export default React.memo(BarCorrectionCount);