import React, { useState, useRef, useEffect } from "react";
import styles from "./RemyAssistant.module.scss";
import { IconArrowUp, IconRemyAi } from "../../assets/icons";
import { setNotification } from "../../redux/app/app.slice";
import { useDispatch } from "react-redux";
import ArrowUp from "../../assets/icons/ArrowUp";

const RemyAssistant = ({ oSubmit, messages: defaultMessages = [] }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentTypingId, setCurrentTypingId] = useState(null);
  const [typingProgress, setTypingProgress] = useState({});
  const messagesEndRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (defaultMessages?.length > 0) {
      setMessages(defaultMessages);
    } else {
      setMessages([
        { id: 1, text: "Hello! How can I help you today?", sender: "bot" },
      ]);
    }
  }, [defaultMessages]);

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (input.trim() === "") return;

    const newUserMessage = {
      id: messages.length + 1,
      text: input,
      sender: "user",
    };
    setMessages([...messages, newUserMessage]);
    setInput("");
    setIsLoading(true);
    const response = await oSubmit?.(input);
    setIsLoading(false);
    
    if (response) {
      const newMessageId = messages.length + 2;
      const botResponse = {
        id: newMessageId,
        text: response,
        sender: "bot",
        isTyping: true,
      };
      
      setTypingProgress({
        fullText: response,
        currentText: "",
        charIndex: 0
      });
      
      setCurrentTypingId(newMessageId);
      setMessages(prevMessages => [...prevMessages, botResponse]);
    }
  };

  useEffect(() => {
    if (!currentTypingId || !typingProgress.fullText) return;
    
    if (typingProgress.charIndex < typingProgress.fullText.length) {
      const typingTimer = setTimeout(() => {
        const nextChar = typingProgress.fullText.charAt(typingProgress.charIndex);
        
        setTypingProgress(prev => ({
          ...prev,
          currentText: prev.currentText + nextChar,
          charIndex: prev.charIndex + 1
        }));
        
      }, 30);

      return () => clearTimeout(typingTimer);
    } else {
      setMessages(prevMessages => 
        prevMessages.map(msg => 
          msg.id === currentTypingId 
            ? { ...msg, isTyping: false } 
            : msg
        )
      );
      setCurrentTypingId(null);
    }
  }, [currentTypingId, typingProgress]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages, typingProgress.currentText]);

  const handleCopyClick = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        console.log("Message copied to clipboard!");
        dispatch(
          setNotification({type: "success",message: "Message copied to clipboard!"})
        );
        
      })
      .catch(err => {
        console.error("Could not copy text: ", err);
        dispatch(setNotification({type: "error",message: "Failed to copy message"}))
      });
  };

  return (
    <div className={styles.chatContainer}>
      <div className={styles.messageContainer}>
        {messages.map((message) => (
          <div
            key={message.id}
            className={`${styles.message} ${styles[message.sender]} ${message.isTyping ? styles.typing : ''}`}
          >
            <div className={styles.messageContent}>
              {message.sender === "bot" && <IconRemyAi />} 
              <span>
                {message.id === currentTypingId ? typingProgress.currentText : message.text}
                {message.id === currentTypingId && <span className={styles.cursor}>|</span>}
              </span>
            </div>
            
            {message.sender === "bot" && !message.isTyping && (
              <div className={styles.messageActions}>
                <button 
                  className={styles.copyButton}
                  onClick={() => handleCopyClick(message.text)}
                  title="Copy message"
                >
                  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 4V16C8 16.5304 8.21071 17.0391 8.58579 17.4142C8.96086 17.7893 9.46957 18 10 18H18C18.5304 18 19.0391 17.7893 19.4142 17.4142C19.7893 17.0391 20 16.5304 20 16V7.242C20 6.97556 19.9467 6.71181 19.8433 6.46624C19.7399 6.22068 19.5885 5.99824 19.398 5.812L16.188 2.602C16.0018 2.41148 15.7793 2.26011 15.5338 2.15668C15.2882 2.05325 15.0244 2 14.758 2H10C9.46957 2 8.96086 2.21071 8.58579 2.58579C8.21071 2.96086 8 3.46957 8 4Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M16 18V20C16 20.5304 15.7893 21.0391 15.4142 21.4142C15.0391 21.7893 14.5304 22 14 22H6C5.46957 22 4.96086 21.7893 4.58579 21.4142C4.21071 21.0391 4 20.5304 4 20V8C4 7.46957 4.21071 6.96086 4.58579 6.58579C4.96086 6.21071 5.46957 6 6 6H8" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                  <span>Copy</span>
                </button>
              </div>
            )}
          </div>
        ))}

        {isLoading && (
          <div className={`${styles.message} ${styles.bot} ${styles.loading}`}>
            <span className={styles.dot}></span>
            <span className={styles.dot}></span>
            <span className={styles.dot}></span>
          </div>
        )}

        <div ref={messagesEndRef} />
      </div>

      <form onSubmit={handleSubmit} className={styles.inputArea}>
        <input
          type="text"
          value={input}
          onChange={handleInputChange}
          placeholder="Type your message..."
          className={styles.textInput}
        />
        <button
          type="submit"
          className={styles.sendButton}
          disabled={input.trim() === ""}
        >
          <IconArrowUp size={16} />
        </button>
      </form>
    </div>
  );
};

export default RemyAssistant;