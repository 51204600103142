import React, { useState, useEffect } from 'react';
import DocsViewer from '../docsViewer';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import styles from './resourceItemPreview.module.css';

const ResourceItemPreview = ({ courseworkFilePath, questions, resourceIndexs }) => {
  const [currentIndex, setCurrentIndex] = useState(resourceIndexs?.indexFile || 0);

  const [fileUrl, setFileUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [fileType, setFileType] = useState(null);
  const [fileName, setFileName] = useState(null);

  const currentQustions  = questions?.[resourceIndexs?.indexQuestion]; 

  const fetchFileBlob = async (filePath) => { 
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/uploads/images/${filePath}`);
      if (!response.ok) {
        throw new Error(`Failed to fetch image from path: ${filePath}`);
      }
      const blob = await response.blob();
      return URL.createObjectURL(blob);
    } catch (error) {
      console.log("Error fetching file:", error);
      return null;
    }
  };

  const truncateFileName = (name, maxLength = 30) => {
    if (name.length > maxLength) {
        return name.substring(0, maxLength - 3) + "...";
    }
    return name;
};

  useEffect(() => {
    const loadCurrentFile = async () => {
      setIsLoading(true);

        const filepath = currentQustions?.resources?.[currentIndex]?.filePath;
        console.log("filepath",filepath,currentQustions?.resources?.[currentIndex]);
        
        const url = await fetchFileBlob(filepath);
        const fType =filepath?.split('.').pop();
        const fName = filepath?.split('/').pop();
        setFileUrl(url);
        setFileType(fType);
        setFileName(fName);
      setIsLoading(false);
    };

    loadCurrentFile();

    // Cleanup function to revoke object URL
    return () => {
      if (fileUrl) {
        URL.revokeObjectURL(fileUrl);
      }
    };
  }, [currentQustions, currentIndex]);

  const handlePrevious = () => {
    setCurrentIndex(prev => Math.max(0, prev - 1));
  };

  const handleNext = () => {
    setCurrentIndex(prev => Math.min(currentQustions?.resources?.length - 1, prev + 1));
  };
  
  return (
    <div className="relative w-full">
      <div className={styles.blockNavButtons}>
        <button 
          className={`${styles.navButton} ${currentIndex === 0 ? styles.disabled : ''}`}
          onClick={handlePrevious}
          disabled={currentIndex === 0 || isLoading}
        >
          <LeftOutlined />
        </button>

        {/* <span className="text-sm text-gray-600">
          {courseworkFilePath.length > 0 ? `${currentIndex + 1} / ${courseworkFilePath.length}` : '0 / 0'}
        </span> */}
        <span className={styles.fileName}>
          {fileName && truncateFileName(fileName)}
        </span>
        <button 
          className={`${styles.navButton} ${currentIndex === currentQustions?.resources?.length - 1 ? styles.disabled : ''}`}

          onClick={handleNext}
          disabled={currentIndex >= currentQustions?.resources?.length - 1 || isLoading}
        >
          <RightOutlined />
        </button>
      </div>

      <div className="mt-4">
        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
          </div>
        ) : (
          fileUrl ? <DocsViewer url={fileUrl} fileType={fileType} /> : <p style={{ textAlign: 'center' }}>Aucun fichier trouvé</p>
        )}
      </div>
    </div>
  );
};

export default ResourceItemPreview;