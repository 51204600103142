import React from "react";
import styles from "./badge.module.scss";

const SIZE = {
  XS: "xs",
  SM: "sm",
  MD: "md",
  LG: "lg",
};

const VARIANT = {
  PRIMARY: "primary",
  PRIMARY_ROUNDED: "primary-rounded",
  ACTIVE: "active",
  PENDING: "pending",
};

const Badge = ({ variant = VARIANT.PRIMARY, text, size = SIZE.MD }) => {
  const classList = [styles.budge, styles[size],styles[variant]]
    .filter(Boolean)
    .join(" ");

  return <div className={classList}>{text}</div>;
};

export { Badge as default,VARIANT, SIZE };
