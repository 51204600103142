import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    coursework: null,
};

export const draftSlice = createSlice({
  name: 'draft',
  initialState,
  reducers: {
      setDraftCoursework: (state, action) => {
      state.coursework = action.payload;
    },
  },
});

export const { setDraftCoursework } = draftSlice.actions;
export const { name: sliceKey } = draftSlice;

export default draftSlice.reducer;
