import React, { useEffect, useMemo, useState } from "react";
import style from "./previewAssignmentEssay.module.css";
import { Formik } from "formik";
import * as Yup from "yup";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthenticatedUser } from "../../redux/auth/auth.selectors";
import {
  useCreateStudentQuizSubmissionMutation,
  useLazyGetFilteredSubmissionsQuery
} from "../../services/studentQuizSubmission.api";
import { useLestStartRecommendationHelpMutation } from "../../services/studentsubmitionassisment.api";
import { useNavigate } from "react-router-dom";
import {
  DoubleRightOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { setNotification } from "../../redux/app/app.slice";
import QuestionsItemForEssay from "../questionsItemForEssay";
import CustomSpin from "../customSpin/CustomSpin";
import { marked } from "marked";
import AssignmentDetails from "../../Pages/AssignmentDetails";
import { Button, Modal } from "antd";
import AvatarStudent from "../../assets/icons/AvatarStudent";
import RightSideBarPreview from "../rightSideBarPreview";
import CountdownTimer from "../CountdownTimer";
import { useLazyGetRubricStandardByIdQuery } from "../../services/rubricsStandard.api";
import { useLazyGetRubricByIdQuery } from "../../services/rubrics.api";
import ViewRubricModal from "../viewRubricModal/ViewRubricModal";
import CustomAccordion from "../customAccordion";

const PreviewAssignmentForEssay = ({
  assignments,
  isPreview = true,
  description,
  allowBack = false,
}) => {
  const [initialValues, setInitialValues] = React.useState([]);
  const [showSubmit, setShowSubmit] = useState(true);
  const [createStudentSubmissionQuiz] =
    useCreateStudentQuizSubmissionMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const intl = useIntl();
  const [isHelpAnswerLoading, setIsHelpAnswerLoading] = useState(false);
  const [activeButton, setActiveButton] = useState(null);
  const [helperAnswer, setHelperAnswer] = useState(null);
  const [isProgressDisabled, setIsProgressDisabled] = useState(true);
  const [answerFormated, setAnswerFormated] = useState("");
  const [isStartClicked, setIsStartClicked] = useState(false);
  const [isReadyToSubmitClicked, setIsReadyToSubmitClicked] = useState(false);
  const [isReadyToSubmit, setIsReadyToSubmit] = useState(false);
  const [isProgressClicked, setIsProgressClicked] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [resourceIndexs, setResourceIndexs] = useState(null);
  const [isActiveFile, setIsActiveFile] = useState(false);
  const [timeLimit, setTimeLimit] = useState(null);
  const [isAllowedRubric, setIsAllowedRubric] = useState(false);
  const [resources, setResources] = useState({});
  const formikRef = React.useRef();
  const [timeLeft, setTimeLeft] = useState(null);
  const [isTimerActive, setIsTimerActive] = useState(false);
  const user = useSelector(selectAuthenticatedUser);
  const [helpLestStartData] = useLestStartRecommendationHelpMutation();
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [rubricItem, setRubricItem] = useState(null);
  const [rubricItemData, setRubricItemData] = useState(null);
  const { confirm } = Modal;
  const [isSubmissionInProgress, setIsSubmissionInProgress] = useState(false);

  const [
    getRubricStandardItem,
    { data: rubricStandardByItem, isLoading: isLoadingRubricStandardItem },
  ] = useLazyGetRubricStandardByIdQuery();
  const [
    getRubricItem,
    { data: rubricByItem, isLoading: isLoadingRubricItem },
  ] = useLazyGetRubricByIdQuery();
  const [triggerStudentCourseWorkSubmissions, {data: studentCourseWorkSubmissions}] = useLazyGetFilteredSubmissionsQuery()

  const enbaleRemy = useMemo(
    () => !isPreview && assignments?.writingAssistance,
    [isPreview, assignments],
  );

  useEffect(() => {
    triggerStudentCourseWorkSubmissions({
      pagination : false,
      student : user?.id,
      coursework : assignments?.id
    })

  }, [user?.id , assignments?.id]);

  const countStudentSubmissions = studentCourseWorkSubmissions?.['hydra:member']?.length;

  useEffect(() => {
    if (!assignments?.setting) return;
    const { timeLimit, hasRubric } = assignments.setting;
    if (timeLimit) {
      setTimeLimit(parseInt(timeLimit));
    }
    setIsAllowedRubric(!!hasRubric);
  }, [assignments]);


  useEffect(() => {
    const canShowSubmit = isPreview ? false : (assignments?.isPracticeMode ? (assignments?.setting?.hasAttempts ? countStudentSubmissions < Number(assignments?.setting?.attempts) : countStudentSubmissions < 1) : countStudentSubmissions < 1);
    setShowSubmit(canShowSubmit);
  }, [isPreview, countStudentSubmissions]);


  const [AssignmentSchema, setAssignmentSchema] = useState(
    Yup.object().shape({}),
  );

  // Initialize timer when component mounts
  useEffect(() => {
    if (!assignments?.setting) return;

    const { timeLimit, hasTimeLimit } = assignments.setting;

    if (hasTimeLimit && timeLimit && !isPreview) {
      const totalSeconds = parseInt(timeLimit) * 60;
      setTimeLeft(totalSeconds);
      setIsTimerActive(true);
    }
  }, [assignments, isPreview]);

  // Timer countdown logic
  useEffect(() => {
    let timerInterval;

    if (isTimerActive && timeLeft > 0) {
      timerInterval = setInterval(() => {
        setTimeLeft((prevTime) => {
          const newTime = prevTime - 1;

          if (newTime === 60) {
            dispatch(
              setNotification({
                type: "info",
                message:
                  "⚠️ One minute remaining! Your work will be automatically submitted when time runs out.",
              }),
            );
          }

          if (newTime === 0) {
            handleTimeUp();
          }

          return newTime;
        });
      }, 1000);
    }

    return () => {
      if (timerInterval) {
        clearInterval(timerInterval);
      }
    };
  }, [isTimerActive, timeLeft]);

  // Handle time up with Formik integration
  const handleTimeUp = async () => {
    setIsTimerActive(false);
    dispatch(
      setNotification({
        type: "info",
        message: "Time's up! Your work is being submitted...",
      }),
    );

    // Get the current form values from Formik
    if (formikRef.current) {
      const currentValues = formikRef.current.values;
      const processedValues = currentValues.map((question) => {
        return {
          ...question,
          studentAnswer:
            question.studentAnswer?.length > 0
              ? question.studentAnswer
              : [
                  {
                    type: "paragraph",
                    children: [{ text: "No answer provided - time expired" }],
                  },
                ],
          slateFormatAnswer: question?.slateFormatAnswer || [
            {
              type: "paragraph",
              children: [{ text: "No answer provided - time expired" }],
            },
          ],
        };
      });

      await __handleSubmit(processedValues, {
        setSubmitting: () => {},
      });
    }
  };

  const isDueDatePast = (dueDate) => {
    // Convert the dueDate string to a Date object
    const dueDateObj = new Date(dueDate);
    // Get the current date
    const currentDate = new Date();
    return dueDateObj < currentDate;
  };

  const checkAnswers = (data) => {
    let result = true;

    data.studentQuestionAnswers.forEach((answerObj) => {
      if (
        (!answerObj.answer || answerObj.answer.trim() === "") &&
        (!answerObj.studentAnswer || answerObj.studentAnswer.length === 0)
      ) {
        result = false;
        return;
      }
    });

    return result;
  };


  const __handleSubmit = async (values, { setSubmitting }) => {
    // Prevent multiple submissions
    if (isSubmissionInProgress) {
      return;
    }

    if (assignments?.dueDate && isDueDatePast(assignments?.dueDate)) {
      dispatch(
        setNotification({
          type: "error",
          message: intl.formatMessage({
            id: "notification.assignment.dueDate.past",
          }),
        }),
      );
      return;
    }

    try {
      // Lock submissions
      setIsSubmissionInProgress(true);
      setSubmitting(true);

      const assignmentsId = assignments.id;
      const payload = {
        studentQuestionAnswers: values.map((question) => ({
          question: `/api/questions/${question.id}`,
          studentAnswer: question.studentAnswer,
          isCorrect: question.isCorrect,
          justification: question.justification || null,
          answer: question.answer || null,
          slateFormatAnswer: question.slateFormatAnswer || [
            {
              type: "paragraph",
              children: [{ text: "" }],
            },
          ],
        })),
        student: `/api/students/${user.id}`,
        coursework: `/api/courseworks/${assignmentsId}`,
        status: "Submitted",
      };

      const isAllAnswered = checkAnswers(payload);

      const submitAction = async () => {
        try {
          const { data, error } = await createStudentSubmissionQuiz(payload);

          const canSubmit = assignments?.isPracticeMode ? assignments?.setting?.hasAttempts && countStudentSubmissions + 1  < Number(assignments?.setting?.attempts) -1 : false;
          if (data) {
            setShowSubmit(canSubmit);
            dispatch(
              setNotification({
                type: "success",
                message: intl.formatMessage({
                  id: "notification.submit.coursework.success",
                }),
              }),
            );
          }

          if (error) {
            dispatch(
              setNotification({
                type: "error",
                message: intl.formatMessage({
                  id: "notification.submit.coursework.error",
                }),
              }),
            );
          }
        } catch (err) {
          console.error("Submission error:", err);
          dispatch(
            setNotification({
              type: "error",
              message: "An unexpected error occurred during submission.",
            }),
          );
        } finally {
          // Always reset submission state
          setIsSubmissionInProgress(false);
          setSubmitting(false);
        }
      };

      if (!isAllAnswered) {
        confirm({
          title: intl.formatMessage({
            id: "notification.submit.coursework.emptyAnswers",
          }),
          okText: intl.formatMessage({ id: "notification.confirmation.ok" }),
          cancelText: intl.formatMessage({
            id: "notification.confirmation.cancel",
          }),
          onOk: submitAction,
          onCancel() {
            setIsSubmissionInProgress(false);
            setSubmitting(false);
          },
        });
      } else {
        await submitAction();
      }
    } catch (error) {
      console.error("Submission process error:", error);
      setIsSubmissionInProgress(false);
      setSubmitting(false);
    }
  };

  //type :
  useEffect(() => {
    const mappedQuestions = assignments?.questions.map((item) => ({
      ...item,
      answerJustification: item?.isJustification && "",
      answer: item?.answer || null,
      justification: item?.justification || null,
      studentAnswer: item?.studentAnswer || [],
    }));
    setInitialValues(mappedQuestions);
  }, [assignments]);

  const handleActivate = (id) => {
    setActiveButton(id);
  };

  const handleLetStartHelp = async (assignment) => {
    handleActivate("btn_1");
    setIsHelpAnswerLoading(true);
    if (assignment?.startHelp && assignment?.startHelp.length > 0) {
      const formatedData = MarkdownRenderer({
        markdownText: assignment?.startHelp,
      });
      setHelperAnswer(formatedData);
      setIsHelpAnswerLoading(false);
    } else {
      try {
        const { data } = await helpLestStartData({
          type: "START",
          courseWorkId: assignment?.coursework.id,
          studentCourseWorkId: assignment?.id,
        });

        if (data) {
          const formatedData = MarkdownRenderer({ markdownText: data });
          setHelperAnswer(formatedData); // Update state with the fetched data
          setIsStartClicked(true);
          //refreshData();
        }
      } catch (error) {
        console.error("error", error); // Handle error
      } finally {
        setIsHelpAnswerLoading(false); // Stop loading state after data or error is handled
      }
    }
  };
  const handleVerifyProgress = async (assignment) => {
    handleActivate("btn_2");
    const words = answerFormated.trim().split(/\s+/);
    if (words.filter((word) => word).length < 10) {
      setIsHelpAnswerLoading(false);
      dispatch(
        setNotification({
          type: "info",
          message: "Please write at least 10 words to verify your progress.",
        }),
      );
      return;
    }
    setIsHelpAnswerLoading(true);

    if (assignment?.progressHelp && assignment?.progressHelp.length > 0) {
      const formatedData = MarkdownRenderer({
        markdownText: assignment?.progressHelp,
      });
      setHelperAnswer(formatedData);
      setIsHelpAnswerLoading(false);
    } else {
      try {
        const { data } = await helpLestStartData({
          type: "PROGRESS",
          courseWorkId: assignment?.coursework.id,
          answer: answerFormated,
          studentCourseWorkId: assignment?.id,
        });

        if (data) {
          const formatedData = MarkdownRenderer({ markdownText: data });
          setHelperAnswer(formatedData); // Update state with the fetched data
          setIsProgressClicked(true);
          //refreshData();
        }
      } catch (error) {
        console.error("error", error); // Handle error
      } finally {
        setIsHelpAnswerLoading(false); // Stop loading state after data or error is handled
      }
    }
  };

  const handleReadyToSubmit = async (assignment) => {
    handleActivate("btn_3");
    const words = answerFormated.trim().split(/\s+/);
    if (words.filter((word) => word).length < 10) {
      setIsHelpAnswerLoading(false);
      dispatch(
        setNotification({
          type: "info",
          message: "Please write at least 10 words to submit your coursework.",
        }),
      );
      return;
    }
    setIsHelpAnswerLoading(true);
    if (assignment?.submitHelp && assignment?.submitHelp.length > 0) {
      const formatedData = MarkdownRenderer({
        markdownText: assignment?.submitHelp,
      });
      setHelperAnswer(formatedData);
      setIsHelpAnswerLoading(false);
    } else {
      try {
        const { data } = await helpLestStartData({
          type: "SUBMIT",
          courseWorkId: assignment?.coursework.id,
          answer: answerFormated,
          studentCourseWorkId: assignment?.id,
        });

        if (data) {
          const formatedData = MarkdownRenderer({ markdownText: data });
          setHelperAnswer(formatedData); // Update state with the fetched data
          setIsReadyToSubmitClicked(true);
          //refreshData();
        }
      } catch (error) {
        console.error("error", error); // Handle error
      } finally {
        setIsHelpAnswerLoading(false); // Stop loading state after data or error is handled
      }
    }
  };

  const initialAccordionStates = {
    1: true,
    3: true,
  };
  const [isPending, setIsPending] = useState(false);

  const [accordionStates, setAccordionStates] = useState(
    initialAccordionStates,
  );

  const toggle = (index, state) => {
    setAccordionStates((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const MarkdownRenderer = ({ markdownText }) => {
    const htmlContent = marked(markdownText);

    return (
      <span
        // Use dangerouslySetInnerHTML to render HTML content
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      />
    );
  };

  const handleActionClick = async (item) => {
    setRubricItem(item.id);
    if (item["@type"] === "RubricStandard") {
      const { data, error } = await getRubricStandardItem(item.id);
      if (data) {
        setRubricItemData(data);
        setIsViewModalOpen(!isViewModalOpen);
      }
    } else {
      const { data, error } = await getRubricItem(item.id);
      if (data) {
        setRubricItemData(data);
        setIsViewModalOpen(!isViewModalOpen);
      }
    }
  };

  const handleOpenHelpAssistant = () => {
    setIsActiveFile(false);
    setIsOpen(true);
  };

  return (
    <div className={style.container}>
      <div className="flex-between">
        <div className={style.header}>
          {isPreview || allowBack ? (
            <button className={style.backButton} onClick={() => navigate(-1)}>
              <LeftOutlined />
            </button>
          ) : (
            <div />
          )}
          <h2 className={style.title}>{assignments?.title}</h2>
        </div>
        <div>
          {enbaleRemy && (
            <button
              onClick={handleOpenHelpAssistant}
              className={style.helpAssistantButton}
            ></button>
          )}
        </div>
      </div>

      <div className={style.containerAssignment}>
        <Formik
          innerRef={formikRef}
          enableReinitialize
          initialValues={initialValues}
          validationSchema={AssignmentSchema}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={__handleSubmit}
        >
          {({ values, setFieldValue, handleSubmit, isSubmitting }) => (
            <div className={style.containerPreview}>
              <div
                className={`${style.containerPreviewContent} ${
                  isOpen ? style.rightSideBarPreview__open : ""
                }`}
              >
                <div className={style.courseworkHeader}>
                  {description && (
                    <div>
                      <h5 className={style.introductionTitle}>
                        {intl.formatMessage({
                          id: "assignment.preview.introduction",
                        })}:{" "}
                      </h5>
                      <p className={style.introduction}>{description}</p>
                    </div>
                  )}

                  <div className={style.rubricButtonContainer}>
                    {isAllowedRubric && (
                      <button
                        className={style.rubricButton}
                        onClick={() => {
                          handleActionClick(assignments?.questions[0]?.rubric);
                        }}
                      >
                        Rubric
                      </button>
                    )}
                    {timeLimit && showSubmit && (
                      <CountdownTimer
                        className={style.timerContainer}
                        minutes={timeLimit}
                      />
                    )}
                  </div>
                </div>

                {values && values[0]?.context && (
                  <div className={style.contextBlock}>
                      <div className={style.context}>
                        <h5 className={style.contextTitle}>
                          {" "}
                          {intl.formatMessage({ id: "context" })}:{" "}
                        </h5>
                        <p className={style.contextContent}>{values[0]?.context}</p>
                      </div>
                  </div>
                )}

                {assignments?.coursework?.writingAssistance && (
                  <div className={"accordion"}>
                    <div
                      className={"accordion__title__help"}
                      onClick={() => toggle(2)}
                    >
                      <h2>Need help? </h2>
                      <DoubleRightOutlined
                        className={`arrow ${
                          accordionStates[2] ? "toggled" : ""
                        }`}
                      />
                    </div>
                    <div
                      className={`accordion__content__help  ${
                        accordionStates[2] ? "toggled" : ""
                      }`}
                    >
                      <div className={"group__btn__help"}>
                        {isHelpAnswerLoading ? (
                          <CustomSpin />
                        ) : (
                          <>
                            <button
                              onClick={() => {
                                handleLetStartHelp(assignments);
                                //toggle(2);
                                setAccordionStates((prevState) => ({
                                  ...prevState,
                                  [2]: true,
                                }));
                              }}
                              className={`${isStartClicked ? "clicked" : ""} ${
                                activeButton === "btn_1" ? "activated" : ""
                              }`}
                            >
                              Let’s start…
                            </button>
                            <button
                              onClick={() => {
                                handleVerifyProgress(assignments);
                                setAccordionStates((prevState) => ({
                                  ...prevState,
                                  [2]: true,
                                }));
                              }}
                              className={`${
                                isProgressClicked ? "clicked" : ""
                              } ${activeButton === "btn_2" ? "activated" : ""}`}
                            >
                              Progress Check
                            </button>
                            <button
                              onClick={() => {
                                handleReadyToSubmit(assignments);
                                setAccordionStates((prevState) => ({
                                  ...prevState,
                                  [2]: true,
                                }));
                              }}
                              className={`${
                                isReadyToSubmitClicked ? "clicked" : ""
                              } ${activeButton === "btn_3" ? "activated" : ""}`}
                            >
                              Ready to submit?
                            </button>
                          </>
                        )}
                      </div>
                      <p>{helperAnswer && helperAnswer}</p>
                    </div>
                  </div>
                )}

                <div className={style.listQuistionsContainer}>
                  {values?.map((question, indexQuestion) => (
                    <>
                      <CustomAccordion
                        data={question}
                        indexQuestion={indexQuestion}
                        key={"Questions+" + indexQuestion}
                        openPreviewDocs={isOpen}
                        setOpenPreviewDocs={setIsOpen}
                        setResourceIndexs={setResourceIndexs}
                        setIsActiveFile={setIsActiveFile}
                        setResources={setResources}
                      />
                      <QuestionsItemForEssay
                        setFieldValue={setFieldValue}
                        question={question}
                        indexQuestion={indexQuestion}
                        key={"Questions+" + indexQuestion}
                        defaultAnswerValue={question.answer}
                        isPreview={!showSubmit}
                        assignment={assignments}
                        openPreviewDocs={isOpen}
                        setOpenPreviewDocs={setIsOpen}
                        setResourceIndexs={setResourceIndexs}
                        setIsActiveFile={setIsActiveFile}
                        setResources={setResources}
                        allowPaste={assignments?.setting?.allowPaste}
                      />
                    </>
                  ))}{" "}
                  {showSubmit && (
                    <div className={style.btnSubmitContainer}>
                      <button
                        type="submit"
                        className={style.btnSubmit}
                        onClick={handleSubmit}
                        disabled={isPreview || isSubmissionInProgress}
                      >
                        {isSubmissionInProgress ? "Submitting..." : "Submit"}
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <RightSideBarPreview
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                resourceIndexs={resourceIndexs}
                setResourceIndexs={setResourceIndexs}
                questions={values}
                isActiveFile={isActiveFile}
                setIsActiveFile={setIsActiveFile}
                resources={resources}
                enbaleRemy={enbaleRemy}
              />
            </div>
          )}
        </Formik>
      </div>
      <ViewRubricModal
        isViewModalOpen={isViewModalOpen}
        setIsViewModalOpen={setIsViewModalOpen}
        rubricItem={rubricItem}
        rubricItemData={rubricItemData}
      />
    </div>
  );
};

export default PreviewAssignmentForEssay;
