import { useMemo } from "react";
import { useGetMeQuery } from "../services/auth.api";
import useTypeUser from "./useTypeUser";

const useGetOrganization = () => {

  const  { data: authenticatedUser, error, isLoading } = useGetMeQuery();
  const {isAdmin,isTeacher,isStudent} = useTypeUser();

  if(isAdmin){
    return {
      organisation: authenticatedUser?.myOrganisation,
      isLoading: isLoading,
    };
  }

  if(isTeacher){
    return {
      organisation:  authenticatedUser?.organisations.length > 0 ? authenticatedUser?.organisations[0] : null,
      isLoading: isLoading,
    };
  }

  return {
    organisation: null,
    isLoading: isLoading,
  };
};

export default useGetOrganization;
