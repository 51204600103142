
import { api } from './api';

export const adminApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getAdmins: builder.query({
            query: () => ({
                url: `/api/admins`,
                method: 'GET',
            }),
            providesTags: [{ type: 'Admins', id: 'LIST' }],
        }),

        deleteAdmin: builder.mutation({
            query: ({ id }) => ({
                url: `/api/admins/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result) =>
                result === undefined
                    ? []
                    : [
                        { type: 'Admins', id: 'LIST' },
                    ],
        }),

        registerUser: builder.mutation({
            query: (data) => ({
                url: 'api/admin-register-user',
                method: 'POST',
                body: JSON.stringify(data),
                headers: { 'Content-Type': 'application/json' },
            }),
        }),
        importMultipleUsers: builder.mutation({
            query: ({ file, ...values }) => {
                const body = new FormData();
                body.append('file', file);
                body.append('organisationCode', values.organisationCode ?? null);
                body.append('columns', JSON.stringify(values.columns));
                body.append('type', values.type);
                body.append('classCode', values.classCode ?? null);

                return {
                    url: 'api/import-users',
                    method: 'POST',
                    body,
                }
            },
            invalidatesTags: (result) =>
                result === undefined
                    ? []
                    : [
                        { type: 'Classrooms'},
                        { type: 'Teachers', id: 'LIST' },
                        { type: 'ClassroomsFilter', id: 'LIST' },
                    ],
        }),

        inviteAdmins: builder.mutation({
            query: (data) => ({
                url: `/api/invite-admins`,
                method: 'POST',
                body: JSON.stringify(data),
                headers: {'Content-Type': 'application/json'},
            }),
            invalidatesTags:(result) =>
                result === undefined
                    ? []
                    : [
                        { type: 'Admins', id: 'LIST' },
                    ],
        }),


    }),
});
export const {
    useLazyGetAdminsQuery,
    useInviteAdminsMutation,
    useDeleteAdminMutation,
    useImportMultipleUsersMutation,
} = adminApi;