import React from "react";
import ClassCard from "../../../Components/classCrad/ClassCard";
import { useNavigate } from "react-router-dom";

const TeacherView = ({
  classes,
  handleActionClick,
  handleEdit,
  handleDelete,
}) =>{

  
  const navigate = useNavigate();
  const onAddStudent = (classroom) => {
    console.log("classroom", classroom);
    navigate(`/classes/${classroom.id}/students-invitations`);  
  }


  return classes.map((classroom) => (
    <ClassCard
      onClick={() => handleActionClick(classroom)}
      key={classroom.id}
      id={classroom.id}
      className={classroom.name}
      subject={classroom.subject}
      submissionsPregraded={"Pre-graded"}
      submissionsGraded={"Graded"}
      courswork={classroom?.courseworks?.length < 10 ? `0${classroom?.courseworks?.length}` : classroom?.courseworks?.length}
      students={
        classroom.students.length < 10
          ? `0${classroom.students.length}`
          : classroom.students.length
      }
      code={classroom.code}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      onAddStudent={()=>onAddStudent(classroom)}
    />
  ))}

export default TeacherView;
