import React from "react";
import StudentClassCard from "../../../Components/studentClassCard/StudentClassCard";

const StudentView = ({
  classes,
  handleActionClick,
  handleEdit,
  handleDelete,
}) =>
  classes.map((classroom) => (
    <StudentClassCard
      onClick={() => handleActionClick(classroom)}
      key={classroom.id}
      id={classroom.id}
      className={classroom.name}
      subject={classroom.subject}
      courswork={
        classroom.courseworks?.length < 10
          ? `0${classroom.courseworks?.length}`
          : classroom.courseworks?.length
      }
      students={
        classroom.students?.length < 10
          ? `0${classroom.students?.length}`
          : classroom.students?.length
      }
      code={classroom.code}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      teacher={`${classroom?.teacher?.first_name} ${classroom?.teacher?.family_name}`}
    />
  ));
export default StudentView;
