import {useEffect, useState} from "react";
import Input2, { SIZE } from "../../../Components/Input";
import { IconArrowUp } from "../../../assets/icons";
import { Popover } from "antd";
import styles from "../../createAssignment/createAssignment.module.css";
import { Button } from "antd";
import MagicIcon from "../../../assets/icons/MagicIcon";
import { useIntl } from "react-intl";
import { LoadingOutlined } from "@ant-design/icons";

const MagicContentPopover = ({  index , handleClick , type , questionText , isLoading}) => {
  const intl = useIntl();

    const [inputValue, setInputValue] = useState("");

    const handleSubmit = () => {
      if (inputValue.trim() !== "") {
        // Submit the form with the input value
        console.log("Submitted input value:", inputValue);
        handleClick({inputValue:inputValue, type:type, questionText:questionText, index:index});
        setInputValue("");}
      }
  const content = (<div className="flex align-end">
    <Input2
      label={intl.formatMessage({
        id: "assignment.create.label.askForSuggestion",
      })}
      size={SIZE.MD}
      value={inputValue}
      onChange={(e) => setInputValue(e.target.value)}
      className={styles.inputPopover}
    />
    <button
      type="button"
      className={"sendButton"}
      disabled={inputValue.trim() === ""}
        onClick={handleSubmit}
    >
      {isLoading ? <LoadingOutlined /> : <IconArrowUp size={16} />}
    </button>
  </div>);

  return (
    <Popover
      placement="topLeft"
      content={content}
      trigger="click"
    >
      <Button
        className={styles.magicButton}
        icon={<MagicIcon />}
        htmlType="button"
      />
    </Popover>
  );
};

export default MagicContentPopover;
