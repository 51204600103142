import React, { useEffect, useState } from "react";
import { IconEmail, IconUser } from "../../../assets/icons";
import Select from "../../../Components/Select";
import * as Yup from "yup";
import { Formik } from "formik";
import Button from "../../../Components/Button";
import TagInput from "../../../Components/TagInput";
import { useIntl } from "react-intl";
import { useInviteStudentToClassroomMutation } from '../../../services/classroom.api'
import { setNotification } from '../../../redux/app/app.slice'
import { useDispatch } from 'react-redux'


const Invitations = ({ classroom, allClassrooms }) => {
  const {formatMessage} = useIntl();
  const [classOptions, setClassOptions] = useState([]);
  const [inviteStudents] = useInviteStudentToClassroomMutation();
  const dispatch = useDispatch();

  const initialValues = {
    studentEmails: [],
  };

  const fullSchema ={
    studentEmails: Yup.array()
      .min(1, formatMessage({id:"form.field.emails.min"}))
      .required(formatMessage({id:"form.field.error.required"},{
        field: formatMessage({id:"form.field.emails"})
      })),
    classCode: Yup.string()
      .required(formatMessage({id:"form.field.error.required"},{
        field: formatMessage({id:"form.field.class"})
      })),
  }

  const { classCode: schemaClass,...schemaWithoutClass} = fullSchema;
  const validationSchema = Yup.object(!classroom  ? fullSchema : schemaWithoutClass );
  const _handleSubmit = async (values, { setSubmitting, resetForm }) => {
      if (classroom) {
          values.classCode = classroom.code;
      }

      const {data, error} = await inviteStudents({
          code: values.classCode,
          emails: values.studentEmails
      });

      if (data) {
          resetForm();
          dispatch(
              setNotification({
                  type: 'success',
                  message: formatMessage({
                      id: 'organization.invite.user.sent',
                  }),
              }),
          )
      }

      if (error) {
      dispatch(
          setNotification({
            type: "error",
            message: formatMessage({
              id: "organization.invite.user.error",
            }),
          })
      );
    }
    setSubmitting(false);
  };

  useEffect(() => {
    if (allClassrooms.length > 0 && !classroom) {
      const options = allClassrooms.map((classroom) => ({
        value: classroom.code,
        label: classroom.name,
      }))

      setClassOptions(options)
    }
  }, [allClassrooms])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={_handleSubmit}
      enableReinitialize
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ values, handleSubmit, setFieldValue, errors, resetForm }) => (
        <form className="section" onSubmit={handleSubmit}>
          <div className="left-side">
            <h3>
              {formatMessage({id:"organization.students.invitations.title"})}
            </h3>
            <p>
              {formatMessage({id:"organization.students.invitations.description"})}
            </p>
          </div>
          <div className="right-side">
            <div className="w-full flex gap-4">
              <TagInput
                name="studentEmails"
                placeholder="you@example.com"
                onChange={(value) => setFieldValue("studentEmails", value)}
                value={values.studentEmails}
                error={errors.studentEmails }
                className={"flex-grow-1"}
              />
              {!classroom && (
                <Select
                  options={classOptions}
                  name="classCode"
                  value={values.classCode}
                  onChange={(value) => setFieldValue("classCode", value)}
                  error={errors.classCode}
                  placeholder="select class"
                  className={"select-classes"}
                />
              )}
            </div>

            <div className="flex justify-end">
              <Button
                variant="primary"
                type="submit"
                icon={<IconEmail size={20} />}
                text={formatMessage({id:"organization.button.invite"})}
              />
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default Invitations;

const renderItemOptions = (text) => (
  <div className="flex-y-center gap-2">
    <IconUser />
    <span>{text}</span>
  </div>
);
