import React from "react";
import Invitations from "./Invitations";
import UploadTeachers from "./UploadTeachers";
import ListTeachers from "./ListTeachers";

const Teachers = () => {

  

  return (
    <div className="admin-teachers">
      <h1 className="h1">Invite Teachers</h1>
      <div className="teachers-container">
       <Invitations />
       <UploadTeachers />
       <ListTeachers />
      </div>
    </div>
  );
};
export default Teachers;
      