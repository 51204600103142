
import { api } from './api';

export const StudentApi = api.injectEndpoints({
    // delete student
    endpoints: (builder) => ({
        deleteStudent: builder.mutation({
            query: ({ id }) => ({
                url: `/api/students/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result) =>
                result === undefined
                    ? []
                    : [
                        { type: 'Student', id: 'LIST' },
                        { type: 'ClassroomsFilter', id: 'LIST' },
                        { type: 'Auth', id: 'ME' },
                        { type: 'Auth', id: result ? result.id : '' },
                        { type: 'Classrooms', id: 'LIST' },
                    ],
        }),
    })
});
export const { useDeleteStudentMutation } = StudentApi;