import React, {useEffect, useMemo, useState} from 'react';
import { SubmissionCard } from '../../Components/submissionCard';
import styles from './courseworkStudentsSubmission.module.css';
import {LeftOutlined, SearchOutlined} from "@ant-design/icons";
import Filter from "../../Components/filter/Filter";
import {useIntl} from "react-intl";
import { useParams } from 'react-router-dom';
import { useGetCourseworkBySubmissionsQuery } from '../../services/courseworkSubmissions.api';
import {useDispatch} from "react-redux";
import CustomSpin from "../../Components/customSpin/CustomSpin";
import {setNotification} from "../../redux/app/app.slice";
import { useNavigate } from "react-router-dom";

const CourseworkStudentsSubmission = () => {

    const [selectedFilterItems, setSelectedFilterItems] = useState([]);
    const intl = useIntl();
    const { id: courseworkId, title: courseworkTitle } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { data: dataCourseWork, isLoading: isLoadingAssignments, error } =
    useGetCourseworkBySubmissionsQuery(courseworkId);
    console.log('dataCourseWork',dataCourseWork);

    // show error if exist
    error && dispatch(
        setNotification({
            type: "error",
            message: "There is an error !"
        })
    )
    
    const filterItems = [
        { key: 'Pregraded', label: 'Pregraded' }, 
        { key: 'Submitted', label: 'Submitted' },
        { key: 'Graded', label: 'Graded' },
    ];

    const handleFilterChange = (items) => {
      setSelectedFilterItems(items);
    };

    const filtredSubmissions = useMemo(()=>{
      const submissions = dataCourseWork?.['hydra:member'] || [];
      if(selectedFilterItems.length === 0){
        return submissions
      }
      return submissions.filter((submission)=>selectedFilterItems.includes(submission.status))
    },[selectedFilterItems, dataCourseWork])

    if (isLoadingAssignments) {
      return <CustomSpin />;
  }
    return (
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.titles}>
              <div className={styles.filterAndTitle}>
                <button
                  className={styles.backButton}
                  onClick={() => navigate(-1)}
                >
                  <LeftOutlined />
                </button>
                <h1 className={styles.mainTitle}>
                  {intl.formatMessage({ id: "student.submissions.list.title" })}
                </h1>
                <Filter
                      filterItems={filterItems}
                      selectedFilterItems={selectedFilterItems}
                      setSelectedFilterItems={setSelectedFilterItems}
                      onChange={handleFilterChange}
                />
              </div>
            </div>
            <div className={styles.controls}>
              <div className={styles.searchContainer}>
                <SearchOutlined className={styles.searchIcon} />
                <input
                  type="text"
                  placeholder={intl.formatMessage({
                    id: "student.submissions.form.search",
                  })}
                  className={styles.searchInput}
                />
              </div>
            </div>
          </div>
          <h2 className={styles.subTitle}>{courseworkTitle}</h2>
          <div className={styles.grid}>
            {isLoadingAssignments ? (
              <CustomSpin />
            ) : filtredSubmissions.length === 0 ? (
              <div className={styles.empty}>
                {intl.formatMessage({ id: "student.submissions.empty.data" })}
              </div>
            ) : (
              filtredSubmissions?.map((submission) => (
                <SubmissionCard
                  key={submission["@id"]}
                  submission={submission}
                  courseId={courseworkId}
                />
              ))
            )}
          </div>
        </div>
    );
}

export default CourseworkStudentsSubmission;