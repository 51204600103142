import React, { useEffect, useMemo, useState } from 'react'
import { IconEmail, IconTrash, IconUser } from "../../../assets/icons";
import { useIntl } from "react-intl";
import MiniTable from "../../../Components/table/MiniTable";
import Badge, { VARIANT, SIZE } from "../../../Components/Badge";
import Swal from "sweetalert2";
import useDebounce from "../../../hooks/useDebounce";
import { useGetMeQuery } from '../../../services/auth.api'
import useGetOrganization from '../../../hooks/useGetOrganization'
import { useDeleteTeacherMutation, useLazyGetTeachersByOrganisationQuery } from '../../../services/teacher.api'
import { setNotification } from '../../../redux/app/app.slice'
import { useDispatch } from 'react-redux'

const ListTeachers = () => {
  const { formatMessage } = useIntl()
  const { organisation } = useGetOrganization();
  const intl = useIntl();
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const [teachers, setTeachers] = useState([]);
  const dispatch = useDispatch()
  const [deleteTeacher] = useDeleteTeacherMutation()

  const [triggerTeachers, { data: dataTeachers, isLoading: isLoadingTeachers }] =
      useLazyGetTeachersByOrganisationQuery();

  const handleDelete = (id) => {
    console.log("id", id);
    Swal.fire({
      icon: "warning",
      title: intl.formatMessage({
        id: "popup.delete.confirmation.title",
      }),
      text: intl.formatMessage(
        {
          id: "popup.form.delete.confirmation.text",
        },
        { item: intl.formatMessage({ id: "admin.organization.teacher" }) }
      ),
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({
        id: "popup.form.delete.confirmation.button.yes",
      }),
      confirmButtonColor: "#000095",
      cancelButtonText: intl.formatMessage({
        id: "popup.form.delete.confirmation.button.cancel",
      }),
    }).then((result) => {
      if (result.isConfirmed) {
        deleteTeacher({ id: id }).then((result) => {
          if (result.data) {
            dispatch(
                setNotification({
                  type: 'success',
                  message: formatMessage({ id: 'remove.user.success' }),
                }),
            )
          }
        })
      }
    });
  };

  const columns = [
    {
      accessor: "teacher",
      title: intl.formatMessage({id:"organization.teachers.list_Teacher.table.header.teacher"}),
      render: (record) => (
        <div className="record-teacher">
          <IconUser size={40} />
          <div className="record-teacher-info">
            <p className="table-name text-14">
              {record.firstName} {record.lastName}
            </p>
            <p className="table-email text-14 light">{record.email}</p>
          </div>
        </div>
      ),
    },
    {
      accessor: "state",
      title: intl.formatMessage({id:"organization.teachers.list_Teacher.table.header.state"}),
      render: ({ state }) => (
        <Badge
          size={SIZE.SM}
          text={state ? intl.formatMessage({id:"organization.teachers.list_Teacher.table.header.active"}) : intl.formatMessage({id:"organization.teachers.list_Teacher.table.header.pending"})}
          variant={state ? VARIANT.ACTIVE : VARIANT.PENDING}
        />
      ),
    },
    {
      key: "actions",
      title: "",
      dataIndex: "actions",
      render: ({ id }) => (
        <div className="flex justify-end ">
          <button className="btn-delete" onClick={() => handleDelete(id)}>
            <IconTrash size={20} />
          </button>
        </div>
      ),
    },
  ];

  const filteredData = useMemo(
    () => teachers.filter((item) =>
        [item.firstName, item.lastName, item.email]
          .join(" ")
          .toLowerCase()
          .includes(debouncedSearchTerm.toLowerCase())
    ),
    [teachers, debouncedSearchTerm]
  );

  useEffect(() => {
    if (organisation) {
      triggerTeachers({ organisationId: organisation.id });
    }
  }, [organisation]);

  useEffect(() => {
    if (dataTeachers) {
      const data = dataTeachers["hydra:member"].map((item) => ({
        id: item.id,
        email: item.email,
        firstName: item.first_name,
        lastName: item.family_name,
        state: item.confirmed,
      }));

      setTeachers(data);
    }
  }, [dataTeachers]);
  
  const isLoading = false;

  return (
    <div className="section">
      <div className="left-side">
        <h3>
          {intl.formatMessage({id:"organization.teachers.list_Teacher.title"})}
        </h3>
        <p>
          {intl.formatMessage({id:"organization.teachers.list_Teacher.description"})}
        </p>
      </div>
      <div className="right-side">
        <MiniTable
          activeSearch
          activeFilter={false}
          searchTerm={searchTerm}
          onChangeSearchTerm={setSearchTerm}
          columns={columns}
          data={filteredData}
          isLoading={isLoading}       
          />
      </div>
    </div>
  );
};

export default ListTeachers;