import React, { useEffect, useRef, useState } from 'react'
import { IconEmail, IconPlus, IconUser } from '../../../assets/icons'
import Select from '../../../Components/Select'
import * as Yup from 'yup'
import { Formik } from 'formik'
import Button from '../../../Components/Button'
import Input, { TYPE } from '../../../Components/Input'
import Upload from '../../../Components/Upload'
import { useIntl } from 'react-intl'
import { useGetMeQuery } from '../../../services/auth.api'
import { useDispatch } from 'react-redux'
import { useImportMultipleUsersMutation } from '../../../services/admin.api'
import { useLazyGetClassroomsByOrganisationQuery } from '../../../services/classroom.api'
import { setNotification } from '../../../redux/app/app.slice'
import useGetOrganization from '../../../hooks/useGetOrganization'

const UploadStudents = ({ classroom, allClassrooms }) => {
    const [importMultipleUsers] = useImportMultipleUsersMutation()
    const { formatMessage, intl } = useIntl()
    const [classOptions, setClassOptions] = useState([])
    const dispatch = useDispatch();

    const initialValues = {
        files: [],
        columns: {
            email: '',
            firstName: '',
            familyName: '',
            phoneNumber: '',
            department: '',
            country: '',
            city: '',
        },
        classCode: '',
    }
    const fullSchema = {
        files: Yup.array().min(1, 'At least one file is required').max(1, 'Only one file is allowed'),
        columns: Yup.object({
            email: Yup.string().required(
                formatMessage(
                    { id: 'form.field.error.required' },
                    { field: formatMessage({ id: "organization.students.upload.form.field.email" })},
                )),
            firstName: Yup.string().required(
                formatMessage(
                    { id: 'form.field.error.required' },
                    { field: formatMessage({ id: "organization.students.upload.form.field.first_name" })},
                )),
            familyName: Yup.string().required(
                formatMessage(
                    { id: 'form.field.error.required' },
                    { field: formatMessage({ id: "organization.students.upload.form.field.family_name" })},
                )),
            phoneNumber: Yup.string(),
            department: Yup.string(),
            country: Yup.string(),
            city: Yup.string(),
        }),
        classCode: Yup.string().required(
            formatMessage(
                { id: 'form.field.error.required' },
                { field: formatMessage({ id: "form.field.class" })},
            ),
        ),
    }

    const { classCode: schemaClass, ...schemaWithoutClass } = fullSchema
    const validationSchema = Yup.object(!classroom ? fullSchema : schemaWithoutClass)

    const _handleSubmit = async (values, { setSubmitting, resetForm }) => {
        // Important to add, that helps to identify the type of imported users
        values.type = 'student'

        if (classroom) {
            values.classCode = classroom.code
        }

        const { files, ...valuesData } = values;

        const { data, error } = await importMultipleUsers({ file: files[0], ...valuesData})

        if (data) {
            resetForm();
            dispatch(
                setNotification({
                    type: "success",
                    message: formatMessage({
                        id: "organization.import.user.success",
                    }),
                })
            );
        }

        if (error) {
            dispatch(
                setNotification({
                    type: "error",
                    message: formatMessage({
                        id: "organization.import.user.error",
                    }),
                })
            );
        }

        setSubmitting(false)
    }

    useEffect(() => {
        if (allClassrooms.length > 0 && !classroom) {
            const options = allClassrooms.map((classroom) => ({
                value: classroom.code,
                label: classroom.name,
            }))

            setClassOptions(options)
        }
    }, [allClassrooms])

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={_handleSubmit}
            enableReinitialize
            validateOnBlur={false}
            validateOnChange={false}
        >
            {({ values, handleSubmit, setFieldValue, errors, isSubmitting, resetForm }) => (
                <form className="section" onSubmit={handleSubmit}>
                    <div className="left-side">
                        <h3>
                            {formatMessage({ id: 'organization.students.upload.title' })}
                        </h3>
                        <p>
                            {formatMessage({
                                id: 'organization.students.upload.description',
                            })}
                        </p>
                        <img
                            src={'/assets/images/teachers-excel.jpg'}
                            alt="Excel spreadsheet template for student data"
                            className="image-excel"
                        />
                    </div>
                    <div className="right-side">
                        <div className="flex-col gap-16">
                            <div className="grid-cols-3 gap-16">
                                <Input
                                    label={formatMessage({ id: 'organization.students.upload.form.field.email' })}
                                    required
                                    type={TYPE.TEXT}
                                    placeholder="Email column"
                                    value={values?.columns?.email}
                                    onChange={(e) =>
                                        setFieldValue('columns.email', e.target.value)
                                    }
                                    error={errors?.columns?.email}
                                />
                                <Input
                                    label={formatMessage({ id: 'organization.students.upload.form.field.first_name' })}
                                    required
                                    type={TYPE.TEXT}
                                    placeholder="First name column"
                                    value={values?.columns?.firstName}
                                    onChange={(e) =>
                                        setFieldValue('columns.firstName', e.target.value)
                                    }
                                    error={errors?.columns?.firstName}
                                />
                                <Input
                                    label={formatMessage({ id: 'organization.students.upload.form.field.family_name' })}
                                    required
                                    type={TYPE.TEXT}
                                    placeholder="Family name column"
                                    value={values?.columns?.familyName}
                                    onChange={(e) =>
                                        setFieldValue('columns.familyName', e.target.value)
                                    }
                                    error={errors?.columns?.familyName}
                                />
                            </div>
                            <Upload
                                value={values?.files}
                                onChange={(files) => setFieldValue('files', files)}
                                error={errors?.files}
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                multiple={false}
                            />

                        </div>

                        <div className={`flex w-full align-end ${!classroom ? 'flex-between' : 'justify-end'}`}>
                            {!classroom && (
                                <Select
                                    label={formatMessage({ id: 'form.field.class' })}
                                    options={classOptions}
                                    name="classCode"
                                    value={values.classCode}
                                    onChange={(value) => setFieldValue('classCode', value)}
                                    error={errors.classCode}
                                    placeholder="select class"
                                    className={"select-classes"}
                                />
                            )}
                            <Button
                                variant="primary"
                                type="submit"
                                icon={<IconPlus size={20} />}
                                text={formatMessage({ id: 'organization.button.import' })}
                                isLoading={isSubmitting}
                            />
                        </div>
                    </div>
                </form>
            )}
        </Formik>
    )
}

export default UploadStudents

const renderItemOptions = (text) => (
    <div className="flex gap-2">
        <IconUser />
        <span>{text}</span>
    </div>
)
