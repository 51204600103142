import Table from "../../Components/table/Table";
import "./style.css";
import React, { useEffect, useState } from "react";
import { Button, Input, Tabs, Spin } from "antd";
import {
  DownOutlined,
  DownloadOutlined,
  SearchOutlined,
  UpOutlined,
  EyeOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { selectClassroom } from "../../redux/app/app.selectors";
import CustomSpin from "../../Components/customSpin/CustomSpin";
import {
  useGenerateStudentSubmissionPdfMutation,
  useLazyGetStudentCourseworkByStudentAndClassroomQuery,
} from "../../services/studentcourseworks.api";
import {useIntl} from "react-intl";

import {
  selectAuthenticatedUser,
} from "../../redux/auth/auth.selectors";
import { setNotification } from "../../redux/app/app.slice";
import dayjs from "dayjs";
import Filter from "../../Components/filter/Filter";
import PracticeModeIcon from "../../assets/icons/PracticeModeIcon";
import ClassModeIcon from "../../assets/icons/ClassModeIcon";

const StudentCourseworkSubmission = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const intl = useIntl();
  const [practiceSubmissions, setPracticeSubmissions] = useState([]);
  const [classSubmissions, setClassSubmissions] = useState([]);
  const classroom = useSelector(selectClassroom);
  const user = useSelector(selectAuthenticatedUser);
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const [
    triggerStudentSubmissions,
    { data: studentSubmissions, isLoading: isLoadingStudentSubmissions },
  ] = useLazyGetStudentCourseworkByStudentAndClassroomQuery();
  const [triggerPDF] = useGenerateStudentSubmissionPdfMutation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState({});
  const navigate = useNavigate();
  const { TabPane } = Tabs;
  const [activeTab, setActiveTab] = useState("class");
  const [selectedFilterItems, setSelectedFilterItems] = useState([]);
  const [expanded, setExpanded] = useState([]);

  const timeConverter = (rawDate) => {
    return moment(rawDate).format("MMM DD YYYY hh:mm");
  };

  useEffect(() => {
    triggerStudentSubmissions({
      studentId: authenticatedUser?.id,
      classroomId: classroom?.id,
    });
  }, []);


  useEffect(() => {
    if (studentSubmissions){
      const classSubmissions = studentSubmissions["hydra:member"]?.filter((submission) => !submission?.coursework?.isPracticeMode)
      setClassSubmissions(formatClassData(classSubmissions));
    }
  }, [studentSubmissions]);

  useEffect(() => {
    if (studentSubmissions){
      const practiceSubmissions = studentSubmissions["hydra:member"]?.filter((submission) => submission?.coursework?.isPracticeMode)
      setPracticeSubmissions(formatPracticeData(practiceSubmissions));
    }
  }, [studentSubmissions]);

  const formatClassData = (data) =>
    data?.map((item) => ({
      id: item?.id,
      title: item?.coursework?.title,
      type: item?.coursework?.type,
      subject: item?.coursework?.subject,
      state: item?.status ? item?.status : "Started",
      status: item?.status ? item?.status : "Started",
      submittedAt: item?.submittedAt,
      isPracticeMode: item?.coursework?.isPracticeMode,
    }));

  const formatPracticeData = (data) => {
    const courseWorks = data?.reduce((acc, {  id,status,submittedAt, coursework
    }) => {
      const courseWorkId = coursework?.id;

      if (!acc[courseWorkId]) {
        acc[courseWorkId] = {
          coursework,
          submissions: []
        };
      }

      acc[courseWorkId].submissions.push({ id,submittedAt, state:status || "Started"});

      return acc;
    }, {});

    return  Object.values(courseWorks)

  }

  const classColumns = [
    { key: 1, title: "ID", dataIndex: "id" },
    { key: 2, title: intl.formatMessage({id: "student.submissions.table.header.coursework.title.label"}), dataIndex: "title" },
    { key: 3, title: intl.formatMessage({id: "student.submissions.table.header.format.label"}), dataIndex: "type" },
    { key: 4, title: intl.formatMessage({id: "student.submissions.table.header.subject.label"}), dataIndex: "subject" },
    { key: 5, title: intl.formatMessage({id: "student.submissions.table.header.state.label"}), dataIndex: "state" },
    { key: 6, title: intl.formatMessage({id: "student.submissions.table.header.practiceMode.label"}), dataIndex: "isPracticeMode" },
    {
      key: 7,
      title: intl.formatMessage({id: "student.submissions.table.header.date.submitted.label"}),
      dataIndex: "submittedAt",
      render: (submittedAt) => timeConverter(submittedAt),
    },
    { key: 8, title: intl.formatMessage({id: "student.submissions.table.header.action.label"}), dataIndex: "action" },
  ];

  const practiceColumns = [
    { key: 1, title: "ID", dataIndex: "id" },
    { key: 2, title: intl.formatMessage({id: "student.submissions.table.header.coursework.title.label"}), dataIndex: "title" },
    { key: 3, title: intl.formatMessage({id: "student.submissions.table.header.format.label"}), dataIndex: "type" },
    { key: 4, title: intl.formatMessage({id: "student.submissions.table.header.subject.label"}), dataIndex: "subject" },
    { key: 6, title: intl.formatMessage({id: "student.submissions.table.header.practiceMode.label"}), dataIndex: "isPracticeMode" },
    { key: 7, title: intl.formatMessage({id: "student.submissions.table.header.action.label"}), dataIndex: "action" },
  ];

  const handleActionClick = async (id,courseworkTitle,submittedAt ) => {
    const userName = `${user?.first_name} ${user?.family_name}`;
    const submissionDate = dayjs(submittedAt).format('DD_MM_YYYY')
    setLoading(prev => ({ ...prev, [id]: true }));
    const { data, error } = await triggerPDF({
      student_coursework_id: id,
      classroom_id: classroom?.id,
    });

    const fileName = `${classroom.name}_${courseworkTitle}_${userName}_${submissionDate}`

    if (data) {
      const blob = new Blob([data]);
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", `${fileName}.pdf`); // Set the download file name

      // Append to the body and click to trigger download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    if (error) {
      dispatch(
        setNotification({
          type: "error",
          message: intl.formatMessage({id: "student.submissions.file.upload.message.error"})
        })
      );
    }
    setLoading(prev => ({ ...prev, [id]: false }));
    return data;
  };

  const handleSort = (columnKey) => {
    let direction;
    if (sortConfig.key === columnKey) {
      if (sortConfig.direction === "ascending") {
        direction = null; // Second click, reset to default (unsorted)
      } else if (sortConfig.direction === null) {
        direction = "descending"; // Third click, sort descending
      } else {
        direction = "ascending"; // First click, sort ascending
      }
    } else {
      direction = "ascending"; // New column clicked, sort ascending
    }
    setSortConfig({ key: columnKey, direction });
  };

  const handleShowCorrection = async (item) => {
    const studentId = authenticatedUser?.id;
    const submissionId = item?.id;
    submissionId && navigate(`/student/${studentId}/assignment/correction/${submissionId}`);
  }

  const TableHeadCell = (column) => {
    if (column.dataIndex === "id" || column.dataIndex === "action") {
      return column.title;
    } else {
      return (
        <span
          onClick={() => handleSort(column.dataIndex)}
          className={"sort_column"}
        >
            <span>{column.title}</span>
            <span className={"sort__icons"}>
              <UpOutlined
                className={
                  (
                    Array.isArray(sortConfig.key) &&
                    Array.isArray(column.dataIndex)
                      ? sortConfig.direction === "ascending" &&
                      sortConfig.key.join() === column.dataIndex.join()
                      : sortConfig.direction === "ascending" &&
                      sortConfig.key === column.dataIndex
                  )
                    ? "up__icon active"
                    : "up__icon"
                }
              />
              <DownOutlined
                className={
                  (
                    Array.isArray(sortConfig.key) &&
                    Array.isArray(column.dataIndex)
                      ? sortConfig.direction === "descending" &&
                      sortConfig.key.join() === column.dataIndex.join()
                      : sortConfig.direction === "descending" &&
                      sortConfig.key === column.dataIndex
                  )
                    ? "down__icon active"
                    : "down__icon"
                }
              />
            </span>
          </span>
      );
    }
  };

  const TableBodyCell = (column, item) => {
    switch (column.dataIndex) {
      case "isPracticeMode":
        return (
          <span>
              {item[column.dataIndex]
                ? intl.formatMessage({
                  id: "assignment.list.table.header.column.isPracticeMode.yes",
                })
                : intl.formatMessage({
                  id: "assignment.list.table.header.column.isPracticeMode.no",
                })}
            </span>
        );
      case "action":
        return (
          <div className={"action__container"}>
            <Button
              onClick={() => handleActionClick(item.id, item.title, item.submittedAt)}
              className="tableBody__cell-openBtn"
              style={{ textAlign: "center" }}
              icon={
                loading[item.id] ? (
                  <LoadingOutlined />
                ) : (
                  <DownloadOutlined />
                )
              }
              disabled={
                item.status === "Submitted" ||
                (!item?.coursework?.isPracticeMode &&
                  item.status === "Pregraded")
              }
            ></Button>
            <Button
              className="tableBody__cell-openBtn"
              icon={<EyeOutlined />}
              onClick={() => handleShowCorrection(item)}
              disabled={
                item.status === "Submitted" ||
                (!item?.coursework?.isPracticeMode &&
                  item.status === "Pregraded")
              }
            />
          </div>
        );
      case "type":
        if (column.render) {
          return (
            <span className="tableBody__cell-formatCol">
              {column.render(item[column.dataIndex], item)}{" "}
            </span>
          );
        } else {
          return (
            <span className="tableBody__cell-formatCol">
              {item[column.dataIndex]}{" "}
            </span>
          );
        }

      default:
        if (column.render) {
          return column.render(item[column.dataIndex], item);
        } else {
          return item[column.dataIndex];
        }
    }
  };

  const TableExpandedBodyCell = (column, item) => {
    switch (column.dataIndex) {
      case "isPracticeMode":
        return (
          <span>
              {item.coursework[column.dataIndex]
                ? intl.formatMessage({
                  id: "assignment.list.table.header.column.isPracticeMode.yes",
                })
                : intl.formatMessage({
                  id: "assignment.list.table.header.column.isPracticeMode.no",
                })}
            </span>
        );
      case "type":
          return (
            <span className="tableBody__cell-formatCol">
              {item.coursework[column.dataIndex]}{" "}
            </span>
          );
      default:
          return item.coursework[column.dataIndex];
    }
  };


  const RenderRow = ({ item }) => {
    if (item.isPracticeMode && !item.isPracticeMode) {
      return (
        <tr>
          {classColumns.map((column, index) =>
            {
              return (
                <td key={index}><div>{TableBodyCell(column, item)}</div></td>
              )
            }
          )}
        </tr>
      );
    }
    return (
      <>
        <tr
          className={
            expanded.includes(item.coursework.id)
              ? "expandedTable row-clickable"
              : "row-clickable"
          }
          onClick={() => {
            setExpanded(
              expanded.includes(item.coursework.id)
                ? expanded.filter((id) => id !== item.coursework.id)
                : [...expanded, item.coursework.id]
            );
          }}
        >
          {practiceColumns.map((column, index) => {
            return (
              <td key={index} className={"w-max-content"}>
                <div>
                  {index !== practiceColumns.length - 1 ? (
                    TableExpandedBodyCell(column, item)
                  ) : expanded ? (
                    <button style={{display: "block", width: "30px", textAlign: "center"}}><DownOutlined/></button>
                  ) : (
                    <button style={{display: "block", width: "30px", textAlign: "center"}}><DownOutlined/></button>
                  )}
                </div>
              </td>
            );
          })}
        </tr>
        {expanded.includes(item.coursework.id) && (
          <tr className={expanded ? "expandedTable" : ""}>
            <td colSpan={practiceColumns.length}>
              <div className={"sub-table"}>
                <div className={"sub-table-header-item"}>
                  <div className={"sub-table-title-item"}>{intl.formatMessage({id: "student.submissions.table.expanded.header.state.label"})}</div>
                  <div className={"sub-table-title-item"}>{intl.formatMessage({id: "student.submissions.table.expanded.header.dateSubmitted.label"})}</div>
                  <div className={"sub-table-title-item"}>{intl.formatMessage({id: "student.submissions.table.expanded.header.action.label"})}</div>
                </div>

                {item?.submissions?.map((subItem) => (
                  <div
                    className={"sub-table-row-item"}
                    key={`sub-item${subItem.id}`}
                  >
                    <div className={"sub-table-body-item"}>
                      {subItem?.state}
                    </div>
                    <div className={"sub-table-body-item"}>
                      {dayjs(subItem.submittedAt).format("MMM DD YYYY HH:mm")}
                    </div>
                    <div className={"sub-table-body-item"}>
                      <Button
                        className="tableBody__cell-openBtn"
                        onClick={async (e) => await handleActionClick(subItem.id, item.coursework.title, subItem.submittedAt )}
                        icon={
                          loading[item.id] ? (
                            <LoadingOutlined />
                          ) : (
                            <DownloadOutlined />
                          )
                        }
                        disabled={
                          subItem.state === "Submitted"
                        }
                      />
                      <Button
                        className="tableBody__cell-openBtn"
                        icon={<EyeOutlined />}
                        onClick={() => handleShowCorrection(subItem)}
                        disabled={
                          subItem.state === "Submitted"
                        }
                      />
                    </div>
                  </div>
                ))}
              </div>
            </td>
          </tr>
        )}
      </>
    );
  };

  const filterItems = [
    { label: intl.formatMessage({id: "assignment.list.table.filter.essay"}), key: 'Essay',},
    { label: intl.formatMessage({id: "assignment.list.table.filter.quiz"}), key: 'Quiz',},
    // { label: intl.formatMessage({id: "assignment.list.table.filter.mcq"}), key: 'MCQ',},
    // { label: intl.formatMessage({id: "assignment.list.table.filter.qr"}), key: 'Q/R',},
  ];

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  // const filteredData = dataSorted.filter(item => {
  //   if (activeTab === "all") return true;
  //   if (activeTab === "practice") return item.isPracticeMode === true;
  //   if (activeTab === "class") return item.isPracticeMode === false;
  //   return true;
  // });

  // useEffect(() => {
  //   if (!sortConfig){
  //     setDataSorted(formatClassData(studentSubmissions));
  //   }
  //   else {
  //     const studentSubmissionsData = formatClassData(studentSubmissions) || [];
  //     const sortedData = [...studentSubmissionsData]?.sort((a, b) => {
  //       if (sortConfig.direction === 'ascending') {
  //         return a?.[sortConfig?.key] > b?.[sortConfig?.key] ? 1 : -1;
  //       }
  //       if(sortConfig.direction === 'descending'){
  //         return a?.[sortConfig?.key] < b?.[sortConfig?.key] ? 1 : -1;
  //       }
  //       return 0;
  //     })
  //     setDataSorted(sortedData)
  //   }
  // }, [sortConfig, studentSubmissions]);

  if (isLoadingStudentSubmissions) {
    return <CustomSpin />;
  }
  return (
    <div className="content__submissions content__submissions__student">
      <div className={"submission__top-page"}>
        <div className="submissions__filter">
          <h1>{intl.formatMessage({id: "student.submissions.list.title"})}</h1>
          <Filter
            filterItems={filterItems}
            selectedFilterItems={selectedFilterItems}
            setSelectedFilterItems={setSelectedFilterItems}
          />
        </div>

        <div className="submissions-new">
          <Input
            type="text"
            placeholder={intl.formatMessage({id: "student.submissions.form.search"})}
            prefix={<SearchOutlined />}
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
      </div>

      <Tabs defaultActiveKey="all" onChange={handleTabChange} className="student-submissions-tabs">
        {/*<TabPane tab={intl.formatMessage({ id: "assignment.tabs.all" })} key="all" className="student-submissions-tab">*/}
        {/*  <Table*/}
        {/*    columns={columns}*/}
        {/*    data={filteredData}*/}
        {/*    TableHeadCell={TableHeadCell}*/}
        {/*    expanable={(item) => <RenderRow item={item} />}*/}
        {/*    noDataText={intl.formatMessage({id: "student.submissions.table.empty.data"})}*/}
        {/*    searchTerm={searchTerm}*/}
        {/*    isLoading={isLoadingStudentSubmissions}*/}
        {/*    selectedFilterItems={selectedFilterItems}*/}
        {/*  />*/}
        {/*</TabPane>*/}
        <TabPane tab={<><span className="tab-mode-icon-container"><ClassModeIcon className="tab-mode-icon" /></span> {intl.formatMessage({ id: "assignment.tabs.class" })}</>} key="class" className="student-submissions-tab">
          <Table
            columns={classColumns}
            data={classSubmissions}
            TableHeadCell={TableHeadCell}
            TableBodyCell={TableBodyCell}
            noDataText={intl.formatMessage({id: "student.submissions.table.empty.data"})}
            searchTerm={searchTerm}
            isLoading={isLoadingStudentSubmissions}
            selectedFilterItems={selectedFilterItems}
          />
        </TabPane>
        <TabPane tab={<><span className="tab-mode-icon-container"><PracticeModeIcon className="tab-mode-icon" /></span> {intl.formatMessage({ id: "assignment.tabs.practice" })}</>} key="practice" className="student-submissions-tab">
          <Table
          columns={practiceColumns}
          data={practiceSubmissions}
          TableHeadCell={TableHeadCell}
          expanable={(item) => <RenderRow item={item} />}
          noDataText={intl.formatMessage({id: "student.submissions.table.empty.data"})}
          searchTerm={searchTerm}
          isLoading={isLoadingStudentSubmissions}
          selectedFilterItems={selectedFilterItems}
        />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default StudentCourseworkSubmission;
