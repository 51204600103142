

const CloseTabs = () => {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.04167 17.7031L16.7083 17.7031C17.6288 17.7031 18.375 16.9569 18.375 16.0365L18.375 4.36979C18.375 3.44932 17.6288 2.70312 16.7083 2.70312L5.04167 2.70312C4.12119 2.70312 3.375 3.44932 3.375 4.36979L3.375 16.0365C3.375 16.9569 4.12119 17.7031 5.04167 17.7031Z"
          stroke="currentColor"
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.375 17.7031L8.375 2.70313"
          stroke="currentColor"
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.542 7.70312L15.042 10.2031L12.542 12.7031"
          stroke="currentColor"
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
}

export default CloseTabs;