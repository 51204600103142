import React, { useState, useEffect } from "react";
import { Button, Modal, Input } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import { style } from "./mediaViewer.module.css"

const  MediaViewer = ({ initialUrl, isModalVisible, setIsModalVisible }) => {
  const [url, setUrl] = useState(initialUrl);
  const [videoId, setVideoId] = useState(null);

  useEffect(() => {
    setUrl(initialUrl);
  }, [initialUrl]);

  useEffect(() => {
    if (isYouTube(initialUrl)) {
      setIsModalVisible(isModalVisible);
      setVideoId(extractYouTubeVideoId(initialUrl));
    } else {
      setVideoId(null);
    }
  }, [initialUrl]);

  const isYouTube = (link) => {
    return link.includes("youtube.com") || link.includes("youtu.be");
  };

  const extractYouTubeVideoId = (url) => {
    try {
      const parsedUrl = new URL(url);
      
      if (parsedUrl.hostname.includes("youtu.be")) {
        return parsedUrl.pathname.substring(1);
      }
      
      if (parsedUrl.hostname.includes("youtube.com")) {
        if (parsedUrl.pathname.includes("/embed/")) {
          return parsedUrl.pathname.split("/embed/")[1];
        }
        return parsedUrl.searchParams.get("v");
      }
    } catch (error) {
      console.error("Invalid YouTube URL:", error);
    }
    return null;
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  console.log('url', url);

  return (
    <>
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width="80vw"
        bodyStyle={{ padding: "0 20px" }}
      >
        {isYouTube(url) ? (
          <iframe
            height= "500px"
            src={`https://www.youtube.com/embed/${videoId}`}
            className="flex-1 w-full border rounded"
            title="YouTube Video"
            allowFullScreen
            style={{ flex: 1, borderRadius: "8px" }}
          />
        ) : (
          <>
            <Input
              type="text"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              placeholder="Enter media URL"
              className="mb-2"
              style={{ width: "98%", marginBottom: "6px" }}
              readOnly
            />
            <iframe
              height= "500px"
              src={url}
              className="flex-1 w-full border rounded"
              title="Website Viewer"
              style={{ flex: 1, borderRadius: "8px" }}
            />
          </>
        )}
      </Modal>
    </>
  );
}

export default MediaViewer;