import CustomSpin from "../customSpin/CustomSpin";
import style from "./miniTable.module.scss";
import Input, { TYPE, SIZE } from "../Input";
import { useIntl } from "react-intl";
import Filter from "../filter/Filter";
const MiniTable = ({
  columns,
  data,
  isLoading,
  activeSearch,
  activeFilter,
  searchTerm,
  onChangeSearchTerm,
  filterItems,
  selectedFilterItems,
  setSelectedFilterItems,
  onFilterChange,
  className=""
}) => {
  const intl = useIntl();

  const content = data?.map((item,indexItem) => (
    <tr key={item.id +indexItem} className={style.miniTableBodyRow}>
      {columns?.map((column, index) => (
        <td
          key={column.accessor+indexItem + index + item.id}
          className={`${style.miniTableBodyCell} ${column.className}`}
        >
          {column.render(item)}
        </td>
      ))}
    </tr>
  ));

  return (
    <div className={`${style.miniTableContainer} ${className}`}>
      <div className={style.searchContainer}>
        {activeSearch && (
          <>
            {activeFilter && (
              <Filter
                className={style.filterBtn}
                filterItems={filterItems}
                selectedFilterItems={selectedFilterItems}
                setSelectedFilterItems={setSelectedFilterItems}
                onChange={onFilterChange}
              />
            )}
            {activeSearch && (
              <Input
                placeholder="Search"
                type={TYPE.SEARCH}
                size={SIZE.MD}
                value={searchTerm}
                onChange={(e) => onChangeSearchTerm(e.target.value)}
              />
            )}
          </>
        )}
      </div>
      <table className={style.miniTable}>
        <thead>
          <tr className={style.miniTableHead}>
            {columns.map((column) => (
              <th key={column.key} className={style.miniTableHeadCell}>
                <div>{column.title}</div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr className={style.miniTableBodyRow}>
              <td
                colSpan={columns?.length}
                className={`${style.miniTableBodyCell} ${style.loading}`}
              >
                <CustomSpin />
              </td>
            </tr>
          ) : content.length > 0 ? (
            content
          ) : (
            <tr className={style.miniTableBodyRow}>
              <td colSpan={columns?.length} className={style.miniTableBodyCell}>
                <div className={style.noResults}>
                  {intl.formatMessage({ id: "app.table.no.results" })}
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default MiniTable;
