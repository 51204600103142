import {
  DoubleRightOutlined,
  FileAddOutlined,
  FileImageOutlined,
  FileOutlined,
  GlobalOutlined,
  UploadOutlined,
  YoutubeFilled,
} from "@ant-design/icons";
import React, { useEffect, useState, useCallback } from "react";
import style from "../assignmentCorrectionsPreview/assignmentCorrectionsPreview.module.scss";
import styled from "./questionsItemForEssay.module.scss";
import { useIntl } from "react-intl";
import ToolbarSlate from "../_util/toolbarSlate";
import { Editable, Slate, useSlate, withReact } from "slate-react";
import IconSlate from "../_util/iconSlate";
import ButtonSlate from "../_util/buttonSlate";
import HeadingOne from "../icons/headingOne";
import Italic from "../icons/italic";
import UnderLine from "../icons/underLine";
import BoldIcon from "../icons/boldIcon";
import HeadingTwo from "../icons/headingTwo";
import ListNum from "../icons/listNum";
import ListPuce from "../icons/listPuce";
import TextAlignLeft from "../icons/textAlignLeft";
import TextAlignCenter from "../icons/textAlignCenter";
import TextAlignRight from "../icons/textAlignRight";
import BlockQuote from "../icons/blockQuote";
import JustifyIcon from "../icons/justifyIcon";
import { setNotification } from "../../redux/app/app.slice";
import { useDispatch } from "react-redux";
import isHotkey from "is-hotkey";

import {
  Transforms,
  createEditor,
  Node,
  Editor as EditorSlate,
  Element as SlateElement,
} from "slate";

const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code",
};
const LIST_TYPES = ["numbered-list", "bulleted-list"];
const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"];

const serialize = (value) => {
  try {
    return value?.map((n) => Node?.string(n)).join("\n");
  } catch (error) {
    return "";
  }
};

const Editor = ({ onChange, onFormatChange, value, readOnly, allowPaste }) => {
  const dispatch = useDispatch();
  const [wordsCount, setWordsCount] = useState(0);
  const MarkButton = ({ format, icon }) => {
    const editor = useSlate();
    return (
      <ButtonSlate
        active={isMarkActive(editor, format)}
        onMouseDown={(event) => {
          event.preventDefault();
          toggleMark(editor, format);
        }}
      >
        <IconSlate icon={icon} />
      </ButtonSlate>
    );
  };
  const toggleBlock = (editor, format) => {
    const isActive = isBlockActive(
      editor,
      format,
      TEXT_ALIGN_TYPES.includes(format) ? "align" : "type",
    );
    const isList = LIST_TYPES.includes(format);
    Transforms.unwrapNodes(editor, {
      match: (n) =>
        !EditorSlate.isEditor(n) &&
        SlateElement.isElement(n) &&
        LIST_TYPES.includes(n.type) &&
        !TEXT_ALIGN_TYPES.includes(format),
      split: true,
    });
    let newProperties;
    if (TEXT_ALIGN_TYPES.includes(format)) {
      newProperties = {
        align: isActive ? undefined : format,
      };
    } else {
      newProperties = {
        type: isActive ? "paragraph" : isList ? "list-item" : format,
      };
    }
    Transforms.setNodes(editor, newProperties);
    if (!isActive && isList) {
      const block = { type: format, children: [] };
      Transforms.wrapNodes(editor, block);
    }
  };
  const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format);
    if (isActive) {
      EditorSlate.removeMark(editor, format);
    } else {
      EditorSlate.addMark(editor, format, true);
    }
  };
  const isBlockActive = (editor, format, blockType = "type") => {
    const { selection } = editor;
    if (!selection) return false;
    const [match] = Array.from(
      EditorSlate.nodes(editor, {
        at: EditorSlate.unhangRange(editor, selection),
        match: (n) =>
          !EditorSlate.isEditor(n) &&
          SlateElement.isElement(n) &&
          n[blockType] === format,
      }),
    );
    return !!match;
  };
  const isMarkActive = (editor, format) => {
    const marks = EditorSlate.marks(editor);
    return marks ? marks[format] === true : false;
  };
  const Element = ({ attributes, children, element }) => {
    const style = { textAlign: element.align };
    switch (element.type) {
      case "block-quote":
        return (
          <blockquote style={style} {...attributes}>
            {children}
          </blockquote>
        );
      case "bulleted-list":
        return (
          <ul style={style} {...attributes}>
            {children}
          </ul>
        );
      case "heading-one":
        return (
          <h1 style={style} {...attributes}>
            {children}
          </h1>
        );
      case "heading-two":
        return (
          <h2 style={style} {...attributes}>
            {children}
          </h2>
        );
      case "list-item":
        return (
          <li style={style} {...attributes}>
            {children}
          </li>
        );
      case "numbered-list":
        return (
          <ol style={style} {...attributes}>
            {children}
          </ol>
        );
      case "color":
        return <span style={{ color: element.color }}>{children}</span>;
      default:
        return (
          <p style={style} {...attributes}>
            {children}
          </p>
        );
    }
  };
  const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
      children = <strong>{children}</strong>;
    }
    if (leaf.italic) {
      children = <em>{children}</em>;
    }
    if (leaf.underline) {
      children = <u>{children}</u>;
    }
    return <span {...attributes}>{children}</span>;
  };
  const BlockButton = ({ format, icon }) => {
    const editor = useSlate();
    return (
      <ButtonSlate
        active={isBlockActive(
          editor,
          format,
          TEXT_ALIGN_TYPES.includes(format) ? "align" : "type",
        )}
        onMouseDown={(event) => {
          event.preventDefault();
          toggleBlock(editor, format);
        }}
      >
        <IconSlate icon={icon} />
      </ButtonSlate>
    );
  };

  const [editor] = useState(() => withReact(createEditor()));
  const intl = useIntl();
  const renderElement = useCallback((props) => <Element {...props} />, []);

  useEffect(() => {
    onFormatChange?.(serialize(value));
  }, [value]);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  const countWords = (nodes) => {
    const text = nodes.map((node) => Node.string(node)).join(" ");
    return text.trim().split(/\s+/).filter(Boolean)?.length;
  };

  const extract = (value) => {
    /// const value = await api 
    //onChange?.(value);
  };

  return (
    <div className={styled.container}>
      <div className={style.textareaContainer}>
        <Slate
          editor={editor}
          initialValue={
            value || [{ type: "paragraph", children: [{ text: "" }] }]
          }
          readOnly={readOnly}
          onChange={(value) => {
            onChange?.(value);
            setWordsCount(countWords(value));
          }}
        >
          <ToolbarSlate className={styled.header}>
            <MarkButton disabled={readOnly} format="bold" icon={<BoldIcon />} />
            <MarkButton disabled={readOnly} format="italic" icon={<Italic />} />
            <MarkButton
              disabled={readOnly}
              format="underline"
              icon={<UnderLine />}
            />
            <BlockButton
              disabled={readOnly}
              format="heading-one"
              icon={<HeadingOne />}
            />
            <BlockButton
              disabled={readOnly}
              format="heading-two"
              icon={<HeadingTwo />}
            />
            <BlockButton
              disabled={readOnly}
              format="block-quote"
              icon={<BlockQuote />}
            />
            <BlockButton
              disabled={readOnly}
              format="numbered-list"
              icon={<ListNum />}
            />
            <BlockButton
              disabled={readOnly}
              format="bulleted-list"
              icon={<ListPuce />}
            />
            <BlockButton
              disabled={readOnly}
              format="left"
              icon={<TextAlignLeft />}
            />
            <BlockButton
              disabled={readOnly}
              format="center"
              icon={<TextAlignCenter />}
            />
            <BlockButton
              disabled={readOnly}
              format="right"
              icon={<TextAlignRight />}
            />
            <BlockButton
              disabled={readOnly}
              format="justify"
              icon={<JustifyIcon />}
            />
          </ToolbarSlate>
          <Editable
            className={"slate"}
            style={{ minHeight: "400px", fontSize: "16px" }}
            placeholder="Type your answer ..."
            onPaste={(event) => {
              if (!allowPaste) {
                event.preventDefault();
                dispatch(
                  setNotification({
                    type: "info",
                    message: "Pasting is not allowed in this field.",
                  }),
                );
                return;
              }
            }}
            onKeyDown={(event) => {
              for (const hotkey in HOTKEYS) {
                if (isHotkey(hotkey, event)) {
                  event.preventDefault();
                  const mark = HOTKEYS[hotkey];
                  toggleMark(editor, mark);
                }
              }
            }}
            renderLeaf={renderLeaf}
            renderElement={renderElement}
            readOnly={readOnly}
          />
        </Slate>
      </div>
      {wordsCount > 0 && (
        <div className={styled.wordsCount}>Words: {wordsCount}</div>
      )}
    </div>
  );
};
export default Editor;
