import React, { useState } from 'react';
import {Card, Switch, Input, Slider, Button } from 'antd';
import styles from './assignmentSettings.module.css';
import { useIntl, FormattedMessage } from 'react-intl';
import { Field } from 'formik';


const AssignmentSettings = ({values, handleChange, setFieldValue, errors, isEditing = false}) => {

  const intl = useIntl();

  const getSliderBackground = (value) => {
    if (Number(value) === 0) return 'linear-gradient(to left, #D3D3D3 0%, #D3D3D3 100%)';
    if (Number(value) === 50) return 'linear-gradient(to left, #D3D3D3 0%, #D3D3D3 50%, #000095 50%, #000095 100%)';
    return 'linear-gradient(to right, #000095 0%, #000095 100%)';
  };

  const getLabel = (value) => {
    if (Number(value) === 0) return intl.formatMessage({ id: 'severity.permissive' });
    if (Number(value) === 50) return intl.formatMessage({ id: 'severity.moderate' });
    return intl.formatMessage({ id: 'severity.severe' });
  };


    return (
      <div className={styles.settingsContainer}>
        <div className={styles.subtitle}>
          <h2>
            <FormattedMessage id="assignment.settings.title" />
          </h2>
        </div>
        <div className={styles.generaleSettings}>
          <div className={styles.generaleSettings__left}>
            <h4>
              <FormattedMessage id="assignment.settings.form.general.settings.title" />
            </h4>
            <p>
              <FormattedMessage id="assignment.settings.form.general.settings.subtitle" />
            </p>
          </div>
          <div className={styles.generaleSettings__right}>
            <Card className={styles.section}>
              <div className={styles.settingItem}>
                <div className={styles.settingItem__content}>
                  <div className={styles.settingItem__label}>
                    <h5>
                      <FormattedMessage id="assignment.settings.form.general.settings.timeLimit.label" />
                    </h5>
                    <p>
                      <FormattedMessage id="assignment.settings.form.general.settings.timeLimit.label.subtitle" />
                    </p>
                    <div className={styles.settingItem__control}>
                      {values?.setting?.hasTimeLimit && (
                        <div className={styles.control}>
                          {isEditing ? (
                            <>
                              <Field
                                name="setting.timeLimit"
                                type="number"
                                className={styles.inputField}
                                value={
                                  values.setting.timeLimit
                                    ? values.setting.timeLimit
                                    : 1
                                }
                                suffix="Min"
                                onChange={(e) =>
                                  setFieldValue(
                                    "setting.timeLimit",
                                    e.target.value,
                                  )
                                }
                              />
                              <span className={styles.suffix}>Min</span>
                            </>
                          ) : (
                            <>
                              <Field
                                name="setting.timeLimit"
                                type="number"
                                className={styles.inputField}
                                defaultValue={0}
                                suffix="Min"
                                onChange={(e) =>
                                  setFieldValue(
                                    "setting.timeLimit",
                                    e.target.value,
                                  )
                                }
                              />
                              <span className={styles.suffix}>Min</span>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <Switch
                    checked={values?.setting?.hasTimeLimit}
                    name={"setting.hasTimeLimit"}
                    className={styles.switch}
                    onChange={(value) => {
                      setFieldValue("setting.hasTimeLimit", value);
                    }}
                  />
                </div>
              </div>

              {values?.isPracticeMode &&
                <div className={styles.settingItem}>
                  <div className={styles.settingItem__content}>
                    <div className={styles.settingItem__label}>
                      <h5>
                        <FormattedMessage id="assignment.settings.form.general.settings.attempts.label" />
                      </h5>
                      <p>
                        <FormattedMessage id="assignment.settings.form.general.settings.attempts.label.subtitle" />
                      </p>
                      {values?.setting?.hasAttempts && (
                        <div className={styles.settingItem__control}>
                          {isEditing ? (
                            <Field
                              name="attempts"
                              type="number"
                              className={styles.inputField}
                              value={
                                values?.setting?.attempts
                                  ? values?.setting?.attempts
                                  : 1
                              }
                              onChange={(e) =>
                                setFieldValue(
                                  "setting.attempts",
                                  e.target.value,
                                )
                              }
                            />
                          ) : (
                            <Field
                              name="attempts"
                              type="number"
                              className={styles.inputField}
                              defaultValue={1}
                              onChange={(e) =>
                                setFieldValue(
                                  "setting.attempts",
                                  e.target.value,
                                )
                              }
                            />
                          )}
                        </div>
                      )}
                    </div>
                    <Switch
                      checked={values?.setting?.hasAttempts}
                      className={styles.switch}
                      onChange={(value) =>
                        setFieldValue("setting.hasAttempts", value)
                      }
                    />
                  </div>
                </div>
              }

              <div className={styles.settingItem}>
                <div className={styles.settingItem__content}>
                  <div className={styles.settingItem__label}>
                    <h5>
                      <FormattedMessage id="assignment.settings.form.general.settings.allowPasting.label" />
                    </h5>
                    <p>
                      <FormattedMessage id="assignment.settings.form.general.settings.allowPasting.label.subtitle" />
                    </p>
                  </div>
                  <Switch
                    checked={values?.setting?.allowPaste}
                    className={styles.switch}
                    onChange={(value) =>
                      setFieldValue("setting.allowPaste", value)
                    }
                  />
                </div>
              </div>

              <div className={styles.settingItem}>
                <div className={styles.settingItem__content}>
                  <div className={styles.settingItem__label}>
                    <h5>
                      <FormattedMessage id="assignment.settings.form.general.settings.showRubric.label" />
                    </h5>
                    <p>
                      <FormattedMessage id="assignment.settings.form.general.settings.showRubric.label.subtitle" />
                    </p>
                  </div>
                  <Switch
                    checked={values?.setting?.hasRubric}
                    className={styles.switch}
                    onChange={(value) =>
                      setFieldValue("setting.hasRubric", value)
                    }
                  />
                </div>
              </div>
            </Card>
          </div>
        </div>

        <div className={styles.generaleSettings}>
          <div className={styles.generaleSettings__left}>
            <h4>
              <FormattedMessage id="assignment.settings.form.essay.settings.title" />
            </h4>
            <p>
              <FormattedMessage id="assignment.settings.form.essay.settings.subtitle" />
            </p>
          </div>
          <div className={styles.generaleSettings__right}>
            <Card className={styles.section}>
              <div className={styles.settingItem}>
                <div className={styles.settingItem__content}>
                  <div className={styles.settingItem__label}>
                    <h5>
                      <FormattedMessage id="assignment.settings.form.essay.settings.allowHandWritten.label" />
                    </h5>
                    <p>
                      <FormattedMessage id="assignment.settings.form.essay.settings.allowHandWritten.label.subtitle" />
                    </p>
                  </div>
                  <Switch
                    checked={values?.allowHandWriting}
                    className={styles.switch}
                    onChange={(value) =>
                      setFieldValue("allowHandWriting", value)
                    }
                  />
                </div>
              </div>

              <div className={styles.settingItem}>
                <div className={styles.settingItem__content}>
                  <div className={styles.settingItem__label}>
                    <h5>
                      <FormattedMessage id="assignment.settings.form.essay.settings.allowUploadPdf.label" />
                    </h5>
                    <p>
                      <FormattedMessage id="assignment.settings.form.essay.settings.allowUploadPdf.label.subtitle" />
                    </p>
                  </div>
                  <Switch
                    checked={values?.allowUploadFile}
                    className={styles.switch}
                    onChange={(value) =>
                      setFieldValue("allowUploadFile", value)
                    }
                  />
                </div>
              </div>
            </Card>
          </div>
        </div>

        <div className={styles.generaleSettings}>
          <div className={styles.generaleSettings__left}>
            <h4>
              <FormattedMessage id="assignment.settings.form.ai.settings.title" />
            </h4>
            <p>
              <FormattedMessage id="assignment.settings.form.ai.settings.subtitle" />
            </p>
          </div>
          <div className={styles.generaleSettings__right}>
            <Card className={styles.section}>
              <div className={styles.settingItem}>
                <div className={styles.settingItem__content}>
                  <div className={styles.settingItem__label}>
                    <h5>
                      <FormattedMessage id="assignment.settings.form.ai.settings.provideAiAssistance.label" />
                    </h5>
                    <p>
                      <FormattedMessage id="assignment.settings.form.ai.settings.provideAiAssistance.label.subtitle" />
                    </p>
                  </div>
                  <Switch
                    checked={values?.writingAssistance}
                    className={styles.switch}
                    onChange={(value) =>
                      setFieldValue("writingAssistance", value)
                    }
                  />
                </div>
              </div>
            </Card>
          </div>
        </div>

        <div className={styles.generaleSettings}>
          <div className={styles.generaleSettings__left}>
            <h4>
              <FormattedMessage id="assignment.settings.form.grading.settings.title" />
            </h4>
            <p>
              <FormattedMessage id="assignment.settings.form.grading.settings.subtitle" />
            </p>
          </div>
          <div className={styles.generaleSettings__right}>
            <Card className={styles.section}>
              <div className={styles.settingItem}>
                <div className={styles.settingItem__content}>
                  <div className={styles.settingItem__label}>
                    <h5>
                      <FormattedMessage id="assignment.settings.form.grading.settings.anounimousGrading.label" />
                    </h5>
                    <p>
                      <FormattedMessage id="assignment.settings.form.grading.settings.anounimousGrading.label.subtitle" />
                    </p>
                  </div>
                  <Switch
                    checked={values?.setting?.anonymousGrading}
                    className={styles.switch}
                    onChange={(value) =>
                      setFieldValue("setting.anonymousGrading", value)
                    }
                  />
                </div>
              </div>

              <div className={styles.settingItem}>
                <div className={styles.settingItem__content}>
                  <div className={styles.settingItem__labelSeverity}>
                    <h5>
                      <FormattedMessage id="assignment.settings.form.grading.settings.severity.label" />
                    </h5>
                    <p>
                      <FormattedMessage id="assignment.settings.form.grading.settings.severity.label.subtitle" />
                    </p>
                  </div>
                  <div className={styles.sliderContainer}>
                    <div className={styles.labels}>
                      <span>
                        {intl.formatMessage({ id: "severity.permissive" })}
                      </span>
                      <span>
                        {intl.formatMessage({ id: "severity.moderate" })}
                      </span>
                      <span>
                        {intl.formatMessage({ id: "severity.severe" })}
                      </span>
                    </div>
                    <input
                      type="range"
                      min="0"
                      max="100"
                      step="50"
                      value={values?.setting?.severity}
                      onChange={(e) => {
                        setFieldValue("setting.severity", e.target.value);
                      }}
                      className={styles.slider}
                      style={{
                        background: getSliderBackground(
                          values?.setting?.severity,
                        ),
                      }}
                    />
                    <div className={styles.valueIndicator}>
                      {getLabel(values?.setting?.severity)}
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    );
  };


  export default AssignmentSettings;