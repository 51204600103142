import { useEffect, useState } from "react";
import styles from "./createAssignment.module.css";
import { Field, FieldArray } from "formik";
import {
  Button,
  DatePicker,
  Dropdown,
  Input,
  Popover,
  Select,
  Switch,
  Radio
} from "antd";
import dayjs from "dayjs";
import {
  AlignLeftOutlined,
  DeleteOutlined,
  LinkOutlined,
  PlusCircleFilled,
  PlusOutlined,
  UploadOutlined,
  YoutubeFilled,
  YoutubeOutlined,
} from "@ant-design/icons";
import ChatIcon from "../../assets/icons/ChatIcon";
import RemoveIcon from "../../assets/icons/RemoveIcon";
import UploadFileIcon from "../../assets/icons/UploadFileIcon";
import SuffixIcon from "../../assets/icons/SuffixIcon";
import FileIcon from "../../assets/icons/FileIcon";
import TrashIcon from "../../assets/icons/TrashIcon";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { selectAuthenticatedUser } from "../../redux/auth/auth.selectors";
import { useLazyGetRubricsByTeacherQuery } from "../../services/rubrics.api";
import ErrorMessage from "../ErrorMessage";
import MagicContentPopover from "./components/MagicContentPopover";
import {
  disabledDate,
  formatFileSize,
  getInitialQuestion,
  handleFormatChange,
  handleQuestionTypeChange,
} from "./utils";
import {useGenerateCourseworkHelperWithIaMutation} from "../../services/coursworks.api";
import {selectClassroom} from "../../redux/app/app.selectors";

const CreateAssignment = ({
  creationMode,
  values,
  handleBlur,
  setFieldValue,
  errors,
  isEditing = false,
  setIsDraft,
}) => {
  const [showSelect, setShowSelect] = useState({});

  const intl = useIntl();
  const user = useSelector(selectAuthenticatedUser);
  const [triggerRubric, { data: rubricData, isLoading: isLoadingRubrics }] =
    useLazyGetRubricsByTeacherQuery();
  // here
  const classroom = useSelector(selectClassroom);
  const [generateCourseworkHelperWithIa, {isLoading : isLoadingHelperIa}] = useGenerateCourseworkHelperWithIaMutation()
  const [magicValue, setMagicValue] = useState("");

  function updateQuestion( index, updatedQuestion) {

    if (index >= 0 && index < values.questions.length) {
      const question = processQuestion(updatedQuestion.question);
      values.questions[index] = { ...question };
    } else {
      console.log("Invalid question index.");
    }
  }
  const processQuestion = (question) => {
    return {
      ...question,
      isJustification: !!question?.isJustification,
      options: !!question?.options?.length ? question?.options : [],
      links: !!question?.links?.length ? question?.links : [],
    };
  };

  const handleMagicValue =  async (value) => {
    let formData = new FormData();
    formData.append("grade", classroom.grade);
    formData.append("value", value.inputValue);
    formData.append("type", value.type);
    formData.append("questionText", value.questionText);
    console.log("values",values)

    // values.index
    const {data, error} = await generateCourseworkHelperWithIa({formData:formData});

    console.log('dattta',  data)
    // update here values with data
    if(data){
      updateQuestion( value.index, data['hydra:member'][0]);
      console.log("ddfffexqsxqsxref",values)
    }
    if (error){
        console.log(error)
    }
  }
  useEffect(() => {
    if (user) {
      triggerRubric({ teacherId: user.id });
    }
  }, [triggerRubric, user]);

  const [isDescription, setIsDescription] = useState(false);

  const openRubrics = (index) => {
    setShowSelect((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };
console.log(">>errors",errors)
  return (
    <div>
      <div className={styles.newAssignmentContainer}>
        <div className={styles.newAssignmentContent}>
          {/* Basic Fields remain the same */}
          <div className={styles.formField}>
            <label>
              {intl.formatMessage({
                id: "assignment.create.form.field.label.title",
              })}
            </label>
            <div>
              <Field
                name={"title"}
                placeholder={intl.formatMessage({
                  id: "assignment.create.form.field.title.placeholder",
                })}
                className={styles.formFieldInput}
              />

              {errors.title && <ErrorMessage message={errors.title} />}
            </div>
          </div>
          {/** Due date **/}
          <div className={styles.dueDate}>
            <label className="assignmentFields-title">
              {intl.formatMessage({
                id: "assignment.create.form.field.label.dueDate",
              })}
            </label>
            <div>
              <DatePicker
                showTime={{ format: "HH:mm" }}
                format="YYYY-MM-DD HH:mm"
                disabledDate={disabledDate}
                placeholder={intl.formatMessage({
                  id: "assignment.create.form.field.dueDate.placeholder",
                })}
                value={values?.dueDate ? dayjs(values?.dueDate) : null}
                onChange={(date) => {
                  setFieldValue(
                    "dueDate",
                    date ? date.format("YYYY-MM-DDTHH:mm") : null,
                  );
                }}
                style={{
                  height: "44px",
                  width: "512px",
                  fontSize: "16px",
                }}
                size="24px"
              />
              {errors.dueDate && <ErrorMessage message={errors.dueDate} />}
            </div>
          </div>

          {/* ... other basic fields ... */}
          <div className={styles.formField}>
            <label>
              {intl.formatMessage({
                id: "assignment.create.form.field.label.format",
              })}
            </label>
            <Select
              value={values?.type}
              className={styles.formFieldInputSelect}
              onChange={(value) => handleFormatChange(setFieldValue, value)}
              suffixIcon={<SuffixIcon />}
              options={[
                {
                  value: "Quiz",
                  label: intl.formatMessage({
                    id: "assignment.create.form.field.format.option.label.quiz",
                  }),
                },
                {
                  value: "Essay",
                  label: intl.formatMessage({
                    id: "assignment.create.form.field.format.option.label.essay",
                  }),
                },
              ]}
            />
          </div>

          {/* ... state ... */}
          <div className={styles.formField}>
            <label>
              {intl.formatMessage({
                id: "assignment.create.form.field.label.state",
              })}
            </label>
            <Radio.Group
              value={values?.state === "active"}
              onChange={(event) => {
                setFieldValue("state", event.target.value ? "active" : "draft");
                setIsDraft(!event.target.value);
              }
              }
              buttonStyle="solid"
              className={styles.radioGroup}
            >
              <Radio.Button value={true}>
                {intl.formatMessage({
                  id: "assignment.create.form.field.state.checked",
                })}
              </Radio.Button>
              <Radio.Button value={false}>
                {intl.formatMessage({
                  id: "assignment.create.form.field.state.unchecked",
                })}
              </Radio.Button>
            </Radio.Group>
          </div>
          <hr className={styles.divider} />

          <div className={styles.blockDescription}>
            <div className={styles.fieldDescription}>
              {intl.formatMessage({
                id: "assignment.create.form.field.description.label",
              })}
              <Switch
                checked={isDescription}
                value={values?.description !== "" ? true : false}
                onChange={() => setIsDescription(!isDescription)}
              />
            </div>
            {isDescription && (
              <Field
                as={"textarea"}
                name={"description"}
                className={styles.textareaDescription}
                placeholder={intl.formatMessage({
                  id: "assignment.create.form.field.description.placeholder",
                })}
                value={values?.description}
                onChange={(e) => setFieldValue("description", e.target.value)}
              />
            )}
          </div>

          <FieldArray name="questions">
            {({ insert, remove, push }) => (
              <div>
                {values?.questions?.map((question, index) => (
                  <div className={styles.questionBlock} key={index}>
                    <div className={styles.questionHeader}>
                      {values?.format !== "Essay" && (
                        <h3>
                          {intl.formatMessage({
                            id: "assignment.create.form.questions.header.index",
                          })}
                          {" " + (index + 1) + " / " + values?.questions.length}
                        </h3>
                      )}

                      {values?.type !== "Essay" && (
                        <div className={styles.headerBlockBtn}>
                          <button
                            type="button"
                            onClick={() => {
                              if (values?.questions.length > 1) {
                                remove(index);
                              }
                            }}
                            className={styles.removeQuestion}
                          >
                            <DeleteOutlined />
                          </button>
                        </div>
                      )}
                    </div>
                    <div className={styles.questionsBlockContent}>
                      {/* Show type selector for custom and MCQ formats */}
                      {values?.type === "Quiz" && (
                        <div className={styles.questionTypeBlock}>
                          <div className={styles.questionType}>
                            <label>
                              {intl.formatMessage({
                                id: "assignment.create.form.questions.field.label.type",
                              })}
                            </label>
                            <Field
                              as="select"
                              name={`questions.${index}.type`}
                              className={styles.selectField}
                              onChange={(e) =>
                                handleQuestionTypeChange(
                                  setFieldValue,
                                  index,
                                  e.target.value,
                                )
                              }
                            >
                              {values?.type === "Quiz" && (
                                <>
                                  <option value="MultiChoice">
                                    {intl.formatMessage({
                                      id: "assignment.create.form.questions.field.type.option.multiChoice",
                                    })}
                                  </option>
                                  <option value="ShortAnswer">
                                    {intl.formatMessage({
                                      id: "assignment.create.form.questions.field.type.option.shortAnswer",
                                    })}
                                  </option>
                                  <option value="Essay">
                                    {intl.formatMessage({
                                      id: "assignment.create.form.questions.field.type.option.essay",
                                    })}
                                  </option>
                                </>
                              )}
                            </Field>
                          </div>
                          <div className={styles.gradeBlock}>
                            <label>
                              {intl.formatMessage({
                                id: "assignment.create.form.questions.field.label.grade",
                              })}
                            </label>
                            <Field
                              type="number"
                              defaultValue={0}
                              name={`questions.${index}.grade`}
                              className={styles.numberField}
                              min={0}
                              max={100}
                              step={1}
                            />
                            {errors?.questions?.[index]?.grade && (
                              <ErrorMessage
                                message={errors?.questions?.[index]?.grade}
                              />
                            )}
                          </div>
                        </div>
                      )}
                      <div className={styles.contextContainer}>
                        <div className={styles.contextToggleContainer}>
                          <div className={styles.contextToggle}>
                            <div className={styles.contextMenuIcon}>
                              <AlignLeftOutlined />
                            </div>
                            <span className={styles.contextLabel}>
                              {intl.formatMessage({
                                id: "assignment.create.form.questions.field.label.context",
                              })}
                            </span>
                            <Switch
                              checked={
                                question?.context !== null &&
                                question?.context !== undefined
                              }
                              onChange={(value) =>
                                setFieldValue(
                                  `questions.${index}.context`,
                                  question?.context !== null &&
                                    question?.context !== undefined
                                    ? null
                                    : "",
                                )
                              }
                            />
                          </div>
                          {values?.type === "Essay" && (
                            <div className={styles.gradeBlock}>
                              <label>
                                {intl.formatMessage({
                                  id: "assignment.create.form.questions.field.label.grade",
                                })}
                              </label>
                              <Field
                                type="number"
                                defaultValue={0}
                                name={`questions.${index}.grade`}
                                className={styles.numberField}
                                min={0}
                                max={100}
                                step={1}
                              />
                              {errors?.questions?.[index]?.grade && (
                                <ErrorMessage
                                  message={errors?.questions?.[index]?.grade}
                                />
                              )}
                            </div>
                          )}
                        </div>
                        {question?.context !== null &&
                          question?.context !== undefined && (
                            <div className={styles.contextInput}>
                              <div>
                                <Field
                                  as="textarea"
                                  name={`questions.${index}.context`}
                                  placeholder={intl.formatMessage({
                                    id: "assignment.create.form.questions.field.context.contentText.placeholder",
                                  })}
                                  className={styles.textAreaInput}
                                />
                                {errors?.questions?.[index]?.context && (
                                  <ErrorMessage
                                    message={
                                      errors?.questions?.[index]?.context
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          )}
                      </div>
                      {/* start */}
                      {question?.links?.length > 0 && (
                        <div className={styles.linksContainer}>
                          {question?.links?.map((link, linkIndex) => {
                            const error =
                              errors?.questions?.[index]?.links?.[linkIndex]
                                ?.url;
                            return (
                              <>
                                <div
                                  key={linkIndex}
                                  className={styles.linkInputRow}
                                >
                                  <span className={styles.linkType}>
                                    {link?.type === "youtube" ? (
                                      <YoutubeFilled style={{ color: "red" }} />
                                    ) : (
                                      <LinkOutlined
                                        style={{ color: "#4F46E5" }}
                                      />
                                    )}
                                  </span>
                                  <Input
                                    type="text"
                                    className={styles.linkInput}
                                    placeholder={
                                      link?.type === "youtube"
                                        ? "Enter YouTube URL"
                                        : "Enter Hypertext Link"
                                    }
                                    value={link?.url}
                                    onChange={(e) => {
                                      const updatedLinks = [...question.links];
                                      updatedLinks[linkIndex].url =
                                        e.target.value;
                                      setFieldValue(
                                        `questions.${index}.links`,
                                        updatedLinks,
                                      );
                                    }}
                                    suffix={
                                      <DeleteOutlined
                                        onClick={() => {
                                          const updatedLinks =
                                            question.links.filter(
                                              (_, i) => i !== linkIndex,
                                            );
                                          setFieldValue(
                                            `questions.${index}.links`,
                                            updatedLinks,
                                          );
                                        }}
                                      />
                                    }
                                  />
                                </div>
                                {error && <ErrorMessage message={error} />}
                              </>
                            );
                          })}
                        </div>
                      )}

                      {/* end */}
                      {(values?.type === "Quiz" && question.type === "Essay") ||
                      values?.type === "Essay" ? (
                        <>
                          <div className={styles.rubricBlock}>
                            <label>
                              {intl.formatMessage({
                                id: "assignment.create.form.questions.field.label.rubric",
                              })}
                            </label>
                            <Button
                              htmlType="button"
                              className={styles.rubricBtn}
                              onClick={() => openRubrics(index)}
                            >
                              <PlusCircleFilled />
                              {intl.formatMessage({
                                id: "assignment.create.form.questions.button.rubric.add",
                              })}
                            </Button>
                            {(showSelect[index] ||
                              values?.questions?.[index]?.rubric) && (
                              <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                value={values?.questions[index].rubric}
                                className={styles.rubricSelect}
                                style={{ marginTop: 12 }}
                                placeholder={intl.formatMessage({
                                  id: "assignment.create.form.questions.field.rubric.placeholder",
                                })}
                                name={`questions.${index}.rubric`}
                                onChange={(value) =>
                                  setFieldValue(
                                    `questions.${index}.rubric`,
                                    value,
                                  )
                                }
                              >
                                {console.log("valllluesss::::", values)}
                                {rubricData?.["hydra:member"]
                                  ?.filter(
                                    (rubric) =>
                                      rubric.type === values?.type ||
                                      rubric.type ===
                                        values?.questions?.[index]?.type,
                                  )
                                  ?.map((rubric) => (
                                    <Select.Option
                                      key={rubric?.id}
                                      value={rubric["@id"]}
                                    >
                                      {rubric.title}
                                    </Select.Option>
                                  ))}
                              </Select>
                            )}
                            {errors?.questions?.[index]?.rubric && (
                              <ErrorMessage
                                message={errors?.questions?.[index]?.rubric}
                              />
                            )}
                          </div>
                          <div className={styles.questionText}>
                            <label>
                              {intl.formatMessage({
                                id: "assignment.create.form.questions.field.label.instruction",
                              })}
                            </label>
                            <div
                              className={`${styles.magicBlock} ${styles.containerBlockTextAreaAndUpload}`}
                            >
                              <div className={styles.blockTextAreaAndUpload}>
                                <div>
                                  <Field
                                    as="textarea"
                                    name={`questions.${index}.questionText`}
                                    placeholder={intl.formatMessage({
                                      id: "assignment.create.form.questions.field.instruction.placeholder",
                                    })}
                                    className={styles.textAreaInput}
                                  />
                                </div>

                                <div className={styles.uploadFile}>
                                  {/* Custom Styled Button */}
                                  <Dropdown
                                    menu={{
                                      items: [
                                        {
                                          key: "fileUpload",
                                          label: (
                                            <label
                                              htmlFor={`file-upload-${index}`}
                                            >
                                              <UploadOutlined /> Upload File
                                            </label>
                                          ),
                                          onClick: () => {},
                                        },
                                        {
                                          key: "hyperlink",
                                          label: (
                                            <span>
                                              <LinkOutlined /> Link
                                            </span>
                                          ),
                                          onClick: () => {
                                            const updatedLinks = [
                                              ...values.questions[index].links,
                                              { type: "hypertext", url: "" },
                                            ];
                                            setFieldValue(
                                              `questions.${index}.links`,
                                              updatedLinks,
                                            );
                                          },
                                        },
                                        {
                                          key: "youtube",
                                          label: (
                                            <span>
                                              <YoutubeOutlined /> Youtube Video
                                            </span>
                                          ),
                                          onClick: () => {
                                            const updatedLinks = [
                                              ...values.questions[index].links,
                                              { type: "youtube", url: "" },
                                            ];
                                            setFieldValue(
                                              `questions.${index}.links`,
                                              updatedLinks,
                                            );
                                          },
                                        },
                                      ],
                                    }}
                                    placement="bottomRight"
                                  >
                                    <a onClick={(e) => e.preventDefault()}>
                                      <UploadFileIcon />
                                    </a>
                                  </Dropdown>

                                  {/* Hidden File Input */}
                                  <input
                                    name={`questions.${index}.resources`}
                                    accept=".pdf,.doc,.docx,.txt,image/*,.xls,.xlsx,.ppt,.pptx"
                                    id={`file-upload-${index}`}
                                    type="file"
                                    multiple={true}
                                    style={{ display: "none" }}
                                    onChange={(e) => {
                                      const files = Array.from(
                                        e.target.files || [],
                                      );

                                      // Validate file size (e.g., 8MB limit)
                                      const validFiles = files.filter(
                                        (file) => file.size <= 8 * 1024 * 1024,
                                      );

                                      if (validFiles.length === 0) {
                                        console.log("No valid files selected.");
                                        return;
                                      }

                                      // Append new files to existing files
                                      const existingFiles =
                                        values.questions[index].resources || [];
                                      const updatedFiles = [
                                        ...existingFiles,
                                        ...validFiles,
                                      ];

                                      // Update form state
                                      setFieldValue(
                                        `questions.${index}.resources`,
                                        updatedFiles,
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                              <MagicContentPopover
                                index={index}
                                type={question.type}
                                handleClick={handleMagicValue}
                                questionText={
                                  values?.questions?.[index]?.questionText
                                }
                                isLoading={isLoadingHelperIa}
                              />
                            </div>
                            {errors.questions &&
                              errors.questions[index] &&
                              errors.questions[index].questionText && (
                                <ErrorMessage
                                  key={index}
                                  message={
                                    errors?.questions?.[index]?.questionText
                                  }
                                />
                              )}
                          </div>
                          {/* Display Uploaded File Name */}
                          {values?.questions?.[index]?.resources?.length > 0 &&
                            values?.questions?.[index]?.resources?.map(
                              (resource, resourceIndex) => (
                                <div className={styles.blockUploadFiles}>
                                  <div className={styles.fileContent}>
                                    <div className={styles.iconFile}>
                                      <FileIcon className={styles.fileIcon} />
                                    </div>
                                    <div className={styles.blockFileInfo}>
                                      <span>
                                        {resource?.name || resource?.filePath}
                                      </span>
                                      <span>
                                        {formatFileSize(
                                          resource?.size || resource?.fileSize,
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                  <button
                                    type={"button"}
                                    onClick={() => {
                                      // setFieldValue(
                                      //   `questions.${index}.resource[${resourceIndex}]`,
                                      //   null
                                      // );

                                      // Get current resources array
                                      const currentResources = [
                                        ...values.questions[index].resources,
                                      ];

                                      // Remove the item at resourceIndex
                                      currentResources.splice(resourceIndex, 1);

                                      // Update the entire resources array
                                      setFieldValue(
                                        `questions.${index}.resources`,
                                        currentResources,
                                      );
                                    }}
                                  >
                                    <TrashIcon />
                                  </button>
                                </div>
                              ),
                            )}
                        </>
                      ) : (
                        <>
                          {question.type === "ShortAnswer" && (
                            <div className={styles.rubricBlock}>
                              <label>
                                {intl.formatMessage({
                                  id: "assignment.create.form.questions.field.label.rubric",
                                })}
                              </label>
                              <Button
                                htmlType="button"
                                className={styles.rubricBtn}
                                onClick={() => openRubrics(index)}
                              >
                                <PlusCircleFilled />
                                {intl.formatMessage({
                                  id: "assignment.create.form.questions.button.rubric.add",
                                })}
                              </Button>
                              {(showSelect[index] ||
                                values?.questions?.[index]?.rubric) && (
                                <Select
                                  showSearch
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  className={styles.rubricSelect}
                                  style={{ marginTop: 12 }}
                                  placeholder={intl.formatMessage({
                                    id: "assignment.create.form.questions.field.rubric.placeholder",
                                  })}
                                  name={`questions.${index}.rubric`}
                                  onChange={(value) =>
                                    setFieldValue(
                                      `questions.${index}.rubric`,
                                      value,
                                    )
                                  }
                                  value={values?.questions[index]?.rubric}
                                >
                                  {console.log("type ::::-->", question?.type)}
                                  {rubricData?.["hydra:member"]
                                    ?.filter(
                                      (rubric) =>
                                        rubric.type === question?.type,
                                    )
                                    ?.map((rubric) => (
                                      <Select.Option
                                        key={rubric.id}
                                        value={rubric["@id"]}
                                      >
                                        {rubric.title}
                                      </Select.Option>
                                    ))}
                                </Select>
                              )}
                              {errors?.questions?.[index]?.rubric && (
                                <ErrorMessage
                                  message={errors?.questions?.[index]?.rubric}
                                />
                              )}
                            </div>
                          )}
                          <div className={styles.questionText}>
                            <label>
                              {intl.formatMessage({
                                id: "assignment.create.form.questions.field.label.question",
                              })}
                            </label>
                            <div className={styles.magicBlockInput}>
                              <div className={styles.blockTextAndUpload}>
                                <Field
                                  name={`questions.${index}.questionText`}
                                  placeholder={intl.formatMessage({
                                    id: "assignment.create.form.questions.field.question.text.placeholder",
                                  })}
                                  className={styles.textInput}
                                />
                                <div className={styles.uploadFileInTextInput}>
                                  {/* Custom Styled Button */}
                                  <Dropdown
                                    menu={{
                                      items: [
                                        {
                                          key: "fileUpload",
                                          label: (
                                            <label
                                              htmlFor={`file-upload-${index}`}
                                            >
                                              <UploadOutlined /> Upload File
                                            </label>
                                          ),
                                          onClick: () => {},
                                        },
                                        {
                                          key: "hyperlink",
                                          label: (
                                            <span>
                                              <LinkOutlined /> Link
                                            </span>
                                          ),
                                          onClick: () => {
                                            const updatedLinks = [
                                              ...values.questions[index].links,
                                              { type: "hypertext", url: "" },
                                            ];
                                            setFieldValue(
                                              `questions.${index}.links`,
                                              updatedLinks,
                                            );
                                          },
                                        },
                                        {
                                          key: "youtube",
                                          label: (
                                            <span>
                                              <YoutubeOutlined /> Youtube Video
                                            </span>
                                          ),
                                          onClick: () => {
                                            const updatedLinks = [
                                              ...values.questions[index].links,
                                              { type: "youtube", url: "" },
                                            ];
                                            setFieldValue(
                                              `questions.${index}.links`,
                                              updatedLinks,
                                            );
                                          },
                                        },
                                      ],
                                    }}
                                    placement="bottomRight"
                                  >
                                    <a onClick={(e) => e.preventDefault()}>
                                      <UploadFileIcon />
                                    </a>
                                  </Dropdown>

                                  {/* Hidden File Input */}
                                  <input
                                    name={`questions.${index}.resources`}
                                    accept=".pdf,.doc,.docx,.txt,image/*,.xls,.xlsx"
                                    id={`file-upload-${index}`}
                                    type="file"
                                    multiple={true}
                                    style={{ display: "none" }}
                                    onChange={(e) => {
                                      const files = Array.from(
                                        e.target.files || [],
                                      );

                                      // Validate file size (e.g., 8MB limit)
                                      const validFiles = files.filter(
                                        (file) => file.size <= 8 * 1024 * 1024,
                                      );

                                      if (validFiles.length === 0) {
                                        console.log("No valid files selected.");
                                        return;
                                      }

                                      // Append new files to existing files
                                      const existingFiles =
                                        values.questions[index].resources || [];
                                      const updatedFiles = [
                                        ...existingFiles,
                                        ...validFiles,
                                      ];

                                      // Update form state
                                      setFieldValue(
                                        `questions.${index}.resources`,
                                        updatedFiles,
                                      );
                                    }}
                                  />
                                </div>
                              </div>

                              <MagicContentPopover
                                index={index}
                                type={question.type}
                                handleClick={handleMagicValue}
                                questionText={
                                  values?.questions?.[index]?.questionText
                                }
                                isLoading={isLoadingHelperIa}
                              />
                            </div>
                            {errors?.questions?.[index]?.questionText && (
                              <ErrorMessage
                                message={
                                  errors?.questions?.[index]?.questionText
                                }
                              />
                            )}

                            {/* Display Uploaded File Name */}
                            {values?.questions?.[index]?.resources?.length >
                              0 &&
                              values?.questions[index]?.resources?.map(
                                (resource, resourceIndex) => (
                                  <div className={styles.blockUploadFiles}>
                                    <div className={styles.fileContent}>
                                      <div className={styles.iconFile}>
                                        <FileIcon className={styles.fileIcon} />
                                      </div>
                                      <div className={styles.blockFileInfo}>
                                        <span>
                                          {" "}
                                          {resource?.name || resource?.filePath}
                                        </span>
                                        <span>
                                          {formatFileSize(
                                            resource?.size ||
                                              resource?.fileSize,
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                    <button
                                      type={"button"}
                                      onClick={() => {
                                        // setFieldValue(
                                        //   `questions.${index}.resource[${resourceIndex}]`,
                                        //   null
                                        // );

                                        // Get current resources array
                                        const currentResources = [
                                          ...values.questions[index].resources,
                                        ];

                                        // Remove the item at resourceIndex
                                        currentResources.splice(
                                          resourceIndex,
                                          1,
                                        );

                                        // Update the entire resources array
                                        setFieldValue(
                                          `questions.${index}.resources`,
                                          currentResources,
                                        );
                                      }}
                                    >
                                      <TrashIcon />
                                    </button>
                                  </div>
                                ),
                              )}
                          </div>

                          {question.type === "MultiChoice" && (
                            <>
                              <div className={styles.justificationToggle}>
                                <div className="flex align-center gap-4">
                                  <div className={styles.contextMenuIcon}>
                                    <ChatIcon />
                                  </div>
                                  <span className={styles.contextLabel}>
                                    {intl.formatMessage({
                                      id: "assignment.create.form.questions.field.label.question.justification",
                                    })}
                                  </span>
                                </div>
                                <Switch
                                  checked={question.isJustification}
                                  onChange={(value) =>
                                    setFieldValue(
                                      `questions.${index}.isJustification`,
                                      value,
                                    )
                                  }
                                />
                              </div>

                              <FieldArray name={`questions.${index}.options`}>
                                {({
                                  push: pushOption,
                                  remove: removeOption,
                                }) => (
                                  <div className={styles.optionsContainer}>
                                    {question?.options?.map(
                                      (option, optionIndex) => (
                                        <>
                                          {errors?.questions?.[index]
                                            ?.options?.[optionIndex]?.text && (
                                            <ErrorMessage
                                              message={
                                                errors?.questions?.[index]
                                                  ?.options?.[optionIndex]?.text
                                              }
                                            />
                                          )}
                                          <div
                                            className={styles.optionRow}
                                            key={optionIndex}
                                          >
                                            <span
                                              className={styles.optionIndex}
                                            >
                                              {String.fromCharCode(
                                                65 + optionIndex,
                                              )}
                                            </span>
                                            <Field
                                              name={`questions.${index}.options.${optionIndex}.text`}
                                              placeholder={`${intl.formatMessage(
                                                {
                                                  id: "assignment.create.form.questions.field.question.options",
                                                },
                                              )} ${String.fromCharCode(
                                                65 + optionIndex,
                                              )}`}
                                              className={styles.textInput}
                                            />
                                            <div
                                              className={
                                                styles.questionIsCorrect
                                              }
                                            >
                                              <Field
                                                as="select"
                                                name={`questions.${index}.options.${optionIndex}.isCorrect`}
                                                className={styles.selectField}
                                                value={
                                                  question?.options?.[
                                                    optionIndex
                                                  ]?.isCorrect
                                                }
                                              >
                                                <option value="false">
                                                  {intl.formatMessage({
                                                    id: "assignment.create.form.questions.field.question.options.incorrect",
                                                  })}
                                                </option>
                                                <option value="true">
                                                  {intl.formatMessage({
                                                    id: "assignment.create.form.questions.field.question.options.correct",
                                                  })}
                                                </option>
                                              </Field>
                                            </div>
                                            <button
                                              type="button"
                                              onClick={() =>
                                                removeOption(optionIndex)
                                              }
                                              className={styles.removeOption}
                                            >
                                              <RemoveIcon />
                                            </button>
                                          </div>
                                          {errors?.questions?.[index]
                                            ?.options && (
                                            <div>
                                              <ErrorMessage
                                                message={
                                                  errors?.questions?.[index]
                                                    ?.options
                                                }
                                              />
                                            </div>
                                          )}
                                        </>
                                      ),
                                    )}
                                    <div className={styles.blockBottomBtns}>
                                      <button
                                        type="button"
                                        onClick={() =>
                                          pushOption({
                                            text: "",
                                            isCorrect: false,
                                          })
                                        }
                                        className={styles.addOption}
                                      >
                                        <PlusOutlined
                                          className={styles.plusIcon}
                                        />{" "}
                                        {intl.formatMessage({
                                          id: "assignment.create.form.questions.field.question.options.button.add",
                                        })}
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </FieldArray>
                            </>
                          )}
                        </>
                      )}
                    </div>
                    {values?.type !== "Essay" && (
                      <div className={styles.blockButtoAddQuestion}>
                        <button
                          type="button"
                          onClick={() => {
                            insert(
                              index + 1,
                              getInitialQuestion(values?.questions[index].type),
                            );
                          }}
                          className={styles.addQuestion}
                        >
                          <PlusOutlined />{" "}
                          {intl.formatMessage({
                            id: "assignment.create.form.questions.header.button.addQuestion",
                          })}
                        </button>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </FieldArray>
        </div>
      </div>
    </div>
  );
};

export default CreateAssignment;
