import React, { useEffect, useState, useRef, useMemo } from "react";
import style from "./assignmentCorrectionsPreview.module.scss";
import {
  CloseCircleOutlined,
  DoubleRightOutlined,
  DownloadOutlined,
  PlusOutlined,
  LeftOutlined,
  RightOutlined,
  LoadingOutlined
} from "@ant-design/icons";
import { Field, Formik } from "formik";
import * as Yup from "yup";

import BarCorrectionCount from "../barCorrectionCount";
import QuestionsItem from "../questionsItem";

import { useIntl } from "react-intl";
import { Button, Spin } from "antd";
import {
  useStudentQuizSubmissionsUpdateMutation,
  useLazyGetSubmissionsIdByCourseworkQuery,
} from "../../services/studentQuizSubmission.api";
import { useDispatch, useSelector } from "react-redux";
import { setNotification } from "../../redux/app/app.slice";
import RemediaIcon from "../../assets/icons/RemediaIcon";
import { useNavigate } from "react-router-dom";
import QuestionsItemForEssay from "../questionsItemForEssay";
import AvatarStudent from "../../assets/icons/AvatarStudent";
import FractionInput from "../fractionInput";
import { useGenerateStudentSubmissionPdfMutation } from "../../services/studentcourseworks.api";
import { selectClassroom } from "../../redux/app/app.selectors";
import CustomAccordion from "../customAccordion";
import dayjs from "dayjs";

const AssignmentCorrectionsPreview = ({
  assignments,
  isPreview = true,
  courseId,
  studentInfo = "",
  isCorrection = false,
}) => {
  const [initialValues, setInitialValues] = useState([]);
  const intl = useIntl();
  const [questionFeedbacks, setQuestionFeedbacks] = useState({});
  const [isManuallyEdited, setIsManuallyEdited] = useState(false);
  const classroom = useSelector(selectClassroom);
  const textareaRef = useRef([]);
  const [updateStudentQuizSubmission] =
    useStudentQuizSubmissionsUpdateMutation();
  const [AssignmentSchema, setAssignmentSchema] = useState(
    Yup.object().shape({})
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [grades, setGrades] = useState([]);

  const [triggerIDS, { data: dataIds, error }] =
    useLazyGetSubmissionsIdByCourseworkQuery();
  const [triggerPDF, {isLoading: isLoadingPdf}] = useGenerateStudentSubmissionPdfMutation();

  useEffect(() => {
    triggerIDS(courseId);
  }, [triggerIDS, courseId]);


  const listIDS = dataIds?.["hydra:member"] || [];

  const currentIndex = listIDS.findIndex(
    (student) => student?.id === assignments?.id
  );

  const previousStudent = currentIndex > 0 ? listIDS[currentIndex - 1] : null;
  const nextStudent =
    currentIndex !== -1 && currentIndex < listIDS.length - 1
      ? listIDS[currentIndex + 1]
      : null;

  const handlePrevious = () => {
    if (previousStudent) {
      previousStudent &&
        navigate(`/assignment/${courseId}/correction/${previousStudent.id}`);
    }
  };

  const handleNext = () => {
    if (nextStudent) {
      nextStudent &&
        navigate(`/assignment/${courseId}/correction/${nextStudent.id}`);
    }
  };

  if (error) dispatch(setNotification({ type: "error", message: error }));

  const generateOrderQuestions = (length) => {
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    return Array.from({ length }, (_, i) => alphabet[i]);
  };

  const orderQuestions = generateOrderQuestions(26);

  const __handleSubmit = async (values, { setSubmitting }) => {
    const mappedData = {
      status: "Graded",
      assessmentQuiz: {
        "@id": values[0]?.studentAssesmentQuiz,
        llmAnswerQuizzes: values.map((item) => {
          return {
            id: item.llmAnswerId,
            "@id": item.llmAnswerIdLink,
            studentAssesmentQuiz: item.studentAssesmentQuiz,
            question: item["@id"],
            output: item.llmAnswer?.answer || [],
            grade: item?.gradeEvaluation.toString() || 0,
          };
        }),
        mark: values && sumGradeEvaluation(values),
        evaluation: "",
      },
    };

    const { data: CorrectedAssignment, error } =
      await updateStudentQuizSubmission({
        id: assignments?.id,
        formData: mappedData,
      });
    if (CorrectedAssignment) {
      dispatch(
        setNotification({
          type: "success",

          message: "Assignment graded successfully",
        })
      );

      triggerIDS(courseId);
    }
    if (error) {
      dispatch(
        setNotification({
          type: "error",
          message: "An error occurred. Please try again.",
        })
      );
      console.log(error);
    }
    //setSubmitting(false);
  };


  const color = {
    MultiChoice: "#ffcf00",
    ShortAnswer: "#000095",
    Essay: "#662D91",
  };

  useEffect(() => {
    setInitialValues(assignments?.questions);
    setAssignmentSchema(Yup.object().shape(AssignmentSchema));
  }, [assignments]);

  function getHighestScore() {
    const result =
      initialValues?.flatMap(
        (item) =>
          item?.rubric &&
          item?.rubric?.criterias?.flatMap((criteria) =>
            criteria?.levels?.map((level) => parseInt(level?.point || 0))
          )
      ) || [];

    // Filter out NaN or invalid values from the result array
    const filteredResult = result.filter((val) => !isNaN(val));

    return Math.max(...filteredResult);
  }


  const highestScore = getHighestScore(assignments);

  const totalGrade = assignments?.questions?.reduce((sum, item) => {
    // Check if grade is present and is a number
    const grade = item.grade ? parseInt(item.grade, 10) : 0;
    return sum + grade;
  }, 0);
  const sumGradeEvaluation = (items) => {
    // if(assignments?.)
    return items
      ?.reduce((sum, item) => sum + Number(item?.gradeEvaluation || 0), 0).toFixed(2)
      .toString();
  };



  // Ensure refs are updated when feedbacks change
  useEffect(() => {
    textareaRef.current = textareaRef.current.slice(
      0,
      questionFeedbacks.length
    );
  }, [questionFeedbacks]);

  useEffect(() => {
    setQuestionFeedbacks(
      assignments.preAssesmentQuiz?.llmAnswerQuizzes.map((item) => ({
        id: item.id,
        output: item.output, // Include the output here
      })) || []
    );
  }, [assignments]);

  const handleExport = async () => {
    const submissionDate = dayjs(assignments?.submittedAt).format('DD_MM_YYYY')
    const { data, error } = await triggerPDF({
      student_coursework_id: assignments?.id,
      classroom_id: classroom?.id,
    });

    const fileName = `${classroom.name}_${assignments?.title}_${studentInfo}_${submissionDate}`

    if (data) {
      const blob = new Blob([data]);
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", `${fileName}.pdf`); // Set the download file name

      // Append to the body and click to trigger download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    if (error) {
      dispatch(
        setNotification({
          type: "error",
          message: intl.formatMessage({id: "student.submissions.file.upload.message.error"})
        })
      );
    }
    return data;
  };

  return (
    <div className={style.container}>
      <div className={style.header}>
        <div className={style.headerLeft}>
          <button className={style.backButton} onClick={() => navigate(-1)}>
            <LeftOutlined />
          </button>
          <h2 className={style.title}>{assignments?.title}</h2>
        </div>
        <div className={style.headerRight}>
          <div className={style.studentInfoPagination}>
            <Button
              icon={<LeftOutlined />}
              onClick={() => handlePrevious()}
              disabled={!previousStudent}
            />{" "}
            <span className={style.studentInfo}>
              <label>
                {" "}
                <AvatarStudent /> {studentInfo}{" "}
              </label>
            </span>{" "}
            <Button
              icon={<RightOutlined />}
              onClick={() => handleNext()}
              disabled={!nextStudent}
            />
          </div>
        </div>
      </div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={AssignmentSchema}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={__handleSubmit}
      >
        {({ values, setFieldValue, handleSubmit }) => (
          <div className={style.containerCorrectionContent}>
            <div className={style.headerCorrection}>
              {assignments?.description && (
                <div className={style.containerAssignment}>
                  <h5 className={style.introductionTitle}>
                    {intl.formatMessage({
                      id: "assignment.correction.introduction",
                    })}
                  </h5>
                  <p className={style.introduction}>
                    {assignments?.description}
                  </p>{console.log("assignments:::", assignments)}
                </div>
              )}
              {totalGrade > 0 && (
                <div className={style.totalGrade}>
                  {intl.formatMessage({
                    id: "correction.finalGrade",
                  })}:{" "} 
                  <span className={style.totalGradeValue}>
                    {values && sumGradeEvaluation(values)} / {totalGrade}
                  </span>
                </div>
              )}
            </div>

            <div className={style.listQuistionsContainer}>
              {values?.map((question, indexQuestion) => (
                <div
                  className={style.questionItemContainer}
                  key={"Questions+" + indexQuestion}
                >

                  {assignments?.type === "Essay" ? (
                    <div>
                      <CustomAccordion
                        data={question}
                        indexQuestion={indexQuestion}
                        key={"Questions+" + indexQuestion}
                      />
                      <QuestionsItemForEssay
                        setFieldValue={undefined}
                        question={question}
                        indexQuestion={indexQuestion}
                        key={"Questions+" + indexQuestion}
                        defaultAnswerValue={question.answer}
                        isPreview={isPreview}
                        assignment={assignments}
                      />
                    </div>
                  ) : (
                    <QuestionsItem
                      question={question}
                      indexQuestion={indexQuestion}
                      setFieldValue={isPreview ? undefined : setFieldValue}
                      isCorrection={isCorrection}
                    />
                  )}

                  <div
                    className={style.correctionsContainer}
                    style={{
                      border: `1px solid ${color[question?.type]}`,
                      // borderTop: ` 22px solid ${color[question?.type]}`,
                    }}
                  >
                    <div
                      className={style.correctionHeader}
                      style={{
                        height: "22px",
                        backgroundColor: color[question?.type],
                      }}
                    >
                      <FractionInput
                       question={question}
                        initialCurrent={question?.gradeEvaluation || 0}
                        isManuallyEdited={isManuallyEdited}
                        setIsManuallyEdited={setIsManuallyEdited}
                        total={question?.grade}
                        setFieldValue={setFieldValue}
                        indexQuestion={indexQuestion}
                        precision={2}
                      />
                    </div>
                    <div className={style.correctionbody}>
                      <div className={style.correctionsContent}>
                        {question?.rubric ? (
                          <p className={style.rubricName}>
                            {question?.rubric?.title || "Rubric"}
                          </p>
                        ) : (
                          <>
                            <p className={style.correctionsTitle}>
                              {intl.formatMessage({
                                id: "assignment.correction.correctAnswer",
                              })}
                            </p>
                            <div className={style.optionsContainer}>
                              {question?.options?.map((item, index) => (
                                <>
                                  <div className={style.blockOptionsItem}>
                                    <div
                                      key={index}
                                      className={`${style.cercleAnwser} ${
                                        !item?.isCorrect &&
                                        style.cercleAnwserInCorrect
                                      }`}
                                    >
                                      {orderQuestions[index]}
                                    </div>
                                  </div>
                                </>
                              ))}
                            </div>
                          </>
                        )}
                
                        {question?.llmAnswer && (
                          <>
                            {question?.llmAnswer?.answer?.feedbacks?.map(
                              (item, feedbackIndex) => (
                                <>                                  

                                  <div className={style.justificationTitle}>
                                    <BarCorrectionCount
                                      length={item.maxScore}
                                      correctLength={Number(item?.score)}
                                      setFieldValue={setFieldValue}
                                      indexQuestion={indexQuestion}
                                      feedbackIndex={feedbackIndex}
                                      creterion={
                                        item?.criterion ? item?.criterion : ""
                                      }
                                      values={values}
                                      highestScore={highestScore}
                                      isManuallyEdited={isManuallyEdited}
                                      setIsManuallyEdited={setIsManuallyEdited}
                                    />
                                  </div>

                                  <div className={style.optionsContainer}>
                                    <div className={style.correctionAnswer}>
                                      {item?.feedback}
                                    </div>
                                  </div>
                                </>
                              )
                            )}
                          </>
                        )}
                      </div>

                      {question?.justification && (
                        <div className={style.justificationContent}>
                          <div className={style.justificationTitle}>
                            <span>
                              {intl.formatMessage({
                                id: "assignment.correction.justification",
                              })}
                            </span>
                            <div>
                              <div
                                className={style.justificationAnswerContainer}
                              >
                                <p>{question?.answerJustification}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <QuestionsFeedback
                        feedbacks={
                          question?.llmAnswer
                            ? question?.llmAnswer?.answer?.actionable_feedbacks
                            : []
                        }
                        questionIndex={indexQuestion}
                        feedbackIndex={
                          question.preAssesmentQuiz?.llmAnswerQuizzes.length
                        }
                        setFieldValue={setFieldValue}
                      />
                    </div>
                  </div>
                </div>
              ))}
              <div className={style.blockBtns}>
                <div className={style.btnSubmitContainer}>
                  <button
                    type="submit"
                    className={style.btnSubmit}
                    onClick={handleSubmit}
                  >
                    <RemediaIcon />
                    {intl.formatMessage({
                      id: "assignment.correction.button.corrections",
                    })}
                  </button>
                  <button
                    type="submit"
                    className={style.btnDownload}
                    onClick={handleExport}
                    disabled={isLoadingPdf || assignments?.status !== 'Graded'}
                  >
                    {isLoadingPdf ? (
                      <LoadingOutlined />
                    ) : (
                      <>
                        <DownloadOutlined />
                        {intl.formatMessage({
                          id: "assignment.correction.button.export.file",
                        })}
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default AssignmentCorrectionsPreview;

const QuestionsFeedback = ({ feedbacks, questionIndex, setFieldValue }) => {
  if (!feedbacks) return null;
  return (
    <div className={style.feedbackContainer}>
      <div className={style.feedbackHeader}>
        <h3>Feedback</h3>
        <Button
          icon={<PlusOutlined />}
          type="default"
          className={style.addFeedback}
          onClick={() => {
            setFieldValue(
              `${questionIndex}.llmAnswer.answer.actionable_feedbacks`,
              [...feedbacks, { actionable_feedback: "" }]
            );
          }}
        >
          Add
        </Button>
      </div>
      {feedbacks.map((feedback, index) => (
        <div key={index} className={style.feedbackItem}>
          <textarea
            value={feedback.actionable_feedback}
            className={style.feedback}
            onChange={(e) => {
              setFieldValue(
                `${questionIndex}.llmAnswer.answer.actionable_feedbacks.${index}.actionable_feedback`,
                e.target.value
              );
            }}
          />
          <Button
            icon={<CloseCircleOutlined />}
            type="default"
            className={style.removeFeedback}
            onClick={() => {
              setFieldValue(
                `${questionIndex}.llmAnswer.answer.actionable_feedbacks`,
                feedbacks?.filter((_, indexItem) => indexItem !== index)
              );
            }}
          />
        </div>
      ))}
    </div>
  );
};
