import Table from "../../Components/table/Table";
import {DownOutlined, FormOutlined, UpOutlined, DeleteOutlined,ImportOutlined, UploadOutlined} from "@ant-design/icons"
import { Link } from "react-router-dom";
import {Button, message, Spin,Tabs,Radio} from "antd";
import "./rubric.css"
import {useLazyGetRubricsQuery, useLazyGetRubricByIdQuery,useDeleteRubricMutation,useLazyGetRubricsByTeacherQuery,useLazyGetRubricsByTitleQuery,useCreateRubricMutation} from "../../services/rubrics.api";
import {useLazyGetRubricsStandardQuery,useLazyGetRubricStandardByIdQuery} from "../../services/rubricsStandard.api";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import ViewRubricModal from "../../Components/viewRubricModal/ViewRubricModal";
import EditRubricModal from "../../Components/rubricActions/editRubricModal/EditRubricModal.jsx";
import Swal from "sweetalert2";
import {useGetMeQuery} from "../../services/auth.api";
import {setNotification} from "../../redux/app/app.slice";
import {useDispatch, useSelector} from "react-redux";
import { selectAuthenticatedUser } from "../../redux/auth/auth.selectors";

import {useIntl} from "react-intl";
import CustomCourseworkModal from "../../Components/customCourseworkModal/index.jsx";
import AIIcon from "../../assets/icons/AIIcon/index.jsx";
import CustomModalSelection from "../../Components/customModalSelection/index.jsx";
import CustomHandWritingIcon from "../../assets/icons/CustomHandWritingIcon/index.jsx";
import ImportIcon from "../../assets/icons/ImportIcon/index.jsx";

const Rubric = () => {

    const [isViewModalOpen, setIsViewModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [rubricItem, setRubricItem] = useState(null);
    const navigate = useNavigate();
    const [rubricItemData, setRubricItemData] = useState(null);
    const [rubricItemDataEdit, setRubricItemDataEdit] = useState(null);
    const [sortConfig, setSortConfig] = useState({ key: 'id', direction: null });
    const dispatch = useDispatch();

    const [deleteRubric] = useDeleteRubricMutation();
    // const [trigger, { data: rubrics, isLoading: isLoadingRubric}] = useLazyGetRubricsQuery();
    const [trigger, { data: rubrics, isLoading: isLoadingRubric}] = useLazyGetRubricsByTeacherQuery();
    const { data: authenticatedUser, error, isLoading } = useGetMeQuery();
    const [getRubricItem, { data: rubricByItem, isLoading: isLoadingRubricItem }] = useLazyGetRubricByIdQuery();
    const [mode, setMode] = useState('custom');
    const [triggerRubricByTitle, { data: rubricByTitle, isLoading: isLoadingRubricByTitle}] = useLazyGetRubricsByTitleQuery();
    const [triggerRubricsStandard, { data: rubricsStandard, isLoading: isLoadingRubricStandard,errorStandard}] = useLazyGetRubricsStandardQuery();
    const [getRubricStandardItem, { data: rubricStandardByItem, isLoading: isLoadingRubricStandardItem }] = useLazyGetRubricStandardByIdQuery();
    const [createRubric, {isLoading: isLoadingNewRubric}] = useCreateRubricMutation()
    let teacher=null;
    const intl = useIntl();
    const user = useSelector(selectAuthenticatedUser);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedMode, setSelectedMode] = useState(null);

    const columns = [
        { key: 1 , title: 'ID', dataIndex: 'id'},
        { key: 2 , title: intl.formatMessage({id: "rubric.table.header.title"}), dataIndex: 'title'},
        { key: 3 , title: intl.formatMessage({id: "rubric.table.header.format"}), dataIndex: 'format'},
        { key: 4 , title: intl.formatMessage({id: "rubric.table.header.edit"}), dataIndex: 'edit'},
        { key: 5 , title: '', dataIndex: 'Action'},
        { key: 6 , title: intl.formatMessage({id: "rubric.table.header.delete"}), dataIndex: 'deleted'},
    ];

    const columnsStandard = [
        { key: 1 , title: 'ID', dataIndex: 'id'},
        { key: 2 , title: intl.formatMessage({id: "rubric.table.header.title"}), dataIndex: 'title'},
        { key: 3 , title: intl.formatMessage({id: "rubric.table.header.type"}), dataIndex: 'type'},
        { key: 4 , title: '', dataIndex: 'Action'},
        { key: 5 , title: intl.formatMessage({id: "rubric.table.header.import"}), dataIndex: 'Import'},
    ];

    useEffect(() => {
        if(authenticatedUser){
            trigger({teacherId: authenticatedUser.id});
            console.log("rubrics",rubrics)
        }
    }, [authenticatedUser]);
    console.log('rubrics =>', rubrics);


    const handleModeChange = (e) => {
        setMode(e.target.value);
    };

    useEffect(() => {
        triggerRubricsStandard();
    }, [rubricsStandard]);

    const handleActionClick = async (item) => {
        setRubricItem(item.id);
        console.log(item);
        if(item['@type'] === "RubricStandard"){
            const {data, error} = await getRubricStandardItem(item.id);
            if (data) {
                setRubricItemData(data);
                setIsViewModalOpen(!isViewModalOpen);
            }
        }else {
            const {data, error} = await getRubricItem(item.id);
            if (data) {
                setRubricItemData(data);
                setIsViewModalOpen(!isViewModalOpen);
            }
        }
    };

    const handleEditRubric = async (id) => {
        console.log("edit rubric", id);
        const { data, error } = await getRubricItem(id);
        console.log("edit rubric data", data);
        if (data) {
            setRubricItemDataEdit(data);
            setIsEditModalOpen(!isEditModalOpen);
        }

    }

    const handleDeleteRubric = async  (rubric) => {
        if(rubric.questions && rubric.questions.length > 0){
            console.log('Cannot delete rubric with courseworks');
            return Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: intl.formatMessage({id: "rubric.message.delete.error.text"}),
                confirmButtonText: 'OK',
                confirmButtonColor: '#000095',
                timer: 3000 // Optional: Auto close after 3 seconds
            });
        }else {
                Swal.fire({
                    icon: "warning",
                    title: intl.formatMessage({id: "rubric.message.delete.confirm.title"}),
                    text: intl.formatMessage({id: "rubric.message.delete.confirm.text"}),
                    showDenyButton: false,
                    showCancelButton: true,
                    confirmButtonText: intl.formatMessage({id: "rubric.message.delete.confirm.button.yes.text"}),
                    cancelButtonText: intl.formatMessage({id: "rubric.message.delete.confirm.button.cancel.text"}),
                    confirmButtonColor: "#000095",
                }).then((result) => {
                    if (result.isConfirmed) {
                        deleteRubric({ rubricId: rubric.id }).then((result) => {
                            if (result.data) {
                                console.log('Deleted data ::', result.data);
                                dispatch(
                                    setNotification({
                                        type: "success",
                                        message: intl.formatMessage({id: "rubric.message.delete.success.text"}),
                                    })
                                );
                            }
                        });
                    }
                });
        }

    }

    const handleImportClick = async (item) => {

            if(authenticatedUser){
                teacher= "/api/teachers/"+authenticatedUser.id
            }
            const rubricData = {
                title: item.title,
                type: item.type,
                format: item.format,
                criterias: item.criterias,
                teacher:teacher,
                grade: item.grade,
                created_at: new Date().toISOString(),
                updated_at: new Date().toISOString(),
            };
            const {data, error} = await createRubric(rubricData);
            if(data){
                dispatch(
                    setNotification({
                        type: "success",
                        message: intl.formatMessage({id: "rubric.message.import.success.text"}),
                    })
                )
                // redirect to rubric list
                navigate('/rubric');
            }
            if(error){
                console.log("error", error)
                dispatch(
                    setNotification({
                        type: "error",
                        message: intl.formatMessage({id: "rubric.message.import.error.text"}),
                    })
                )
            }


    };
    const handleSort = (columnKey) => {
        let direction;
        if (sortConfig.key === columnKey) {
            if (sortConfig.direction === 'ascending') {
                direction = null; // Second click, reset to default (unsorted)
            } else if (sortConfig.direction === null) {
                direction = 'descending'; // Third click, sort descending
            } else {
                direction = 'ascending'; // First click, sort ascending
            }
        } else {
            direction = 'ascending'; // New column clicked, sort ascending
        }
        setSortConfig({ key: columnKey, direction });
    };

    const rubData = rubrics?.['hydra:member'] || [];
    const sortedData = [...rubData]?.sort((a, b) => {
        if (sortConfig.direction === 'ascending') {
            console.log("a ::", a);
            return a?.[sortConfig?.key] > b?.[sortConfig?.key] ? 1 : -1;
        }
        if(sortConfig.direction === 'descending'){
            return a?.[sortConfig?.key] < b?.[sortConfig?.key] ? 1 : -1;
        }
        return 0;
    })

    const rubDataStandard = rubricsStandard?.['hydra:member'] || [];
    const sortedDataStandard = [...rubDataStandard]?.sort((a, b) => {
        if (sortConfig.direction === 'ascending') {
            console.log("a ::", a);
            return a?.[sortConfig?.key] > b?.[sortConfig?.key] ? 1 : -1;
        }
        if(sortConfig.direction === 'descending'){
            return a?.[sortConfig?.key] < b?.[sortConfig?.key] ? 1 : -1;
        }
        return 0;
    })


    const TableHeadCell = (column) => {
        if (column.dataIndex === 'id' || column.dataIndex === 'Action' || column.dataIndex === 'Edit' || column.dataIndex === 'deleted' || column.dataIndex === 'Import') {
            return column.title;
        }
        else {
            return (
                <>
                    <span onClick={() => handleSort(column.dataIndex)} className={"sort_column"}>
                      <span>{column.title}</span>
                      <span className={"sort__icons"}>
                        <UpOutlined className={(sortConfig.direction === "ascending" && sortConfig.key === column.dataIndex) ? "up__icon active" : "up__icon"}/>
                        <DownOutlined className={(sortConfig.direction === "descending" && sortConfig.key === column.dataIndex) ? "down__icon active" : "down__icon"}/>
                      </span>
                    </span>
                </>
            );
        }
    };

    const TableBodyCell = (column, item) => {
        switch (column.dataIndex) {
            case 'Action':
                return (
                    <Button onClick={() => handleActionClick(item)} className='tableBody__cell-openBtn'>
                        {intl.formatMessage({id: "rubric.table.button.view"})}
                    </Button>
                );
            case 'Import':
                return (
                    <Button onClick={() => handleImportClick(item)} className='tableBody__cell-openBtn'>
                        <ImportOutlined/>
                    </Button>
                );
            case 'Format':
                return <span className='tableBody__cell-formatCol'>{item[column.dataIndex]}</span>;

            case "edit":
                return <button className="tableBody__cell-edit" onClick={() => handleEditRubric(item.id)}>
                    <FormOutlined style={{fontSize : "18px", color:"#000095"}}/>
                </button>

            case 'deleted':
                return <button className="tableBody__cell-delete" onClick={() => handleDeleteRubric(item)}>
                    <DeleteOutlined style={{fontSize : "18px", color:"#a33333"}}/>
                </button>

            default:
                return item[column.dataIndex];
        }
    };
  
    const translations = {
      title: intl.formatMessage({id: "rubric.create.modal.title"}),
      subtitle: intl.formatMessage({id: "assignment.create.modal.subtitle"}),
      continueButton: intl.formatMessage({id: "assignment.create.modal.button.continue"}),
    };
  
    const options = [
      {
        id: 'custom',
        label: intl.formatMessage({id: "assignment.create.modal.choice.custom"}),
        icon: <CustomHandWritingIcon />,
        onSelect: () => {
          navigate('/rubric/new');
        },
      },
      {
        id: 'ai',
        label: intl.formatMessage({id: "assignment.create.modal.choice.ai"}),
        icon: <AIIcon />,
        onSelect: () => {
          navigate('/rubric/generate');
        },
      },
      {
        id: 'import',
        label: intl.formatMessage({id: "assignment.create.modal.choice.import"}),
        icon: <ImportIcon />,
        onSelect: () => {
          navigate('/rubric/import');
        },
      },
    ];
  
    const handleCancel = () => {
      setIsModalVisible(false);
    };
  
    const handleOptionSelect = (mode) => {
      setSelectedMode(mode);
    };

    return (
        <>
            <div className="rubricContent">
                <div className="rubricContent__header">
                    <div className="rubricContent__header-title">
                        <span>{intl.formatMessage({id: "rubric.title"})}</span>
                    </div>

                    <div className="rubricContent__header-new">
                        <Button type="primary" onClick={() => setIsModalVisible(true)}>
                            {intl.formatMessage({id: "rubric.button.create.rubric"})}
                        </Button>
                    </div>
                </div>

                {/* Radio Group for Table Selection */}
                <div className={"rubric__tabs"}>
                    <Radio.Group onChange={handleModeChange} value={mode}>
                        <Radio.Button value="custom">
                            {intl.formatMessage({id: "rubric.button.list.personal.rubric"})}
                        </Radio.Button>
                        <Radio.Button value="standard">
                            {intl.formatMessage({id: "rubric.button.list.library.rubric"})}
                        </Radio.Button>
                    </Radio.Group>
                </div>
                {/* Conditionally Rendered Tables */}
                {mode === 'standard' ? (
                    <Table
                        columns={columnsStandard}
                        data={sortedDataStandard}
                        TableHeadCell={TableHeadCell}
                        TableBodyCell={TableBodyCell}
                        useFilter={false}
                        useSearchBar
                        noDataText={intl.formatMessage({id: "rubric.table.data.standard.empty"})}
                        usePagination={true}
                        showEntriesPerPageSelect={true}
                        isLoading={isLoadingRubric}
                    />
                ) : (
                    <Table
                        columns={columns}
                        data={sortedData}
                        TableHeadCell={TableHeadCell}
                        TableBodyCell={TableBodyCell}
                        useFilter={false}
                        useSearchBar
                        noDataText={intl.formatMessage({id: "rubric.table.data.custom.empty"})}
                        usePagination={true}
                        showEntriesPerPageSelect={true}
                        isLoading={isLoadingRubric}
                    />
                )}

                {/* View Modal */}
                <ViewRubricModal
                    isViewModalOpen={isViewModalOpen}
                    setIsViewModalOpen={setIsViewModalOpen}
                    rubricItem={rubricItem}
                    rubricItemData={rubricItemData}
                    importRubric={handleImportClick}
                />

                {/* Edit Modal */}
                {rubricItemDataEdit && (
                    <EditRubricModal
                        isEditModalOpen={isEditModalOpen}
                        setIsEditModalOpen={setIsEditModalOpen}
                        rubricItemDataEdit={rubricItemDataEdit}
                    />
                )}

                {user.roles.includes("ROLE_TEACHER") && (
                    <CustomModalSelection
                        visible={isModalVisible}
                        onCancel={handleCancel}
                        translations={translations}
                        options={options}
                        onOptionSelect={handleOptionSelect}
                        selectedMode={selectedMode}
                        className="custom-modal-class"
                    />
                )}
            </div>
        </>
    );
}

export default Rubric
