/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from '@reduxjs/toolkit';

import { api } from '../services/api';
import { appSlice } from './app/app.slice';
import { draftSlice } from './draftData/draft.slice';
import { authSlice } from './auth/auth.slice';
import { localeSlice } from './locale/locale.slice';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export function createReducer(injectedReducers = {}) {
  // Initially we don't have any injectedReducers, so returning identity function to avoid the app
  return combineReducers({
    ...injectedReducers,
    [api.reducerPath]: api.reducer,
    app: appSlice.reducer,
    auth: authSlice.reducer,
    draft: draftSlice.reducer,
    locale: localeSlice.reducer,
  });
}
