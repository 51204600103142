import React from "react";
import Invitations from "./Invitations";
import ListAdmins from "./ListAdmins";
import { useIntl } from 'react-intl'

const InviteAdmin = () => {
    const { formatMessage } = useIntl();
  return (
    <div className="admin-teachers">
      <h1 className="h1">
          {formatMessage({ id: "super_admin.list_Teacher.table.header.admin" })}
      </h1>
      <div className="teachers-container">
        <Invitations />
        <ListAdmins />
      </div>
    </div>
  );
};

export default InviteAdmin;
