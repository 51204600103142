import AiGenerateAssignment from "../../Components/aiGenerateAssignment"



const GenerateAssignmentWithAi = () => {

    return(
            <AiGenerateAssignment />
    )

}

export default GenerateAssignmentWithAi