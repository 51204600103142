import React from "react";

const Search = ({ size = 16, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.20905 10.9347C8.71135 10.9347 10.7399 8.90617 10.7399 6.40387C10.7399 3.90156 8.71135 1.87305 6.20905 1.87305C3.70674 1.87305 1.67822 3.90156 1.67822 6.40387C1.67822 8.90617 3.70674 10.9347 6.20905 10.9347Z"
        stroke="currentColor"
        stroke-width="0.907199"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.41406 9.60742L12.2629 12.4563"
        stroke="currentColor"
        stroke-width="0.907199"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Search;
