import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import remediaLogo from "../../../assets/images/remedia-logo.png";
import { Input, Button, Checkbox, Alert } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Link, Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useLazyGetUserByTokenQuery, useVerifyUserAndSetNewPasswordMutation } from '../../../services/auth.api'
import { useDispatch } from "react-redux";
import { Spinner } from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { validationSchema } from "./validation";
import { setNotification } from '../../../redux/app/app.slice'

const defaultValues = {
  familyName: "",
  firstName: "",
  email: "test@test.com",
  password: "",
  confirmPassword: "",
  terms: false,
};

const Register = () => {
  const params = useParams();
  const token = params.token;

  const [completeSignUp, { isLoading }] = useVerifyUserAndSetNewPasswordMutation();
  const [getUserInfo, { data: userAssociated, isLoading: isUserLoading }] = useLazyGetUserByTokenQuery();
  const [errorMessage, setErrorMessage] = useState(null);
  const navigation = useNavigate();

  const [initialValues,setInitialValues] = useState(defaultValues);

  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(() => {
    if (token) {
      getUserInfo(token);
    } else {
      navigation('/sign-in');
    }
  }, []);

  useEffect(() => {
    if (userAssociated) {
        setInitialValues(prev => ({
          ...prev,
          email: userAssociated.email,
          firstName: userAssociated.first_name,
          familyName: userAssociated.family_name
        }));
    }
  }, [userAssociated, isUserLoading, isLoading]);

  const handleSubmit = async (values, { setSubmitting }) => {
    values.token = token;
    const { data, error: userError } = await completeSignUp(values);

    if (data) {
        setErrorMessage(null);
        setSubmitting(false);

        dispatch(
            setNotification({
              type: 'success',
              message: intl.formatMessage({
                id: "signUp.form.field.success.message",
              }),
            })
        );

        navigation('/sign-in');
    }

    if (userError) {
        setErrorMessage('An error occurred while sending email. Please try again or contact support!');
        setSubmitting(false);
    }
  };

  if(isUserLoading || isLoading){
    return <Spinner />
  }

  if (!userAssociated && !isUserLoading && !isLoading) {
      navigation('/sign-in');
  }

  return (
    <main className={"register__page"}>
      <img src={remediaLogo} alt="remedia logo" className="__remediaLogo" />
      <div className="container">
        <div className="register __box">
          <h1 className="__title">
            {intl.formatMessage({ id: "register.title" })}
          </h1>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema(intl.formatMessage)}
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, isValid, errors }) => (
              <Form className="register__form">
                {errorMessage && (
                  <Alert
                    message={errorMessage}
                    type="error"
                    icon={<InfoCircleOutlined />}
                    showIcon
                    className="__errorField"
                  />
                )}
                {!isValid && Object.keys(errors).length > 0 && (
                  <Alert
                    message={intl.formatMessage({
                      id: "signUp.form.message.error",
                    })}
                    type="error"
                    icon={<InfoCircleOutlined />}
                    showIcon
                    className="__errorField"
                  />
                )}

                <div className="register__form-emailField">
                  <Field name="email">
                    {({ field }) => (
                      <Input
                        {...field}
                        placeholder={intl.formatMessage({
                          id: "signUp.form.field.email.placeholder",
                        })}
                        disabled={true}
                        readOnly={true}
                        autoComplete="new-email"
                        size="large"
                        type="email"
                        className="register__form-email"
                      />
                    )}
                  </Field>
                </div>

                <div className="register__form-fullName">
                  <div className="register__form-firstNameField">
                    <Field name="familyName">
                      {({ field }) => (
                        <Input
                          {...field}
                          placeholder={intl.formatMessage({
                            id: "signUp.form.field.fName.placeholder",
                          })}
                          size="large"
                          type="text"
                          autoComplete="new-firstName"
                          className="register__form-firstName"
                        />
                      )}
                    </Field>
                  </div>

                  <div className="register__form-lastName">
                    <Field name="firstName">
                      {({ field }) => (
                        <Input
                          {...field}
                          placeholder={intl.formatMessage({
                            id: "signUp.form.field.lName.placeholder",
                          })}
                          size="large"
                          type="text"
                          autoComplete="new-lastName"
                          className="register__form-lastName"
                        />
                      )}
                    </Field>
                  </div>
                </div>

                <div className="register__form-passwordField">
                  <Field name="password">
                    {({ field }) => (
                      <Input.Password
                        {...field}
                        placeholder={intl.formatMessage({
                          id: "signUp.form.field.password.placeholder",
                        })}
                        type="password"
                        size="large"
                        autoComplete="new-password"
                        className="register__form-password"
                      />
                    )}
                  </Field>
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="__errorMessage"
                  />
                </div>

                <div className="register__form-confirmPasswordField">
                  <Field name="confirmPassword">
                    {({ field }) => (
                      <Input.Password
                        {...field}
                        placeholder={intl.formatMessage({
                          id: "signUp.form.field.confirm.password.placeholder",
                        })}
                        type="password"
                        size="large"
                        autoComplete="new-confirmPassword"
                        className="register__form-confirmPassword"
                      />
                    )}
                  </Field>
                  <ErrorMessage
                    name="confirmPassword"
                    component="div"
                    className="__errorMessage"
                  />
                </div>

                <div className="register__form-checkboxField">
                  <Field name="terms">
                    {({ field }) => (
                      <Checkbox {...field} className="register__form-checkbox">
                        {intl.formatMessage({
                          id: "signUp.form.field.terms.read.and.agree",
                        })}{" "}
                        <a href="?#">
                          {intl.formatMessage({ id: "Terms of Use" })}
                        </a>{" "}
                        {intl.formatMessage({
                          id: "signUp.form.field.terms.and",
                        })}{" "}
                        <a href="?#">
                          {intl.formatMessage({
                            id: "signUp.form.field.terms.privacy.policy",
                          })}
                        </a>
                      </Checkbox>
                    )}
                  </Field>
                </div>
                <div className={"sign_up__btn__block"}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    className="register__form-btn __authBtn"
                    disabled={isSubmitting}
                  >
                    {isLoading ? (
                      <span>
                        {intl.formatMessage({
                          id: "signUp.form.field.button.submit",
                        })}{" "}
                        <Spinner height={10} width={10} />
                      </span>
                    ) : (
                      intl.formatMessage({ id: "signUp.form.field.button.submit" })
                    )}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </main>
  );
};

export default Register;
