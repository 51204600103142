import {useState} from "react";
import styles from "./importRubric.module.css";
import {Formik} from "formik";
import { Upload} from "antd";
import {
    LoadingOutlined,
} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import {useIntl} from "react-intl";
import CloudDownload from "../../assets/icons/CloudDownload";
import * as Yup from "yup";
import {useSelector} from "react-redux";
import {selectClassroom} from "../../redux/app/app.selectors";
import {useGenerateRubricWithIaMutation, useLazyGetRubricsByTeacherQuery} from "../../services/rubrics.api";
import ErrorMessage from "../ErrorMessage";
import { useDispatch } from "react-redux";
import {setNotification} from "../../redux/app/app.slice";
import EditRubricModal from "../rubricActions/editRubricModal/EditRubricModal";
import {FormattedMessage} from "react-intl";

const { Dragger } = Upload;

const validationSchema = Yup.object().shape({
    image: Yup.mixed().required(<FormattedMessage id="form.field.error.required" values={{ field: "file" }}/>),
});


const defaultInitialValues = {
    image: null,
}

const ImportRubric = ({
                              initialValues = defaultInitialValues,
                          }) => {
    const [toUpdate, setToUpdate] = useState(false);
    const [IaInitialValues, setIaInitialValues] = useState(null);
    const [generateRubricWithIa, {isLoading: isLoadingGenerateRubricWithIa}] = useGenerateRubricWithIaMutation()
    const intl = useIntl();
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const classroom = useSelector(selectClassroom);
    const dispatch = useDispatch()

    const navigate = useNavigate();
    const handleCancel = () => { setIsVisible(false); };

    const _handleSubmit = async (values) => {
        const formData = new FormData();

        formData.append("grade", classroom.grade);
        formData.append("image", values.image);
        formData.append("type", "Upload");
        const {data, error } = await generateRubricWithIa({formData: formData});
  
        if (data) {
            setToUpdate(true);
            setIsEditModalOpen(true);
            setIaInitialValues(data);
        }
        if (error) {
            console.log("error", error);
            dispatch(setNotification(
                {
                    type: "error",
                    message: intl.formatMessage({ id: "notification.error.import" }, {type: "rubric"}),
                }
            ))
        }
    };

    const [visible, setIsVisible] = useState(false);

    const propsFileContext = {
        name: "file",
        multiple: true,
        onChange(info) {
            const { status } = info.file;
            if (status !== "uploading") {
                console.log(info.file, info.fileList);
            }
            if (status === "done") {
                console.log("done");
            } else if (status === "error") {
                console.log("error");
            }
        },
        onDrop(e) {
            console.log("Dropped files", e.dataTransfer.files);
        },
    };




    return (
        <>
            <div className={styles.newAssignmentPage}>
                <div className={styles.newAssignmentContainer}>
                    <div className={styles.newAssignmentContent}>
                        <Formik
                            initialValues={initialValues}
                            onSubmit={_handleSubmit}
                            enableReinitialize
                            validationSchema={validationSchema}
                        >
                            {({
                                  values,
                                  handleBlur,
                                  setFieldValue,
                                  handleSubmit,
                                  errors,
                              }) => (
                                <form className={styles.newAssignmentForm} onSubmit={handleSubmit}>
                                    <div className={styles.newAssignmentHeader}>
                                        <div className={styles.newAssignmentTitle}>
                                            <h1>
                                                {intl.formatMessage({
                                                    id: "rubric.create.form.title",
                                                })}
                                            </h1>
                                        </div>

                                    </div>
                                    {/** context file **/}
                                    <div className={styles.uploadFileContextBlock}>
                                        <label>
                                            {intl.formatMessage({
                                                id: "assignment.create.form.field.label.contextFile",
                                            })}
                                        </label>
                                        <div>
                                            <Dragger
                                                multiple={false}
                                                beforeUpload={(file) => {
                                                    setFieldValue("image", file);
                                                    return false;
                                                }}
                                                onRemove={() => {
                                                    setFieldValue("image", null);
                                                }}
                                                {...propsFileContext}
                                                className={styles.uploadFileContext}

                                            >
                                                <div
                                                    className={`ant-upload-drag-icon ${styles.fileCloudUpload}`}
                                                >
                                                    <CloudDownload className={styles.iconCloudUpload} />
                                                </div>
                                                <p className={styles.uploadTextContent}>
                                                    <b>
                                                        {intl.formatMessage({
                                                            id: "rubric.create.form.field.label.upload.title",
                                                        })}
                                                    </b>
                                                    {intl.formatMessage({
                                                        id: "rubric.create.form.field.label.upload.text",
                                                    })}
                                                </p>
                                            </Dragger>
                                            {errors.image && (<ErrorMessage message={errors.image} />)}
                                        </div>
                                    </div>
                                    <div className={styles.blockBtn}>
                                        <button type="submit" className={styles.submitButton}>
                                            {intl.formatMessage({ id: "button.import" })} {" "}
                                            {isLoadingGenerateRubricWithIa ? <LoadingOutlined /> : ""}
                                        </button>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>

                { toUpdate &&
                    <EditRubricModal
                        isEditModalOpen={isEditModalOpen}
                        setIsEditModalOpen={setIsEditModalOpen}
                        rubricItemDataEdit={IaInitialValues}
                        isEdit={false}
                    />}

            </div>

        </>
    );
};

export default ImportRubric;