import { DoubleRightOutlined, FileOutlined, GlobalOutlined } from "@ant-design/icons";
import style from "./customAccordion.module.css";
import React, { useEffect, useState } from "react";
import Tooltip from "antd/lib/tooltip";
import CustomSpin from "../customSpin/CustomSpin";
import MediaViewer from "../MediaViewer";
import IconPlayVideo from "../../assets/icons/IconPlayVideo";
import CloseTabs from "../../assets/icons/CloseTabs";
import { useIntl } from "react-intl";

const CustomAccordion = ({ data, indexQuestion, openPreviewDocs, setOpenPreviewDocs, setResourceIndexs, setIsActiveFile, isCorrection=false, setResources }) => {
  const initialAccordionStates = {
    1: true,
    2: true,
  };
  const [accordionStates, setAccordionStates] = useState(
    initialAccordionStates,
  );
  const [LinkUrl, setLinkUrl] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const intl = useIntl();

  const getCoverImageUrl = (url) => {
    const regex = /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|.*[?&]v=))([^"&?/]+)/;
    const match = url.match(regex);
    const id =  match ? match[1] : null;
    return `https://img.youtube.com/vi/${id}/maxresdefault.jpg`;
  };

  const getFirstYoutubeLink = (links) => {
    const youtubeLink = links.find(link => link.type === "youtube");
    return youtubeLink ? youtubeLink.url : null;
  };

  const youtubeLink = getFirstYoutubeLink(data?.links);

  const toggle = (index) => {
    setAccordionStates((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  if (!data) return <CustomSpin />;

  return (
    <>
      <div id={"right"}>
        <div className={`${style["accordion"]}`}>
          <div className={style["accordion__title"]} onClick={() => toggle(1)}>
            <Tooltip title={"Instructions & Resources for Coursework"} placement={"top"}>
              <h1>{intl.formatMessage({ id: "instructionsAndResourcesForCoursework" })}</h1>
            </Tooltip>

            <DoubleRightOutlined
              className={`${style["arrow"]} ${accordionStates[1] ? style["toggled"] : ""}`}
            />
          </div>
          <div
            className={`${style["accordion__content"]} ${accordionStates[1] ? style["toggled"] : ""}`}
          >
            <div className={style.context}>
              <div
                  className={`${style.assignmentInfos} ${youtubeLink === null ? style.resourceRow : ""} ${openPreviewDocs ? style.resourceColomn : ""}`}
              >
                {youtubeLink && (
                    <div className={style.videoContainer}>
                      <img src={getCoverImageUrl(youtubeLink)} alt="" />
                      <IconPlayVideo
                          className={style.playIcon}
                          onClick={() => {
                            setIsModalVisible(true);
                            setLinkUrl(youtubeLink);
                          }}
                      />
                    </div>
                )}
                {data && data?.image && (
                    <div className={style["figure-coursework"]}>
                      <img
                          src={`${process.env.REACT_APP_API_BASE_URL}/uploads/images/${data?.image.filePath}`}
                          alt={data?.title}
                      />
                    </div>
                )}

                <div className={style["instruction__content"]}>
                  <h1>{data?.title}</h1>
                  <p>{data?.description ? data?.description : data?.questionText}</p>
                </div>
              </div>
            </div>


            <div className={style.resourcesConteiner}>
              <div className={style.resourceItemContainer}>
                {(() => {
                  // Get all resources and hypertext links
                  const resources = data?.resources || [];
                  const hypertextLinks = (data?.links || []).filter(
                    (item) => item?.type === "hypertext",
                  );
                  const totalItems = resources.length + hypertextLinks.length;

                  console.log("question vv", data);

                  // all links
                  const allLinks = data?.links || [];

                  // Always show max 3 items
                  const visibleResources = resources.slice(0, 3);
                  const remainingItems = 3 - visibleResources.length;
                  const visibleLinks = hypertextLinks.slice(
                    0,
                    Math.max(0, remainingItems),
                  );

                  const handleShowMore = () => {
                    setIsActiveFile(false);
                    setOpenPreviewDocs(true);
                    setResources({
                      indexQuestion,
                      files: [...resources],
                      links: [...allLinks],
                    });
                  };

                  return (
                    <>
                      {visibleResources.map((item, indexFile) => (
                        <span
                          key={indexFile}
                          className={style.resourceItem}
                          onClick={() => {
                            setIsActiveFile(true);
                            setOpenPreviewDocs(true);
                            setResourceIndexs({ indexQuestion, indexFile });
                          }}
                        >
                          <FileOutlined />
                          <span>
                            {item?.filePath?.split("/").pop().slice(0, 20) +
                              "..."}
                          </span>
                        </span>
                      ))}

                      {visibleLinks.map((item, index) => (
                        <span
                          key={`link-${index}`}
                          className={style.resourceItem}
                          onClick={() => {
                            setIsModalVisible(true);
                            setLinkUrl(item.url);
                          }}
                        >
                          <GlobalOutlined />
                          {item.url.length > 20
                            ? item.url.slice(0, 20) + "..."
                            : item.url}
                        </span>
                      ))}

                      {totalItems > 3 && (
                        <span
                          className={`${style.resourceItem} ${style.showMoreButton}`}
                          onClick={handleShowMore}
                        >
                          <CloseTabs />
                          <span>View All ({totalItems})</span>
                        </span>
                      )}
                    </>
                  );
                })()}
              </div>
            </div>
          </div>
        </div>
        <MediaViewer
          initialUrl={LinkUrl}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
        />
      </div>
    </>
  );
};

export default CustomAccordion;
