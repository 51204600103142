import React from "react";

import { Route, Routes } from "react-router-dom";
import Correction from "../Pages/Correction/Correction";
import SignIn from "../Pages/Sign-in/SignIn";
import SignUp from "../Pages/auth/signUp-flow/SignUpFlow";
import ForgetPassword from "../Components/ForgetPassword/ForgetPassword";
import NewPassword from "../Components/NewPassword/NewPassword";
import ProtectedRoute from "../Components/protectedRoute/protectedRoute";
import Assignment from "../Pages/Assignments/Assignment";
import Rubric from "../Pages/rubric/Rubric";
import NewRubric from "../Pages/new-rubric/NewRubric";
import Submissions from "../Pages/submissions/Submissions";
import SubmissionDetails from "../Pages/submissionDetails/SubmissionDetails";
import StudentCourseworkSubmission from "../Pages/studentCourseworkSubmission/StudentCourseworkSubmission";
import NotFound from "../Pages/notFound/NoutFound";
import GoogleClassroom from "../Pages/googleClassroom/GoogleClassroom";
import NewAssignment from "../Pages/newAssignment";
import AssignmentPreview from "../Pages/AssignmentPreview";
import CorrectionPage from "../Pages/correctionPage";
import StudentInvitations from "../Pages/Organization/Students";
import TeachersInvitations from "../Pages/Organization/Teachers";
import Classes from "../Pages/Organization/Classes";
import Organization from "../Pages/Organization";
import AppLayout from "../layout/AppLayout";
import AllIcons from "../Pages/AllIcons";
import ROLES from "../Utils/roles";
import GenerateAssignmentWithAi from "../Pages/generateAssignmentwithAi";
import CourseworkStudentsSubmission from "../Pages/courseworkStudentsSubmission";
import StudentCorrectionPage from "../Pages/studentCorrectionPage";
import AssignmentDetails from "../Pages/AssignmentDetails";
import InviteAdmin from "../Pages/InviteAdmin";
import Register from "../Pages/auth/register/register";
import AiGenerateRubric from "../Components/aiGenerateRubric";
import ImportRubric from "../Components/importRubric";
import ImportAssignment from "../Components/importAssignment";
import ReportingPdfs from "../Pages/reportingPdfs";

const MainRoutes = () => {
  {
    /* 
    <Route path="correction" element={<Correction />} />
    
    */
  }

  return (
    <Routes>




      <Route
        path="/"
        element={
          <ProtectedRoute roles={[ROLES.SUPER_ADMIN]}>
            <AppLayout />
          </ProtectedRoute>
        }
        >
          <Route path={"invite-admin"} element={<InviteAdmin />} />
      </Route>
      <Route
        path="/"
        element={
          <ProtectedRoute roles={[ROLES.ADMIN]}>
            <AppLayout />
          </ProtectedRoute>
        }
      >
        <Route index={true} element={<Organization />} />
        <Route path="teachers-invitations" element={<TeachersInvitations />} />
      </Route>

      <Route
        path="/"
        element={
          <ProtectedRoute roles={ROLES.ALL}>
            <AppLayout />
          </ProtectedRoute>
        }
      >
        <Route path="classes" element={<Classes />} />
        <Route path="AllIcons" element={<AllIcons />} />
        <Route path="coursework" element={<Assignment />} />
        <Route path="/courseworks/:assignmentId" element={<AssignmentDetails />} />

        {/* need confirm */}
        <Route
          path="/assignment/preview/:assignmentId"
          element={<AssignmentPreview />}
        />
        <Route path="/assignment/preview/:id" element={<AssignmentDetails />} />
      </Route>

      <Route
        path="/"
        element={
          <ProtectedRoute roles={[ROLES.TEACHER, ROLES.ADMIN]}>
            <AppLayout />
          </ProtectedRoute>
        }
      >
        <Route path="rubric" element={<Rubric />} />
        <Route path="rubric/new" element={<NewRubric />} />
        <Route path="students-invitations" element={<StudentInvitations key={"new"} />} />
        <Route path="submissions" element={<Submissions />} />
        <Route
          path="classes/:classId/students-invitations"
          element={<StudentInvitations />}
        />
        <Route path="rubric/generate" element={<AiGenerateRubric />} />
        <Route path="rubric/import" element={<ImportRubric />} />
      </Route>

      <Route
        path="/"
        element={
          <ProtectedRoute roles={[ROLES.TEACHER]}>
            <AppLayout />
          </ProtectedRoute>
        }
      >
        {/*<Route path="/submission/:id/show" element={<SubmissionDetails />} />*/}
        <Route path="/preview-assignment" element={<AssignmentPreview />} />
        <Route path="/get-google-classroom" element={<GoogleClassroom />} />
        <Route path="/correction-page" element={<CorrectionPage />} />
        <Route path="/assignment/create/custom" element={<NewAssignment />} />
        <Route
          path="/assignment/generate/ai"
          element={<GenerateAssignmentWithAi />}
        />
        <Route path="/assignment/edit/:id" element={<NewAssignment />} />
        <Route
          path="/assignment/:courseId/correction/:submissionId"
          element={<CorrectionPage />}
        />
        <Route
          path="/submissions/students/coursework/:id/:title"
          element={<CourseworkStudentsSubmission />}
        />
        <Route path="/assignment/import" element={<ImportAssignment />} />
      </Route>

      <Route
        path="/"
        element={
          <ProtectedRoute roles={ROLES.STUDENT}>
            <AppLayout />
          </ProtectedRoute>
        }
      >
        <Route
          path="/student/:id/assignment/correction/:id"
          element={<StudentCorrectionPage />}
        />

        <Route
          path="/student/submission"
          element={<StudentCourseworkSubmission />}
        />

        <Route
          path="/student/reporting-pdfs/:submissionId"
          element={<ReportingPdfs />}
        />
      </Route>

      <Route exact path="/sign-in" element={<SignIn />} />
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/password-resets" element={<ForgetPassword />} />
      <Route path="/new-password" element={<NewPassword />} />
      <Route path="/register/:token" element={<Register />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default MainRoutes;
