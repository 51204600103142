import React, { useEffect, useRef } from "react";
import { Formik, useFormik } from "formik";
import { useIntl } from "react-intl";
import * as Yup from "yup";
import Select from "../Select";
import "./classModal.css";
import Button from "../Button";
import Input from "../Input";
import Modal from "../Modal";
import { IconClass } from "../../assets/icons";
import ErrorMessage from "../ErrorMessage";

const SUBJECT_OPTIONS = [
  { key: "english", value: "English Language Arts" },
  { key: "esl", value: "English as a Second Language (ESL)" },
  { key: "math", value: "Mathematics" },
  { key: "biology", value: "Biology" },
  { key: "physics", value: "Physics" },
  { key: "chemistry", value: "Chemistry" },
  { key: "history", value: "History" },
  { key: "geography", value: "Geography" },
  { key: "economics", value: "Economics" }
];

const GRADE_OPTIONS = [
  { key: "grade1", value: "Grade 1" },
  { key: "grade2", value: "Grade 2" },
  { key: "grade3", value: "Grade 3" },
  { key: "grade4", value: "Grade 4" },
  { key: "grade5", value: "Grade 5" },
  { key: "grade6", value: "Grade 6" },
  { key: "secondary1", value: "Secondary I (Grade 7)" },
  { key: "secondary2", value: "Secondary II (Grade 8)" },
  { key: "secondary3", value: "Secondary III (Grade 9)" },
  { key: "secondary4", value: "Secondary IV (Grade 10)" },
  { key: "secondary5", value: "Secondary V (Grade 11)" },
]
const MODE = {
  CREATE: "create",
  EDIT: "edit",
};

const TYPE_USER = {
  TEACHER: "teacher",
  ADMIN: "admin",
};

const ClassModal = ({
  isModalOpen,
  onCancel,
  handleSubmit,
  mode = MODE.CREATE,
  initialValues,
  typeUser,
  teachers = [],
}) => {
  const intl = useIntl();
  const isEditMode = mode === MODE.EDIT;
  const isAdmin = typeUser === TYPE_USER.ADMIN;

  const basValidateSchama = {
    name: Yup.string().required(
      intl.formatMessage({ id: "classroom.form.error.name.required" }),
    ),
    subject: Yup.string().required(
      intl.formatMessage({ id: "classroom.form.error.subject.required" }),
    ),
    grade: Yup.string().required(
      intl.formatMessage({ id: "classroom.form.error.grade.required" }),
    ),
  };

  const validationSchema = Yup.object({
    ...basValidateSchama,
    teacher: isAdmin
      ? Yup.string().required(
          intl.formatMessage({ id: "classroom.form.error.teacher.required" }),
        )
      : Yup.string(),
  });



  const { values, setFieldValue, handleChange, handleSubmit:handleSubmitForm, errors ,resetForm} =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit:handleSubmit,
      validateOnBlur: false,
      validateOnChange: false,
      enableReinitialize: true,
    });


  const subjectOptions = SUBJECT_OPTIONS.map(({ key, value }) => ({
    value,
    label: intl.formatMessage({ id: `classroom.form.subject.options.${key}` }),
  }));

  const gradetOptions = GRADE_OPTIONS.map(({ key, value }) => ({
    value,
    label: intl.formatMessage({ id: `classroom.form.grade.options.${key}` }),
  }));

  const teacherOptions = teachers?.map((teacher) => ({
    value: teacher.id,
    label: `${teacher.first_name} ${teacher.family_name}`,
  }));

  const handleCancel = (e) => {
    e.preventDefault();
    resetForm();
    onCancel();
  };

  

  return (
    <Modal
      open={isModalOpen}
      onCancel={handleCancel}
      onClose={handleCancel}
      onConfirm={handleSubmitForm}
      centered
      footer={null}
      width={685}
      className="class-modal"
      title={<IconClass />}
      ConfirmText={intl.formatMessage({
        id: `app.form.button.${isEditMode ? "saveChanges" : "create"}`,
      })}
      cancelText={intl.formatMessage({ id: `app.form.button.cancel` })}
    >
      <div className="modal-form">
        <h2 className="h3">
          {intl.formatMessage({
            id: isEditMode
              ? "classroom.form.title.edit"
              : "classroom.form.title.create",
          })}
        </h2>

        <Input
          label={intl.formatMessage({
            id: "classroom.form.label.classTitle",
          })}
          name="name"
          placeholder={intl.formatMessage({
            id: "classroom.form.label.classTitle.placeholder",
          })}
          value={values.name}
          onChange={handleChange}
          error={errors.name}
        />

        <Select
          label={intl.formatMessage({
            id: "classroom.form.label.subject.title",
          })}
          options={subjectOptions}
          name="subject"
          value={values.subject}
          error={errors.subject}
          onChange={(value) => setFieldValue("subject", value)}
        />

        <Select
          name="grade"
          label={intl.formatMessage({
            id: "classroom.form.label.grade.title",
          })}
          options={gradetOptions}
          value={values.grade}
          error={errors.grade}
          onChange={(value) => setFieldValue("grade", value)}
        />

        {isAdmin && (
          <Select
            label={intl.formatMessage({
              id: "classroom.form.label.teacher.title",
            })}
            name="teacher"
            options={teacherOptions}
            value={values.teacher}
            error={errors.teacher}
            onChange={(value) => setFieldValue("teacher", value)}
          />
        )}
      </div>
    </Modal>
  );
};

export default ClassModal;
