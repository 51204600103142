import React, { useState, useCallback, useEffect, useRef } from "react";
import style from "./fractionInput.module.css";
import { useIntl } from "react-intl";

const FractionInput = ({
  question,
  initialCurrent = 0,
  total = 3,
  setFieldValue,
  indexQuestion,
  precision = 2,
  isManuallyEdited,
  setIsManuallyEdited,
}) => {
  const safeInitialValue =
    typeof initialCurrent === "number" &&
    !isNaN(initialCurrent) &&
    isFinite(initialCurrent)
      ? initialCurrent
      : isFinite(parseFloat(initialCurrent))
        ? parseFloat(initialCurrent)
        : 0;
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(
    safeInitialValue.toFixed(precision),
  );
  const [current, setCurrent] = useState(safeInitialValue.toFixed(precision));
  const refInput = useRef(null);
  const intl = useIntl();

  useEffect(() => {
    console.log("1 ->isCorrect",question);

    if (!isManuallyEdited && question?.type === "MultiChoice") {
        // chack is correct options
        const studentAnswers = question?.studentAnswer || [];
        const correctAnswers = question?.options?.filter(option => option?.isCorrect)?.map(option => option?.id) || [];
        const isCorrect = studentAnswers.every((answer) =>
            correctAnswers.includes(answer)
        );
        if (isCorrect) {
            setCurrent(total);
            setFieldValue(`${indexQuestion}.gradeEvaluation`, total);
        }
    }
  }, [total]);

  useEffect(() => {
    // Update current value when initialCurrent changes
    const safeValue =
      typeof initialCurrent === "number" &&
      !isNaN(initialCurrent) &&
      isFinite(initialCurrent)
        ? initialCurrent
        : isFinite(parseFloat(initialCurrent))
          ? parseFloat(initialCurrent)
          : 0;
    setCurrent(safeValue.toFixed(precision));
    setInputValue(safeValue.toFixed(precision));
  }, [initialCurrent, precision]);

  useEffect(() => {
    if (isEditing) {
      refInput.current?.focus();
      refInput.current?.select();
    }
  }, [isEditing]);

  const validateValue = useCallback(
    (value) => {
      const floatValue = parseFloat(value);

      if (isNaN(floatValue)) return 0; // Default to 0 if invalid
      if (floatValue < 0) return 0;
      if (floatValue > total) return total;

      // Round to the specified precision
      return (
        Math.round(floatValue * Math.pow(10, precision)) /
        Math.pow(10, precision)
      );
    },
    [total, precision],
  );


  const handleChange = (e) => {
    setIsManuallyEdited(true);
    const newInputValue = e.target.value;

    // Allow empty input, decimal point, and numbers with decimal points
    if (
      newInputValue === "" ||
      newInputValue === "." ||
      /^-?\d*\.?\d*$/.test(newInputValue)
    ) {
      setInputValue(newInputValue);

      // Only update Formik value if we have a valid number
      if (newInputValue !== "" && newInputValue !== ".") {
        const numericValue = validateValue(newInputValue);
        setCurrent(numericValue);
        setFieldValue(`${indexQuestion}.gradeEvaluation`, numericValue);
      } else {
        // If empty or just a decimal point, set to 0 in Formik
        setFieldValue(`${indexQuestion}.gradeEvaluation`, 0);
      }
    }
  };

  const handleBlur = () => {
    setIsEditing(false);

    // On blur, convert whatever was entered to a valid value
    const validatedValue = validateValue(inputValue);
    setCurrent(validatedValue);

    // Format the display value with proper precision
    setInputValue(validatedValue.toFixed(precision));

    // Update Formik
    setFieldValue(`${indexQuestion}.gradeEvaluation`, validatedValue);
  };

  return (
    <div className={style.fractionInput} onClick={() => setIsEditing(true)}>
      {isEditing ? (
        <input
          type="text"
          value={inputValue}
          onChange={handleChange}
          onBlur={handleBlur}
          className=""
          autoFocus
          ref={refInput}
        />
      ) : (
        <span className="">
          <span>{intl.formatMessage({ id: "correction.grade" })}: </span> {current} {/* Display the current value */}
        </span>
      )}{" "}
      {" / "}
      <span className="">{total}</span>
    </div>
  );
};

export default FractionInput;
