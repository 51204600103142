import React, { useState } from "react";
import { IconEmail, IconUser } from "../../../assets/icons";
import * as Yup from "yup";
import { Formik } from "formik";
import Button from "../../../Components/Button";
import TagInput from "../../../Components/TagInput";
import { useIntl } from "react-intl";
import { setNotification } from '../../../redux/app/app.slice'
import useGetOrganization from '../../../hooks/useGetOrganization'
import { useDispatch } from 'react-redux'
import { useInviteTeacherToOrganisationMutation } from '../../../services/organisation.api'



const Invitations = () => {
  const { formatMessage } = useIntl();
  const { organisation } = useGetOrganization();
  const dispatch = useDispatch();
  const [inviteTeachers] = useInviteTeacherToOrganisationMutation();

  const validationSchema = Yup.object({
    emails: Yup.array()
      .min(1, formatMessage({ id: "form.field.emails.min" }))
      .required(formatMessage({ id: "form.field.error.required" }, { field: formatMessage({ id: "app.form.field.students" }) })),
  });

  const initialValues = {
    emails: [],
  };
  const _handleSubmit = async (values, { setSubmitting, resetForm }) => {

    const {data, error} = await inviteTeachers({
      code: organisation.code,
      emails: values.emails
    });

    if (data) {
      resetForm();
      dispatch(
          setNotification({
            type: 'success',
            message: formatMessage({
              id: 'organization.invite.user.sent',
            }),
          }),
      )
    }

    if (error) {
      dispatch(
          setNotification({
            type: "error",
            message: formatMessage({
              id: "organization.invite.user.error",
            }),
          })
      );
    }
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={_handleSubmit}
      enableReinitialize
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ values, handleSubmit, setFieldValue, errors }) => (
        <form className="section" onSubmit={handleSubmit}>
          <div className="left-side">
            <h3>
              {formatMessage({ id: "organization.teachers.invitations.title" })}
            </h3>
            <p>
              {formatMessage({
                id: "organization.teachers.invitations.description",
              })}
            </p>
          </div>
          <div className="right-side">
            <TagInput
              name="emails"
              placeholder="you@example.com"
              onChange={(value) => setFieldValue("emails", value)}
              value={values.emails}
              error={errors.emails}
              className={"flex-grow-1"}
            />
            <div className="flex justify-end">
              <Button
                variant="primary"
                type="submit"
                icon={<IconEmail size={20} />}
                text={formatMessage({ id: "organization.button.invite" })}
              />
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default Invitations;

const renderItemOptions = (text) => (
  <div className="flex gap-2">
    <IconUser />
    <span>{text}</span>
  </div>
);
