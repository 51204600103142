import React, { useState } from "react";
import { Modal, Card, Button, Typography } from "antd";
import styles from './customModalSelection.module.css';

const { Title, Text } = Typography;

/**
 * A reusable modal component for selecting a creation method
 * @param {Object} props
 * @param {boolean} props.visible - Controls modal visibility
 * @param {Function} props.onCancel - Handler for modal cancel
 * @param {Object} props.translations - Object containing translated strings
 * @param {Array} props.options - Array of selection options
 * @param {Function} props.onOptionSelect - Handler for option selection
 * @param {string} props.selectedMode - Currently selected mode (if controlled)
 * @returns {JSX.Element}
 */
const SelectionModal = ({
    visible,
    onCancel,
    translations = {},
    options = [],
    onOptionSelect,
    selectedMode: controlledMode,
    className = '',
}) => {
    const [internalMode, setInternalMode] = useState(null);
  
    // Use controlled mode if provided, otherwise use internal state
    const mode = controlledMode !== undefined ? controlledMode : internalMode;
    
    const handleModeChange = (newMode) => {
      setInternalMode(newMode);
      if (onOptionSelect) {
        onOptionSelect(newMode);
      }
    };

    console.log('mode ::', mode);
  
    const handleContinue = () => {
      if (mode) {
        const selectedOption = options.find(opt => opt.id === mode);
        if (selectedOption && selectedOption.onSelect) {
          selectedOption.onSelect(mode);
        }
      }
    };
  
    return (
      <Modal
        title={
          <Title level={3}>
            {translations.title || "Select Method"}
          </Title>
        }
        visible={visible}
        onCancel={onCancel}
        footer={null}
        centered
        className={`custom__modal ${styles.customModal} ${className}`}
        width={600}
      >
        <Text className="sub__title">
          {translations.subtitle || "Choose your preferred method"}
        </Text>
        <div className={styles.customCardContainer}>
          {options.map((option) => (
            <div key={option.id}>
              <Card
                hoverable
                className={`${styles.customCard} ${
                  mode === option.id ? styles.selected : ""
                }`}
                onClick={() => handleModeChange(option.id)}
              >
                {React.cloneElement(option.icon, {
                  className: `${styles.customIcon} ${option.iconClassName || ''}`
                })}
              </Card>
              <Text
                className={`${styles.customText} ${
                  mode === option.id ? styles.selected : ""
                }`}
              >
                {option.label}
              </Text>
            </div>
          ))}
        </div>
        <Button
          type="primary"
          className={styles.customButton}
          onClick={handleContinue}
        >
          {translations.continueButton || "Continue"}
        </Button>
      </Modal>
    );
};

export default SelectionModal;