import React from "react";

const Student = ({ size = 16, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect y="0.330078" width="40" height="40" rx="20" fill="#F2F4F7" />
      <path
        d="M29.4188 19.2524C29.5978 19.1735 29.7498 19.0437 29.8558 18.8792C29.9617 18.7148 30.0172 18.5228 30.0151 18.3272C30.0131 18.1315 29.9537 17.9408 29.8444 17.7785C29.735 17.6163 29.5804 17.4897 29.3998 17.4144L20.8288 13.5104C20.5683 13.3916 20.2852 13.3301 19.9988 13.3301C19.7124 13.3301 19.4294 13.3916 19.1688 13.5104L10.5988 17.4104C10.4208 17.4884 10.2693 17.6166 10.163 17.7793C10.0566 17.9419 10 18.1321 10 18.3264C10 18.5208 10.0566 18.7109 10.163 18.8736C10.2693 19.0363 10.4208 19.1645 10.5988 19.2424L19.1688 23.1504C19.4294 23.2693 19.7124 23.3308 19.9988 23.3308C20.2852 23.3308 20.5683 23.2693 20.8288 23.1504L29.4188 19.2524Z"
        stroke="#475467"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30 18.3301V24.3301"
        stroke="#475467"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 20.8301V24.3301C14 25.1257 14.6321 25.8888 15.7574 26.4514C16.8826 27.014 18.4087 27.3301 20 27.3301C21.5913 27.3301 23.1174 27.014 24.2426 26.4514C25.3679 25.8888 26 25.1257 26 24.3301V20.8301"
        stroke="#475467"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Student;
