import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {selectIsAuthenticated} from "../../redux/auth/auth.selectors";
import {useGetMeQuery} from "../../services/auth.api";
import { Navigate, useLocation } from 'react-router-dom'
import {Spin} from "antd";
import ROLES from "../../Utils/roles";
import useTypeUser from '../../hooks/useTypeUser';

const ProtectedRoute = ({children, roles=null}) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const { data: authenticatedUser, error, isLoading } = useGetMeQuery();

  const location = useLocation();

  const {typeUser,isLoading:typeUserLoading} = useTypeUser()

  if (isLoading || typeUserLoading ) {
    return (
      <div className={"loading"}>
      <div className='h-screen w-full flex-center'>
      <div className='loader-full-screen'>
      <Spin
          
          size={"large"}   />  
      </div>
      </div>
    </div>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to={'/sign-in'} />;
  }

  if (isAuthenticated){
    // if its super admin
    if(roles && roles.includes(ROLES.SUPER_ADMIN) &&
        typeUser === ROLES.SUPER_ADMIN &&
        location.pathname !== '/invite-admin'
    ){
      return <Navigate to={'/invite-admin'} />
    }

    if(roles && !roles.includes(typeUser)){
      return <Navigate to={'/classes'} />
    }
  }


  if (error) {
    // commented due to a bug when accessing the home directly with an expired token
    // Bug: Warning: Cannot update a component (`ProtectedRoute`) while rendering a different component (`ProtectedRoute`).
    // To locate the bad setState() call inside `ProtectedRoute`,
    // follow the stack trace as described in https://reactjs.org/link/setstate-in-render
    // TODO: fix this bug

    // dispatch(
    //   setNotification({
    //     title: 'Erreur',
    //     message: error.data?.message,
    //     type: 'danger',
    //   })
    // );

    return <Navigate to={'/sign-in'} />;
  }

  return <>{children}</>;
};

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
  role: PropTypes.string,
};

export default ProtectedRoute;
