import React, { useEffect, useState } from "react";
import { setNotification } from "../../redux/app/app.slice";
import { useGenerateStudentSubmissionPdfMutation } from "../../services/studentcourseworks.api";
import { useDispatch, useSelector } from "react-redux";
import { selectClassroom } from "../../redux/app/app.selectors";
import CustomSpin from "../../Components/customSpin/CustomSpin";
import { useIntl } from "react-intl";
import DocsViewer from "../../Components/docsViewer";
import { useParams } from "react-router-dom";

const ReportingPdfs = () => {
  const { submissionId } = useParams();
  const intl = useIntl();
  const dispatch = useDispatch();
  const classroom = useSelector(selectClassroom);
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState(null);
  const [triggerPDF] = useGenerateStudentSubmissionPdfMutation();

  const fetchFile = async () => {
    const { data, error } = await triggerPDF({
      student_coursework_id: submissionId,
      classroom_id: classroom?.id,
    });

    if (data) {
      const blob = new Blob([data], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      setFile(url);
    }

    if (error) {
      dispatch(
        setNotification({
          type: "error",
          message: intl.formatMessage({
            id: "student.submissions.file.upload.message.error",
          }),
        }),
      );
    }
    setLoading(false);

    return file;
  };

  useEffect(() => {
    fetchFile();
  }, []);

  if (loading) {
    return <CustomSpin />;
  }

  return <DocsViewer url={file} fileType={"pdf"} />;
};

export default ReportingPdfs;
