import Table from "../../Components/table/Table";
import "./submissions.css"
import React, {useEffect, useState} from "react"
import { Tabs, Input, Button} from "antd";
import {DownOutlined, FormOutlined, SearchOutlined, SyncOutlined, UpOutlined} from "@ant-design/icons";
import {useNavigate, useParams} from "react-router-dom";
import moment from 'moment';
import {useDispatch, useSelector} from "react-redux";
import {selectClassroom} from "../../redux/app/app.selectors";

import {useIntl} from "react-intl";
import DocIcon from "../../assets/icons/DocIcon";
import InsightIcon from "../../assets/icons/InsightIcon";
import { selectAuthenticatedUser } from "../../redux/auth/auth.selectors";

import Badge from "./Badge";
import {
  useLazyGetAssignmentsByTeacherAndClassQuery,
} from "../../services/assignments.api";
import Filter from "../../Components/filter/Filter";
import PracticeModeIcon from "../../assets/icons/PracticeModeIcon";
import ClassModeIcon from "../../assets/icons/ClassModeIcon";
import {setNotification} from "../../redux/app/app.slice";


const Submissions = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const [dataSorted, setDataSorted] = useState([]);
  const classroom = useSelector(selectClassroom);
   const [
      triggerAssignment,
      { data: assignmentData, isLoading: isLoadingAssignments },
    ] = useLazyGetAssignmentsByTeacherAndClassQuery();
  const dispatch = useDispatch()
  const intl = useIntl();
  const { TabPane } = Tabs;
  const [activeTab, setActiveTab] = useState("all");
  const [selectedFilterItems, setSelectedFilterItems] = useState([]);

  const user = useSelector(selectAuthenticatedUser);

    useEffect(() => {
      const params = { classroom: classroom?.id };
      if (!user.roles.includes("ROLE_TEACHER")) {
        const params = { classroom: classroom?.id, state: "active" };
        triggerAssignment(params);
        return;
      }
  
      triggerAssignment(params);
    }, [classroom]);




  console.log("    console.log(studentAssignments)\n",dataSorted)

  const workData = assignmentData ? assignmentData["hydra:member"] : [];
  const sortedData = [...workData]?.sort((a, b) => {
    if (sortConfig.direction === "ascending") {
      return a?.[sortConfig?.key] > b?.[sortConfig?.key] ? 1 : -1;
    }
    if (sortConfig.direction === "descending") {
      return a?.[sortConfig?.key] < b?.[sortConfig?.key] ? 1 : -1;
    }
    return 0;
  });

  // useEffect(() => {
  //   if (!sortConfig){
  //     setDataSorted(formtedData(studentCourseWorks));
  //     console.log('studentCourseWorkssss', studentCourseWorks);
  //   }
  //   else {
  //     const studentSubmissionsData = formtedData(studentCourseWorks) || [];
  //     const sortedData = [...studentSubmissionsData]?.sort((a, b) => {
  //       if (sortConfig.direction === 'ascending') {
  //         return a?.[sortConfig?.key] > b?.[sortConfig?.key] ? 1 : -1;
  //       }
  //       if(sortConfig.direction === 'descending'){
  //         return a?.[sortConfig?.key] < b?.[sortConfig?.key] ? 1 : -1;
  //       }
  //       return 0;
  //     })
  //     setDataSorted(sortedData)
  //   }
  // }, [sortConfig, studentCourseWorks]);

  const handleSync = () => {
    const service = "SYNC_GOOGLE_API_SUBMISSION";
    const redirect = `${window.location.origin}/submissions`;
    const idService = classroom.id;
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const idUser = user?.id;
    const url = `${baseUrl}/google/${idUser}/oauth?service=${service}&redirect=${redirect}&idService=${idService}`;
    window.location.href = url;

    dispatch(
        setNotification({
          type: "info",
          message: intl.formatMessage({id: "submission.message.sync.info.text"}),
        })
    );
  };


  const timeConverter = (rawDate) => {
    return moment(rawDate).format("MMM DD YYYY hh:mm");
  }


   const formtedData = (data)=>(
       data?.map(item=>({
         id: item.id,
         first_name : item.student.family_name,
         title: item.coursework.title ,
         type: item.coursework.type,
         subject: item.coursework.subject ,
         state: item.studentSubmission? item.studentSubmission.state:"Started" ,
         created_at: item.coursework.created_at,
       }))
   )


  const columns = [
    { key: 1 , title: 'ID', dataIndex: 'id'},
    { key: 2 , title: intl.formatMessage({id: "student.submissions.table.header.coursework.title.label"}), dataIndex: "title"},
    { key: 3 , title: intl.formatMessage({id: "student.submissions.table.header.format.label"}), dataIndex:  "type"},
    { key: 4 , title: intl.formatMessage({id: "student.submissions.table.header.subject.label"}), dataIndex: "subject"},
    { key: 5 , title: intl.formatMessage({id: "student.submissions.table.header.state.label"}), dataIndex: 'state' },
    {
      key: 6,
      title: intl.formatMessage({id: "student.submissions.table.header.date.submitted.label"}),
      dataIndex: 'created_at',
      render: (created_at) => timeConverter(created_at),

    },
    { key: 7 , title: '', dataIndex: 'countSubmissions'},
    { key: 8 , title: '', dataIndex: 'insight'},
  ];


  const navigate = useNavigate();

  const handleActionClick = (item) => {
    navigate(`/submissions/students/coursework/${item.id}/${item.title}`)
  };


  const handleSort = (columnKey) => {
    let direction;
    if (sortConfig.key === columnKey) {
      if (sortConfig.direction === 'ascending') {
        direction = null; // Second click, reset to default (unsorted)
      } else if (sortConfig.direction === null) {
        direction = 'descending'; // Third click, sort descending
      } else {
        direction = 'ascending'; // First click, sort ascending
      }
    } else {
      direction = 'ascending'; // New column clicked, sort ascending
    }
    setSortConfig({ key: columnKey, direction });
  };



  const TableHeadCell = (column) => {
    if (column.dataIndex === 'id' || column.dataIndex === 'countSubmissions' || column.dataIndex === 'insight') {
      return column.title;
    }
    else {
      return (
          <>
            <span onClick={() => handleSort(column.dataIndex)} className={"sort_column"}>
              {()=> console.log('column.dataIndex', column.dataIndex)}
              <span>{column.title}</span>
              <span className={"sort__icons"}>
                <UpOutlined className={
                  (Array.isArray(sortConfig.key) && Array.isArray(column.dataIndex)
                      ? (sortConfig.direction === "ascending" && sortConfig.key.join() === column.dataIndex.join())
                      : (sortConfig.direction === "ascending" && sortConfig.key === column.dataIndex))
                      ? "up__icon active" : "up__icon"}/>
                <DownOutlined className={
                  (Array.isArray(sortConfig.key) && Array.isArray(column.dataIndex)
                      ? (sortConfig.direction === "descending" && sortConfig.key.join() === column.dataIndex.join())
                      : (sortConfig.direction === "descending" && sortConfig.key === column.dataIndex))
                      ? "down__icon active" : "down__icon"}/>
              </span>
            </span>
          </>
      );
    }
  };


  const TableBodyCell = (column, item) => {

    switch (column.dataIndex) {
      case 'countSubmissions':
        return (
            <Badge
                color={"#FFCF00"}
                className={"submissions-counter"}
                handeleActionClick={() => handleActionClick(item)}
                item={item}
            >
              <DocIcon /> Submissions
            </Badge>
        );
      case 'insight':
        return (
            <div
                className={"submissions-counter"}
                onClick={() => {}}
            >
              <InsightIcon /> Insights
            </div>
        );
      case 'type':
        if(column.render){
          return <span className='tableBody__cell-formatCol'>{column.render(item[column.dataIndex], item)} </span>;
        }
        else {
          return <span className='tableBody__cell-formatCol'>{ item[column.dataIndex] } </span>;
        }

      default:
        if(column.render){
         return column.render(item[column.dataIndex], item)
        }
        else {
            return item[column.dataIndex];
        }
    }
  };


  const filterItems = [
    { label: intl.formatMessage({id: "assignment.list.table.filter.essay"}), key: 'Essay',},
    { label: intl.formatMessage({id: "assignment.list.table.filter.quiz"}), key: 'Quiz',},
    // { label: intl.formatMessage({id: "assignment.list.table.filter.mcq"}), key: 'MCQ',},
    // { label: intl.formatMessage({id: "assignment.list.table.filter.qr"}), key: 'Q/R',},
  ];

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const filteredData = sortedData.filter(item => {
    if (activeTab === "all") return true;
    if (activeTab === "practice") return item.isPracticeMode === true;
    if (activeTab === "class") return item.isPracticeMode === false;
    return true;
  });


  return (
      <div className="content__submissions submissions">
        <div className={"submission__top-page"}>
          <div className="submissions__filter">
            <h1>
              {intl.formatMessage({ id: "student.submissions.list.title" })}
            </h1>
            <Filter
              filterItems={filterItems}
              selectedFilterItems={selectedFilterItems}
              setSelectedFilterItems={setSelectedFilterItems}
            />
          </div>

          <div className="submissions-new">
            <Input
              type="text"
              placeholder={intl.formatMessage({
                id: "student.submissions.form.search",
              })}
              prefix={<SearchOutlined />}
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
        </div>

        <Tabs
          defaultActiveKey="all"
          onChange={handleTabChange}
          className="student-submissions-tabs"
          tabBarExtraContent={ user.roles.includes("ROLE_TEACHER") && { right: <Button className="btn_synchro" icon={<SyncOutlined />} onClick={handleSync}>Sync</Button> }}
        >
          <TabPane
            tab={intl.formatMessage({ id: "assignment.tabs.all" })}
            key="all"
            className="student-submissions-tab"
          >
            <Table
              columns={columns}
              data={filteredData}
              TableHeadCell={TableHeadCell}
              TableBodyCell={TableBodyCell}
              noDataText={intl.formatMessage({
                id: "student.submissions.table.empty.data",
              })}
              searchTerm={searchTerm}
              isLoading={isLoadingAssignments}
              selectedFilterItems={selectedFilterItems}
            />
          </TabPane>
          <TabPane
            tab={<><span className="tab-mode-icon-container"><ClassModeIcon className="tab-mode-icon" /></span> {intl.formatMessage({ id: "assignment.tabs.class" })}</>}
            key="class"
            className="student-submissions-tab"
          >
            <Table
              columns={columns}
              data={filteredData}
              TableHeadCell={TableHeadCell}
              TableBodyCell={TableBodyCell}
              noDataText={intl.formatMessage({
                id: "student.submissions.table.empty.data",
              })}
              searchTerm={searchTerm}
              isLoading={isLoadingAssignments}
              selectedFilterItems={selectedFilterItems}
            />
          </TabPane>
          <TabPane
            tab={<><span className="tab-mode-icon-container"><PracticeModeIcon className="tab-mode-icon" /></span> {intl.formatMessage({ id: "assignment.tabs.practice" })}</>}
            key="practice"
            className="student-submissions-tab"
          >
            <Table
              columns={columns}
              data={filteredData}
              TableHeadCell={TableHeadCell}
              TableBodyCell={TableBodyCell}
              noDataText={intl.formatMessage({
                id: "student.submissions.table.empty.data",
              })}
              searchTerm={searchTerm}
              isLoading={isLoadingAssignments}
              selectedFilterItems={selectedFilterItems}
            />
          </TabPane>
        </Tabs>
      </div>
  );
}

export default Submissions



