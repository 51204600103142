import React from "react";

const Class = ({ size = 16, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 4.5H16C16.5304 4.5 17.0391 4.71071 17.4142 5.08579C17.7893 5.46086 18 5.96957 18 6.5V20.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 20.5H5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 20.5H22"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 12.5V12.51"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 5.0622V21.2192C13 21.3711 12.9653 21.521 12.8987 21.6575C12.8321 21.794 12.7352 21.9135 12.6155 22.007C12.4958 22.1005 12.3564 22.1655 12.2078 22.1971C12.0592 22.2287 11.9054 22.226 11.758 22.1892L5 20.5002V6.0622C5.00007 5.61621 5.1492 5.18303 5.42371 4.83153C5.69821 4.48002 6.08232 4.23035 6.515 4.1222L10.515 3.1222C10.8098 3.04852 11.1174 3.04297 11.4146 3.10595C11.7118 3.16894 11.9908 3.29881 12.2303 3.48571C12.4699 3.67261 12.6637 3.91163 12.797 4.18461C12.9304 4.4576 12.9998 4.75838 13 5.0622Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Class;
