import React, { useEffect, useMemo, useState, useTransition } from 'react'
import { IconStudent, IconTrash } from '../../../assets/icons'
import { useIntl } from 'react-intl'
import MiniTable from '../../../Components/table/MiniTable'
import Budge, { VARIANT, SIZE } from '../../../Components/Badge'
import Swal from 'sweetalert2'
import useDebounce from '../../../hooks/useDebounce'
import { useDeleteStudentMutation } from '../../../services/student.api'
import { setNotification } from '../../../redux/app/app.slice'
import { useDispatch } from 'react-redux'

const ListStudents = ({ classroom, allClassrooms }) => {
    const { formatMessage } = useIntl()
    const [searchTerm, setSearchTerm] = useState('')
    const debouncedSearchTerm = useDebounce(searchTerm, 300)
    const [selectedFilterItems, setSelectedFilterItems] = useState([])
    const [students, setStudents] = useState([])
    const [deleteStudent] = useDeleteStudentMutation()
    const dispatch = useDispatch()

    useEffect(() => {
        if (classroom) {
            console.log("classroom",classroom)
            console.log("allClassrooms",allClassrooms)
            const studentDatas = classroom.students.map((student) => ({
                id: student.id,
                email: student.email,
                firstName: student.first_name,
                lastName: student.family_name,
                className: classroom.name,
                state: student.confirmed,
            }))

            setStudents(studentDatas)
        }
    }, [classroom,allClassrooms])

    useEffect(() => {
        let classroomStudents = []
        if (allClassrooms.length > 0 && !classroom) {
            allClassrooms.forEach((classroom) => {
                classroom.students.forEach((student) => {
                    classroomStudents.push({
                        id: student.id,
                        email: student.email,
                        firstName: student.first_name,
                        lastName: student.family_name,
                        className: classroom.name,
                        state: student.confirmed,
                    })
                })
            })
            setStudents(classroomStudents)
        } else if (classroom) {
            classroom.students.forEach((student) => {
                classroomStudents.push({
                    id: student.id,
                    email: student.email,
                    firstName: student.first_name,
                    lastName: student.family_name,
                    className: classroom.name,
                    state: student.confirmed,
                })
            })
            setStudents(classroomStudents)
        }

    }, [allClassrooms, classroom?.students])

    const handleDelete = (id) => {
        Swal.fire({
            icon: 'warning',
            title: formatMessage({
                id: 'popup.delete.confirmation.title',
            }),
            text: formatMessage(
                {
                    id: 'popup.form.delete.confirmation.text',
                },
                { item: formatMessage({ id: 'admin.organization.student' }) },
            ),
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: formatMessage({
                id: 'popup.form.delete.confirmation.button.yes',
            }),
            confirmButtonColor: '#000095',
            cancelButtonText: formatMessage({
                id: 'popup.form.delete.confirmation.button.cancel',
            }),
        }).then((result) => {
            if (result.isConfirmed) {
                deleteStudent({ id: id }).then((result) => {
                    if (result.data) {
                        dispatch(
                            setNotification({
                                type: 'success',
                                message: formatMessage({ id: 'remove.user.success' }),
                            }),
                        )
                    }
                })
            }
        })
    }

    const columns = [
        {
            accessor: 'students',
            title: formatMessage({ id: 'organization.students.list_Student.table.header.students' }),
            render: (record) => (
                <div className="record-teacher">
                    <IconStudent size={40} />
                    <div className="record-teacher-info">
                        <p className="table-name text-14">
                            {record.firstName} {record.lastName}
                        </p>
                        <p className="table-email text-14 light">{record.email}</p>
                    </div>
                </div>
            ),
        },
        {
            accessor: 'className',
            title: formatMessage({ id: 'organization.students.list_Student.table.header.class' }),
            render: ({ className }) => (
                <Budge size={SIZE.MD} text={className} variant={VARIANT.PRIMARY} />
            ),
        },
        {
            accessor: 'state',
            title: formatMessage({ id: 'organization.students.list_Student.table.header.state' }),
            render: ({ state }) => (
                <Budge
                    size={SIZE.SM}
                    text={state ? formatMessage({ id: 'organization.students.list_Student.table.header.active' }) : formatMessage({ id: 'organization.students.list_Student.table.header.pending' })}
                    variant={state ? VARIANT.ACTIVE : VARIANT.PENDING}
                />
            ),
        },
        {
            key: 'actions',
            title: formatMessage({ id: 'organization.students.list_Student.table.header.action' }),
            dataIndex: 'actions',
            render: ({ id }) => (
                <div className="flex justify-end ">
                    <button className="btn-delete" onClick={() => handleDelete(id)}>
                        <IconTrash size={20} />
                    </button>
                </div>
            ),
        },
    ]

    const filterItems = useMemo(() =>
            [...new Set(students.map((item) => item.className))].map((item) => ({
                label: item,
                key: item,
            }))
        , [students])


    const filteredData = useMemo(
        () => {
            const newData = students.filter((item) =>
                [item.firstName, item.lastName, item.email]
                    .join(' ')
                    .toLowerCase()
                    .includes(debouncedSearchTerm.toLowerCase()),
            )

            if (selectedFilterItems.length > 0) {
                return newData.filter((item) => selectedFilterItems.includes(item.className))
            }

            return newData
        },
        [students, debouncedSearchTerm, selectedFilterItems,allClassrooms],
    )

    console.log("filteredData",students);
    console.log("filteredData",filteredData);
    

    return (
        <div className="section">
            <div className="left-side">
                <h3>{formatMessage({ id: 'organization.students.list_Student.title' })}</h3>
                <p>{formatMessage({ id: 'organization.students.list_Student.description' })}</p>
            </div>
            <div className="right-side">
                <MiniTable
                    activeSearch
                    activeFilter={!classroom}
                    searchTerm={searchTerm}
                    onChangeSearchTerm={setSearchTerm}
                    columns={columns}
                    data={filteredData}
                    filterItems={filterItems}
                    selectedFilterItems={selectedFilterItems}
                    setSelectedFilterItems={setSelectedFilterItems}
                    className="tbody-mx-600"

                />
            </div>
        </div>
    )
}

export default ListStudents
