import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

/*

import { isDebugEnabled, STORAGE_CONFIG_KEY } from '../utils/config';
const debugConfig = localStorage.getItem(STORAGE_CONFIG_KEY);

let platformApiBaseUrl = process.env.NX_APP_API_BASE_URL || 'http://localhost';

if (isDebugEnabled && debugConfig) {
  platformApiBaseUrl =
    JSON.parse(debugConfig)['platform.apiBaseUrl'] ||
    process.env.NX_APP_API_BASE_URL ||
*/

export const apiBaseUrl = process.env.REACT_APP_API_BASE_URL ;

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
  baseUrl: apiBaseUrl,
  prepareHeaders: (headers, { getState, endpoint }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = getState().auth.access_token;

    if (token !== null && 'sign-in' !== endpoint) {
      // check if it's authentication request and don't add token to headers
      headers.set('Authorization', `Bearer ${token}`);
    }

    return headers;
  },
  responseHandler: async (response) => {
    if (
        !response.ok &&
        response.status === 401 &&
        !response.url.includes('authentication_token')
    ) {
      localStorage.removeItem('persist:root');
    }
    return response.status === 204 ? response : response.json();
  },
});

// const baseQueryWithRetry = retry(baseQuery, { maxRetries: 3 });

/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded
 */
export const api = createApi({
  /**
   * `reducerPath` is optional and will not be required by most users.
   * This is useful if you have multiple API definitions,
   * e.g. where each has a different domain, with no interaction between endpoints.
   * Otherwise, a single API definition should be used in order to support tag invalidation,
   * among other features
   */
  reducerPath: 'api',
  /**
   * A bare bones base query would just be `baseQuery: fetchBaseQuery({ baseUrl: '/' })`
   */
  baseQuery: baseQuery,
  /**
   * Tag types must be defined in the original API definition
   * for any tags that would be provided by injected endpoints
   */
  tagTypes: [
    'Auth',
    'FilteredStudentQuizSubmissions'
  ],
  /**
   * This api has endpoints injected in adjacent files,
   * which is why no endpoints are shown below.
   * If you want all endpoints defined in the same file, they could be included here instead
   */
  endpoints: () => ({}),
});
