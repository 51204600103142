import React, { useState, useEffect } from "react";
import style from "../../assignmentCorrectionsPreview/assignmentCorrectionsPreview.module.scss";
import QuestionsItem from "../../questionsItem";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthenticatedUser } from "../../../redux/auth/auth.selectors";
import { useIntl } from "react-intl";
import { Formik } from "formik";


const PreviewQuiz = ({ assignments, isPreview = true }) => {
  const [initialValues, setInitialValues] = React.useState([]);
  const [showSubmit, setShowSubmit] = useState(true);
  const [isOpen, setIsOpen] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const intl = useIntl();

  const [timeLimit, setTimeLimit] = useState(null);
  const [isAllowedRubric, setIsAllowedRubric] = useState(false);

  const user = useSelector(selectAuthenticatedUser);
  const [courseworkFilePath, setCourseworkFilePath] = useState("");
  const [isActiveFile, setIsActiveFile] = useState(false);
  const [resourceIndexs, setResourceIndexs] = useState(null);
  const [resources, setResources] = useState({});
  const formikRef = React.useRef();
  const [timeLeft, setTimeLeft] = useState(null);
  const [isTimerActive, setIsTimerActive] = useState(false);
  
    // Initialize timer when component mounts
    useEffect(() => {
      if (!assignments?.setting) return;
      
      const { timeLimit, hasTimeLimit } = assignments.setting;
      
      if (hasTimeLimit && timeLimit && !isPreview) {
        const totalSeconds = parseInt(timeLimit) * 60;
        setTimeLeft(totalSeconds);
        setIsTimerActive(true);
      }
    }, [assignments, isPreview]);
  
  //type :
  useEffect(() => {
    const mappedQuestions = assignments?.questions.map((item) => ({
      ...item,
      answerJustification: item?.isJustification && "",
      answer: item?.answer || null,
      justification: item?.justification || null,
      studentAnswer: item?.studentAnswer || [],
    }));
    setInitialValues(mappedQuestions);
  }, [assignments]);


  return (
    <div className={style.container}>
      <div className={style.header}>
        <h2 className={style.title}>{assignments?.title}</h2>
      </div>
      <Formik
        innerRef={formikRef}
        enableReinitialize
        initialValues={initialValues}
        validateOnChange={true}
        validateOnBlur={true}
      >
        {({ values, setFieldValue, handleSubmit, isSubmitting }) => (
          <div className={style.containerPreview}>
            <div
              className={`${style.containerPreviewContent} ${style.rightSideBarPreview__open }`}
            >
              <div className={style.courseworkHeader}>
                {assignments?.description && (
                  <div className={style.containerAssignment}>
                    <h5 className={style.introductionTitle}>
                      {intl.formatMessage({
                        id: "assignment.preview.introduction",
                      })}
                    </h5>
                    <p className={style.introduction}>
                      {assignments?.description}
                    </p>
                  </div>
                )}
              </div>

              <div className={style.listQuistionsContainer}>
                {values?.map((question, indexQuestion) => (
                  <QuestionsItem
                    setFieldValue={undefined}
                    question={question}
                    indexQuestion={indexQuestion}
                    key={"Questions+" + indexQuestion}
                    openPreviewDocs={isOpen}
                    setOpenPreviewDocs={setIsOpen}
                    setCourseworkFilePath={setCourseworkFilePath}
                    setResourceIndexs={setResourceIndexs}
                    setIsActiveFile={setIsActiveFile}
                    setResources={setResources}
                    isAllowedRubric={isAllowedRubric}
                    
                  />
                ))}
              </div>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default PreviewQuiz;