import { object, string, ref, boolean } from "yup";


export const validationSchema = (formatMessage) => object().shape({
  firstName: string().required(formatMessage({ id: "form.field.error.required" }, { field: formatMessage({ id: "form.field.firstName" }) })),
  familyName: string(),
  email: string().email(formatMessage({ id: "app.form.field.email.invalid" })).required(formatMessage({ id: "form.field.error.required" }, { field: formatMessage({ id: "form.field.email" }) })),
  password: string()
      .min(5, formatMessage({ id: "form.field.error.password_min" }))
      .required(""),
  confirmPassword: string()
      .oneOf([ref("password"), null], formatMessage({ id: "form.field.error.password_match" }))
      .required(""),
  terms: boolean()
      .oneOf([true], formatMessage({ id: "form.field.error.terms" }))
      .required(formatMessage({ id: "form.field.error.terms" }, { field: formatMessage({ id: "form.field.terms" }) })),
})
