
import { api } from './api';

export const TeacherApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getTeachersByOrganisation: builder.query({
            query: ({ organisationId }) => ({
                url: `/api/teachers?organisations[]=/api/organisations/${organisationId}`,
                method: 'GET',
            }),
            providesTags: (result) =>
                result === undefined
                    ? []
                    : [
                        { type: 'Teachers', id: 'LIST' },
                    ],
        }),
        deleteTeacher: builder.mutation({
            query: ({ id }) => ({
                url: `/api/teachers/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result) =>
                result === undefined
                    ? []
                    : [
                        { type: 'Teachers', id: 'LIST' },
                        { type: 'ClassroomsFilter', id: 'LIST' },
                        { type: 'Auth', id: 'ME' },
                        { type: 'Auth', id: result ? result.id : '' }
                    ],
        }),
    })
});
export const {
    useLazyGetTeachersByOrganisationQuery,
    useDeleteTeacherMutation
} = TeacherApi;