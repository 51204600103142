import React from "react";
import styles from "./button.module.css";
import { Spin } from "antd";

const SIZE = {
  SM: "sm",
  MD: "md",
  LG: "lg",
};

const VARIANT = {
  PRIMARY: "primary",
  DEFAULT: "default",
  YELLOW: "yellow",
  GHOST: "ghost",
};
const Button = ({
  icon,
  isLoading,
  text,
  size = SIZE.MD,
  variant = VARIANT.DEFAULT,
  block = false,
  className,
  ...props
}) => {
  const buttonClasses = [
    styles.button,
    block && styles.block,
    styles[variant],
    styles[size],
    className,
  ]
    .filter(Boolean)
    .join(" ");

  const content = (
    <button className={buttonClasses} {...props}>
      {icon && !isLoading && icon}
      {text && <span className={styles.text}>{text}</span>}
    </button>
  );

  return isLoading ? <Spin spinning={isLoading}> {content}</Spin> : content;
};

export { Button as default, SIZE, VARIANT };
