import dayjs from "dayjs";

export const getInitialQuestion = (format) => {
  switch (format) {
    case "MultiChoice":
      return {
        type: "MultiChoice",
        context: null,
        isJustification: false,
        justification: "",
        questionText: "",
        resources: [],
        grade: 0,
        links: [],
        options: [
          { text: "", isCorrect: false },
          { text: "", isCorrect: false },
        ],
      };
    case "Essay":
      return {
        type: "Essay",
        context: null,
        description: "",
        resources: [],
        rubric: null,
        grade: 0,
        links: [],
      };
    case "ShortAnswer":
      return {
        type: "ShortAnswer",
        description: "",
        resources: [],
        rubric: null,
        grade: 0,
        links: [],
      };
    default:
      return {
        type: "MultiChoice",
        context: null,
        isJustification: false,
        justification: "",
        resources: [],
        questionText: "",
        grade: 0,
        links: [],
        options: [
          { text: "", isCorrect: false },
          { text: "", isCorrect: false },
        ],
      };
  }
};

export const handleQuestionTypeChange = (setFieldValue, index, value) => {
  setFieldValue(`questions.${index}.type`, value);
  if (value === "MultiChoice") {
    setFieldValue(`questions.${index}.options`, [
      { text: "", isCorrect: false },
      { text: "", isCorrect: false },
    ]);
  } else if (value === "ShortAnswer") {
    // Initialize ShortAnswer specific fields
    setFieldValue(`questions.${index}.options`, undefined);
    setFieldValue(`questions.${index}.rubric`, null);
    setFieldValue(`questions.${index}.description`, "");
  } else if (value === "Essay") {
    // Initialize Essay specific fields
    setFieldValue(`questions.${index}.options`, undefined);
    setFieldValue(`questions.${index}.rubric`, null);
    setFieldValue(`questions.${index}.description`, "");
  }
};

export const handleFormatChange = (setFieldValue, value) => {
  setFieldValue("type", value);
  setFieldValue("questions", [getInitialQuestion(value)]);
};

export const formatFileSize = (bytes) => {
  if (!bytes) return "0 KB";

  // Convert to KB first since the original size is in bytes
  const sizeInKB = bytes / 1024;

  // If size is greater than 1024 KB (1 MB)
  if (sizeInKB > 1024) {
    return `${(sizeInKB / 1024).toFixed(2)} MB`;
  }

  return `${sizeInKB.toFixed(2)} KB`;
};

export const disabledDate = (current) => {
  return current && current < dayjs().startOf("day");
};
