import { Layout, Dropdown, Avatar, Menu, Tooltip } from "antd";
import {
  MenuFoldOutlined,
  DownOutlined,
  MenuUnfoldOutlined,
  MoreOutlined,
  SettingOutlined,
  RightOutlined,
} from "@ant-design/icons";
import avatar from "../../assets/images/Avatar.svg";
import MenuList from "../LeftSideMenu/LeftSideMenu.jsx";
import { Link } from "react-router-dom";
import "./LeftSideBar.css";
import { useGetMeQuery } from "../../services/auth.api";
import Button from "../Button";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../redux/auth/auth.slice";
import useTypeUser from "../../hooks/useTypeUser.js";
import { IconLogout } from "../../assets/icons/index.js";
import En from "../../assets/icons/En";
import Fr from "../../assets/icons/Fr";
import { useIntl } from "react-intl";
import { changeLanguage } from "../../redux/locale/locale.slice";
import ChevronRight from "../../assets/icons/ChevronRight/index.jsx";
import ROLES from "../../Utils/roles.js";

const { Sider } = Layout;

const LeftSideBar = ({ handlSidebarCollapse, collapsed, hideClass }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();
  const _logout = () => {
    dispatch(logout);
    navigate("/sign-in");
  };
  const { data: authenticatedUser, error, isLoading } = useGetMeQuery();
  const { typeUser: typeDashboard } = useTypeUser();
  const currentLocale = useSelector((state) => state.locale.locale);
  const languageLabels = {
    en: <En size={18} />,
    fr: <Fr size={18} />,
  };

  const reformatRole = (role) => {
    if (role === ROLES.SUPER_ADMIN) return "Super Admin";
    return role;
  };

  const content = (
    <div className="sidebar__footerMenu-info">
      <span className="sidebar__footerMenu-info-name">
        {authenticatedUser.first_name} {authenticatedUser.family_name}
      </span>
      <span className="sidebar__footerMenu-info-role">
        {reformatRole(typeDashboard)}
      </span>
    </div>
  );

  const menu = (
    <Menu
      onClick={({ key }) => {
        dispatch(changeLanguage(key)); // Dispatch changeLanguage action
      }}
    >
      {Object.entries(languageLabels).map(([key, label]) => (
        <Menu.Item key={key}>
          <div className="flex-y-center gap-8">
            {label} {key === "fr" ? "Français" : "English"}
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );
  const items = [
    {
      label: (
        <div className="flex-y-center gap-8 flex-between">
          <div className="flex-y-center gap-8">
            {languageLabels[currentLocale]}
            {currentLocale === "fr" ? "Français" : "English"}
          </div>
          <Dropdown
            overlay={menu}
            className={"selected-language"}
            placement="rightBottom"
          >
            <button className=" flex-y-center">
              <ChevronRight size={16} />
            </button>
          </Dropdown>
        </div>
      ),
      key: "0",
    },
    {
      label: (
        <button onClick={_logout} className=" flex-y-center gap-8">
          <IconLogout size={16} />
          {intl.formatMessage({ id: "app.logout" })}
        </button>
      ),
      key: "1",
    },
  ];

  return (
    <div
      className={
        collapsed
          ? `sidebar sidebar-${typeDashboard} sidebarCollapsed`
          : `sidebar sidebar-${typeDashboard}`
      }
    >
      <div>
        <div className="sidebar__headerMenu">
          <Link to={"/"}>
            <img
              src={"/remedia.svg"}
              alt="remedia logo"
              width={95}
              className={
                collapsed
                  ? `sidebar__headerMenu-LogoCollapsed`
                  : `sidebar__headerMenu-Logo`
              }
            />
          </Link>
          <button type="default" onClick={handlSidebarCollapse}>
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </button>
        </div>

        <Sider
          className="btn-collapsed-sidebar"
          collapsed={collapsed}
          collapsible
        >
          <MenuList
            collapsed={collapsed}
            hideClass={hideClass}
            user={authenticatedUser}
          />
        </Sider>
      </div>

      <div className="flex-col gap-12">
        <div
          className={
            collapsed
              ? `sidebar__footer-vertical`
              : `sidebar__footer-horizontal`
          }
        >
          <div className="sidebar__footerMenu ">
            {collapsed ? (
              <Tooltip
                placement="left"
                title={content}
                className="avatar-tooltip"
              >
                <Avatar src={avatar} />
              </Tooltip>
            ) : (
              <>
                <Avatar src={avatar} />
                {content}
              </>
            )}
          </div>
          <div className="logout">
            <Dropdown menu={{ items }} placement="bottomLeft">
              <button>
                <ChevronRight size={16} />
              </button>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftSideBar;
