import Table from "../../Components/table/Table";
import "./Assignment.css";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Dropdown, Input } from "antd";
import {
  SearchOutlined,
  FormOutlined,
  DeleteOutlined,
  DownOutlined,
  UpOutlined,
  EyeOutlined,
  LoadingOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {
  useLazyGetAssignmentsByTeacherAndClassQuery,
} from "../../services/assignments.api";
import {
  useCheckStudentCourseworkMutation,
  useLazyGetStudentCourseWorkByClassroomQuery
} from "../../services/studentcourseworks.api";
import { useDeleteCourseWorkMutation } from "../../services/coursworks.api";
import { Modal, Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  selectClassroom,
  selectNotification,
} from "../../redux/app/app.selectors";
import CustomSpin from "../../Components/customSpin/CustomSpin";
import { selectAuthenticatedUser } from "../../redux/auth/auth.selectors";
import { setNotification } from "../../redux/app/app.slice";
import CustomCourseworkModal from "../../Components/customCourseworkModal";
import { useIntl } from "react-intl";
import { useLazyGetCheckIsSubmittedQuery } from "../../services/studentQuizSubmission.api";
import CustomModalSelection from "../../Components/customModalSelection";
import CustomHandWritingIcon from "../../assets/icons/CustomHandWritingIcon";
import AIIcon from "../../assets/icons/AIIcon";
import Filter from "../../Components/filter/Filter";
import ImportIcon from "../../assets/icons/ImportIcon";
import ClassModeIcon from "../../assets/icons/ClassModeIcon";
import PracticeModeIcon from "../../assets/icons/PracticeModeIcon";
import {isTeacher} from "../../services/auth.api";


const Remedia = () => {
  const classroom = useSelector(selectClassroom);
  const [searchTerm, setSearchTerm] = useState("");
  const intl = useIntl();
  const [isModalCustomCourseworkOpen, setIsModalCustomCourseworkOpen] =
    useState(false);
  const [trigger] = useCheckStudentCourseworkMutation();
  const [deleteCourseWork] = useDeleteCourseWorkMutation();
  const [checkIsSubmitted] = useLazyGetCheckIsSubmittedQuery();
  const [currentPage, setCurrentPage] = useState(1);
  const [sortConfig, setSortConfig] = useState({ key: "id", direction: null });
  const dispatch = useDispatch();
  const [
    triggerAssignment,
    { data: assignmentData, isLoading: isLoadingAssignments },
  ] = useLazyGetAssignmentsByTeacherAndClassQuery();

  const [triggerAssignmentStudent, { data: assignmentDataStudent , isLoading: isLoadingAssignmentsStudent }]  = useLazyGetStudentCourseWorkByClassroomQuery();

  console.log(assignmentDataStudent, 'assignmentDataStudent');

  const navigate = useNavigate();
  const user = useSelector(selectAuthenticatedUser);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedMode, setSelectedMode] = useState(null);
  const { TabPane } = Tabs;
  const [activeTab, setActiveTab] = useState("all");
  const [selectedFilterItems, setSelectedFilterItems] = useState([]);

  useEffect(() => {
    if(isTeacher(user)){
      triggerAssignment({classroom: classroom?.id});
    }else{
      triggerAssignmentStudent({classroom: classroom?.id, state: "active"});
    }
  }, [classroom]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const addCustomCoursework = () => {
    setIsModalCustomCourseworkOpen(!isModalCustomCourseworkOpen);
  };

  const VerifyIsSubmitted = async (item) => {
    const submitted = await checkIsSubmitted(item.id);
    const isSubmitted = submitted?.data["hydra:member"][0] || false;
    return isSubmitted;
  }

  const editAssisement = async (item) => {

    const formData = new FormData();
    formData.append("courseworkId", item.id);

    const payload = { courseworkId: item.id };

    const isSubmitted = await VerifyIsSubmitted(item);
    if(!isSubmitted){
      navigate(`/assignment/edit/${item.id}`)
    }else{
      dispatch(
        setNotification({
          type: "error",
          message: intl.formatMessage({
            id: "assignment.access.action.edit.denied",
          }),
        })
      );
    }
    
    console.log("check submitted", VerifyIsSubmitted(item));

  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const deleteAssisement = async(item) => {
    const formData = new FormData();
    formData.append("courseworkId", item.id);

    const isSubmitted = await VerifyIsSubmitted(item);

    if (isSubmitted) {
      dispatch(
        setNotification({
          type: "error",
          message: intl.formatMessage({
            id: "assignment.access.action.delete.denied",
          }),
        })
      );
      return;
    }
    const { confirm } = Modal;
    confirm({
      title: intl.formatMessage({
        id: "assignment.list.item.delete.message.confirm.title",
      }),
      content: `${intl.formatMessage({
        id: "assignment.list.item.delete.message.confirm.content",
      })}: ${item.title}`,
      okText: intl.formatMessage({
        id: "assignment.list.item.delete.message.confirm.okText",
      }),
      okType: "danger",
      cancelText: intl.formatMessage({
        id: "assignment.list.item.delete.message.confirm.cancelText",
      }),
      onOk() {
        deleteCourseWork({ assignmentId: item.id }).then((result) => {
          if (result.data) {
            // triggerAssignment();
            dispatch(
              setNotification({
                type: "success",
                message: intl.formatMessage({
                  id: "assignment.list.item.delete.message.success.text",
                }),
              })
            );
          }
        });
      },
      onCancel() {
        console.log('Deletion canceled');
      },
    });
  };

  const ViewAssisement = (item) => {

    navigate(`/assignment/preview/${item.id}`);
    
  }

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
  };

  const formatDueDate = (duedate) => {
    const date = new Date(duedate);
    return (
      date.toLocaleDateString(undefined, {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      }) +
      " " +
      date.toLocaleTimeString(undefined, {
        hour: "2-digit",
        minute: "2-digit",
      })
    );
  };

  const columns = [
    { key: 1, title: "ID", dataIndex: "id" },
    {
      key: 2,
      title: intl.formatMessage({
        id: "assignment.list.table.header.column.title",
      }),
      dataIndex: "title",
      sorter: true,
    },
    {
      key: 3,
      title: intl.formatMessage({
        id: "assignment.list.table.header.column.type",
      }),
      dataIndex: "type",
    },
    {
      key: 4,
      title: intl.formatMessage({
        id: "assignment.list.table.header.column.subject",
      }),
      dataIndex: "subject",
    },
    {
      key: 5,
      title: intl.formatMessage({
        id: "assignment.list.table.header.column.description",
      }),
      dataIndex: "description",
    },
    {
      key: 6,
      title: intl.formatMessage({
        id: "assignment.list.table.header.column.due.date",
      }),
      dataIndex: "due_date",
      render: (item) => {
        const { dueDate } = item; // Accessing dateStart and dateEnd directly from the item
        if (dueDate) {
          return formatDueDate(dueDate); // Concatenate and format the dates
        } else {
          return "No dates available";
        }
      },
      sorter: (a, b) => {
        const dateA = new Date(a.dateStart);
        const dateB = new Date(b.dateEnd);
        return dateA - dateB;
      },
    },
    {
      key: 7,
      title: intl.formatMessage({
        id: "assignment.list.table.header.column.state",
      }),
      dataIndex: "state",
    },
    {
      key: 8,
      title: intl.formatMessage({
        id: "assignment.list.table.header.column.action",
      }),
      dataIndex: "Action",
    },
  ];

  const handleActionClick = async (id) => {
    let id_student = user.id.toString();
    let id_course = id.toString();
    console.log(id_student, id_course);
    if (!user.roles.includes("ROLE_TEACHER")) {
      const { data, error } = await trigger({
        student: id_student,
        course: id_course,
      });
      if (data) {
        // console.log("trigger",data)
        navigate(`/courseworks/${id_course}`);
      }
      if (error) {
        console.log(error);
      }
    }
  };

  const handleSort = (columnKey) => {
    let direction;
    if (sortConfig.key === columnKey) {
      if (sortConfig.direction === "ascending") {
        direction = null; // Second click, reset to default (unsorted)
      } else if (sortConfig.direction === null) {
        direction = "descending"; // Third click, sort descending
      } else {
        direction = "ascending"; // First click, sort ascending
      }
    } else {
      direction = "ascending"; // New column clicked, sort ascending
    }
    setSortConfig({ key: columnKey, direction });
  };

  const data = assignmentData?.["hydra:member"] ? assignmentData?.["hydra:member"]: ( assignmentDataStudent?.["hydra:member"] ? assignmentDataStudent?.["hydra:member"]  : []);
  const workData = data ? data : [];
  const sortedData = [...workData]?.sort((a, b) => {
    if (sortConfig.direction === "ascending") {
      return a?.[sortConfig?.key] > b?.[sortConfig?.key] ? 1 : -1;
    }
    if (sortConfig.direction === "descending") {
      return a?.[sortConfig?.key] < b?.[sortConfig?.key] ? 1 : -1;
    }
    return 0;
  });
  const TableHeadCell = useCallback(
    (column) => {
      if (column.dataIndex === "id" || column.dataIndex === "Action") {
        return column.title;
      }
      if (
        column.dataIndex === "state" &&
        !user.roles.includes("ROLE_TEACHER")
      ) {
        return;
      }
      return (
        <>
          <span
            onClick={() => handleSort(column.dataIndex)}
            className={"sort_column"}
          >
            <span>{column.title}</span>
            <span className={"sort__icons"}>
              <UpOutlined
                className={
                  sortConfig.direction === "ascending" &&
                  sortConfig.key === column.dataIndex
                    ? "up__icon active"
                    : "up__icon"
                }
              />
              <DownOutlined
                className={
                  sortConfig.direction === "descending" &&
                  sortConfig.key === column.dataIndex
                    ? "down__icon active"
                    : "down__icon"
                }
              />
            </span>
          </span>
        </>
      );
    },
    [sortConfig]
  );

  const TableBodyCell = useCallback((column, item) => {
    switch (column.dataIndex) {
      case "Action":
        return user.roles.includes("ROLE_TEACHER") ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              onClick={() => editAssisement(item)}
              className="tableBody__cell-openBtn"
              icon={<FormOutlined />}
            ></Button>
            <Button
              onClick={() => ViewAssisement(item)}
              className="tableBody__cell-openBtn"
              icon={<EyeOutlined />}
            ></Button>

            <Button
              onClick={() => deleteAssisement(item)}
              className="tableBody__cell-openBtn"
              icon={<DeleteOutlined />}
            ></Button>
          </div>
        ) : (
          <Button
            onClick={() => handleActionClick(item.id)}
            className="tableBody__cell-openBtn"
          >
            {intl.formatMessage({
              id: "assignment.list.table.header.column.action.button",
            })}
          </Button>
        );

      case "state":
        return (
          user.roles.includes("ROLE_TEACHER") && (
            <span>{item[column.dataIndex]}</span>
          )
        );

      case "type":
        return (
          <span className="tableBody__cell-formatCol">
            {item[column.dataIndex]}
          </span>
        );
      case "due_date":
        const { dueDate } = item;
        if (dueDate) {
          return <span>{formatDueDate(dueDate)}</span>;
        } else {
          return (
            <span>
              {intl.formatMessage({
                id: "assignment.list.table.header.column.no.date",
              })}
            </span>
          );
        }
      case "description":
        return (
          item?.type === "Essay" && (
            <div>{truncateText(item?.quiz?.questions[0]?.questionText, 80)}</div>
          )
        );
      default:
        return item[column.dataIndex];
    }
  }, []);

  const importCourseworkFromGoogle = async (e) => {
    window.location.href = `${process.env.REACT_APP_API_BASE_URL}/google/${user.id}/oauth`;
  };

  const items = [
    {
      key: "1",
      label: (
        <Button
          color={"#FDD832"}
          className={"import__assignment__dropdown"}
          onClick={importCourseworkFromGoogle}
        >
          {intl.formatMessage({
            id: "assignment.list.button.import.googleClassroom.text",
          })}
        </Button>
      ),
    },
  ];

  const translations = {
    title: intl.formatMessage({id: "assignment.create.modal.title"}),
    subtitle: intl.formatMessage({id: "assignment.create.modal.subtitle"}),
    continueButton: intl.formatMessage({id: "assignment.create.modal.button.continue"}),
  };

  const options = [
    {
      id: 'custom',
      label: intl.formatMessage({id: "assignment.create.modal.choice.custom"}),
      icon: <CustomHandWritingIcon />,
      onSelect: () => {
        navigate('/assignment/create/custom');
      },
    },
    {
      id: 'ai',
      label: intl.formatMessage({id: "assignment.create.modal.choice.ai"}),
      icon: <AIIcon />,
      onSelect: () => {
        navigate('/assignment/generate/ai');
      },
    },
    {
      id: 'import',
      label: intl.formatMessage({id: "assignment.create.modal.choice.import"}),
      icon: <ImportIcon />,
      onSelect: () => {
        navigate('/assignment/import');
      },
    },
  ];

  const filterItems = [
    { label: intl.formatMessage({id: "assignment.list.table.filter.essay"}), key: 'Essay',},
    { label: intl.formatMessage({id: "assignment.list.table.filter.quiz"}), key: 'Quiz',},
    // { label: intl.formatMessage({id: "assignment.list.table.filter.mcq"}), key: 'MCQ',},
    // { label: intl.formatMessage({id: "assignment.list.table.filter.qr"}), key: 'Q/R',},
];

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOptionSelect = (mode) => {
    setSelectedMode(mode);
  };

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const filteredData = sortedData.filter(item => {
    if (activeTab === "all") return true;
    if (activeTab === "practice") return item.isPracticeMode === true;
    if (activeTab === "class") return item.isPracticeMode === false;
    return true;
  });

  if (isLoadingAssignments || isLoadingAssignmentsStudent) {
    return <CustomSpin />;
  }


  const handleSync = () => {
    const service = "SYNC_GOOGLE_API_COURSEWORK";
    const redirect = `${window.location.origin}/coursework`;
    const idService = classroom.id;
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const idUser = user?.id;
    const url = `${baseUrl}/google/${idUser}/oauth?service=${service}&redirect=${redirect}&idService=${idService}`;
    window.location.href = url;

    dispatch(
        setNotification({
          type: "info",
          message: intl.formatMessage({id: "coursework.message.sync.info.text"}),
        })
    );

  };

  return (
    <>
      <div className="content__assignments">
        <div className={"assignment__top-page"}>
          <div className="assignments__filter">
            <span> {intl.formatMessage({ id: "assignment.list.title" })} </span>
            <Filter
              filterItems={filterItems}
              selectedFilterItems={selectedFilterItems}
              setSelectedFilterItems={setSelectedFilterItems}
                    />
          </div>

          <div className="assignments-new">
            <Input
              type="text"
              placeholder={intl.formatMessage({
                id: "assignment.list.form.search.placeholder",
              })}
              prefix={<SearchOutlined />}
              value={searchTerm}
              onChange={handleSearchChange}
            />
            {user.roles.includes("ROLE_TEACHER") && (
              <>
                <Button color="#FDD832" icon={<SyncOutlined />} onClick={handleSync}>Sync</Button>
                <Button color="#FDD832" onClick={() => setIsModalVisible(true)}>
                  {intl.formatMessage({
                    id: "assignment.list.button.new.assignment.text",
                  })}
                </Button>
              </>
            )}
          </div>
        </div>
        {user.roles.includes("ROLE_TEACHER") && (
          <CustomModalSelection
          visible={isModalVisible}
          onCancel={handleCancel}
          translations={translations}
          options={options}
          onOptionSelect={handleOptionSelect}
          selectedMode={selectedMode}
          className="custom-modal-class"
          />
        )}

        <Tabs defaultActiveKey="all" onChange={handleTabChange} className="assignment-tabs">
          <TabPane tab={intl.formatMessage({ id: "assignment.tabs.all" })} key="all" className="assignment-tab">
            <Table
              columns={columns}
              data={filteredData}
              TableHeadCell={TableHeadCell}
              TableBodyCell={TableBodyCell}
              noDataText={`You haven't added any assignments yet.`}
              searchTerm={searchTerm}
              page={currentPage}
              onPageChange={handlePageChange}
              selectedFilterItems={selectedFilterItems}
            />
          </TabPane>
          <TabPane tab={<><span className="tab-mode-icon-container"><ClassModeIcon className="tab-mode-icon" /></span> {intl.formatMessage({ id: "assignment.tabs.class" })}</>} key="class" className="assignment-tab">
            <Table
                columns={columns}
                data={filteredData}
                TableHeadCell={TableHeadCell}
                TableBodyCell={TableBodyCell}
                noDataText={`You haven't added any assignments yet.`}
                searchTerm={searchTerm}
                page={currentPage}
                onPageChange={handlePageChange}
                selectedFilterItems={selectedFilterItems}
            />
          </TabPane>
          <TabPane tab={<><span className="tab-mode-icon-container"><PracticeModeIcon className="tab-mode-icon" /></span> {intl.formatMessage({ id: "assignment.tabs.practice" })}</>} key="practice" className="assignment-tab">
            <Table
            columns={columns}
            data={filteredData}
            TableHeadCell={TableHeadCell}
            TableBodyCell={TableBodyCell}
            noDataText={`You haven't added any assignments yet.`}
            searchTerm={searchTerm}
            page={currentPage}
            onPageChange={handlePageChange}
            selectedFilterItems={selectedFilterItems}
          />
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};

export default Remedia;
