import { useMemo } from "react";
import { useGetMeQuery } from "../services/auth.api";

const useTypeUser = () => {
  const { data: authenticatedUser, error, isLoading } = useGetMeQuery();
  const typeUser = useMemo(() => {
    if (authenticatedUser?.roles?.includes("ROLE_ADMIN")) return "admin";
    if (authenticatedUser?.roles?.includes("ROLE_TEACHER")) return "teacher";
    if (authenticatedUser?.roles?.includes("ROLE_SUPER_ADMIN")) return "super_admin";
    return "student";
  }, [authenticatedUser]);

  const isAdmin = typeUser === "admin";
  const isTeacher = typeUser === "teacher";
  const isStudent = typeUser === "student";
  const isSuperAdmin = typeUser === "super_admin";

  return { typeUser, isLoading, isAdmin, isTeacher, isStudent, isSuperAdmin };
};

export default useTypeUser;
