import { api } from './api';

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: '/authentication_token',
        method: 'POST',
        body: JSON.stringify(credentials),
        headers: { 'Content-Type': 'application/json' },
      }),
      invalidatesTags: [{ type: 'Auth', id: 'TOKEN' }],
    }),
    getMe: builder.query({
      query: () => ({
        url: '/users/me',
        method: 'GET',
      }),
      providesTags: (result) =>
        result === undefined
          ? []
          : [
              { type: 'Auth', id: 'ME' },
              { type: 'Auth', id: result ? result.id : '' },
            ],
    }),
    getUserByToken: builder.query({
      query: (token) => ({
        url: `/get-user-by-token/${token}`,
        method: 'GET',
      }),
    }),
    verifyUserAndSetNewPassword: builder.mutation({
        query: (data) => ({
            url: '/api/check-and-set-password',
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json' },
        }),
        invalidatesTags: [
            { type: 'Auth', id: 'TOKEN' },
            { type: 'Auth', id: 'ME' },
        ],
    }),
  }),
});

export const isTeacher = (user) => {
    return (
        user && (user.roles.includes("ROLE_TEACHER"))
    );
};

export const { useLoginMutation, useGetMeQuery, useLazyGetMeQuery, useVerifyUserAndSetNewPasswordMutation, useLazyGetUserByTokenQuery } = authApi;