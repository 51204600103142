import React, { useEffect, useState } from 'react';
import styles from './correction.module.css';
import AssignmentCorrectionsPreview from "../../Components/assignmentCorrectionsPreview";
import { useParams } from 'react-router-dom';
import { useGetStudentQuizSubmissionQuery } from '../../services/studentQuizSubmission.api';
import StudentAssignmentCorrectionsPreview from '../../Components/studentAssignmentCorrectionsPreview';


const formatSubmissionData = (studentQuestionAnswers, llmAnswers = []) => {
    console.log("llmAnswers:::", llmAnswers);
    console.log("studentQuestionAnswers:::", studentQuestionAnswers);
    return studentQuestionAnswers?.map((item) => {
      const { question, ...data } = item;
      
      // Find corresponding LLM answer for this question
      const matchingLlmAnswer = llmAnswers.find(
        llmItem => llmItem.question.id === question.id
      );

      return {
        ...data,
        ...question,
        llmAnswer: matchingLlmAnswer ? {
          answer: matchingLlmAnswer.output,
          score: matchingLlmAnswer.score || null
        } : null
      };
    });
};

const StudentCorrectionPage = () => {
    const {id} = useParams();
  
    const { data: dataSumissions, isLoading} = useGetStudentQuizSubmissionQuery(id);
    const [dataFormated, setDataFormated] = useState([]);
    

    useEffect(() => {
        if (dataSumissions?.studentQuestionAnswers?.length) {
            const formattedQuestions = formatSubmissionData(
                dataSumissions?.studentQuestionAnswers, 
                dataSumissions?.status ==="Graded" ? dataSumissions?.assessmentQuiz?.llmAnswerQuizzes : dataSumissions?.preAssesmentQuiz?.llmAnswerQuizzes
            );
            setDataFormated({ questions: formattedQuestions, id: dataSumissions.id, description: dataSumissions?.coursework?.description, title: dataSumissions?.coursework?.title, type: dataSumissions?.coursework?.type });
        }
    }, [dataSumissions]);
    return (!isLoading && <StudentAssignmentCorrectionsPreview assignments={dataFormated} isPreview={true} />);

};

export default StudentCorrectionPage;