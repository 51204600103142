import Modal from "../Modal";
import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import Input, { TYPE } from "../Input";
import { IconOrganization } from "../../assets/icons";
import { useIntl } from "react-intl";
import {useCreateOrganisationMutation} from "../../services/organisation.api";
import {setNotification} from "../../redux/app/app.slice";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

const ModalCreateOrganization = ({ isModalOpen, setIsModalOpen }) => {
  const [createOrganisation, { isLoading: isCreatingOrganisation }] = useCreateOrganisationMutation();
  const intl = useIntl();
  const dispatch = useDispatch();
  const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
  });
  const initialValues = {
    name: "",
  };
  const navigate = useNavigate();

  const _handleSubmit = async (values, { setSubmitting }) => {
    const {data, error} = await createOrganisation(values);

    if(data){
      setSubmitting(false);
      setIsModalOpen(false);
      dispatch(setNotification({message: 'Organization created successfully', type: 'success'}));
      navigate('/teachers-invitations');
    }

    if(error){
      setSubmitting(false);
      console.log("error", error)
      dispatch(setNotification({message: 'Organization could not be created', type: 'error'}));
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={_handleSubmit}
    >
      {({ values, handleSubmit, handleChange, errors }) => (
        <Modal
          title={<IconOrganization size={24} className="black" />}
          open={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          onClose={() => setIsModalOpen(false)}
          onConfirm={() => handleSubmit()}
          ConfirmText={intl.formatMessage({
            id: `app.form.button.create`,
          })}
          cancelText={intl.formatMessage({ id: `app.form.button.cancel` })}
        >
          <Input
            label="Organization name"
            placeholder={"Organization name"}
            name="name"
            type={TYPE.TEXT}
            value={values.name}
            onChange={handleChange}
            error={errors.name}
          />
        </Modal>
      )}
    </Formik>
  );
};

export default ModalCreateOrganization;
