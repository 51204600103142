import React, { useEffect, useMemo, useState } from "react";
import "./classes.css";
import { useGetMeQuery } from "../../../services/auth.api";
import {
  useCreateClassRoomMutation,
  useEditClassroomMutation,
  useDeleteClassroomMutation, useLazyGetClassroomsByTeacherAndOrganisationQuery,
} from '../../../services/classroom.api'
import CustomSpin from "../../../Components/customSpin/CustomSpin";
import ClassModal from "../../../Components/classModal/ClassModal";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setClassroom, setNotification } from "../../../redux/app/app.slice";
import {
  useLazyGetClassroomsByOrganisationQuery,
  useLazyGetClassroomsByStudentQuery,
} from "../../../services/classroom.api";
import { selectClassroom } from "../../../redux/app/app.selectors";
import { useIntl } from "react-intl";
import useTypeUser from "../../../hooks/useTypeUser";

import Header from "./Header";
import TeacherView from "./TeacherView";
import StudentView from "./StudentView";
import AdminView from "./AdminView";
import NotFound from "../../../Components/NotFound";
import useGetOrganization from '../../../hooks/useGetOrganization'
import { useLazyGetTeachersByOrganisationQuery } from '../../../services/teacher.api'

const Classes = () => {
  const classroom = useSelector(selectClassroom);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data: authenticatedUser } = useGetMeQuery();
  const [createClassRoom] = useCreateClassRoomMutation();
  const [codeGenerated, setCodeGenerated] = useState("");
  const intl = useIntl();
  const [mode, setMode] = useState("create");
  const [updateClassRoom] = useEditClassroomMutation();
  const [deleteClassRoom] = useDeleteClassroomMutation();
  const { organisation, isLoading: isOrganisationLoading } = useGetOrganization();
  const { typeUser, isLoading: isLoadingTypeUser, isAdmin,isTeacher,isStudent } = useTypeUser();

  const [editingClassId, setEditingClassId] = useState(null);

  const [triggerClassrooms, { data: dataClassroom, isLoading: isLoadingClassroom }] =
      useLazyGetClassroomsByOrganisationQuery();

  const [triggerClassroomsByStudent, { data: studentClassrooms, isLoading: isLoadingStudentClassroom }] =
    useLazyGetClassroomsByStudentQuery();

  const [triggerClassroomsByTeacher, { data: teacherClassrooms, isLoading: isLoadingTeacherClassroom }] =
      useLazyGetClassroomsByTeacherAndOrganisationQuery();

  const [triggerTeachers, { data: dataTeachers, isLoading: isLoadingTeachers }] =
      useLazyGetTeachersByOrganisationQuery();

  const [initialValues, setInitialValues] = useState({
    name: "",
    subject: "",
    grade: "",
  });

  const canCreateClass = isTeacher || isAdmin;

  useEffect(() => {
    if (authenticatedUser?.id) {
      if (isAdmin && organisation) {
        triggerTeachers({ organisationId: organisation?.id });
        triggerClassrooms({ organisationId: organisation?.id });
      }

      if (isTeacher && organisation) {
        triggerClassroomsByTeacher({ organisationId: organisation.id, teacherId: authenticatedUser.id })
      } else if (isTeacher && !organisation) {
        triggerClassroomsByTeacher({ teacherId: authenticatedUser.id })
      }

      if (isStudent) {
        triggerClassroomsByStudent({ students: authenticatedUser.id });
      }
    }
  }, [
    classroom,
    authenticatedUser,
    triggerClassroomsByStudent,
    triggerClassroomsByTeacher,
    triggerClassrooms,
    isTeacher,
    isAdmin,
  ]);

  useEffect(() => {
    generateRandomCode();
  }, []);

  const classes = isAdmin ? dataClassroom?.["hydra:member"] :
      isTeacher ? teacherClassrooms?.["hydra:member"] :
          studentClassrooms?.["hydra:member"];

  const generateRandomCode = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const codeLength = 10;
    const result = Array.from({ length: codeLength }, () =>
      characters.charAt(Math.floor(Math.random() * characters.length))
    ).join("");
    setCodeGenerated(result);
  };

  useEffect(() => {
    if (!isModalOpen) {
      setInitialValues({
        name: "",
        subject: "",
        grade: "",
      });
      setEditingClassId(null);
      setMode("create");
    }
  }, [isModalOpen]);


  const handleActionClick = (classroom) => {
    dispatch(setClassroom(classroom));
    navigate(`/coursework`);
  };

  const handleEdit = (classData) => {
    setInitialValues({ ...classData, teacher: classData.teacher?.id });
    setEditingClassId(classData?.id);
    setMode("edit");
    setIsModalOpen(true);
    if (classroom?.id === classData?.id) {
      dispatch(setClassroom(classData));
    }
  };

  const handleDelete = (id) => {
    Swal.fire({
      icon: "warning",
      title: intl.formatMessage({
        id: "classroom.form.delete.message.confirmation.title",
      }),
      text: intl.formatMessage({
        id: "classroom.form.delete.message.confirmation.text",
      }),
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({
        id: "classroom.form.delete.message.confirmation.button.yes",
      }),
      confirmButtonColor: "#000095",
      cancelButtonText: intl.formatMessage({
        id: "classroom.form.delete.message.confirmation.button.cancel",
      }),
    }).then((result) => {
      if (result.isConfirmed) {
        deleteClassRoom(id).then((result) => {
          if (result.data) {
            dispatch(
              setNotification({
                type: "success",
                message: intl.formatMessage({
                  id: "classroom.form.delete.message.success",
                }),
              })
            );
          }
        });
      }
    });
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const classroomData = {
      ...values,
      code: codeGenerated,
      created_at: new Date().toISOString(),
    };

    try {
      if (mode === "create") {
        classroomData.teacher = classroomData.teacher ? "/api/teachers/" + classroomData.teacher : null;

        if (isTeacher) {
          classroomData.teacher = "/api/teachers/" + authenticatedUser.id;
        }

        if (organisation) {
          classroomData.organisation = "/api/organisations/" + organisation.id;
        } else {
          if (isTeacher) {
            classroomData.organisation = "/api/organisations/" + authenticatedUser.myOrganisation.id;
          }
        }

        const { data, error } = await createClassRoom({
          ...classroomData,
          code: codeGenerated,
        });

        if (data) {
          dispatch(
            setNotification({
              type: "success",
              message: intl.formatMessage({
                id: "classroom.form.create.message.success",
              }),
            })
          );
          setSubmitting(false);
          resetForm();
          setIsModalOpen(false);
        }

        if (error) {
          dispatch(
            setNotification({
              type: "error",
              message: intl.formatMessage({
                id: "classroom.form.create.message.error",
              }),
            })
          );
          setSubmitting(false);
          setIsModalOpen(false);
        }
      } else if (mode === "edit" && editingClassId) {

        const { data } = await updateClassRoom({
          id: editingClassId,
          name: classroomData.name,
          subject: classroomData.subject,
          grade: classroomData.grade,
          teacher: '/api/teachers/' + classroomData.teacher,
        });

        if (data) {
          dispatch(
            setNotification({
              type: "success",
              message: intl.formatMessage({
                id: "classroom.form.update.message.success",
              }),
            })
          );
          setIsModalOpen(false);
          resetForm();
          setSubmitting(false);

        }
      }
    } catch (error) {
      dispatch(
        setNotification({
          type: "error",
          message: intl.formatMessage({
            id: "classroom.form.create.message.error",
          }),
        })
      );
      setSubmitting(false);
      setIsModalOpen(false);
    }
  };

  const renderContent = useMemo(() => {
    if (isAdmin) {
      return (
        <AdminView
          classes={classes}
          handleActionClick={handleActionClick}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
        />
      );
    }

    if (isTeacher) {
      return (
        <TeacherView
          classes={classes}
          handleActionClick={handleActionClick}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
        />
      );
    }
    return (
      <StudentView
        classes={classes}
        handleActionClick={handleActionClick}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
      />
    );
  }, [isAdmin, isTeacher, classes]);

  const isLoading =
    isLoadingClassroom ||
    isLoadingTeacherClassroom ||
    isLoadingStudentClassroom ||
    isLoadingTypeUser ||
    classes === undefined;

  if (isLoading) {
    return <CustomSpin />;
  }



  return (
    <div className="mainClasses">
      {classes?.length === 0 ? (
        <NotFound
          image={"assets/empty-classes.svg"}
          header={"Classes"}
          title={"Create a Class"}
          description={
            "Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en"
          }
          buttonText="Create a class"
          onClickAdd={() => setIsModalOpen(true)}
          displayButton={!isStudent}
        />
      ) : (
        <>
          <Header
            canCreateClass={canCreateClass}
            showModal={() => setIsModalOpen(true)}
          />

          <div className="classesContent__Bottom">{renderContent}</div>
        </>
      )}

      <ClassModal
        isModalOpen={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        handleSubmit={handleSubmit}
        mode={mode}
        initialValues={initialValues}
        typeUser={typeUser}
        teachers={isAdmin && dataTeachers ? dataTeachers["hydra:member"] : []}
      />
    </div>
  );
};

export default Classes;
