import React, { useEffect, useState } from "react";
import { Layout, Button, Menu, message } from "antd";
import "./AppLayout.css";
import LeftSideBar from "../Components/LeftSideBar/LeftSideBar";
import { selectNotification } from "../redux/app/app.selectors";
import { useDispatch, useSelector } from "react-redux";
import Notification from "../Components/notification/Notification";
import { Outlet } from "react-router-dom";

const { Content, Header } = Layout;

const AppLayout = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const notification = useSelector(selectNotification);
  const [messageApi, contextHolder] = message.useMessage();

  const handlSidebarCollapse = () => {
    setCollapsed(!collapsed);
  };
  return (
    <Layout style={{ minHeight: "100vh" }} className={"app-layout"}>
      {!props.hidesidebar && (
        <LeftSideBar
          collapsed={collapsed}
          handlSidebarCollapse={handlSidebarCollapse}
          hideClass={props.hideClass}
          classroom={props.classroom}
        />
      )}
      <Content className={"layout"}>
        <div className={"main-content"}>
          {contextHolder}
          <Notification notification={notification} />
          <Outlet />
        </div>
      </Content>
    </Layout>
  );
};

export default AppLayout;