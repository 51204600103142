import React, { useEffect, useMemo, useState } from "react";
import style from "./previewAssignment.module.css";
import QuestionsItem from "../questionsItem";
import { Formik } from "formik";
import * as Yup from "yup";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthenticatedUser } from "../../redux/auth/auth.selectors";
import {
  useCreateStudentQuizSubmissionMutation,
  useLazyGetFilteredSubmissionsQuery
} from "../../services/studentQuizSubmission.api";
import { useNavigate } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import { setNotification } from "../../redux/app/app.slice";
import RightSideBarPreview from "../rightSideBarPreview";
import CountdownTimer from "../CountdownTimer";
import { Modal } from "antd";
import RemediaIconWhite from "../../assets/icons/RemediaIconWhite";

const PreviewAssignment = ({ assignments, isPreview = true }) => {
  const [initialValues, setInitialValues] = React.useState([]);
  const [showSubmit, setShowSubmit] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [createStudentSubmissionQuiz, { isLoading: isLoadingSubmit }] =
    useCreateStudentQuizSubmissionMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const intl = useIntl();

  const [timeLimit, setTimeLimit] = useState(null);
  const [isAllowedRubric, setIsAllowedRubric] = useState(false);

  const user = useSelector(selectAuthenticatedUser);
  const [courseworkFilePath, setCourseworkFilePath] = useState("");
  const [isActiveFile, setIsActiveFile] = useState(false);
  const [resourceIndexs, setResourceIndexs] = useState(null);
  const [resources, setResources] = useState({});
  const formikRef = React.useRef();
  const [timeLeft, setTimeLeft] = useState(null);
  const [isTimerActive, setIsTimerActive] = useState(false);
  const { confirm } = Modal;
  const [isSubmissionInProgress, setIsSubmissionInProgress] = useState(false);
  const [triggerStudentCourseWorkSubmissions, {data: studentCourseWorkSubmissions}] = useLazyGetFilteredSubmissionsQuery()


  
  const enbaleRemy = useMemo(
    () => !isPreview && assignments?.writingAssistance,
    [isPreview, assignments],
  );

  useEffect(() => {
    triggerStudentCourseWorkSubmissions({
      pagination : false,
      student : user?.id,
      coursework : assignments?.id
    })

  }, [user?.id , assignments?.id]);

  const countStudentSubmissions = studentCourseWorkSubmissions?.['hydra:member']?.length;

  useEffect(() => {
    const canShowSubmit = isPreview ? false : (assignments?.isPracticeMode ? (assignments?.setting?.hasAttempts ? countStudentSubmissions < Number(assignments?.setting?.attempts) : countStudentSubmissions < 1) : countStudentSubmissions < 1);
    setShowSubmit(canShowSubmit);
  }, [isPreview, countStudentSubmissions]);

  useEffect(() => {
    if (!assignments?.setting) return;
    const { timeLimit, hasRubric } = assignments.setting;
    if (timeLimit) {
      setTimeLimit(parseInt(timeLimit));
    }
    setIsAllowedRubric(!!hasRubric);
  }, [assignments]);

  const [AssignmentSchema, setAssignmentSchema] = useState(
    Yup.object().shape({}),
  );

  // Initialize timer when component mounts
  useEffect(() => {
    if (!assignments?.setting) return;

    const { timeLimit, hasTimeLimit } = assignments.setting;

    if (hasTimeLimit && timeLimit && !isPreview) {
      const totalSeconds = parseInt(timeLimit) * 60;
      setTimeLeft(totalSeconds);
      setIsTimerActive(true);
    }
  }, [assignments, isPreview]);

  // Timer countdown logic
  useEffect(() => {
    let timerInterval;

    if (isTimerActive && timeLeft > 0) {
      timerInterval = setInterval(() => {
        setTimeLeft((prevTime) => {
          const newTime = prevTime - 1;

          if (newTime === 60) {
            dispatch(
              setNotification({
                type: "info",
                message:
                  "⚠️ One minute remaining! Your work will be automatically submitted when time runs out.",
              }),
            );
          }

          if (newTime === 0) {
            handleTimeUp();
          }

          return newTime;
        });
      }, 1000);
    }

    return () => {
      if (timerInterval) {
        clearInterval(timerInterval);
      }
    };
  }, [isTimerActive, timeLeft]);

  // Handle time up with Formik integration
  const handleTimeUp = async () => {
    setIsTimerActive(false);
    dispatch(
      setNotification({
        type: "info",
        message: "Time's up! Your work is being submitted...",
      }),
    );

    // Get the current form values from Formik
    if (formikRef.current) {
      const currentValues = formikRef.current.values;
      const processedValues = currentValues.map((question) => {
        return {
          ...question,
          studentAnswer:
            question?.studentAnswer?.length > 0
              ? question.studentAnswer
              : [
                {
                  type: "paragraph",
                  children: [{ text: "No answer provided - time expired" }],
                },
              ],
            slateFormatAnswer: question?.slateFormatAnswer || [
              {
                type: "paragraph",
                children: [{ text: "No answer provided - time expired" }],
              },
            ],
          answer: question?.answer || null,
        };
      });

      await __handleSubmit(processedValues, {
        setSubmitting: () => {},
      });
    }
  };

  const isDueDatePast = (dueDate) => {
    // Convert the dueDate string to a Date object
    const dueDateObj = new Date(dueDate);
    // Get the current date
    const currentDate = new Date();
    return dueDateObj < currentDate;
  };

  const checkAnswers = (data) => {
    let result = true;

    data?.studentQuestionAnswers?.forEach((answerObj) => {
      if (
        (answerObj?.type !== "MultiChoice" && (!answerObj?.answer || answerObj?.answer.trim() === "")) &&
        (!answerObj?.studentAnswer || answerObj?.studentAnswer?.length === 0)
      ) {
        result = false;
        return;
      }
    });

    return result;
  };

  const __handleSubmit = async (values, { setSubmitting }) => {
    // Prevent multiple submissions
    if (isSubmissionInProgress) {
      return;
    }

    if (assignments?.dueDate && isDueDatePast(assignments?.dueDate)) {
      dispatch(
        setNotification({
          type: "error",
          message: intl.formatMessage({
            id: "notification.assignment.dueDate.past",
          }),
        }),
      );
      return;
    }

    try {
      // Lock submissions
      setIsSubmissionInProgress(true);
      setSubmitting(true);
      const assignmentsId = assignments.id;
      const formData = new FormData();

      formData.append("student", user.id);
      formData.append("coursework", assignmentsId);
      formData.append("status", "Submitted");

      // Transform questions to studentQuestionAnswers
      const studentQuestionAnswers = values.map((question) => {
        return {
          question: `/api/questions/${question.id}`,
          studentAnswer: question.studentAnswer,
          isCorrect: question.isCorrect,
          justification: question.justification || null,
          answer: question.answer || null,
          type: question.type,
          slateFormatAnswer: question.slateFormatAnswer || [
            {
              type: "paragraph",
              children: [{ text: "" }],
            },
          ],
        };
      });

      formData.append(
        "studentQuestionAnswers",
        JSON.stringify(studentQuestionAnswers),
      );
      const payload = {
        studentQuestionAnswers: studentQuestionAnswers,
        student: `/api/students/${user.id}`,
        coursework: `/api/courseworks/${assignmentsId}`,
        status: "Submitted",
      };

      const isAllAnswered = checkAnswers(payload);
      const submitAction = async () => {
        try {
          const { data, error } = await createStudentSubmissionQuiz(payload);
          const canSubmit = assignments?.isPracticeMode ? assignments?.setting?.hasAttempts && countStudentSubmissions + 1  < Number(assignments?.setting?.attempts) -1 : false;
          if (data) {
            setShowSubmit(canSubmit);
            dispatch(
              setNotification({
                type: "success",
                message: intl.formatMessage({
                  id: "notification.submit.coursework.success",
                }),
              }),
            );
          }

          if (error) {
            dispatch(
              setNotification({
                type: "error",
                message: intl.formatMessage({
                  id: "notification.submit.coursework.error",
                }),
              }),
            );
          }
        } catch (err) {
          console.error("Submission error:", err);
          dispatch(
            setNotification({
              type: "error",
              message: "An unexpected error occurred during submission.",
            }),
          );
        } finally {
          // Always reset submission state
          setIsSubmissionInProgress(false);
          setSubmitting(false);
        }
      };

      if (!isAllAnswered) {
        confirm({
          title: intl.formatMessage({
            id: "notification.submit.coursework.emptyAnswers",
          }),
          okText: intl.formatMessage({ id: "notification.confirmation.ok" }),
          cancelText: intl.formatMessage({
            id: "notification.confirmation.cancel",
          }),
          onOk: submitAction,
          onCancel() {
            setIsSubmissionInProgress(false);
            setSubmitting(false);
          },
        });
      } else {
        await submitAction();
      }
    } catch (error) {
      console.error("Submission process error:", error);
      setIsSubmissionInProgress(false);
      setSubmitting(false);
      dispatch(
        setNotification({
          type: "error",
          message: intl.formatMessage({
            id: "notification.submit.coursework.error",
          }),
        }),
      );
    }
  };

  useEffect(() => {
    const mappedQuestions = assignments?.questions.map((item) => ({
      ...item,
      answerJustification: item?.isJustification && "",
      answer: item?.answer || null,
      justification: item?.justification || null,
      studentAnswer: item?.studentAnswer || [],
      type: item.type
    }));
    setInitialValues(mappedQuestions);
  }, [assignments]);

  const handleOpenHelpAssistant = () => {
    setIsActiveFile(false);
    setIsOpen(true);
  };

  return (
    <div className={style.container}>
      <div className="flex-between">
        <div className={style.header}>
          <button
            className={style.backButton}
            onClick={() => navigate("/coursework")}
          >
            <LeftOutlined />
          </button>
          <h2 className={style.title}>{assignments?.title}</h2>
        </div>
        <div>
          {enbaleRemy && (
            <button
              onClick={handleOpenHelpAssistant}
              className={style.helpAssistantButton}
            ></button>
          )}
        </div>
      </div>
      <Formik
        innerRef={formikRef}
        enableReinitialize
        initialValues={initialValues}
        validationSchema={AssignmentSchema}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={__handleSubmit}
      >
        {({ values, setFieldValue, handleSubmit, isSubmitting }) => (
          <div className={style.containerPreview}>
            <div
              className={`${style.containerPreviewContent} ${
                isOpen ? style.rightSideBarPreview__open : ""
              }`}
            >
              <div className={style.courseworkHeader}>
                {assignments?.description && (
                  <div className={style.containerAssignment}>
                    <h5 className={style.introductionTitle}>
                      {intl.formatMessage({
                        id: "assignment.preview.introduction",
                      })}
                    </h5>
                    <p className={style.introduction}>
                      {assignments?.description}
                    </p>
                  </div>
                )}

                <div className={style.rubricButtonContainer}>
                  {timeLimit && showSubmit && (
                    <CountdownTimer
                      className={style.timerContainer}
                      minutes={timeLimit}
                    />
                  )}
                </div>
              </div>

              <div className={style.listQuistionsContainer}>
                {values?.map((question, indexQuestion) => (
                  <QuestionsItem
                    setFieldValue={!showSubmit ? undefined : setFieldValue}
                    question={question}
                    indexQuestion={indexQuestion}
                    key={"Questions+" + indexQuestion}
                    openPreviewDocs={isOpen}
                    setOpenPreviewDocs={setIsOpen}
                    setCourseworkFilePath={setCourseworkFilePath}
                    setResourceIndexs={setResourceIndexs}
                    setIsActiveFile={setIsActiveFile}
                    setResources={setResources}
                    isAllowedRubric={isAllowedRubric}
                    allowPaste={assignments?.setting?.allowPaste}
                  />
                ))}
                {showSubmit && (
                  <div className={style.btnSubmitContainer}>
                    <button
                      type="submit"
                      className={style.btnSubmit}
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                      loading={isLoadingSubmit}
                    >
                      {isSubmissionInProgress ? "Submitting..." : "Submit"}
                    </button>
                  </div>
                )}
              </div>
            </div>

            <RightSideBarPreview
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              resourceIndexs={resourceIndexs}
              setResourceIndexs={setResourceIndexs}
              questions={values}
              isActiveFile={isActiveFile}
              setIsActiveFile={setIsActiveFile}
              resources={resources}
              enbaleRemy={enbaleRemy}
            />
          </div>
        )}
      </Formik>
    </div>
  );
};

export default PreviewAssignment;
