import React from 'react'
import useTypeUser from '../../hooks/useTypeUser'
import { Navigate } from 'react-router-dom'
import NotFound from '../../Components/NotFound'
import ModalCreateOrganization from '../../Components/ModalCreateOrganization'
import CustomSpin from '../../Components/customSpin/CustomSpin'
import { useIntl } from 'react-intl'
import useGetOrganization from '../../hooks/useGetOrganization'

const Organization = () => {
    const { isAdmin } = useTypeUser()
    const [isModalOpen, setIsModalOpen] = React.useState(false)
    const intl = useIntl()

    const { organisation, isLoading } = useGetOrganization();

    if (isLoading) {
        return <CustomSpin />
    }

    if (organisation) {
        return <Navigate to={'/classes'} />
    }

    if (isAdmin) {
        return (
            <>
                <NotFound
                    image={'assets/empty-organization.svg'}
                    header={intl.formatMessage({ id: 'organization.title' })}
                    title={intl.formatMessage({ id: 'organization.create_organization.title' })}
                    description={
                        intl.formatMessage({ id: 'organization.create_organization.description' })
                    }
                    buttonText={intl.formatMessage({ id: 'organization.button.new_organization' })}
                    onClickAdd={() => {
                        setIsModalOpen(true)
                    }}
                />
                <ModalCreateOrganization
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                />
            </>
        )
    }

    return <Navigate to={'/classes'} />
}

export default Organization
