import React from "react";

const User = ({ size = 16, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="16" height="16" rx="8" fill="#F2F4F7" />
      <path
        d="M11.5557 12C11.5557 11.3797 11.5557 11.0696 11.4791 10.8173C11.3068 10.2491 10.8622 9.80446 10.294 9.6321C10.0416 9.55555 9.7315 9.55555 9.11125 9.55555H6.88903C6.26877 9.55555 5.95865 9.55555 5.70629 9.6321C5.13812 9.80446 4.69349 10.2491 4.52113 10.8173C4.44458 11.0696 4.44458 11.3797 4.44458 12M10.0001 6C10.0001 7.10457 9.10471 8 8.00014 8C6.89557 8 6.00014 7.10457 6.00014 6C6.00014 4.89543 6.89557 4 8.00014 4C9.10471 4 10.0001 4.89543 10.0001 6Z"
        stroke="#475467"
        strokeWidth="0.888889"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default User;
