import React from 'react';
import * as Icons from '../../assets/icons';
import { background } from '@chakra-ui/react';

const AllIcons = () => {
  return (
    <div className="flex flex-wrap gap-12" style={{background:"white"}}>
      {Object.entries(Icons).map(([key, IconComponent]) => (
        <div key={key} className='flex flex-col items-center'>
           <IconComponent  size={20} />
          <span>{key}</span>
        </div>
      ))}
    </div>
  );
};

export default AllIcons;
