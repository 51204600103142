

const RemediaIconWhite = () => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.48344 15.5484C1.36346 15.5484 1.24348 15.5254 1.12843 15.4777C0.781649 15.3339 0.555664 14.9954 0.555664 14.6198V11.4042C0.555664 10.8915 0.971477 10.4765 1.48426 10.4765C1.99704 10.4765 2.41285 10.8923 2.41285 11.4051V12.3797L5.01292 9.77961C5.37531 9.41721 5.96287 9.41721 6.32609 9.77961C6.68849 10.142 6.68849 10.7296 6.32609 11.0928L2.14085 15.278C1.96335 15.4555 1.72586 15.55 1.48426 15.55L1.48344 15.5484Z"
        fill="white"
      />
      <path
        d="M13.0637 14.3364C12.8263 14.3364 12.5888 14.246 12.4072 14.0644L7.25387 8.91113C7.07965 8.73691 6.98186 8.50107 6.98186 8.25454C6.98186 8.00801 7.07965 7.77216 7.25387 7.59795L10.1226 4.72917C10.5434 4.30843 10.6584 3.72908 10.4308 3.17932C10.2032 2.62956 9.71176 2.30168 9.11681 2.30168H2.41203V8.43944C2.41203 8.95222 1.99622 9.36803 1.48344 9.36803C0.970655 9.36803 0.555664 8.95222 0.555664 8.43944V1.37391C0.555664 0.861125 0.971477 0.445312 1.48426 0.445312H9.11763C10.4686 0.445312 11.6298 1.22106 12.1467 2.46932C12.6635 3.71758 12.3915 5.08746 11.4358 6.04235L9.22281 8.25536L13.7195 12.7521C14.0819 13.1145 14.0819 13.702 13.7195 14.0652C13.5379 14.2468 13.3004 14.3372 13.0629 14.3372L13.0637 14.3364Z"
        fill="white"
      />
    </svg>
  );
};
export default RemediaIconWhite