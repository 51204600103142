
const PracticeModeIcon = ({ className }) => {
    return (
        <svg width="24" height="24" className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.0005 2.44531V8.44531C14.0003 8.7808 14.0846 9.11093 14.2455 9.40531L19.7555 19.4853C19.9221 19.7899 20.0066 20.1327 20.0006 20.4798C19.9946 20.827 19.8984 21.1666 19.7213 21.4653C19.5442 21.764 19.2924 22.0114 18.9907 22.1832C18.6889 22.3551 18.3477 22.4454 18.0005 22.4453H6.00046C5.65324 22.4454 5.31198 22.3551 5.01025 22.1832C4.70853 22.0114 4.45674 21.764 4.27965 21.4653C4.10257 21.1666 4.00629 20.827 4.0003 20.4798C3.9943 20.1327 4.0788 19.7899 4.24546 19.4853L9.75546 9.40531C9.91635 9.11093 10.0006 8.7808 10.0005 8.44531V2.44531" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.45312 15.4453H17.5471" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.5 2.44531H15.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

export default PracticeModeIcon;
