import { PlusCircleFilled } from "@ant-design/icons";
import {
  Button,
  Modal,
  Select,
  Input,
  Switch,
  DatePicker,
  Checkbox,
  Tag,
} from "antd";
import { Formik } from "formik";
import { useEffect, useState, useRef } from "react";
import {
  useLazyGetRubricByIdQuery,
  useLazyGetRubricsByTeacherQuery,
} from "../../services/rubrics.api";
import "./newAssignment.css";
import {
  useCreateAssignmentMutation,
  useLazyGetAssignmentsQuery,
} from "../../services/assignments.api";
import { AssignmentSchema } from "../../Pages/Assignments/form.schema";
import { useCreateNewCourseworkMutation } from "../../services/coursworks.api";
import { useGetMeQuery } from "../../services/auth.api";
import Swal from "sweetalert2";
import {useDispatch, useSelector} from "react-redux";
import { selectClassroom } from "../../redux/app/app.selectors";
import {setNotification} from "../../redux/app/app.slice";

const { TextArea } = Input;
const { RangePicker } = DatePicker;

const defaultValue = {
  title: "",
  type: "",
  subject: "",
  grade: "",
  description: "",
  rubric: "",
  state: "",
  image: null,
  isPracticeMode: false,
  writingAssistance: false,
  allowHandWriting: false,
  allowUploadFile: false,
  dateStart: null,
  dateEnd: null,
};

const NewAssignment = ({
  isModalOpen,
  onClose,
  initialValues = defaultValue,
  GradeAndSubjectData
}) => {
  const [triggerRubric, { data: rubricData, isLoading: isLoadingRubrics }] =
    useLazyGetRubricsByTeacherQuery();
  const [getRubricById, { data: rubricById, isLoading: isLoadingRubricById }] =
    useLazyGetRubricByIdQuery();
  const [selectedImage, setSelectedImage] = useState(null);
  const [showSelect, setShowSelect] = useState(false);
  const [triggerCreateCoursework, { data, isLoading: isLoadingCreate }] =
    useCreateNewCourseworkMutation();
  const [selectedRubricTitle, setSelectedRubricTitle] = useState("");
  const [selectedRubricId, setSelectedRubricId] = useState("");
  const { data: authenticatedUser, error } = useGetMeQuery();
  const [trigger, { data: assignments, isLoading: isLoadingAssignment }] =
    useLazyGetAssignmentsQuery();
  const classroom = useSelector(selectClassroom);
  const dispatch = useDispatch();
  useEffect(() => {
    if (initialValues?.image_url) {
      document.getElementById("selectedfile").innerText = "  1 file selected ";
    }
  }, [initialValues?.image_url]);

  useEffect(() => {
    if (authenticatedUser) {
      triggerRubric({ teacherId: authenticatedUser.id });
    }
  }, [triggerRubric, authenticatedUser]);
  const openRubrics = () => {
    setShowSelect(!showSelect);
  };

  const handleRubricChange = async (value, setFieldValue) => {
    const { data } = await getRubricById(value);
    if (data) {
      setFieldValue("rubric", data["@id"]);
      setSelectedRubricTitle(data.title); // Set the selected rubric title
      setSelectedRubricId(data.id); // Set the selected rubric title
    }
  };

  function handleImageImport(e) {
    if (e.target.files.length) {
      setSelectedImage(e.target.files[0]);
      // id of the div where the file name will be displayed
      document.getElementById("selectedfile").innerText =
        "  1 file selected : " + e.target.files[0].name;
    } else {
      document.getElementById("selectedfile").innerText = "No file selected";
    }
  }

  const handleSubmit = async (values, resetForm) => {
    const formData = new FormData();
    formData.append("image", selectedImage);
    if (!selectedRubricId) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select a Rubric",
        confirmButtonText: "OK",
        confirmButtonColor: "#3085d6",
        timer: 3000, // Optional: Auto close after 3 seconds
      });
    }

    const assignmentData = {
      ...values,
      subject: GradeAndSubjectData?.subject,
      grade: GradeAndSubjectData?.grade,
      teacher: authenticatedUser.id,
      rubric: selectedRubricId,
      classroom: classroom?.id,
    };
    formData.append("data", JSON.stringify(assignmentData));
    const { data, error } = await triggerCreateCoursework({
      formData: formData,
    });
    if (data) {
      trigger();
      onClose();
      resetForm();
      setSelectedImage(null);
      document.getElementById("selectedfile").innerText = "No file selected";
      return (
          dispatch(
              setNotification(
                  {
                    type: "success",
                    message: "Coursework created successfully",
                  }
              )
          )
      )
    }
    if (error) {
      console.log("image not uploaded");
      return (
            dispatch(
                setNotification(
                    {
                        type: "error",
                        message: "Error creating coursework",
                    }
                )
            )
      )
    }
  };
  return (
    <Modal
      title="New Assignment"
      open={isModalOpen}
      onCancel={onClose}
      footer={null}
      className="newAssignment-modal"
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={AssignmentSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          handleSubmit(values, resetForm);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          /* and other goodies */
        }) => (
            <form className={"newAssignment-form"} onSubmit={handleSubmit}>
              <div className="assignmentFields">
                <span className="assignmentFields-title">Assignment Title*</span>
                <Input
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {errors.title && touched.title && (
                    <div className="error">{errors.title}</div>
                )}
              </div>

              {/*<div className="assignmentFields">*/}
              {/*  <span className="assignmentFields-title">*/}
              {/*    Activate Practice Mode*/}
              {/*  </span>*/}
              {/*  <Checkbox*/}
              {/*    name="isPracticeMode"*/}
              {/*    value={values.isPracticeMode}*/}
              {/*    onChange={(e) =>*/}
              {/*      setFieldValue("isPracticeMode", e.target.checked)*/}
              {/*    }*/}
              {/*  >*/}
              {/*    Practice Mode*/}
              {/*  </Checkbox>*/}
              {/*  {errors.isPracticeMode && touched.isPracticeMode && (*/}
              {/*    <div className="error">{errors.isPracticeMode}</div>*/}
              {/*  )}*/}
              {/*</div>*/}

              <div className="assignmentFields">
                <span className="assignmentFields-title">Format*</span>
                <p className="assignmentFields-desc">
                  Add information about the assignment format
                </p>
                <Select
                    value={values.type}
                    name="type"
                    style={{width: 120}}
                    onChange={(value) => setFieldValue("type", value)}
                    options={[
                      {
                        value: "essay",
                        label: "Essay",
                      },
                      {
                        value: "MCQ",
                        label: "MCQ",
                      },
                      {
                        value: "Q/R",
                        label: "Q/R",
                      },
                    ]}
                />
                {errors.type && touched.type && (
                    <div className="error">{errors.type}</div>
                )}
              </div>
              <div className="assignmentFields">
                <span className="assignmentFields-title">Subject</span>
                <Select defaultValue={GradeAndSubjectData?.subject} disabled/>
              </div>
              <div className="assignmentFields">
                <span className="assignmentFields-title">Grade</span>
                <Select defaultValue={GradeAndSubjectData?.grade} disabled/>
              </div>

              <div className="assignmentFields">
                <span className="assignmentFields-title">State*</span>
                <Select
                    value={values.state}
                    style={{width: 120}}
                    name="state"
                    onChange={(value) => setFieldValue("state", value)}
                    options={[
                      {
                        value: "active",
                        label: "active",
                      },
                      {
                        value: "draft",
                        label: "draft",
                      },
                    ]}
                />
                {errors.state && touched.state && (
                    <div className="error">{errors.state}</div>
                )}
              </div>

              <div className="assignmentFields practice__mode">
                <span className="assignmentFields-title__practice_mode mb-2">
                  Practice Options
                </span>
                <div className={"features__field"}>
                  <label>Practice Mode</label>
                  <Switch
                      handleBg={"#000095"}
                      onChange={(value) =>
                          setFieldValue("isPracticeMode", value)
                      }
                      size={"default"}
                  />
                </div>
              </div>

              <div className="assignmentFields">
                <span className="assignmentFields-title">Rubric</span>
                  <Button
                      htmlType="button"
                      className="assignmentFields-rubricBtn"
                      onClick={openRubrics}
                  >
                    <PlusCircleFilled/>
                    Add existing Rubric
                  </Button>
                  {showSelect && rubricData && (
                      <Select
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          className={"select-rubric"}
                          style={{marginTop: 12}}
                          placeholder="Select or search for a rubric"
                          name="rubric"
                          // value={values.rubric}
                          value={selectedRubricTitle || undefined}
                          // onChange={value => setFieldValue('rubric', value)}
                          onChange={(value) => handleRubricChange(value, setFieldValue)}
                      >
                        {rubricData["hydra:member"].map((rubric) => (
                            <Select.Option key={rubric.id} value={rubric.id}>
                              {rubric.title}
                            </Select.Option>
                        ))}
                      </Select>
                  )}
                  {errors.rubric && touched.rubric && (
                      <div className="error">{errors.rubric}</div>
                  )}
                </div>
                <div className={"assignmentFields"}>
                  <span className="assignmentFields-title field__feacher mb-2">
                    Assignment Features
                  </span>
                  <div className={"features__field"}>
                    <label>Provide writing assistance features.</label>
                    <Switch
                        handleBg={"#000095"}
                        onChange={(value) =>
                            setFieldValue("writingAssistance", value)
                        }
                    />
                  </div>
                  <div className={"features__field"}>
                    <label>Allow students to upload handwritten documents.</label>
                    <Switch
                        onChange={(value) => setFieldValue("allowHandWriting", value)}
                    />
                  </div>
                  <div className={"features__field"}>
                    <label>Allow students to upload PDF or Word documents.</label>
                    <Switch
                        onChange={(value) => setFieldValue("allowUploadFile", value)}
                    />
                  </div>
                </div>

                <div className={"assignmentFields"}>
                  <span className="assignmentFields-title">Due date</span>
                  <RangePicker
                      showTime={{format: "HH:mm"}}
                      format="YYYY-MM-DD HH:mm"
                      value={
                        values.dateStart && values.dateEnd
                            ? [values.dateStart, values.dateEnd]
                            : null
                      }
                      onChange={(dates) => {
                        setFieldValue("dateStart", dates ? dates[0] : null);
                        setFieldValue("dateEnd", dates ? dates[1] : null);
                      }}
                  />
                </div>

                <div className="assignmentFields">
                  <span className="assignmentFields-title">Instructions</span>
                  <p className="assignmentFields-desc">
                    Provide instructions or reference texts for the students.
                    <br/> This text will be visible to them as they compose their
                    responses.
                  </p>
                  <TextArea
                      rows={5}
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                  />
                  {errors.description && touched.description && (
                      <div className="error">{errors.description}</div>
                  )}

                  <div className="assignmentFields last-child">
                    <label style={{textAlign: "right"}}>
                      <input hidden type="file" onChange={handleImageImport}/>
                      <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                      >
                        <div
                            id={"selectedfile"}
                            style={{
                              fontWeight: "600",
                              fontSize: "10px",
                              lineHeight: "13px",
                              cursor: "pointer",
                              color: "#000095",
                              marginTop: "6px",
                            }}
                        ></div>
                        <div
                            style={{
                              fontWeight: "600",
                              fontSize: "10px",
                              lineHeight: "13px",
                              cursor: "pointer",
                              color: "#000095",
                              marginTop: "6px",
                            }}
                        >
                          Add Image
                        </div>
                      </div>
                    </label>
                  </div>
                </div>

                <div style={{textAlign: "center"}}>
                  <button
                      type="submit"
                      disabled={isSubmitting}
                      className="createAssignment-btn"
                  >
                    Create
                  </button>
                </div>
            </form>
          )}
      </Formik>
    </Modal>
  );
};

export default NewAssignment;
