import { Modal } from "antd";
import PreviewQuiz from "./previewQuiz";
import PreviewEssay from "./previewEssay";
import { useIntl } from "react-intl";
import "./modalPreviewAssignment.css";

const ModalPreviewAssignment = ({ visible, className, onCancel, data }) => {
  const intl = useIntl();
  if (!data) return null;

  return (
    <Modal visible={visible} className={className} onCancel={onCancel} footer={null} width={600}>
      <div>
        <h1>{intl.formatMessage({ id: "assignment.preview.title" })}</h1>
        {
            data?.type === "Quiz" ? <PreviewQuiz assignments={data} isPreview={true} /> : <PreviewEssay assignments={data} />
        }
      </div>
    </Modal>
  );
};

export default ModalPreviewAssignment;