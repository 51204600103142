import style from "../assignmentCorrectionsPreview/assignmentCorrectionsPreview.module.scss";
import React from "react";

const CustomCheckbox = ({ label, value, isSelected, onChange, index }) => {
    return (
        <div
            className={`${style.checkboxContainer} ${
                isSelected ? style.checkedOption : ""
            }`}
            onClick={() => onChange(value)}
        >
            <div
                className={`${style.customCheckbox} ${isSelected ? style.checked : ""}`}
            >
                {isSelected && (
                    <svg
                        width={18}
                        height={18}
                        className={style.checkmark}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <polyline points="20 6 9 17 4 12" />
                    </svg>
                )}
            </div>
            {label && <label className={style.checkboxLabel}>{String.fromCharCode(65 + index) + ". "}{label}</label>}
        </div>
    );
};

export default CustomCheckbox;