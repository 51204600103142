import { useState } from "react";
import MediaViewer from "../MediaViewer";
import styles from "./listResources.module.css";
import { GlobalOutlined, FileOutlined, YoutubeFilled } from "@ant-design/icons";

const ListResources = ({ resources, selectedFile, setIsPreviewFile,setIsActiveFile, setResourceIndexs }) => {

  const [LinkUrl, setLinkUrl] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleClick = (indexFile) => {
    setIsActiveFile(true);
    setResourceIndexs(prev => ({
      ...prev,
      indexQuestion: resources?.indexQuestion,
      indexFile: indexFile
    }));
    console.log("item, indexFile::", resources?.indexQuestion, indexFile);
  }

    return (
      <div className={styles.listContainer}>

        {resources?.files?.map((item, indexFile) => (
          <div 
          key={indexFile} 
          className={styles.resourceItem}>
            <div 
            className={`${styles.iconWrapper}`}
            onClick={() => {
              handleClick(indexFile)
            }}
            >
              <FileOutlined className={`${styles.icon} ${styles.iconFile}`} />
              <div className={styles.textContainer}>
                <div className={styles.fileName}>
                  {item?.filePath?.length > 40
                    ? `${item?.filePath.slice(0, 40)}...`
                    : item?.filePath}
                </div>
              </div>
              <div className={styles.textContainer}>
                <div className={styles.fileName}>
                  {item?.url?.length > 40
                    ? `${item?.url.slice(0, 40)}...`
                    : item?.url}
                </div>
              </div>
            </div>
          </div>
        ))}

        {resources?.links?.map((item) => (
          <div 
            key={item.id} 
            className={styles.resourceItem}
            onClick={() => {
              setLinkUrl(item?.url)
              setIsModalVisible(true)
            }}
            >
            <div
              className={`${styles.iconWrapper} ${
                item.type === "youtube" ? styles.video : ""
              }`}
            >
              {item.type === "hypertext" ? (
                <GlobalOutlined
                  className={`${styles.icon} ${styles.iconLink}`}
                />
              ) : item.type === "youtube" ? (
                <YoutubeFilled className={`${styles.icon} ${styles.iconVid}`} />
              ) : null}
              <div className={styles.textContainer}>
                <div className={styles.fileName}>
                  {item?.url?.length > 40
                    ? `${item?.url.slice(0, 40)}...`
                    : item?.url}
                </div>
              </div>
            </div>
          </div>
        ))}

        <MediaViewer
          initialUrl={LinkUrl}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
        />
      </div>
    );
  };

export default ListResources