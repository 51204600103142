import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Formik } from "formik";
import style from "../../PreviewAssignmentForEssay/previewAssignmentEssay.module.css";
import QuestionsItemForEssay from "../../questionsItemForEssay";

const PreviewEssay = ({ assignments }) => {
    const [initialValues, setInitialValues] = React.useState([]);
    const intl = useIntl();
    const [isOpen, setIsOpen] = useState(false);
    const formikRef = React.useRef();

    //type :
    useEffect(() => {
      const mappedQuestions = assignments?.questions.map((item) => ({
        ...item,
        answerJustification: item?.isJustification && "",
        answer: item?.answer || null,
        justification: item?.justification || null,
        studentAnswer: item?.studentAnswer || [],
      }));
      setInitialValues(mappedQuestions);
    }, [assignments]);
 
    const initialAccordionStates = {
      1: true,
      3: true,
    };
  
    const [accordionStates, setAccordionStates] = useState(
      initialAccordionStates
    );
  
    const toggle = (index, state) => {
      setAccordionStates((prevState) => ({
        ...prevState,
        [index]: !prevState[index],
      }));
    };
  
    return (
      <div className={style.container}>
        <div className={style.header}>
          <h2 className={style.title}>{assignments?.title}</h2>
        </div>
        <div className={style.containerAssignment}>
          <Formik
            innerRef={formikRef}
            enableReinitialize
            initialValues={initialValues}
            validateOnChange={true}
            validateOnBlur={true}
          >
            {({ values, setFieldValue, handleSubmit, isSubmitting }) => (
              <div className={style.containerPreview}>
                <div
                  className={`${style.containerPreviewContent} ${style.assignmentPreviewActive}`}
                >
                  <div className={style.courseworkHeader}>
                    {assignments?.description && (
                      <div>
                        <h5 className={style.introductionTitle}>
                          {intl.formatMessage({
                            id: "assignment.preview.introduction",
                          })}
                        </h5>
                        <p className={style.introduction}>{assignments?.description}</p>
                      </div>
                    )}
                  </div>
  
  
                  <div className={style.listQuistionsContainer}>
                    {values?.map((question, indexQuestion) => (
                      <QuestionsItemForEssay
                        setFieldValue={undefined}
                        question={question}
                        indexQuestion={indexQuestion}
                        key={"Questions+" + indexQuestion}
                        defaultAnswerValue={question.answer}
                        isPreview={true}
                        assignment={assignments}
                        openPreviewDocs={isOpen}
                        setOpenPreviewDocs={setIsOpen}
                      />
                    ))}
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
    );
};

export default PreviewEssay;