import React from "react";
import { Select as AntdSelect } from "antd";
import styles from "./select.module.css";

const Select = ({
  name,
  value,
  onChange,
  options,
  className,
  label,
  error,
  required,
  mode,
  ...props
}) => {
  const classList = [
    styles["custom-select"],
    error && styles.error,
    !mode && styles.default,
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <div className={`${styles.selectWrapper} ${className}`}>
      {label && (
        <label htmlFor={name} className={styles.label}>
          {label}
          {required && <span className={styles.required}>*</span>}
        </label>
      )}
      <AntdSelect
$       mode={mode}
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        options={options}
        className={classList}
        popupClassName={styles["custom-select-popup"]}
        status={error ? "error" : ""}
        {...props}
      />
      {error && <span className={styles.errorMessage}>{error}</span>}
    </div>
  );
};

export default Select;
