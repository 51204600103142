import React, {useEffect, useState} from 'react'
import {Link} from "react-router-dom";
import {Button} from "antd";
import {useLazyGetGoogleClassroomsByUserIdQuery,useLazyGetCourseworkByClassroomAndUserIdQuery,useLazyGetRubricsByClassAndCourseWorkIdQuery} from "../../services/googleClassroom.api";
import {useSelector} from "react-redux";
import {selectAuthenticatedUser} from "../../redux/auth/auth.selectors";
import Table from "../../Components/table/Table";
import {DownOutlined, ImportOutlined, UpOutlined} from "@ant-design/icons";
import CustomSpin from "../../Components/customSpin/CustomSpin";
import ClassModal from "../../Components/classModal/ClassModal";
import {use} from "marked";
import NewAssignment from "../../Components/newAssignment/NewAssignment";
import {log} from "util";
import {selectClassroom} from "../../redux/app/app.selectors";
const defaultValue  = { title: '', type: '', subject: '', grade: '', description: '', rubric: '',state:'',writingAssistance:false,allowHandWriting:false,allowUploadFile:false,dateStart:null,dateEnd:null,isPracticeMode: false}

const GoogleClassroom = () => {

    const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
    const user = useSelector(selectAuthenticatedUser);
    const [trigger, {data: googleClassrooms, isLoading:isLoading }] = useLazyGetGoogleClassroomsByUserIdQuery();
    const [triggerCourseWork, {data: courseWorks, isLoading:isLoadingCourseWork }] = useLazyGetCourseworkByClassroomAndUserIdQuery();
    const [triggerRubrics, {data: rubrics, isLoading:isLoadingRubrics }] = useLazyGetRubricsByClassAndCourseWorkIdQuery();
    const [dataSorted, setDataSorted] = useState([]);
    const [isModalOpen,setIsModalOpen] = useState(false)
    const [initialValues,setInitialValues] = useState(defaultValue)
    const [GradeAndSubjectData, setGradeAndSubjectData] = useState({subject: '', grade: ''});
    const classroom = useSelector(selectClassroom);

    useEffect(() => {
        if (!user.roles.includes('ROLE_TEACHER')) {
            return;
        }
        setGradeAndSubjectData({
            subject: classroom?.subject,
            grade: classroom?.grade
        })
    }, []);

    useEffect(() => {
        if (user)
        trigger(user.id);

        console.log('googleClassrooms', googleClassrooms);

    }, [user]);

    useEffect(() => {
        if (googleClassrooms)
            setDataSorted(formatedData(googleClassrooms))

    },[googleClassrooms]);

    const handleActionClick = async (item) => {
        const courseWork = [];

        const {title,description,state,image} = item
        const init = { title,description,state,image_url:image}
        setInitialValues({ ...defaultValue,...init})
        setIsModalOpen(true)

        console.log("class",{class_id:item.id,course_work_id:item.courseWorkId,user_id:user.id})
        const {data, error} = await triggerCourseWork({class_id:item.courseId,course_work_id:item.id,user_id:user.id});
        if (data) {
            console.log('data;;;::', data);
            // data?.courseWork.map(async (el) => {
            //     console.log('item :;;;;::',el);
            //     // const {rubricData, rubricError} = await triggerRubrics({course_id:el?.courseId, course_work_id:el?.id, user_id:user.id});
            //     // if(rubricData){
            //     //     console.log('rubricData::;;;', rubricData);
            //     // }
            //     // if (rubricError){
            //     //     console.log('rubricError', rubricError);
            //     // }
            // });
        }
        if (error){
            console.log('error:: ', error);
        }

    }

    //{ title: '', description: '',state:'',allowUploadFile:false}
    const formatedData = (data)=>(
        data?.map((item,index)=>({
            id: item.id,
            courseId:item.courseId,
            title : item.title,
            description: item.description ,
            state: item?.state === "PUBLISHED" ? "active" : "draft",
            room: item?.room || null ,
            section: item?.section || null,
            image: item?.materials?.[0]?.driveFile?.driveFile?.thumbnailUrl
        }))
    )

    const handleSort = (columnKey) => {
        let direction;
        if (sortConfig.key === columnKey) {
            if (sortConfig.direction === 'ascending') {
                direction = null; // Second click, reset to default (unsorted)
            } else if (sortConfig.direction === null) {
                direction = 'descending'; // Third click, sort descending
            } else {
                direction = 'ascending'; // First click, sort ascending
            }
        } else {
            direction = 'ascending'; // New column clicked, sort ascending
        }
        setSortConfig({ key: columnKey, direction });
    };

    const columns = [
        { key: 1 , title: 'ID', dataIndex: 'id'},

        { key: 2 , title: 'Coursework Name', dataIndex: "title"},
        { key: 3 , title: 'Description', dataIndex:  "description"},
        { key: 4 , title: 'State', dataIndex: "state"},
        { key: 5 , title: 'Room', dataIndex: 'room' },
        {key: 6, title: 'Section', dataIndex: 'section',},
        { key: 7 , title: 'Action', dataIndex: 'action'},
    ];

    const TableHeadCell = (column) => {
        if (column.dataIndex === 'id' || column.dataIndex === 'action') {
            return column.title;
        }
        else {
            return (
                <>
            <span onClick={() => handleSort(column.dataIndex)} className={"sort_column"}>
              {()=> console.log('column.dataIndex', column.dataIndex)}
                <span>{column.title}</span>
              <span className={"sort__icons"}>
                <UpOutlined className={
                    (Array.isArray(sortConfig.key) && Array.isArray(column.dataIndex)
                        ? (sortConfig.direction === "ascending" && sortConfig.key.join() === column.dataIndex.join())
                        : (sortConfig.direction === "ascending" && sortConfig.key === column.dataIndex))
                        ? "up__icon active" : "up__icon"}/>
                <DownOutlined className={
                    (Array.isArray(sortConfig.key) && Array.isArray(column.dataIndex)
                        ? (sortConfig.direction === "descending" && sortConfig.key.join() === column.dataIndex.join())
                        : (sortConfig.direction === "descending" && sortConfig.key === column.dataIndex))
                        ? "down__icon active" : "down__icon"}/>
              </span>
            </span>
                </>
            );
        }
    };

    const TableBodyCell = (column, item) => {
        switch (column.dataIndex) {
            case 'action':
                return (
                    <Button onClick={() => handleActionClick(item)} className='tableBody__cell-openBtn' title={"import"} icon={<ImportOutlined/>}>
                    </Button>
                );
            case 'state':
                if(column.render){
                    return <span className='tableBody__cell-formatCol'>{column.render(item[column.dataIndex], item)} </span>;
                }
                else {
                    return <span className='tableBody__cell-formatCol'>{ item[column.dataIndex] } </span>;
                }

            default:
                if(column.render){
                    return column.render(item[column.dataIndex], item)
                }
                else {
                    return item[column.dataIndex];
                }
        }
    };

    const handleSubmit = (values) =>{
        console.log(values)
    }

    if(isLoading){
        return <CustomSpin />
    }

    return (
        <>
            <div className="google__classroom__content">
                <div className="rubricContent__header">
                    <div className="rubricContent__header-title">
                        <span>Google classroom</span>
                    </div>
                </div>
                <div>
                    <Table
                        columns={columns}
                        data={dataSorted}
                        TableHeadCell={TableHeadCell}
                        TableBodyCell={TableBodyCell}
                        noDataText={`You haven't added any submission yet.`}
                        isLoading={isLoading}
                        useFilter={false}
                    />
                    <NewAssignment isModalOpen={isModalOpen} onClose={() => {
                        setInitialValues(defaultValue)
                        setIsModalOpen(false)
                    }} initialValues={initialValues}
                    GradeAndSubjectData={GradeAndSubjectData}

                    />

                </div>
            </div>
        </>
    )
}

export default GoogleClassroom