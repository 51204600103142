import { DoubleRightOutlined, FileAddOutlined, FileImageOutlined, FileOutlined, GlobalOutlined, UploadOutlined, YoutubeFilled } from "@ant-design/icons";
import React, { useEffect, useState, useCallback } from "react";
import style from "../assignmentCorrectionsPreview/assignmentCorrectionsPreview.module.scss";
import styled from './questionsItemForEssay.module.css'
import { useIntl } from "react-intl";
import ToolbarSlate from "../_util/toolbarSlate";
import {Editable, Slate, useSlate, withReact} from "slate-react";
import IconSlate from "../_util/iconSlate";
import ButtonSlate from "../_util/buttonSlate";
import HeadingOne from "../icons/headingOne";
import Italic from "../icons/italic";
import UnderLine from "../icons/underLine";
import BoldIcon from "../icons/boldIcon";
import HeadingTwo from "../icons/headingTwo";
import ListNum from "../icons/listNum";
import ListPuce from "../icons/listPuce";
import TextAlignLeft from "../icons/textAlignLeft";
import TextAlignCenter from "../icons/textAlignCenter";
import TextAlignRight from "../icons/textAlignRight";
import BlockQuote from "../icons/blockQuote";
import JustifyIcon from "../icons/justifyIcon";
import {setNotification} from "../../redux/app/app.slice";
import {useDispatch} from "react-redux";
import isHotkey from 'is-hotkey';
import mammoth from "mammoth";
import pdfToText from "react-pdftotext";
import { Button } from "antd";

import {
    useUploadHandwritingMutation
  } from "../../services/studentcourseworks.api";

import { Transforms, createEditor, Node, Editor ,  Element as SlateElement} from "slate";
import CustomSpin from "../customSpin/CustomSpin";
import MediaViewer from "../MediaViewer";
import IconPlayVideo from "../../assets/icons/IconPlayVideo";
import CloseTabs from "../../assets/icons/CloseTabs";

const HOTKEYS = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
    'mod+`': 'code',
}
const LIST_TYPES = ['numbered-list', 'bulleted-list']
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']

const resetEditor = (editor, nodes) => {
    const {selection} = editor;

    editor?.children?.map((item) => {
        Transforms.delete(editor, { at: [0] });
    });

    // Insert new nodes if `nodes` parameter is provided
    if (nodes) editor?.insertNodes(Node?.isNode(nodes) ? [nodes] : nodes);
};

const serialize = (value) => {
    return (
        value
            // Return the string content of each paragraph in the value's children.
            .map((n) => Node.string(n))
            // Join them all with line breaks denoting paragraphs.
            .join("\n")
    );
};


const QuestionsItemForEssay = ({ question, indexQuestion, setFieldValue,isPreview, assignment, openPreviewDocs, setOpenPreviewDocs, setResourceIndexs, setIsActiveFile, isCorrection=false, setResources, allowPaste }) => {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState({handwriting:false, wordPdf:false});
    const [answerFormated, setAnswerFormated] = useState("");
    const [wordsCount, setWordsCount] = useState(0);
    const [uploadHandwritingStudentCourseWork] = useUploadHandwritingMutation()

    const MarkButton = ({ format, icon }) => {
        const editor = useSlate()
        return (
            <ButtonSlate
                active={isMarkActive(editor, format)}
                onMouseDown={event => {
                    event.preventDefault()
                    toggleMark(editor, format)
                }}
            >
                <IconSlate icon={icon} />
            </ButtonSlate>
        )
    }

    const toggleBlock = (editor, format) => {
        const isActive = isBlockActive(
            editor,
            format,
            TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
        )
        const isList = LIST_TYPES.includes(format)
        Transforms.unwrapNodes(editor, {
            match: n =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                LIST_TYPES.includes(n.type) &&
                !TEXT_ALIGN_TYPES.includes(format),
            split: true,
        })
        let newProperties
        if (TEXT_ALIGN_TYPES.includes(format)) {
            newProperties = {
                align: isActive ? undefined : format,
            }
        } else {
            newProperties = {
                type: isActive ? 'paragraph' : isList ? 'list-item' : format,
            }
        }
        Transforms.setNodes(editor, newProperties)
        if (!isActive && isList) {
            const block = { type: format, children: [] }
            Transforms.wrapNodes(editor, block)
        }
    }
    const toggleMark = (editor, format) => {
        const isActive = isMarkActive(editor, format)
        if (isActive) {
            Editor.removeMark(editor, format)
        } else {
            Editor.addMark(editor, format, true)
        }
    }
    const isBlockActive = (editor, format, blockType = 'type') => {
        const { selection } = editor
        if (!selection) return false
        const [match] = Array.from(
            Editor.nodes(editor, {
                at: Editor.unhangRange(editor, selection),
                match: n =>
                    !Editor.isEditor(n) &&
                    SlateElement.isElement(n) &&
                    n[blockType] === format,
            })
        )
        return !!match
    }
    const isMarkActive = (editor, format) => {
        const marks = Editor.marks(editor)
        return marks ? marks[format] === true : false
    }
    const Element = ({ attributes, children, element }) => {
        const style = { textAlign: element.align }
        switch (element.type) {
            case 'block-quote':
                return (
                    <blockquote style={style} {...attributes}>
                        {children}
                    </blockquote>
                )
            case 'bulleted-list':
                return (
                    <ul style={style} {...attributes}>
                        {children}
                    </ul>
                )
            case 'heading-one':
                return (
                    <h1 style={style} {...attributes}>
                        {children}
                    </h1>
                )
            case 'heading-two':
                return (
                    <h2 style={style} {...attributes}>
                        {children}
                    </h2>
                )
            case 'list-item':
                return (
                    <li style={style} {...attributes}>
                        {children}
                    </li>
                )
            case 'numbered-list':
                return (
                    <ol style={style} {...attributes}>
                        {children}
                    </ol>
                )
            case 'color':
                return (
                    <span style={{ color: element.color }}>{children}</span>
                )
            default:
                return (
                    <p style={style} {...attributes}>
                        {children}
                    </p>
                )
        }
    }
    const Leaf = ({ attributes, children, leaf }) => {
        if (leaf.bold) {
            children = <strong>{children}</strong>
        }
        if (leaf.italic) {
            children = <em>{children}</em>
        }
        if (leaf.underline) {
            children = <u>{children}</u>
        }
        return <span {...attributes}>{children}</span>
    }
    const BlockButton = ({ format, icon }) => {
        const editor = useSlate()
        return (
            <ButtonSlate
                active={
                    isBlockActive(
                        editor,
                        format,
                        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
                    )}
                onMouseDown={event => {
                    event.preventDefault()
                    toggleBlock(editor, format)
                }}
            >
                <IconSlate icon={icon} />
            </ButtonSlate>
        )
    }

    const [editor] = useState(() => withReact(createEditor()));
    const intl = useIntl();
    const url = process.env.REACT_APP_API_BASE_URL;
    const [openCollapse, setOpenCollapse] = React.useState(true);
    console.log("question?.slateFormatAnswer", question?.slateFormatAnswer);
    const [answer, setAnswer] = useState(
        question?.slateFormatAnswer || [  { type: "paragraph", children: [{ text: "" }],  },]);
    const bgColor = {
        MultiChoice: { background: "#FFCF00" },
        Essay: { background: "#662D91" },
        ShortAnswer: { background: "#000095" },
    };
    const isEssayMode = question?.type === "Essay";

    const renderElement = useCallback(props => <Element {...props} />, [])

    const renderLeaf = useCallback(props => <Leaf {...props} />, [])


    
  const handleExtractTextFromWordOrPdf = async (event) => {
    const file = event.target.files?.[0];
    const type = file?.type;
    // check if file is word
    if (
      type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      type === "application/msword"
    ) {
      try {
        setIsLoading({ ...isLoading, wordPdf: true });
        extractTextFromWord(file).then((text) => {
          const slateContent = [
            { type: "paragraph", children: [{ text: text }] },
          ];
          resetEditor(editor, slateContent);
          setAnswer(slateContent);
          setAnswerFormated(text);
          setIsLoading({ ...isLoading, wordPdf: false });
          dispatch(
            setNotification({
              type: "success",
              message: "Your WORD upload has been completed successfully.",
            })
          );

          console.log("Extracted text www:", text);
          console.log("Updated Slate content ww:", slateContent);
        });
      } catch (error) {
        setIsLoading({ ...isLoading, wordPdf: false });
        dispatch(
          setNotification({
            type: "error",
            message: "Failed to extract text from Word!",
          })
        );
        console.error("Failed to extract text from Word:", error);
      }
    } else if (type === "application/pdf") {
      try {
        setIsLoading({ ...isLoading, wordPdf: true });
        let text = await pdfToText(file);
        // check if text start with "Page 1 of"
        if (text.startsWith("Page 1 of")) {
          text = "";
        }
        const slateContent = [
          { type: "paragraph", children: [{ text: text }] },
        ];
        resetEditor(editor, slateContent);
        setAnswer(slateContent);
        setAnswerFormated(text);
        setIsLoading({ ...isLoading, wordPdf: false });
        setWordsCount(countWords(slateContent));
        dispatch(
          setNotification({
            type: "success",
            message: "Your PDF upload has been completed successfully.",
          })
        );
      } catch (error) {
        setIsLoading({ ...isLoading, wordPdf: false });

        dispatch(
          setNotification({
            type: "error",
            message: "Failed to extract text from PDF!",
          })
        );
        console.error("Failed to extract text from PDF:", error);
      }
    }
  };
  const extractTextFromWord = async (file) => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      if (!arrayBuffer) {
        throw new Error("Failed to read file as ArrayBuffer");
      }
      const { value: textContent } = await mammoth.extractRawText({
        arrayBuffer,
      });
      return textContent;
    } catch (error) {
      console.error("Error extracting text from Word file:", error);
      throw error; // Optionally rethrow the error if you want it to be caught by outer try/catch
    }
  };

  const countWords = (nodes) => {
    const text = nodes.map((node) => Node.string(node)).join(' ');
     return text.trim().split(/\s+/).filter(Boolean)?.length;
  };


  const handleExtractTextHandwriting = async (event) => {
    const file = event.target.files?.[0];
    const formData = new FormData();
    formData.append("file", file); // `file` is the file you want to upload
    formData.append("studentworkId", assignment.id); // Any other data you want to send

    

    setIsLoading({ ...isLoading, handwriting: true });
    const { data, error } = await uploadHandwritingStudentCourseWork(formData);
    if (data) {
      setIsLoading({ ...isLoading, handwriting: false });
      dispatch(
        setNotification({
          type: "success",
          message: "Your handwriting upload has been completed successfully.",
        })
      );
      const message = data;
      const slateContent = [
        { type: "paragraph", children: [{ text: message }] },
      ];

      resetEditor(editor, slateContent);
      setAnswer(slateContent);
      setAnswerFormated(message);
      setWordsCount(countWords(slateContent));
    }
    if (error) {
      setIsLoading({ ...isLoading, handwriting: false });
      dispatch(setNotification({ type: "error", message: "Upload failed!" }));
      console.error("Upload failed:", error);
    }
  };


    return (
      <div
        className={style.questionItemContant}
        style={bgColor[question?.type]}
      >
        <div className={style.header}>
          <div className={style.titleQuistion}>
            <span className={style.badgeType}>
              {intl.formatMessage({ id: "correction.studentAnswer" })}
            </span>
          </div>
          <div>
            <button onClick={() => setOpenCollapse(!openCollapse)}>
              <DoubleRightOutlined
                style={{
                  color: "#fff",
                  transform: openCollapse ? "rotate(90deg)" : "rotate(-90deg)",
                }}
              />
            </button>
          </div>
        </div>
        <div
          className={`${style.contentMain} ${openCollapse ? "" : style.hide} `}
        >
          <div
            className={` ${
              openPreviewDocs ? style.resourceColomn : ""
            }`}
          >
            {/* {question.image && (
              <img
                src={`${url}/uploads/images/${question.image.filePath}`}
                alt="textContent"
              />
            )} */}
            {/* <p className={style.textContent}>{question.questionText}</p> */}
          </div>

          {/* {question?.context && (
              <div className={style.context}>
                <span className={style.contextTitle}>Context:</span>
                <p className={style.textContent}>{question?.context}</p>
              </div>
            )} */}

          {isEssayMode && (
            <div className={style.textareaContainer}>
              <Slate
                editor={editor}
                initialValue={answer}
                readOnly={isPreview}
                onChange={(value) => {
                  const isAstChange = editor.operations.some(
                    (op) => "set_selection" !== op.type
                  );
                  if (isAstChange && setFieldValue) {
                    // Serialize the value and save the string value to Local Storage.
                    setAnswer(value);
                    setFieldValue(`${indexQuestion}.answer`, serialize(value));
                    setFieldValue(`${indexQuestion}.slateFormatAnswer`, value);

                    // setAnswerFormated(serialize(value));
                    // setWordsCount(countWords(value));
                    // if (value?.length < 10) {
                    //     setIsProgressDisabled(true);
                    // } else {
                    //     setIsProgressDisabled(false);
                    // }
                  }
                }}
              >
                <ToolbarSlate className={styled.toolbar}>
                  <MarkButton
                    disabled={isPreview}
                    format="bold"
                    icon={<BoldIcon />}
                  />
                  <MarkButton
                    disabled={isPreview}
                    format="italic"
                    icon={<Italic />}
                  />
                  <MarkButton
                    disabled={isPreview}
                    format="underline"
                    icon={<UnderLine />}
                  />
                  <BlockButton
                    disabled={isPreview}
                    format="heading-one"
                    icon={<HeadingOne />}
                  />
                  <BlockButton
                    disabled={isPreview}
                    format="heading-two"
                    icon={<HeadingTwo />}
                  />
                  <BlockButton
                    disabled={isPreview}
                    format="block-quote"
                    icon={<BlockQuote />}
                  />
                  <BlockButton
                    disabled={isPreview}
                    format="numbered-list"
                    icon={<ListNum />}
                  />
                  <BlockButton
                    disabled={isPreview}
                    format="bulleted-list"
                    icon={<ListPuce />}
                  />
                  <BlockButton
                    disabled={isPreview}
                    format="left"
                    icon={<TextAlignLeft />}
                  />
                  <BlockButton
                    disabled={isPreview}
                    format="center"
                    icon={<TextAlignCenter />}
                  />
                  <BlockButton
                    disabled={isPreview}
                    format="right"
                    icon={<TextAlignRight />}
                  />
                  <BlockButton
                    disabled={isPreview}
                    format="justify"
                    icon={<JustifyIcon />}
                  />
                </ToolbarSlate>
                <Editable
                  className={"slate"}
                  style={{ minHeight: "400px", fontSize: "16px" }}
                  placeholder="Type your answer ..."
                  onPaste={(event) => {
                    if (!allowPaste) {
                        event.preventDefault();
                        dispatch(
                            setNotification({
                                type: "info",
                                message: "Pasting is not allowed in this field.",
                            })
                        );
                        return;
                    }
                  }}
                  onKeyDown={(event) => {
                    for (const hotkey in HOTKEYS) {
                      if (isHotkey(hotkey, event)) {
                        event.preventDefault();
                        const mark = HOTKEYS[hotkey];
                        toggleMark(editor, mark);
                      }
                    }
                  }}
                  renderLeaf={renderLeaf}
                  renderElement={renderElement}
                  readOnly={isPreview}
                />
              </Slate>
            </div>
          )}
          {wordsCount > 0 && (
            <div className={styled.wordsCount}>Words: {wordsCount}</div>
          )}{console.log('assignment?.allowHandWriting:::', assignment?.allowHandWriting)}
          {(!isPreview || (!isPreview && assignment?.isPracticeMode)) && (
            <div className={styled.fileContainer}>
              {assignment?.allowHandWriting && (
                <Button
                  className={styled.fileBtn}
                  loading={isLoading.handwriting}
                >
                  <FileImageOutlined style={{ fontSize: "20px" }} /> image
                  <input
                    type="file"
                    className={styled.fileInputHandwriting}
                    name="myfile"
                    onChange={handleExtractTextHandwriting}
                  />
                </Button>
              )}

              {assignment?.allowUploadFile && (
                <Button className={styled.fileBtn} loading={isLoading.wordPdf}>
                  <FileAddOutlined style={{ fontSize: "20px" }} /> file
                  <input
                    type="file"
                    className={styled.fileInputWordPdf}
                    name="filewordpdf"
                    onChange={handleExtractTextFromWordOrPdf}
                  />
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    );
};
export default QuestionsItemForEssay;