import React from 'react'
import { IconPlus } from '../../../assets/icons'
import * as Yup from 'yup'
import { Formik } from 'formik'
import Button from '../../../Components/Button'
import Input, { TYPE } from '../../../Components/Input'
import Upload from '../../../Components/Upload'
import { useIntl } from 'react-intl'
import { useImportMultipleUsersMutation } from '../../../services/admin.api'
import { useGetMeQuery } from '../../../services/auth.api'
import { setNotification } from '../../../redux/app/app.slice'
import { useDispatch } from 'react-redux'


const UploadTeachers = () => {
    const { data: authenticatedUser, error } = useGetMeQuery()
    const dispatch = useDispatch();
    const { formatMessage, intl,  } = useIntl()
    const [importMultipleUsers, { isLoading }] = useImportMultipleUsersMutation()

    let organisation = null

    if (authenticatedUser) {
        organisation = authenticatedUser.myOrganisation
    }

    const validationSchema = Yup.object({
        files: Yup.array().min(1, 'At least one file is required').max(1, 'Only one file is allowed'),
        columns: Yup.object({
            email: Yup.string().required(formatMessage({ id: "form.field.error.required" }, { field: formatMessage({ id: "form.field.email" }) })),
            firstName: Yup.string().required(formatMessage({ id: "form.field.error.required" }, { field: formatMessage({ id: "form.field.firstName" }) })),
            familyName: Yup.string().required(formatMessage({ id: "form.field.error.required" }, { field: formatMessage({ id: "form.field.familyName" }) })),
            phoneNumber: Yup.string(),
            department: Yup.string(),
            country: Yup.string(),
            city: Yup.string(),
        }),
    })

    const initialValues = {
        files: [],
        columns: {
            email: '',
            firstName: '',
            familyName: '',
            phoneNumber: '',
            department: '',
            country: '',
            city: '',
        },
    }
    const _handleSubmit = async (values, { setSubmitting, resetForm }) => {
        // Important to add, that helps to identify the type of imported users
        values.type = 'teacher'

        if (organisation) values.organisationCode = organisation.code;

        const { files, ...valuesData } = values

        const { data, error } = await importMultipleUsers({ file: files[0], ...valuesData})

        if (data) {
            resetForm();
            dispatch(
                setNotification({
                    type: "success",
                    message: formatMessage({
                        id: "organization.import.user.success",
                    }),
                })
            );
        }

        if (error) {
            dispatch(
                setNotification({
                    type: "error",
                    message: formatMessage({
                        id: "organization.import.user.error",
                    }),
                })
            );
        }

        setSubmitting(false)
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={_handleSubmit}
            enableReinitialize
            validateOnBlur={false}
            validateOnChange={false}
        >
            {({ values, handleSubmit, setFieldValue, errors, isSubmitting, resetForm }) => (
                <form className="section" onSubmit={handleSubmit}>
                    <div className="left-side">
                        <h3>
                            {formatMessage({ id: 'organization.teachers.upload.title' })}
                        </h3>
                        <p>
                            {formatMessage({
                                id: 'organization.teachers.upload.description',
                            })}
                        </p>
                        <img
                            src={'assets/images/teachers-excel.jpg'}
                            alt="Excel spreadsheet template for teacher data"
                            className="image-excel"
                        />
                    </div>
                    <div className="right-side">
                        <div className="flex-col gap-16">
                            <div className="grid-cols-3 gap-16">
                                <Input
                                    label={formatMessage({ id: 'organization.teachers.upload.form.field.email' })}
                                    required
                                    type={TYPE.TEXT}
                                    placeholder="Email column"
                                    value={values?.columns?.email}
                                    onChange={(e) =>
                                        setFieldValue('columns.email', e.target.value)
                                    }
                                    error={errors?.columns?.email}
                                />
                                <Input
                                    label={formatMessage({ id: 'organization.teachers.upload.form.field.first_name' })}
                                    required
                                    type={TYPE.TEXT}
                                    placeholder="First name column"
                                    value={values?.columns?.firstName}
                                    onChange={(e) =>
                                        setFieldValue('columns.firstName', e.target.value)
                                    }
                                    error={errors?.columns?.firstName}
                                />
                                <Input
                                    label={formatMessage({ id: 'organization.teachers.upload.form.field.family_name' })}
                                    required
                                    type={TYPE.TEXT}
                                    placeholder="Family name column"
                                    value={values?.columns?.familyName}
                                    onChange={(e) =>
                                        setFieldValue('columns.familyName', e.target.value)
                                    }
                                    error={errors?.columns?.familyName}
                                />
                            </div>
                            <Upload
                                value={values?.files}
                                onChange={(files) => setFieldValue('files', files)}
                                error={errors?.files}
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                multiple={false}
                            />
                        </div>
                        <div className="flex justify-end">
                            <Button
                                variant="primary"
                                type="submit"
                                icon={<IconPlus size={20} />}
                                text={formatMessage({ id: 'organization.button.import' })}
                                isLoading={isSubmitting}
                            />
                        </div>
                    </div>
                </form>
            )}
        </Formik>
    )
}

export default UploadTeachers
