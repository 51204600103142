import { DoubleRightOutlined, FileOutlined, GlobalOutlined, LinkOutlined, YoutubeFilled } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import style from "../assignmentCorrectionsPreview/assignmentCorrectionsPreview.module.scss";
import { useIntl } from "react-intl";
import CustomCheckbox from "../customCheckbox";
import { log } from "util";
import MediaViewer from "../MediaViewer";
import IconPlayVideo from "../../assets/icons/IconPlayVideo";
import CloseTabs from "../../assets/icons/CloseTabs";
import { useLazyGetRubricStandardByIdQuery } from "../../services/rubricsStandard.api";
import { useLazyGetRubricByIdQuery } from "../../services/rubrics.api";
import ViewRubricModal from "../viewRubricModal/ViewRubricModal";
import { useDispatch } from "react-redux";
import { setNotification } from "../../redux/app/app.slice";
import Editor from "../Editor";

const QuestionsItem = ({ question, indexQuestion, setFieldValue, openPreviewDocs, setOpenPreviewDocs, setResourceIndexs, setIsActiveFile, isCorrection=false, setResources, isAllowedRubric, allowPaste }) => {
    const isMultiple = question?.type === "MultiChoice";
    const isEssayMode = question?.type === "Essay";
    const isShortAnswer = question?.type === "ShortAnswer";
    const intl = useIntl();
    const bgColor = {
        MultiChoice: { background: "#FFCF00" },
        Essay: { background: "#662D91" },
        ShortAnswer: { background: "#000095" },
    };
    console.log("question33", question);
    const url = process.env.REACT_APP_API_BASE_URL;
    const [openCollapse, setOpenCollapse] = React.useState(true);
    const [LinkUrl, setLinkUrl] = useState("");
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isViewModalOpen, setIsViewModalOpen] = useState(false);
    const [rubricItem, setRubricItem] = useState(null);
    const [rubricItemData, setRubricItemData] = useState(null);
    const dispatch = useDispatch();
  
  
    const [getRubricStandardItem, { data: rubricStandardByItem, isLoading: isLoadingRubricStandardItem }] = useLazyGetRubricStandardByIdQuery();
    const [getRubricItem, { data: rubricByItem, isLoading: isLoadingRubricItem }] = useLazyGetRubricByIdQuery();

   const getCoverImageUrl = (url) => {
      const regex = /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|.*[?&]v=))([^"&?/]+)/;
      const match = url?.match(regex);
      const id =  match ? match[1] : null;
      return `https://img.youtube.com/vi/${id}/maxresdefault.jpg`;
  }

  const getFirstYoutubeLink = (links) => {
    const youtubeLink = links.find(link => link.type === "youtube");
    return youtubeLink ? youtubeLink.url : null;
  };

  const youtubeLink = getFirstYoutubeLink(question?.links);

  const handleActionClick = async (item) => {
    setRubricItem(item.id);
    console.log(item);
    if(item['@type'] === "RubricStandard"){
        const {data, error} = await getRubricStandardItem(item.id);
        if (data) {
            setRubricItemData(data);
            setIsViewModalOpen(!isViewModalOpen);
        }
    }else {
        const {data, error} = await getRubricItem(item.id);
        if (data) {
            setRubricItemData(data);
            setIsViewModalOpen(!isViewModalOpen);
        }
    }
};

    return (
      <div
        className={style.questionItemContant}
        style={bgColor[question?.type]}
      >
        <div className={style.header}>
          <div className={style.titleQuistion}>
            <h6>{`${intl.formatMessage({
              id: "assignment.preview.question.index",
            })} ${indexQuestion + 1}`}</h6>
            <span className={style.badgeType}>{question.type}</span>
          </div>
          <div className={style.actionsBtns}>
            {isAllowedRubric && question?.rubric && (
              <button
                className={style.rubricButton}
                onClick={() => {
                  handleActionClick(question?.rubric);
                }}
              >
                Rubric
              </button>
            )}
            <button onClick={() => setOpenCollapse(!openCollapse)}>
              <DoubleRightOutlined
                style={{
                  color: "#fff",
                  transform: openCollapse ? "rotate(90deg)" : "rotate(-90deg)",
                }}
              />
            </button>
          </div>
        </div>
        <div
          className={`${style.contentMain} ${openCollapse ? "" : style.hide} `}
        >
          {!isCorrection && (
            <div className={style.resourcesConteiner}>
              <div className={style.resourceItemContainer}>
                {(() => {
                  // Get all resources and hypertext links
                  const resources = question?.resources || [];
                  const hypertextLinks = (question?.links || []).filter(
                    (item) => item?.type === "hypertext",
                  );
                  const totalItems = resources.length + hypertextLinks.length;

                  // Always show max 3 items
                  const visibleResources = resources.slice(0, 3);
                  const remainingItems = 3 - visibleResources.length;
                  const visibleLinks = hypertextLinks.slice(
                    0,
                    Math.max(0, remainingItems),
                  );
                  const allLinks = question?.links || [];

                  const handleShowMore = () => {
                    setIsActiveFile(false);
                    setOpenPreviewDocs(true);
                    setResources({
                      indexQuestion,
                      files: [...resources],
                      links: [...allLinks],
                    });
                  };

                  return (
                    <>
                      {visibleResources.map((item, indexFile) => (
                        <span
                          key={indexFile}
                          className={style.resourceItem}
                          onClick={() => {
                            setIsActiveFile(true);
                            setOpenPreviewDocs(true);
                            setResourceIndexs({ indexQuestion, indexFile });
                          }}
                        >
                          <FileOutlined />
                          <span title={item?.filePath?.split("/").pop()}>
                            {item?.filePath?.split("/").pop()?.slice(0, 20)}
                          </span>
                        </span>
                      ))}

                      {visibleLinks.map((item, index) => (
                        <span
                          key={`link-${index}`}
                          className={style.resourceItem}
                          onClick={() => {
                            setIsModalVisible(true);
                            setLinkUrl(item.url);
                          }}
                        >
                          <GlobalOutlined />
                          {item.url.length > 20
                            ? item.url.slice(0, 20) + "..."
                            : item.url}
                        </span>
                      ))}

                      {totalItems > 3 && (
                        <span
                          className={`${style.resourceItem} ${style.showMoreButton}`}
                          onClick={handleShowMore}
                        >
                          <CloseTabs />
                          <span>View All ({totalItems})</span>
                        </span>
                      )}
                    </>
                  );
                })()}
              </div>
            </div>
          )}
          <div>
            <div
              className={`${style.bodyQuestion} ${
                openPreviewDocs ? style.resourceColomn : ""
              }`}
            >
              {youtubeLink && (
                <div className={style.videoContainer}>
                  <img src={getCoverImageUrl(youtubeLink)} alt="" />
                  <IconPlayVideo
                    className={style.playIcon}
                    onClick={() => {
                      setIsModalVisible(true);
                      setLinkUrl(youtubeLink);
                    }}
                  />
                </div>
              )}

              {/* {question.image && (
                <img
                  src={`${url}/uploads/images/${question.image.filePath}`}
                  alt="textContent"
                />
              )} */}
              <div className={style.textContainer}>
                <p className={style.textContent}>{question.questionText}</p>
              </div>
            </div>
            {question?.context && (
              <div className={style.context}>
                <span className={style.contextTitle}>Context:</span>
                <p className={style.contextContent}>{question?.context}</p>
              </div>
            )}
          </div>
          <div className={style.options}>
            {isMultiple &&
              question?.options.map((option, index) => (
                <div key={index} className={style.optionChoiceItem}>
                  <CustomCheckbox
                    key={option?.id}
                    label={option?.text}
                    value={option?.id}
                    isSelected={question?.studentAnswer?.includes(option?.id)}
                    index={index}
                    onChange={(value) => {
                      const updatedSelection =
                        question?.studentAnswer?.includes(value)
                          ? question?.studentAnswer?.filter(
                              (item) => item !== value,
                            ) // Remove the value
                          : [...(question?.studentAnswer || []), value]; // Add the value
                      setFieldValue?.(
                        `${indexQuestion}.studentAnswer`,
                        updatedSelection,
                      );
                    }}
                  />
                </div>
              ))}
          </div>
          {isShortAnswer && (
            <>
              {/* <textarea
                className={style.textarea}
                readOnly={!setFieldValue}
                rows={5}
                name={`${indexQuestion}.answer`}
                value={question?.answer}
                onChange={(e) => {
                  setFieldValue?.(`${indexQuestion}.answer`, e.target.value);
                }}
                ref={(textarea) => {
                  if (textarea) {
                    textarea.style.height = "auto";
                    textarea.style.height = `${textarea.scrollHeight}px`;
                  }
                }}
                onPaste={(event) => {
                  if (!allowPaste) {
                    event.preventDefault();
                    dispatch(
                      setNotification({
                        type: "info",
                        message: "Pasting is not allowed in this field.",
                      }),
                    );
                    return;
                  }
                }}
              ></textarea> */}
              <Editor
                onChange={(value) => setFieldValue?.(`${indexQuestion}.slateFormatAnswer`, value)}
                onFormatChange={(value) => setFieldValue?.(`${indexQuestion}.answer`, value)}
                value={question?.slateFormatAnswer}
                readOnly={!setFieldValue}
                allowPaste={allowPaste}
              />
            </>
          )}
          {isEssayMode && (
            <div className={style.textareaContainer}>
              {/* <textarea
                className={style.textareaV2}
                readOnly={!setFieldValue}
                rows={5}
                name={`${indexQuestion}.answer`}
                value={question?.answer}
                onChange={(e) => {
                  setFieldValue?.(`${indexQuestion}.answer`, e.target.value);
                }}
                ref={(textarea) => {
                  if (textarea) {
                    textarea.style.height = "auto";
                    textarea.style.height = `${textarea.scrollHeight}px`;
                  }
                }}
                onPaste={(event) => {
                  if (!allowPaste) {
                    event.preventDefault();
                    dispatch(
                      setNotification({
                        type: "info",
                        message: "Pasting is not allowed in this field.",
                      }),
                    );
                    return;
                  }
                }}
              ></textarea> */}
              <Editor
                onChange={(value) => setFieldValue?.(`${indexQuestion}.slateFormatAnswer`, value)}
                onFormatChange={(value) => setFieldValue?.(`${indexQuestion}.answer`, value)}
                value={question?.slateFormatAnswer}
                readOnly={!setFieldValue}
                allowPaste={allowPaste}
              />
            </div>
          )}
          {question?.isJustification && isMultiple && (
            <div className={style.textareaContainer}>
              <label
                htmlFor={"justification" + indexQuestion}
                className={style.textareaLabel}
              >
                {intl.formatMessage({
                  id: "assignment.preview.question.label.justification",
                })}
              </label>
              {/* <textarea
                className={style.textarea}
                rows={5}
                readOnly={!setFieldValue}
                name={`${indexQuestion}.justification`}
                id={"justification" + indexQuestion}
                value={question?.justification}
                onChange={(e) => {
                  setFieldValue?.(
                    `${indexQuestion}.justification`,
                    e.target.value,
                  );
                }}
                ref={(textarea) => {
                  if (textarea) {
                    textarea.style.height = "auto";
                    textarea.style.height = `${textarea.scrollHeight}px`;
                  }
                }}
                onPaste={(event) => {
                  if (!allowPaste) {
                    event.preventDefault();
                    dispatch(
                      setNotification({
                        type: "info",
                        message: "Pasting is not allowed in this field.",
                      }),
                    );
                    return;
                  }
                }}
              ></textarea> */}
              <Editor
                onChange={(value) => setFieldValue?.(`${indexQuestion}.slateFormatAnswer`, value)}
                onFormatChange={(value) => setFieldValue?.(`${indexQuestion}.answer`, value)}
                value={question?.slateFormatAnswer}
                readOnly={!setFieldValue}
                allowPaste={allowPaste}
              />
            </div>
          )}
        </div>
        <MediaViewer
          initialUrl={LinkUrl}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
        />
        <ViewRubricModal
          isViewModalOpen={isViewModalOpen}
          setIsViewModalOpen={setIsViewModalOpen}
          rubricItem={rubricItem}
          rubricItemData={rubricItemData}
        />
      </div>
    );
};
export default QuestionsItem;