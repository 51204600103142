import React from "react";
import styles from "./notFound.module.css";
import Button from "../Button";
import { PlusOutlined } from "@ant-design/icons";

const NotFound = ({
  image,
  header,
  title,
  description,
  buttonText,
  buttonIcon = <PlusOutlined width={20} height={20} />,
  onClickAdd,
  displayButton = true,
}) => {
  return (
    <div className={styles.container}>
      <p className={styles.header}>{header}</p>
      <div className={styles.main}>
        <div className={styles.content}>
          <img src={image} alt={title} />
          <div className={styles.text}>
            <p className={styles.title}>{title}</p>
            <p className={styles.description}>{description}</p>
          </div>
          {displayButton && (
            <Button
              text={buttonText}
              icon={buttonIcon}
              isLoading={false}
              variant="primary"
              onClick={onClickAdd}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default NotFound;
