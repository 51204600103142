import React, { useState, useRef, forwardRef } from "react";
import { IconCloudUpload, IconFileUpload, IconTrash } from "../../assets/icons";
import styles from "./upload.module.scss";
import { useIntl } from "react-intl";

const SIZE = {
  SM: "sm",
  MD: "md",
  LG: "lg",
};

const Upload = forwardRef(
  (
    {
      onChange,
      onDrop,
      accept,
      error,
      multiple = false,
      size = SIZE.MD,
      className = "",
      children,
      value = [],
      ...props
    },
    ref
  ) => {
    const [isDragging, setIsDragging] = useState(false);
    const fileInputRef = useRef(null);
    const componentRef = ref || fileInputRef;
    const { formatMessage } = useIntl();

    const handleDragEnter = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(true);
    };

    const handleDragLeave = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);
    };

    const handleDragOver = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(true);
    };

    const handleDrop = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);

      const files = [...e.dataTransfer.files];
      if (onDrop) {
        onDrop(files);
      }
      if (onChange) {
        onChange(files);
      }
    };

    const handleClick = () => {
      componentRef.current?.click();
    };

    const handleChange = (e) => {
      const files = [...e.target.files];
      if (onChange) {
        onChange(files);
      }
    };

    const handleRemove = (index) => {
      const newFiles = value.filter((_, i) => i !== index);
      if (onChange) {
        onChange(newFiles);
      }
    };

    const renderFileList = () => {
      return (
        <div className={styles.fileList}>
          {value.map((file, index) => (
            <div key={index} className={styles.fileItem}>
              <div className={styles.fileInfo}>
                <IconFileUpload size={34} />
                <div className={styles.fileDetails}>
                  <span className={styles.fileName}>{file?.name}</span>
                  <span className={styles.fileSize}>
                    {Math.round(file?.size / 1024)} KB
                  </span>
                </div>
              </div>
              <button
                type="button"
                className={styles.removeButton}
                onClick={() => handleRemove(index)}
              >
                <IconTrash />
              </button>
            </div>
          ))}
        </div>
      );
    };

    const classList = [
      styles.wrapper,
      styles[size],
      isDragging && styles.dragging,
      error && styles.error,
      className,
    ]
      .filter(Boolean)
      .join(" ");

    const renderDragDrop = () => {
      return (
        <div
          className={classList}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          onClick={handleClick}
          {...props}
        >
          <input
            type="file"
            ref={componentRef}
            className={styles.input}
            onChange={handleChange}
            accept={accept}
            multiple={multiple}
          />
          <div className={styles.content}>
            <div className={styles.icon}>
              <IconCloudUpload size={46} />
            </div>
            {children || (
              <>
                <p
                  className={styles.title}
                  dangerouslySetInnerHTML={{
                    __html: formatMessage({
                      id: "app.component.upload.title",
                    }),
                  }}
                />
                <p
                  className={styles.description}
                  dangerouslySetInnerHTML={{
                    __html: formatMessage({
                      id: "app.component.upload.description",
                    }),
                  }}
                />
              </>
            )}
          </div>
        </div>
      );
    };

    const newClassList = [styles.uploadWrapper, error && styles.error]
      .filter(Boolean)
      .join(" ");
    return (
      <div className={newClassList}>
        {value?.length > 0 ? renderFileList() : renderDragDrop()}
        <input
          type="file"
          ref={componentRef}
          className={styles.input}
          onChange={handleChange}
          accept={accept}
          multiple={multiple}
          style={{ display: "none" }}
        />
        {error && <p className={styles.errorMessage}>{error}</p>}
      </div>
    );
  }
);

export { Upload as default, SIZE };
