import React, { useEffect, useState, useRef } from "react";
import { CloseOutlined, FileOutlined } from "@ant-design/icons";
import style from "./rightSideBarPreview.module.css";
import { Button, Dropdown, Select, Tabs } from "antd";
import CloseTabs from "../../assets/icons/CloseTabs";
import RemediaIcon from "../../assets/icons/RemediaIcon";
import RemediaIconWhite from "../../assets/icons/RemediaIconWhite";
import ListResources from "../listResources";
import ResourceItemPreview from "../ResourceItemPreview";
import RemyAssistant from "../RemyAssistant";

const RightSideBarPreview = ({ isOpen, setIsOpen, files, selectedFile, courseworkFilePath, questions, resourceIndexs, setResourceIndexs, isActiveFile, setIsActiveFile, resources,enbaleRemy }) => {
  const [isPreviewFile, setIsPreviewFile] = useState(false);
  const [activeKey, setActiveKey] = useState('1');
  const [isClosing, setIsClosing] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const closeTimeoutRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
      // Small delay to ensure DOM is ready before adding the open class
      setTimeout(() => {
        setIsClosing(false);
      }, 50);
    } else {
      if (isVisible) {
        setIsClosing(true);
        // Wait for the animation to complete before hiding the component
        closeTimeoutRef.current = setTimeout(() => {
          setIsVisible(false);
        }, 300); // This should match the CSS transition duration
      }
    }

    // Cleanup timeout on unmount
    return () => {
      if (closeTimeoutRef.current) {
        clearTimeout(closeTimeoutRef.current);
      }
    };
  }, [isOpen]);

  useEffect(() => {
    if ((resources && Object.keys(resources)?.length > 0) || isActiveFile) {
      setActiveKey('2');
    }
  }, [resources, isActiveFile]);

  useEffect(() => {
    if (isActiveFile) {
      setIsPreviewFile(true);
    }
  }, [isActiveFile]);

  useEffect(() => {
    if (resources && Object.keys(resources)?.length > 0) {
      setIsPreviewFile(false);
      setIsActiveFile(false);
    }
  }, [resources, setIsActiveFile]);

  useEffect(() => {
    if (!isOpen) {
      setActiveKey('1');
    }
  }, [isOpen]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const options = questions?.map((_, index) => ({
    label: `Question ${index + 1}`,
    value: index
  }));
  
  selectedFile = { questionId: 1, url: "" } || null;

  const getBotResponse = (userInput) => {
    const input = userInput.toLowerCase();

    if (input.includes("hello") || input.includes("hi")) {
      return "Hello there! How can I assist you today?";
    } else if (input.includes("help")) {
      return "I'm here to help! You can ask me questions or just chat.";
    } else if (input.includes("bye")) {
      return "Goodbye! Feel free to come back if you have more questions.";
    } else if (input.includes("thank")) {
      return "You're welcome! Is there anything else I can help with?";
    } else {
      return "That's interesting. Can you tell me more about that?";
    }
  };

  const onSubmitRemyAssistant = async (value) => {
    const response = getBotResponse(value);
    await new Promise(resolve => setTimeout(resolve, 1000));
    return response;
  };

  // Don't render at all if not visible
  if (!isVisible) {
    return null;
  }

  // Determine the CSS classes to apply
  const sidebarClasses = [
    style.rightSideBarPreview,
    isClosing ? style.rightSideBarPreview__closing : (isOpen ? style.rightSideBarPreview__open : "")
  ].filter(Boolean).join(" ");


  const baseItems = [
    {
      key: "2",
      label:
        <>
          Files {" "}
          {
            isPreviewFile && (
              <Select
                className={style.selectRightSideBar}
                value={resourceIndexs?.indexQuestion}
                onChange={(value) => {
                  setResourceIndexs(prev => ({
                    ...prev,
                    indexQuestion: value
                  }))
                }}
                options={options}
              />
            )
          }
        </>,
      children: (
        <div className={style.rightSideBarPreview__details}>
          {
            isPreviewFile ? (
              <ResourceItemPreview courseworkFilePath={courseworkFilePath} questions={questions} resourceIndexs={resourceIndexs} />
            ) : (
              <ListResources resources={resources} selectedFile={selectedFile} setIsPreviewFile={setIsPreviewFile} setResourceIndexs={setResourceIndexs} setIsActiveFile={setIsActiveFile} />)
          }
        </div>
      ),
      icon: <FileOutlined />
    }
  ]

  const items = enbaleRemy ? [
    {
      key: "1",
      label: "Remy",
      children: <RemyAssistant oSubmit={onSubmitRemyAssistant} messages={[]} />,
      icon:  <RemediaIconWhite className={style.remediaIcon} />
    },
    ...baseItems
  ] : [...baseItems]

  return (
    <div className={sidebarClasses}>
      <div className={style.rightSideBarPreview__content}>
        <Tabs
          tabBarExtraContent={{
            right: (
              <button className={style.closeIcon} onClick={handleClose}>
                <CloseTabs />
              </button>
            ),
          }}
          activeKey={activeKey}
          onChange={(value) => setActiveKey(value)}
          items={items}
        />
      </div>
    </div>
  );
};

export default RightSideBarPreview;