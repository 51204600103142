import { object, string } from "yup";
import * as Yup from "yup";
import { FormattedMessage } from "react-intl";
import dayjs from "dayjs";

export const AssignmentSchema = object({
  title: string().required("Title is required"),
  type: string().required("Type is required"),
  description: string().required("Instruction is required"),
  //  rubric: string().required('Rubric is required'),
  state: string().required("Select a state value"),
});

const urlRegex = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,6})(\/\S*)?$/;

const youtubeRegex =
  /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=[\w-]+)(\&[\w-]+=[\w-]+)*$/;

export const validationSchema = Yup.object().shape({
  title: Yup.string()
    .required(
      <FormattedMessage
        id="form.field.error.required"
        values={{ field: "Title" }}
      />,
    )
    .max(60, (max) => (
      <FormattedMessage
        id="form.field.error.maxLength"
        values={{ field: "Title", max: max.max }}
      />
    ))
    .matches(
      /^[a-zA-Z0-9\s.,!?àâäéèêëîïôöùûüçÀÂÄÉÈÊËÎÏÔÖÙÛÜÇ&’'()-]+$/, // Allow only alphanumeric and basic punctuation
      <FormattedMessage
        id="form.field.error.invalid.characters"
        values={{ field: "Title" }}
      />,
    ),
  dueDate: Yup.date()
    .required(
      <FormattedMessage
        id="form.field.error.required"
        values={{ field: "Due date" }}
      />,
    )
    .test(
      "is-future",
      <FormattedMessage
        id="form.field.error.dueDate.future"
        values={{ field: "Due date" }}
      />,
      (value) => {
        return dayjs(value).isAfter(dayjs().subtract(1, "minute")); // Autoriser maintenant ou plus tard
      },
    ),
  type: Yup.string().required(
    <FormattedMessage
      id="form.field.error.required"
      values={{ field: "Type" }}
    />,
  ),
  questions: Yup.array().of(
    Yup.object().shape({
      type: Yup.string()
        .required(
          <FormattedMessage
            id="form.field.error.required"
            values={{ field: "Type" }}
          />,
        )
        .oneOf(
          ["MultiChoice", "Essay", "ShortAnswer"],
          "Invalid question type*",
        ),
      questionText: Yup.string().required("Question text is required*"),
      isJustification: Yup.boolean(),
      options: Yup.lazy((_, { type, ...obj }) => {
        if (obj.originalValue?.type === "MultiChoice") {
          return Yup.array()
            .of(Yup.object().shape({}))
            .min(
              2,
              <FormattedMessage
                id="form.field.error.atLeastTwoOptions.required"
                values={{ field: "Options" }}
              />,
            );
        }
        return Yup.mixed().notRequired();
      }),
      rubric: Yup.lazy((_, { type, ...obj }) => {
        if (
          obj.originalValue?.type === "Essay" ||
          obj.originalValue?.type === "ShortAnswer"
        ) {
          return Yup.string().required(
            <FormattedMessage
              id="form.field.error.required"
              values={{ field: "Rubric" }}
            />,
          );
        }
        return Yup.mixed().notRequired();
      }),

      links: Yup.array().of(
        Yup.object().shape({
          type: Yup.string().required(),
          url: Yup.string()
            .required("Link cannot be empty")
            .test("is-url", "Invalid link format", (value, context) => {
              const { type } = context.parent;
              if (type === "hypertext") {
                return urlRegex.test(value);
              }
              if (type === "youtube") {
                return youtubeRegex.test(value);
              }
              return true;
            }),
        }),
      ),
      grade: Yup.string()
        .required(
          <FormattedMessage
            id="form.field.error.required"
            values={{ field: "Grade" }}
          />,
        )
        .test(
          "positive-number",
          <FormattedMessage
            id="form.field.error.positiveNumber"
            values={{ field: "Grade" }}
          />,
          (value) => {
            const num = parseFloat(value);
            return !isNaN(num) && num > 0;
          },
        ),
    }),
  ),
});

export const draftValidationSchema = Yup.object().shape({
  title: Yup.string()
    .required(
      <FormattedMessage
        id="form.field.error.required"
        values={{ field: "Title" }}
      />,
    )
    .max(60, (max) => (
      <FormattedMessage
        id="form.field.error.maxLength"
        values={{ field: "Title", max: max.max }}
      />
    ))
    .matches(
      /^[a-zA-Z0-9\s.,!?àâäéèêëîïôöùûüçÀÂÄÉÈÊËÎÏÔÖÙÛÜÇ&’'()-]+$/, // Allow only alphanumeric and basic punctuation
      <FormattedMessage
        id="form.field.error.invalid.characters"
        values={{ field: "Title" }}
      />,
    ),

  type: Yup.string().required(
    <FormattedMessage
      id="form.field.error.required"
      values={{ field: "Type" }}
    />,
  ),
  questions: Yup.array().of(
    Yup.object().shape({
      type: Yup.string()
        .required(
          <FormattedMessage
            id="form.field.error.required"
            values={{ field: "Type" }}
          />,
        )
        .oneOf(
          ["MultiChoice", "Essay", "ShortAnswer"],
          "Invalid question type*",
        ),
      questionText: Yup.string().required("Question text is required*"),
      isJustification: Yup.boolean(),
      options: Yup.lazy((_, { type, ...obj }) => {
        if (obj.originalValue?.type === "MultiChoice") {
          return Yup.array()
            .of(Yup.object().shape({}))
            .min(
              2,
              <FormattedMessage
                id="form.field.error.atLeastTwoOptions.required"
                values={{ field: "Options" }}
              />,
            );
        }
        return Yup.mixed().notRequired();
      }),

      links: Yup.array().of(
        Yup.object().shape({
          type: Yup.string().required(),
          url: Yup.string()
            .required("Link cannot be empty")
            .test("is-url", "Invalid link format", (value, context) => {
              const { type } = context.parent;
              if (type === "hypertext") {
                return urlRegex.test(value);
              }
              if (type === "youtube") {
                return youtubeRegex.test(value);
              }
              return true;
            }),
        }),
      ),
    }),
  ),
});
