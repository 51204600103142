
const ClassModeIcon = ({ className }) => {
    return (
        <svg width="24" height="24" className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 22.4453V18.4453C14 17.9149 13.7893 17.4062 13.4142 17.0311C13.0391 16.656 12.5304 16.4453 12 16.4453C11.4696 16.4453 10.9609 16.656 10.5858 17.0311C10.2107 17.4062 10 17.9149 10 18.4453V22.4453" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M18 10.4453L21.447 12.1693C21.6131 12.2523 21.7528 12.3799 21.8504 12.5378C21.9481 12.6957 21.9999 12.8777 22 13.0633V20.4453C22 20.9757 21.7893 21.4845 21.4142 21.8595C21.0391 22.2346 20.5304 22.4453 20 22.4453H4C3.46957 22.4453 2.96086 22.2346 2.58579 21.8595C2.21071 21.4845 2 20.9757 2 20.4453V13.0633C2.0001 12.8777 2.05188 12.6957 2.14955 12.5378C2.24722 12.3799 2.38692 12.2523 2.553 12.1693L6 10.4453" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M18 5.44531V22.4453" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4 6.44362L11.106 2.89062C11.3836 2.7519 11.6897 2.67969 12 2.67969C12.3103 2.67969 12.6164 2.7519 12.894 2.89062L20 6.44362" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6 5.44531V22.4453" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12 11.4453C13.1046 11.4453 14 10.5499 14 9.44531C14 8.34074 13.1046 7.44531 12 7.44531C10.8954 7.44531 10 8.34074 10 9.44531C10 10.5499 10.8954 11.4453 12 11.4453Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default ClassModeIcon;
