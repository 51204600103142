import {
  AppstoreAddOutlined,
  ArrowLeftOutlined,
  ProjectOutlined,
  SnippetsOutlined,
  FolderAddOutlined
} from "@ant-design/icons";
import "./leftSideMenu.css";
import { useDispatch, useSelector } from "react-redux";
import { selectClassroom } from "../../redux/app/app.selectors";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import useTypeUser from "../../hooks/useTypeUser";
import { setClassroom } from "../../redux/app/app.slice";
import {
  IconArrowUp,
  IconChevronRight,
  IconLogout,
  IconSquareArrow,
} from "../../assets/icons";
import useGetOrganization from "../../hooks/useGetOrganization";

const getMenuItems = (typeUser, intl, myOrganization) => {
  const commonItems = [
    {
      to: "/coursework",
      icon: SnippetsOutlined,
      text: intl.formatMessage({ id: "menu.item.coursework" }),
      requireSelectClass: true,
    },
  ];

  const studentMenu = [
    {
      to: "/classes",
      text: intl.formatMessage({ id: "menu.item.classes" }),
      icon : ProjectOutlined,
      requireSelectClass: false,
    },
    ...commonItems,
    {
      to: "/student/submission",
      icon: AppstoreAddOutlined,
      text: intl.formatMessage({ id: "menu.item.submissions" }),
      requireSelectClass: true,
    },
  ];

  const teacherMenu = [
    {
      to: "/",
      icon: ProjectOutlined,
      text: myOrganization,
      onClick: (e) => e.preventDefault(),
      children: [
        {
          to: "/classes",
          text: intl.formatMessage({ id: "menu.item.classes" }),
          requireSelectClass: false,
        },
        {
          to: "/students-invitations",
          text: intl.formatMessage({ id: "menu.item.students" }),
          requireSelectClass: false,
        },
      ],
    },
    ...commonItems,
    {
      to: "/rubric",
      icon: ProjectOutlined,
      text: intl.formatMessage({ id: "menu.item.rubrics" }),
      isRubrics: true,
      requireSelectClass: true,
    },
    {
      to: "/submissions",
      icon: AppstoreAddOutlined,
      text: intl.formatMessage({ id: "menu.item.submissions" }),
      requireSelectClass: true,
    },
  ];

  const teacherWithoutOrganisationMenu = [
    {
      to: "/classes",
      icon: FolderAddOutlined,
      text: intl.formatMessage({ id: "menu.item.classes" }),
      children: [
        {
          to: "/students-invitations",
          text: intl.formatMessage({ id: "menu.item.students" }),
          requireSelectClass: false,
        },
      ],
    },
    ...commonItems,
    {
      to: "/rubric",
      icon: ProjectOutlined,
      text: intl.formatMessage({ id: "menu.item.rubrics" }),
      isRubrics: true,
      requireSelectClass: true,
    },
    {
      to: "/submissions",
      icon: AppstoreAddOutlined,
      text: intl.formatMessage({ id: "menu.item.submissions" }),
      requireSelectClass: true,
    },
  ];

  const adminMenu = [
    {
      to: "/",
      icon: ProjectOutlined,
      text: myOrganization ? myOrganization : "Organization",
      requireSelectClass: false,
      children: myOrganization ? [
        {
          to: "/teachers-invitations",
          text: intl.formatMessage({ id: "menu.item.teachers" }),
          requireSelectClass: false,
        },
        {
          to: "/classes",
          text: intl.formatMessage({ id: "menu.item.classes" }),
          requireSelectClass: false,
        },
        {
          to: "/students-invitations",
          text: intl.formatMessage({ id: "menu.item.students" }),
          requireSelectClass: false,
        },
      ] : [],
    },
    ...commonItems,
    {
      to: "/rubric",
      icon: ProjectOutlined,
      text: intl.formatMessage({ id: "menu.item.rubrics" }),
      isRubrics: true,
      requireSelectClass: true,
    },
    {
      to: "/submissions",
      icon: AppstoreAddOutlined,
      text: intl.formatMessage({ id: "menu.item.submissions" }),
      requireSelectClass: true,
    },
  ];

  const superAdminMenu = [
    {
      to: "/invite-admin",
      icon: ProjectOutlined,
      text: intl.formatMessage({ id: "super_admin.list_Teacher.table.header.admin" }),
      requireSelectClass: false,
    },
  ];

  const menuItems = {
    student: studentMenu,
    teacher: myOrganization ? teacherMenu : teacherWithoutOrganisationMenu,
    admin: adminMenu,
    super_admin: superAdminMenu,
  };

  return menuItems[typeUser];
};

const MenuItem = ({
  to,
  icon: Icon,
  text,
  isRubrics,
  collapsed,
  isSubmenu = false,
  children,
  location,
  requireSelectClass,
  hasSelectClass,
  ...props
}) => {
  return (
    ((requireSelectClass && hasSelectClass) ||
      (!requireSelectClass && !hasSelectClass)) && (
      <div
        className={`menu-item-container ${
          isSubmenu ? "submenu-container" : ""
        }`}
      >
        <NavLink
          caseSensitive
          to={to}
          className={
            children &&
            children.find(
              (child) =>
                child.to?.split("/")[1] === location.pathname.split("/")[1]
            )
              ? "active"
              : ""
          }
          {...props}
        >
          <button
            type="button"
            className={`list-btn ${collapsed ? "collapsed" : ""} ${
              children ? "submenu-item" : ""
            }`}
          >
            {collapsed ? (
              <Icon
                className="icon"
                style={isRubrics ? { rotate: "-90deg" } : {}}
              />
            ) : (
              <>
                {Icon && <Icon style={isRubrics ? { rotate: "-90deg" } : {}} />}
                <span>{text}</span>
              </>
            )}
          </button>
        </NavLink>
        {children && !collapsed && (
          <div className="submenu">
            {children?.map((child, index) => (
              <MenuItem
                key={index}
                {...child}
                collapsed={collapsed}
                isSubmenu={true}
                hasSelectClass={hasSelectClass}
              />
            ))}
          </div>
        )}
      </div>
    )
  );
};

const LeftSideMenu = ({ collapsed, user }) => {
  const classroom = useSelector(selectClassroom);
  const intl = useIntl();
  const { typeUser, isStudent, isTeacher } = useTypeUser();
  const { organisation } = useGetOrganization();
  const menuItems = getMenuItems(typeUser, intl, organisation?.name);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleGoBack = () => {
    dispatch(setClassroom(null));
    navigate("/classes");
  };

  //!collapsed
  return (
    <div className="list">
      {classroom && (
        <div className={`classroom-name ${collapsed ? "align-center" : ""} `}>
          {isStudent && collapsed &&  <IconSquareArrow size={17} />}
        {!collapsed &&  <div>{classroom?.name}</div>}
          {(organisation && !isStudent) && (
            <button
              type="button"
              className="flex-y-center gap-10"
              onClick={handleGoBack}
            >
              <IconSquareArrow size={17} />
              {!collapsed && organisation?.name}
            </button>
          )}
          {((isTeacher && !organisation) || isStudent) && (
            <button
              type="button"
              className="flex-y-center gap-10"
              onClick={handleGoBack}
            >
              <IconSquareArrow size={17} />
              {!collapsed && intl.formatMessage({ id: "menu.item.classes" })}
            </button>
          )}
        </div>
      )}
      {menuItems?.map((item, index) => (
        <MenuItem
          key={index}
          {...item}
          collapsed={collapsed}
          location={location}
          hasSelectClass={!!classroom}
        />
      ))}
    </div>
  );
};

export default LeftSideMenu;
