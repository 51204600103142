

const IconPlayVideo = ({className, onClick}) => {
  return (
    <svg
      width="55"
      height="55"
      viewBox="0 0 55 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M43.9903 7.78125H12.6543C10.182 7.78125 8.17773 9.78548 8.17773 12.2578V43.5938C8.17773 46.0662 10.182 48.0704 12.6543 48.0704H43.9903C46.4626 48.0704 48.4669 46.0662 48.4669 43.5938V12.2578C48.4669 9.78548 46.4626 7.78125 43.9903 7.78125Z"
        stroke="white"
        stroke-width="4.47657"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.6074 18.9688L35.0371 27.9219L21.6074 36.875V18.9688Z"
        stroke="white"
        stroke-width="4.47657"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default IconPlayVideo;
