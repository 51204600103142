

import CourseworkCreation from "../../Components/courseworkCreation";


const NewAssignment = ({ PassedDefaultInitialValues }) => {

  return (
      <CourseworkCreation />
  );
};

export default NewAssignment;
