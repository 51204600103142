import React from "react";
import { ImportOutlined, PlusOutlined } from "@ant-design/icons";
import { FormattedMessage, useIntl } from "react-intl";
import Input, { TYPE } from "../../../Components/Input";
import Button, { VARIANT } from "../../../Components/Button";
import { Dropdown } from "antd";
import { useSelector } from "react-redux";
import { selectAuthenticatedUser } from "../../../redux/auth/auth.selectors";
import useGetOrganization from "../../../hooks/useGetOrganization";

const Header = ({ canCreateClass, showModal }) => {
  const intl = useIntl();
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const {organisation} = useGetOrganization();

  const items = [
    {
        key: "1",
        label: (
            <Button
                color={"#FDD832"}
                className={"import__assignment__dropdown"}
                onClick={() => {
                  const service = "GET_ALL_GOOGLE_API_ENTITY";
                  const redirect = `${window.location.origin}/classes`;
                  const baseUrl = process.env.REACT_APP_API_BASE_URL;
                  const idUser = authenticatedUser?.id;
                  const organization = organisation?.id;
                  const url = `${baseUrl}/google/${idUser}/oauth?service=${service}&redirect=${redirect}&organization=${organization}`;
                  window.location.href = url;
                }}
                text={intl.formatMessage({ id: "assignment.list.button.import.googleClassroom.text" })}
            />
          ),
      },
  ];

  return (
    <div className="classesContent__header">
      <div className="classesContent__header-title">
        <h1 className="h1">
          <FormattedMessage id={"classroom.title"} />
        </h1>
      </div>

      <div className="classesContent__header-new">
        <Input
          type={TYPE.SEARCH}
          placeholder={intl.formatMessage({
            id: "inputs.search.placeholder",
          })}
        />
        {canCreateClass && (
          <>
            <Dropdown menu={{ items }} icon={<ImportOutlined />}>
              <Button 
                variant={VARIANT.PRIMARY}
                text = {intl.formatMessage({
                  id: "button.import.importFrom.text",
                })}
                icon={<ImportOutlined />}
              />
            </Dropdown>
            <Button
              variant={VARIANT.PRIMARY}
              onClick={showModal}
              text={intl.formatMessage({ id: "classroom.create" })}
              icon={<PlusOutlined />}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Header;
