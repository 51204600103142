import { useEffect, useState } from "react";
import styles from "./aiGenerateAssignment.module.css";
import { Field, Formik } from "formik";
import { Upload } from "antd";
import dayjs from "dayjs";
import {
  LoadingOutlined,
} from "@ant-design/icons";
import UploadFileIcon from "../../assets/icons/UploadFileIcon";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import CloudDownload from "../../assets/icons/CloudDownload";
import {
  useGenerateCourseworkWithIaMutation,
} from "../../services/coursworks.api";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { selectClassroom } from "../../redux/app/app.selectors";
import { selectAuthenticatedUser } from "../../redux/auth/auth.selectors";
import ErrorMessage from "../ErrorMessage";
import { useDispatch } from "react-redux";
import { setNotification } from "../../redux/app/app.slice";
import CourseworkCreation from "../courseworkCreation";
import CustomSpin from "../customSpin/CustomSpin";

const { Dragger } = Upload;

const validationSchema = Yup.object().shape({});

const defaultInitialValues = {
  title: "",
  objective: "",
  image: null,
};

const AiGenerateAssignment = ({
  creationMode,
  initialValues = defaultInitialValues,
  isEditing = false,
}) => {
 
  const [toUpdate, setToUpdate] = useState(false);
  const [IaInitialValues, setIaInitialValues] = useState(null);
  const [generateCourseworkWithIa, { isLoading: isGenerating }] =
    useGenerateCourseworkWithIaMutation();
  const user = useSelector(selectAuthenticatedUser);
  const dispatch = useDispatch();

  const intl = useIntl();
  const classroom = useSelector(selectClassroom);

  const handleCancel = () => {
    setIsVisible(false);
  };
  const onHandleSubmit = async (values) => {
    const formData = new FormData();
    formData.append("teacher", `/api/teachers/${user.id}`);
    formData.append("objective", values.objective);
    formData.append("title", values.title);
    formData.append("image", values.image);
    formData.append("type", "Create");
    formData.append("grade", classroom.grade);

     const {data, error } = await generateCourseworkWithIa({formData: formData});
      console.log("data", data);
      if (data) {
        const formatedQuestions = data['hydra:member'][0]?.quiz?.questions.map(item=>(
            {
                ...item,
                isJustification : !!item.isJustification,
                links: !!item.links ? item.links : [] ,
                options: !!item.options ? item.options : []
            }
        ))
        const formatedData = {
            ...data['hydra:member'][0],
            type : data['hydra:member'][0]?.type || 'Quiz',
            quiz: {
                ...data['hydra:member'][0]?.quiz,
                questions: formatedQuestions
            }
        }
        console.log("formatedData", formatedData);
        setIaInitialValues(formatedData);
        setToUpdate(true);

      }
      if (error) {
        console.log("error", error);
        dispatch(setNotification({
            message: intl.formatMessage({id: "notification.error.generate"}, {type: "coursework"}),
            type: 'error'
        }))
      }
  };

  const [visible, setIsVisible] = useState(false);

  const propsFileContext = {
    name: "file",
    multiple: true,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        console.log("done");
      } else if (status === "error") {
        console.log("error");
      }
    },
  };


  return (
    <>
        {toUpdate ?  <CourseworkCreation PassedDefaultInitialValues={IaInitialValues} teacher={`/api/teachers/${user.id}`}/>:<div className={styles.newAssignmentPage}>
            <div className={styles.newAssignmentContainer}>
                <div className={styles.newAssignmentContent}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onHandleSubmit}
                        enableReinitialize
                        validationSchema={validationSchema}
                    >
                        {({
                              values,
                              handleBlur,
                              setFieldValue,
                              handleSubmit,
                              errors,
                          }) => (
                            <form className={styles.newAssignmentForm} onSubmit={handleSubmit}>
                                <div className={styles.newAssignmentHeader}>
                                    <div className={styles.newAssignmentTitle}>
                                        <h1>
                                            {intl.formatMessage({
                                                id: "assignment.create.form.title",
                                            })}
                                        </h1>
                                    </div>

                                </div>
                                {/* Basic Fields remain the same */}
                                <div className={styles.formField}>
                                    <label>
                                        {intl.formatMessage({
                                            id: "assignment.create.form.field.label.title",
                                        })}
                                    </label>
                                    <div>
                                        <Field
                                            name={"title"}
                                            placeholder={intl.formatMessage({
                                                id: "assignment.create.form.field.title.placeholder",
                                            })}
                                            className={styles.formFieldInput}
                                        />

                                        {errors.title && <ErrorMessage message={errors.title} />}
                                    </div>
                                </div>
                                {/** objective */}
                                <div className={styles.objectiveBlock}>
                                    <label>
                                        {intl.formatMessage({
                                            id: "assignment.create.form.field.label.objective",
                                        })}
                                    </label>
                                    <div className={styles.objectiveFileContainer}>
                                        <Field
                                            as="textarea"
                                            name={"objective"}
                                            placeholder={intl.formatMessage({
                                                id: "assignment.create.form.field.objective.placeholder",
                                            })}
                                            className={styles.textAreaObjective}
                                        />
                                    </div>
                                </div>
                                {/** context file **/}
                                <div className={styles.uploadFileContextBlock}>
                                    <label>
                                        {intl.formatMessage({
                                            id: "assignment.create.form.field.label.contextFile",
                                        })}
                                    </label>
                                    <Dragger
                                        multiple={false}
                                        beforeUpload={(file) => {
                                            if (file.type !== "application/pdf") {
                                                alert("Seuls les fichiers PDF sont autorisés !");
                                                return Upload.LIST_IGNORE; // Ignore le fichier non PDF
                                            }
                                            setFieldValue("image", file);
                                            return false; // Empêche l’upload automatique
                                        }}
                                        onRemove={() => setFieldValue("image", null)}
                                        accept="application/pdf"
                                        {...propsFileContext}
                                        className={styles.uploadFileContext}

                                    >
                                        <div
                                            className={`ant-upload-drag-icon ${styles.fileCloudUpload}`}
                                        >
                                            <CloudDownload className={styles.iconCloudUpload} />
                                        </div>
                                        <p className={styles.uploadTextContent}>
                                            <b>
                                                {intl.formatMessage({
                                                    id: "assignment.create.form.field.label.uploadAi.title",
                                                })}
                                            </b>
                                            {intl.formatMessage({
                                                id: "assignment.create.form.field.label.uploadAi.text",
                                            })}
                                        </p>
                                    </Dragger>
                                </div>
                                <div className={styles.blockBtn}>
                                    <button type="submit" className={styles.submitButton} disabled={isGenerating}>
                                        {intl.formatMessage({ id: "button.generate" })} {" "}
                                        {isGenerating ? <LoadingOutlined /> : null}
                                    </button>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>

        </div>}

    </>
  );
};

export default AiGenerateAssignment;