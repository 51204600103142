import { api } from './api';

export const CourseWorks = api.injectEndpoints({
    endpoints: (builder) => ({
        getCourseWorks: builder.query({
            query: () => ({
                url: '/courseworks',
                method: 'GET',

            }),
            providesTags: (result) =>
                result === undefined
                    ? []
                    : [
                        { type: 'CourseWorks', id: 'LIST' },
                    ],
        }),
        getCourseWork: builder.query({
            query: (assignmentId) => ({
                url: `/api/courseworks/${assignmentId}`,
                method: 'GET',
            }),
            providesTags: (result, error, { assignmentId }) => [{ type: 'CourseWorks', id: assignmentId }],
        }),
        createNewCoursework: builder.mutation({
            query: ({ formData }) => ({
                url: `/api/course-works/new`,
                method: 'POST',
                body: formData,
                headers: {'Content-Type': 'multipart/form-data'},

            }),
            invalidatesTags: (result) =>
                result === undefined
                    ? []
                    : [
                        { type: 'CourseWorks', id: 'LIST' },
                        { type: 'AssignmentsFilter', id: 'LIST' },
                    ],
        }),

        generateCourseworkWithIa: builder.mutation({
            query: ({formData}) => ({
                url: `/api/generate-coursework-with-ia`,
                method: 'POST',
                body: formData,
                attachments: formData,
            }),
            invalidatesTags: (result) =>
                result === undefined
                    ? []
                    : [
                        { type: 'CourseWorks', id: 'LIST' },
                        { type: 'AssignmentsFilter', id: 'LIST' },
                    ],
        }),


        generateCourseworkHelperWithIa: builder.mutation({
            query: ({formData}) => ({
                url: `/api/generate-coursework-helper-with-ia`,
                method: 'POST',
                body: formData,
                attachments: formData,
            }),
            invalidatesTags: (result) =>
                result === undefined
                    ? []
                    : [
                        { type: 'CourseWorks', id: 'LIST' },
                        { type: 'AssignmentsFilter', id: 'LIST' },
                    ],
        }),
        updateCoursework: builder.mutation({
            query: ({ formData }) => ({
                url: `/api/course-work-global-update/mcq`,
                method: 'POST',
                body: formData,
                attachments: formData,

            }),
            invalidatesTags: (result) =>
                result === undefined
                    ? []
                    : [
                        { type: 'CourseWorks', id: 'LIST' },
                        { type: 'AssignmentsFilter', id: 'LIST' },
                    ],
        }),

        deleteCourseWork: builder.mutation({
            query: ({ assignmentId }) => ({
                url: `/api/courseworks/${assignmentId}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{type: 'AssignmentsFilter', id: 'LIST'}],
        }),

        getGoogleClassroomCourseWorks: builder.query({
            query: (courseId) => ({
                url: `/google/oauth/${courseId}`,
                method: 'GET',
            }),
            providesTags: (result, error, {courseId}) => [{type: 'CourseWorks', id: courseId}],

        }),
        getCourseworksByClassroom: builder.query({
            query: (data) => ({
                url: `/api/courseworks?classroom=${data.classroom}`,
                method: 'GET',
            }),
            providesTags: (result) =>
                result === undefined
                    ? []
                    : [{ type: 'ClassroomsFilter', id: 'LIST' }],
        }),
        createMcqCoursework: builder.mutation({
            query: ({ formData }) => ({
                url: `/api/course-work-global/mcq`,
                method: 'POST',
                body: formData,
                attachments: formData,
            }),
            invalidatesTags: (result) =>
                result === undefined
                    ? []
                    : [
                        { type: 'CourseWorks', id: 'LIST' },
                        { type: 'AssignmentsFilter', id: 'LIST' },
                    ],
        }),
        // New endpoint to fetch the image
        getImageFromServer: builder.query({
            query: (url) => ({
                url: `image/${url}`,
                method: 'GET',
                responseHandler: async (response) =>  await response.blob(), // Handle binary response
            }),
        }),


    }),
});

export const {useGenerateCourseworkHelperWithIaMutation , useGenerateCourseworkWithIaMutation,useLazyGetGoogleClassroomCourseWorksQuery,useLazyGetCourseWorkQuery,useGetCourseWorkQuery,useLazyGetCourseworksByClassroomQuery, useCreateNewCourseworkMutation,useUpdateCourseworkMutation, useDeleteCourseWorkMutation , useCreateMcqCourseworkMutation,useLazyGetImageFromServerQuery} = CourseWorks;