import {useEffect, useState} from "react";
import { selectClassroom } from "../../redux/app/app.selectors";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Formik, Form } from 'formik';
import { useIntl } from "react-intl";
import CreateAssignment from "../../Components/createAssignment";
import { setNotification } from "../../redux/app/app.slice";
import {
  useLazyGetCourseWorkQuery,
  useCreateMcqCourseworkMutation,
  useLazyGetImageFromServerQuery,
  useUpdateCourseworkMutation,
} from "../../services/coursworks.api";
import { encapsulateCourseWork } from "../../Utils/encapsulateCourseWork";
import CustomSpin from "../../Components/customSpin/CustomSpin";
import AssignmentSettings from "../../Components/assignmentSettings";
import styles from "./courseworkCreation.module.css";
import { Radio, Modal, Tabs } from "antd";
import { useNavigate } from "react-router-dom";
import { CheckCircleOutlined } from "@ant-design/icons";
import ModalPreviewAssignment from "../../Components/modalPreviewAssignment";
import PracticeModeIcon from "../../assets/icons/PracticeModeIcon";
import ClassModeIcon from "../../assets/icons/ClassModeIcon";
import { draftValidationSchema, validationSchema } from "./form.schema";

const getInitialQuestion = (format) => {
  switch (format) {
    case "MultiChoice":
      return {
        type: "MultiChoice",
        context: null,
        isJustification: false,
        justification: "",
        questionText: "",
        resources: [],
        grade: 0,
        links: [],
        options: [
          { text: "", isCorrect: false },
          { text: "", isCorrect: false },
        ],
      };
    case "Essay":
      return {
        type: "Essay",
        context: null,
        description: "",
        resources: [],
        rubric: null,
        grade: 0,
        links: [],
      };
    case "ShortAnswer":
      return {
        type: "ShortAnswer",
        description: "",
        resources: [],
        rubric: null,
        grade: 0,
        links: [],
      };
    default:
      return {
        type: "MultiChoice",
        context: null,
        isJustification: false,
        justification: "",
        resources: [],
        questionText: "",
        grade: 0,
        links: [],
        options: [
          { text: "", isCorrect: false },
          { text: "", isCorrect: false },
        ],
      };
  }
};

const defaultInitialValues = {
  title: "",
  type: "Quiz",
  description: "",
  dueDate: null,
  state: "active",
  image: null,
  rubric: null,
  isPracticeMode: false,
  allowHandWriting: false,
  writingAssistance: false,
  allowUploadFile: false,
  setting:{
    timeLimit: null,
    hasTimeLimit: false,
    attempts: "",
    hasAttempts: false,
    allowPaste: false,
    hasRubric: false,
    anonymousGrading: false,
    severity: 50,
  },
  questions: [getInitialQuestion("Quiz")],
};
const mergeObjects = (defaultValues, newValues) => {
  return {
    ...defaultValues,
    ...newValues,
    questions: newValues.quiz?.questions
        ? newValues.quiz.questions.map((q) => ({
          ...getInitialQuestion(q.type),
          ...q,
        }))
        : defaultValues.questions,
  };


};


const NewAssignment = ({ PassedDefaultInitialValues , teacher=null}) => {

  const params = useParams();
  const intl = useIntl();
  const [initialValues, setInitialValues] = useState(PassedDefaultInitialValues ? mergeObjects(defaultInitialValues, PassedDefaultInitialValues)  : defaultInitialValues);
  const classroom = useSelector(selectClassroom);
  const [idAssignement, setIdAssignement] = useState(false);
  const [visible, setIsVisible] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dataPreview, setDataPreview] = useState(null);
  const [modalPreview, setModalPreview] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [
    triggerCourseWorkById,
    { data: dataF, isLoading: isLoadingAssignments },
  ] = useLazyGetCourseWorkQuery();

  const [triggerCreateMcqCoursework, { data, isLoading: isLoadingCreate }] =
  useCreateMcqCourseworkMutation();

  useEffect(() => {
    if (params.id) triggerCourseWorkById(params.id);
  }, []);

  useEffect(() => {
    if (dataF) {
      const { quiz, ...data } = dataF;      
      // const questions = quiz?.questions || [];

      setInitialValues(encapsulateCourseWork({
        ...dataF
      }))
      setIsDraft(dataF?.state === "draft");
    }
  }, [dataF]);


  const [
    triggerUpdateMcqCoursework,
    { dataUpdate, isLoading: isLoadingUpdate },
  ] = useUpdateCourseworkMutation();
  const [triggerGetImage] = useLazyGetImageFromServerQuery();

  const handleCancel = () => {
    setIsVisible(false);
    navigate('/coursework');
  };


  const appendFormData = async (formData, key, value) => {
    if (value instanceof File) {
      formData.append(key, value);
    } else if (Array.isArray(value)) {
      for (const [index, item] of value.entries()) {
        await appendFormData(formData, `${key}[${index}]`, item);
      }
    } else if (value && typeof value === "object") {
      for (const subKey of Object.keys(value)) {
        const subValue = value[subKey];
        let fullKey = `${key}[${subKey}]`;

        if (fullKey.includes('filePath')) {
          try {
            const data = await fetchImageBlob(subValue);
            console.log('datta', data)
            formData.append(key, data);
          } catch (error) {
            console.error(`Error processing image for ${fullKey}:`, error);
            value[subKey] = null;
          }
        }

        await appendFormData(formData, fullKey, value[subKey]);
      }
    } else {
      formData.append(key, value);
    }
  };

  const fetchImageBlob = async (filePath) => {
    console.log('Fetching image for file path:', filePath);
    const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/uploads/images/${filePath}`,
        {
          method: 'GET',
          headers: {
            'Accept': '*/*',
          },
        }
    );



    const blob = await response.blob();
    const fileName = filePath.split("/").pop(); // Extract file name from the path

    // Return the file object with proper mime type
    return new File([blob], fileName, { type: blob.type });
  };

  const processValues = async (formData, values) => {
    // Loop over values and process them without creating a new FormData
    for (const key of Object.keys(values)) {
      const newKey = key?.startsWith("questions")
        ? key.replace("questions", "quiz[questions]")
        : key;
      await appendFormData(formData, newKey, values[key]);
    }
  };

  const showModal = () => {
    setIsVisible(true);
  };

  const onHandleSubmit = async (values) => {
    const formData = new FormData();
    await processValues(formData, values);

    for (const key of formData.keys()) {
      if (key.startsWith("classrooms[0]") || key.startsWith("teacher")) {
        formData.delete(key);
      }
    }

    formData.append("grade", classroom.grade);
    formData.append("subject", classroom.subject);
    if (!!params?.id) {
      formData.append("teacher",teacher || classroom?.teacher["@id"]);
      formData.append("classroom", classroom["@id"]);
      formData.append("setting[id]",dataF?.setting?.id );
      formData.append("setting[@id]",dataF?.setting['@id']);

      formData.delete("id");
      formData.append("id-real", values.id);
      setIdAssignement(values.id);
      const { data, error } = await triggerUpdateMcqCoursework({
        formData: formData,
      });
      if (data) {
        showModal();
        dispatch(
          setNotification({
            type: "success",
            message: intl.formatMessage(
              {
                id: "notification.edit.message.success",
              },
              {
                type: "Assignment",
              }
            ),
          })
        );
      }
      if (error) {
        dispatch(
          setNotification({
            type: "error",
            message: intl.formatMessage(
              {
                id: "notification.edit.message.error",
              },
              {
                type: "Assignment",
              }
            ),
          })
        );
      }
    } else {
      formData.append("teacher",teacher || classroom?.teacher["@id"]);
      formData.append("classroom", classroom["@id"]);

      const { data, error } = await triggerCreateMcqCoursework({
        formData: formData,
      });

      if (data) {
        setIdAssignement(data.id);
        dispatch(
          setNotification({
            type: "success",
            message: intl.formatMessage(
              {
                id: "notification.create.message.success",
              },
              {
                type: "Assignment",
              }
            ),
          })
        );
        showModal();
      }
      if (error) {
        dispatch(
          setNotification({
            type: "error",
            message: intl.formatMessage(
              {
                id: "notification.create.message.error",
              },
              {
                type: "Assignment",
              }
            ),
          })
        );
      }
    }
  };

  if (isLoadingAssignments) {
    return <CustomSpin />;
  }

  return (
      <div className={`${styles.newAssignmentPage}`}>
        <div className={styles.newAssignmentContainer}>
          <Formik
            initialValues={initialValues}
            validationSchema={isDraft? draftValidationSchema : validationSchema}
            onSubmit={onHandleSubmit}
            enableReinitialize
            validateOnBlur={false}
            validateOnChange={false}
          >
            {(formikProps) => (

              <Form className={styles.newAssignmentForm}>
                <Tabs
                  tabBarExtraContent={{
                    left: !!params?.id ? (
                        <h1 className={styles.pageTitle}>
                          {intl.formatMessage({
                            id: "assignment.edit.form.title",
                          })}
                        </h1>
                    ) : (
                        <h1 className={styles.pageTitle}>
                          {intl.formatMessage({
                            id: "assignment.create.form.title",
                          })}
                        </h1>
                    ),
                    right: (
                        <Radio.Group
                            value={formikProps?.values?.isPracticeMode}
                            onChange={(e) => {
                              formikProps?.setFieldValue(
                                "isPracticeMode",
                                e.target.value,
                              );
                              formikProps?.setFieldValue(
                                "setting.hasAttempts",
                                false,
                              );
                              formikProps?.setFieldValue("setting.attempts", 1);
                            }
                            }
                            buttonStyle="solid"
                            className={styles.radioGroup}
                        >
                            <Radio.Button value={false}>
                        <span className="flex align-center gap-4">
                          <ClassModeIcon />
                            {intl.formatMessage({
                                id: "assignment.create.form.setting.practice.mode.unchecked",
                            })}
                        </span>
                            </Radio.Button>
                            <Radio.Button value={true}>
                        <span className="flex align-center gap-4">
                          <PracticeModeIcon />
                            {intl.formatMessage({
                                id: "assignment.create.form.setting.practice.mode.checked",
                            })}
                        </span>
                            </Radio.Button>
                        </Radio.Group>
                    ),
                  }}
                  defaultActiveKey="assignment"
                  items={[
                    {
                      key: "assignment",
                      label: "Assignment",
                      children: (
                        <CreateAssignment
                          isEditing={!!params?.id}
                          setIsDraft={setIsDraft}
                          {...formikProps}
                        />
                      ),
                    },
                    {
                      key: "settings",
                      label: "Settings",
                      children: <AssignmentSettings {...formikProps} isEditing={!!params?.id} />,
                    },
                  ]}
                  className={styles.assignmentTabs}
                  type="card"
                  animated={false}
                />

              <div className={styles.blockBtn}>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setDataPreview(formikProps?.values);
                    setModalPreview(true);
                  }}
                  className={styles.draftButton}
                  disabled={formikProps?.isSubmitting}
                >
                  {intl.formatMessage({ id: "button.preview" })}
                </button>
                <button
                  type="submit"
                  className={styles.submitButton}
                  disabled={formikProps?.isSubmitting}
                >
                  {intl.formatMessage({ id: "button.publish" })}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <Modal
        visible={visible}
        footer={null}
        closable={false}
        centered
        className={styles.modal}
        onCancel={handleCancel}
      >
        <div className={styles.modalContent}>
          <div>
            <div className={styles.iconWrapper}>
              <CheckCircleOutlined className={styles.icon} />
            </div>
          </div>
          <div>
            <div className={styles.title}>
              {intl.formatMessage({ id: "assignment.modal.preview.success" })}
            </div>
            <div className={styles.message}>
              {intl.formatMessage({
                id: "assignment.modal.preview.success.text",
              })}
            </div>
          </div>
        </div>

        <div className={styles.footer}>
          <button
            className={styles.lib}
            onClick={() => {
              navigate(`/coursework`);
            }}
          >
            {intl.formatMessage({
              id: "assignment.modal.preview.link.library",
            })}
          </button>
          &nbsp;|&nbsp;{" "}
          <button
            onClick={() => {
              navigate(`/assignment/preview/${idAssignement}`);
            }}
          >
            {intl.formatMessage({
              id: "assignment.modal.preview.button.preview",
            })}
          </button>
        </div>
      </Modal>
      <ModalPreviewAssignment
        visible={modalPreview}
        className={styles.modal}
        onCancel={() => setModalPreview(false)}
        data={dataPreview}
      />
    </div>
  );
};

export default NewAssignment;