import { useState } from "react";
import { IconUser } from "../../assets/icons"; 
import styles from "./TagInput.module.scss";
import { useIntl } from "react-intl";

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email validation regex

const TagInput = ({ value = [], onChange,className="", placeholder = "Add email...", error }) => {
  const [inputValue, setInputValue] = useState("");
  const [localError, setLocalError] = useState("");

  const {formatMessage} = useIntl();

  const handleKeyDown = (e) => {
    const isEnterOrTab = e.key === 'Enter' || e.key === 'Tab';
    const isCommaOrSemicolon = e.key === ',' || e.key === ';';
  
    if ((isEnterOrTab || isCommaOrSemicolon) && inputValue.trim() !== "") {
      e.preventDefault();
      
      const trimmedEmail = inputValue.trim();
  
      if (!emailRegex.test(trimmedEmail)) {
        setLocalError(formatMessage({id:"tagInput.invalid_email_format"}));
        return;
      }
  
      if (value.includes(trimmedEmail)) {
        setLocalError(formatMessage({id:"tagInput.email_already_added"}));
        return;
      }
  
      setLocalError("");
  
      onChange([...value, trimmedEmail]);
      setInputValue("");
    }
  };

  const removeTag = (index) => {
    onChange(value.filter((_, i) => i !== index));
  };

  return (
    <div className={`${styles.tagInputContainer} ${className}`}>
      <div className={`${styles.tagInput} ${error || localError ? styles.error : ""}`}>
        {value.map((tag, index) => (
          <div key={index} className={styles.tag}>
            <IconUser className={styles.userIcon} />
            {tag}
            <button onClick={() => removeTag(index)}>x</button>
          </div>
        ))}
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
        />
      </div>
      {(error || localError) && <p className={styles.errorText}>{error || localError}</p>}
    </div>
  );
};

export default TagInput;
