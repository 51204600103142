
import { api } from './api';

export const OrganisationApi = api.injectEndpoints({
    endpoints: (builder) => ({
        createOrganisation: builder.mutation({
            query: (data) => ({
                url: 'api/organisations',
                method: 'POST',
                body: JSON.stringify(data),
                headers: { 'Content-Type': 'application/json' },
            }),
            invalidatesTags: [
                { type: 'Auth', id: 'ME' },
            ],
        }),

        inviteTeacherToOrganisation: builder.mutation({
            query: (data) => ({
                url: `/api/invite-teachers`,
                method: 'POST',
                body: JSON.stringify(data),
                headers: {'Content-Type': 'application/json'},
            }),
            invalidatesTags:(result) =>
                result === undefined
                    ? []
                    : [
                        { type: 'Teachers', id: 'LIST' },
                        { type: 'ClassroomsFilter', id: 'LIST' },
                        { type: 'Auth', id: 'ME' },
                        { type: 'Auth', id: result ? result.id : '' }
                    ],
        }),
    }),
});
export const {
    useCreateOrganisationMutation,
    useInviteTeacherToOrganisationMutation,
} = OrganisationApi;