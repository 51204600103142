import React from "react";
import { IconEmail, IconSearch } from "../../assets/icons";
import styles from "./input.module.scss";

const TYPE = {
  TEXT: "text",
  PASSWORD: "password",
  EMAIL: "email",
  NUMBER: "number",
  SEARCH: "search",
};

const VARIANT = {
  DEFAULT: "default",
  PRIMARY: "primary",
};

const SIZE = {
  SM: "sm",
  MD: "md",
  LG: "lg",
};

const Input = ({
  label,
  error,
  hint,
  variant = VARIANT.DEFAULT,
  type = TYPE.TEXT,
  size = SIZE.MD,
  value,
  onChange,
  placeholder,
  className = "",
  prefix,
  suffix,
  required,
  ...props
}) => {
  return (
    <div
      className={`${styles.wrapper} ${styles[variant]} ${styles[size]} ${className}`}
    >
      {label && (
        <label className={styles.label}>
          {label} {required && <span className={styles.required}>*</span>}
        </label>
      )}
      <div className={`${styles.container} ${error ? styles.error : ""}`}>
        {(type === TYPE.EMAIL || type === TYPE.SEARCH || prefix) && (
          <div className={styles.prefix}>
            {prefix}
            {!prefix && type === TYPE.EMAIL && <IconEmail />}
            {!prefix && type === TYPE.SEARCH && <IconSearch />}
          </div>
        )}
        <input
          type={type}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className={`${styles.field} ${
            type === TYPE.EMAIL || type === TYPE.SEARCH || prefix
              ? styles.withPrefix
              : ""
          } ${suffix ? styles.withSuffix : ""}`}
          {...props}
        />
        {suffix && <div className={styles.suffix}>{suffix}</div>}
      </div>
      {error && <span className={styles.error}>{error}</span>}
      {hint && <span className={styles.hint}>{hint}</span>}
    </div>
  );
};

export { Input as default, TYPE, VARIANT, SIZE };
