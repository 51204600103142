import React, { useEffect, useMemo, useState } from 'react'
import { IconEmail, IconTrash, IconUser } from '../../assets/icons'
import Select from '../../Components/Select'
import * as Yup from 'yup'
import { Formik } from 'formik'
import Button from '../../Components/Button'
import Table from '../../Components/table/Table'
import { useIntl } from 'react-intl'
import MiniTable from '../../Components/table/MiniTable'
import Budge, { VARIANT, SIZE } from '../../Components/Badge'
import Swal from 'sweetalert2'
import useDebounce from '../../hooks/useDebounce'
import { useLazyGetTeachersByOrganisationQuery } from '../../services/teacher.api'
import { useDeleteAdminMutation, useLazyGetAdminsQuery } from '../../services/admin.api'
import useGetOrganization from '../../hooks/useGetOrganization'
import { setNotification } from '../../redux/app/app.slice'
import { useDispatch } from 'react-redux'


const ListAdmins = () => {
    const { formatMessage } = useIntl()
    const [searchTerm, setSearchTerm] = useState('')
    const debouncedSearchTerm = useDebounce(searchTerm, 300)
    const [admins, setAdmins] = useState([])
    const dispatch = useDispatch()

    const [triggerAdmins, { data: dataAdmins, isLoading: isLoadingAdmins }] =
        useLazyGetAdminsQuery()

    const [deleteAdmin] = useDeleteAdminMutation();

    const handleDelete = (id) => {
        Swal.fire({
            icon: 'warning',
            title: formatMessage({
                id: 'popup.delete.confirmation.title',
            }),
            text: formatMessage(
                {
                    id: 'popup.form.delete.confirmation.text',
                },
                { item: formatMessage({ id: 'admin.organization.admin' }) },
            ),
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: formatMessage({
                id: 'popup.form.delete.confirmation.button.yes',
            }),
            confirmButtonColor: '#000095',
            cancelButtonText: formatMessage({
                id: 'popup.form.delete.confirmation.button.cancel',
            }),
        }).then((result) => {
            if (result.isConfirmed) {
                deleteAdmin({ id: id }).then((result) => {
                    if (result.data) {
                        dispatch(
                            setNotification({
                                type: 'success',
                                message: formatMessage({ id: 'remove.user.success' }),
                            }),
                        )
                    }
                })
            }
        })
    }

    const columns = [
        {
            accessor: 'admin',
            title: formatMessage({ id: 'super_admin.list_Teacher.table.header.admin' }),
            render: (record) => (
                <div className="record-teacher">
                    <IconUser size={40} />
                    <div className="record-teacher-info">
                        <p className="table-name text-14">
                            {record.firstName} {record.lastName}
                        </p>
                        <p className="table-email text-14 light">{record.email}</p>
                    </div>
                </div>
            ),
        },
        {
            accessor: 'state',
            title: formatMessage({ id: 'super_admin.list_Teacher.table.header.state' }),
            render: ({ state }) => (
                <Budge
                    size={SIZE.SM}
                    text={state ? formatMessage({ id: 'super_admin.list_Teacher.table.header.active' }) : formatMessage({ id: 'super_admin.list_Teacher.table.header.pending' })}
                    variant={state ? VARIANT.ACTIVE : VARIANT.PENDING}
                />
            ),
        },
        {
            accessor: 'organization',
            title: formatMessage({ id: 'super_admin.list_Teacher.table.header.organization' }),
            render: (record) => (
                <p className="table-email text-14 light">{record.organization}</p>
            ),
        },
        {
            accessor: 'class',
            title: formatMessage({ id: 'super_admin.list_Teacher.table.header.class' }),
            render: (record) => (
                <p className="table-email text-14 light">{record.class}</p>
            ),
        },
        {
            accessor: 'teacher',
            title: formatMessage({ id: 'super_admin.list_Teacher.table.header.teacher' }),
            render: (record) => (
                <p className="table-email text-14 light">{record.teacher}</p>
            ),
        },
        {
            accessor: 'student',
            title: formatMessage({ id: 'super_admin.list_Teacher.table.header.student' }),
            render: (record) => (
                <p className="table-email text-14 light">{record.student}</p>
            ),
        },
        {
            key: 'actions',
            title: '',
            dataIndex: 'actions',
            render: ({ id }) => (
                <div className="flex justify-end ">
                    <button className="btn-delete" onClick={() => handleDelete(id)}>
                        <IconTrash size={20} />
                    </button>
                </div>
            ),
        },
    ]

    const filteredData = useMemo(
        () => admins.filter((item) =>
            [item.firstName, item.lastName, item.email]
                .join(' ')
                .toLowerCase()
                .includes(debouncedSearchTerm.toLowerCase()),
        ),
        [admins, debouncedSearchTerm],
    )

    useEffect(() => {
        triggerAdmins()
    }, [])

    useEffect(() => {

        if (dataAdmins) {
            const admins = []
            dataAdmins['hydra:member'].map((admin) => {
                const classrooms = admin.myOrganisation?.classrooms ?? []

                const students = classrooms.reduce((acc, classroom) => {
                    const allStudents = classroom.students
                    allStudents.forEach(student => {
                        if (!acc.find(s => s.email === student.email)) {
                            acc.push(student)
                        }
                    })
                    return acc
                }, [])

                const studentsCount = students?.length ?? 0;
                const classesCount = classrooms?.length ?? 0;
                const teachersCount = admin.myOrganisation?.teachers?.length ?? 0;

                admins.push({
                    id: admin.id,
                    email: admin.email,
                    firstName: admin.first_name,
                    lastName: admin.family_name,
                    state: admin.confirmed,
                    organization: admin.myOrganisation?.name,
                    class: classesCount,
                    teacher: teachersCount,
                    student: studentsCount,
                });
            })

            setAdmins(admins);
        }


    }, [dataAdmins])


    const isLoading = false


    return (
        <div className="section">
            <div className="right-side">
                <MiniTable
                    activeSearch
                    activeFilter={false}
                    searchTerm={searchTerm}
                    onChangeSearchTerm={setSearchTerm}
                    columns={columns}
                    data={filteredData}
                    isLoading={isLoading}
                />
            </div>
        </div>
    )
}

export default ListAdmins